import { createIntlCache, createIntl } from 'react-intl';
import { makeRemoteGetAllClinicalDocs } from '~/main/factories/usecases/clinicalDocs/GetAllClinicalDocsFactory';
import { makeRemoteGetClinicalDocs } from '~/main/factories/usecases/clinicalDocs/GetClinicalDocsFactory';
import { makeRemoteGetOneConsultant } from '~/main/factories/usecases/consultant/GetOneConsultant';
import { ClinicalDocDetails, iClinicalDocs, iUser } from '../interface';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

export const MapConsultantDetails = async (
  consultant: number,
): Promise<ClinicalDocDetails> => {
  const patient = await makeRemoteGetClinicalDocs().get({
    docId: consultant,
  });

  if (patient?.patientExternal?.fullname)
    return {
      user: {
        name: patient.patientExternal.fullname,
        age: `${patient.patientExternal.age} anos`,
        contact: '-',
        address: '-',
        email: '-',
      },
    };
  const detail = await makeRemoteGetOneConsultant().getOne({
    id: patient.patient.id,
  });

  function countMonths() {
    const item = detail.user.birthdate?.split('T')[0];
    const today = Date.now();
    const birthDate = new Date(item);

    const diffTime = Math.abs(today - Number(birthDate));
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    const age = Math.floor(diffDays / 365);
    let months = ((diffDays / 365) % 1) * 12;
    months = Math.trunc(months);

    return `${age} ${intl.formatMessage({
      id: 'anos e',
    })}  ${months} ${intl.formatMessage({ id: 'meses' })}`;
  }

  const user: iUser = {
    id: Number(detail.consultant),
    name: `${detail.user.firstName} ${detail.user.lastName}`,
    src: patient.patient.avatar,
    age: countMonths(),
    contact: detail.user.phone,
    address: `${detail.address?.street}, ${detail.address?.neighborhood}, ${detail.address?.number} ${detail.address?.city}, ${detail.address?.uf}`,
    email: detail.user.email,
    user: detail.user.id,
  };

  return { user };
};

export const MapClinicalDocuments = async (
  participant: number,
): Promise<iClinicalDocs[]> => {
  const docs = await makeRemoteGetAllClinicalDocs().getAll({
    filter: {
      patient: participant,
      enabled: true,
    },
  });

  const arr: iClinicalDocs[] = [];

  docs.results.forEach(item => {
    arr.push({
      document: {
        sent: item.document.sent,
      },
      type: item.type,
      signed: item.document.status === 'SIGNED',
      index: item.index.index,
      content: item.document.content, // TODO: Atualizar content
    });
  });

  return arr;
};
