import styled, { css } from 'styled-components';

interface ContainerProps {
  width: string;
  gridArea?: string;
}

interface InputContainerProps {
  height: string;
  focus: boolean;
  disabled: boolean | undefined;
  error: boolean;
}

interface MessageProps {
  error: boolean;
}

export const Container = styled.div<ContainerProps>`
  width: ${props => props.width};
  grid-area: ${props => props.gridArea};
  font-family: 'Roboto', Oxygen, Ubuntu, Cantarell, 'Open Sans',
    'Helvetica Neue', sans-serif;
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;

  /* border-radius: 4px; */
  font-weight: lighter;
  padding-left: 16px;
  /* border: 1px solid #bfc4ca;
  background: #ffffff; */
`;

export const Label = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #444a51;
  margin-bottom: 4px;
`;

export const InputContainer = styled.span<InputContainerProps>`

.react-tel-input .form-control {
  /* display: flex;
  align-items: center;
  justify-content: space-around;
  height: ${props => props.height}; */
  border: 1px solid;
  border-radius: 4px;
  border-color: ${props => (props.focus ? '#106bef' : '#bfc4ca')};
}

/* .react-tel-input .form-control:focus {
    background-color: #fff;
    border-color: #106bef;
    outline: 0;
    box-shadow: none;
} */
.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #106bef;
    outline: 0;
    box-shadow: none;
}

.react-tel-input .selected-flag:focus {
    color: #495057;
    background-color: #fff;
    border-color: #106bef;
    outline: 0;
    box-shadow: none;
}
  /* display: flex;
  align-items: center;
  justify-content: space-around;
  height: ${props => props.height}; */
  /* border: 1px solid;
  border-radius: 4px;
  border-color: ${props => (props.focus ? '#106bef' : '#bfc4ca')}; */

  /* input {
    font-family: inherit;
    border-radius: 4px;
    width: 100%;
    height: 100%;

    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #444a51;
    padding-left: 16px;

    ::placeholder {
      color: #bfc4ca;
    }

    :focus {
      #spanfocus {
        border-color: #106bef;
      }
    }
  } */

  /* .icon {
    margin-right: 13px;
  } */

  /* :hover {
    border-color: #106bef;
  } */

  /* ${({ disabled }) =>
    disabled &&
    css`
      background-color: #fafafb;

      :hover {
        border-color: #bfc4ca;
      }
    `}

  ${({ error }) =>
    error &&
    css`
      border-color: #ea3e4f;

      :hover {
        border-color: #ea3e4f;
      }
    `} */
`;

export const Message = styled.span<MessageProps>`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #7b8591;

  ${({ error }) =>
    error &&
    css`
      color: #ea3e4f;
    `}
`;
