import { iClinicalDocs } from '~/domain/interfaces/models/ClinicalDocs';
import { ClinicalDocsTypes } from '~/domain/interfaces/redux/clinicalDocs/types';
import { ClinicalDocsActions } from './actions';
import { AuthTypes } from '~/domain/interfaces/redux/auth/types';

export const initialState: iClinicalDocs = {
  loading: false,
  pageSize: 0,
  totalItems: 0,
  selected: 0,
  results: [],
  create: {},
};

const reducer = (
  state = initialState,
  action: ClinicalDocsActions,
): iClinicalDocs => {
  switch (action.type) {
    case ClinicalDocsTypes.GETALL:
      return { ...state, loading: true };
    case ClinicalDocsTypes.GETALL_SUCCESS:
      return { ...state, ...action.payload, loading: false, selected: 0 };
    case ClinicalDocsTypes.GETALL_FAILED:
      return { ...state, loading: false, selected: 0 };

    case ClinicalDocsTypes.GETALL_BY_CODE:
      return { ...state, loading: true };
    case ClinicalDocsTypes.GETALL_BY_CODE_SUCCESS:
      return { ...state, ...action.payload, loading: false, selected: 0 };
    case ClinicalDocsTypes.GETALL_BY_CODE_FAILED:
      return { ...state, loading: false, selected: 0 };

    case ClinicalDocsTypes.MAKE_CLINICAL_DOC_CANCEL:
      return { ...state, create: {} };

    case ClinicalDocsTypes.CREATE:
      return { ...state, loading: true };
    case ClinicalDocsTypes.CREATE_SUCCESS:
      return { ...state, create: {}, loading: false };
    case ClinicalDocsTypes.CREATE_FAILED:
      return { ...state, loading: false };

    case ClinicalDocsTypes.SELECT_ONE:
      return { ...state, loading: true };
    case ClinicalDocsTypes.SELECT_ONE_SUCCESS:
      return { ...state, selected: action.payload, loading: false };

    case ClinicalDocsTypes.UPDATE:
      return { ...state, loading: true };
    case ClinicalDocsTypes.UPDATE_SUCCESS:
      return { ...state, loading: false };
    case ClinicalDocsTypes.UPDATE_FAILED:
      return { ...state, loading: false };

    case ClinicalDocsTypes.SIGN:
      return { ...state, loading: true };
    case ClinicalDocsTypes.SIGN_SUCCESS:
      return { ...state, loading: false };
    case ClinicalDocsTypes.SIGN_FAILED:
      return { ...state, loading: false };

    case ClinicalDocsTypes.INSERT_CLINICAL_DOC: {
      const newDoc = action.payload;
      const { results } = state;

      results.push({
        patient: newDoc.patient,
        professional: {
          firstName: 'Professional',
          lastName: 'Dev',
          id: 3,
        },
        document: {
          id: newDoc.id,
          token: newDoc.token,
          content: newDoc.content,
          sent: newDoc.sent,
          status: newDoc.status,
          origin: newDoc.origin,
        },
        appointment: newDoc.appointment,
        org: newDoc.org,
        orgUnit: newDoc.orgUnit,
        specialty: newDoc.specialty,
        type: newDoc.type,
      });

      return { ...state, results, totalItems: state.totalItems + 1 };
    }

    case AuthTypes.LOGOUT:
      return initialState;
    case ClinicalDocsTypes.MAKE_CLINICAL_DOC:
      if (action.payload.type === 1) {
        return {
          ...state,
          create: {
            ...state.create,
            ...action.payload,
            type: 1,
            content: action.payload.content,
          },
        };
      }
      if (action.payload.type === 2) {
        return {
          ...state,
          create: {
            ...state.create,
            ...action.payload,
            type: 2,
            content: action.payload.content,
          },
        };
      }
      if (action.payload.type === 3) {
        return {
          ...state,
          create: {
            ...state.create,
            ...action.payload,
            type: 3,
            content: action.payload.content,
          },
        };
      }

      return {
        ...state,
        create: {
          ...state.create,
          ...action.payload,
          type: undefined,
          content: undefined,
        },
      };
    default:
      return state;
  }
};

export default reducer;
