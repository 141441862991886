/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-curly-brace-presence */
import React, { useMemo, useState } from 'react';
import { iMessage } from '~/domain/interfaces/models/Message';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import { Button } from '~/presentation/components/UI';
import { ConsultAsset } from '~/presentation/base/icons';

import { translator } from '../i18n'; // TODO: check this component translation

import {
  Body,
  ConsultInfoContainer,
  ConsultInfoMessage,
  Container,
  ContainerModal,
  Footer,
  Header,
  ScrollContainer,
  TextBold,
  TextBoldTitle,
  TextNormal,
} from './styles/styles';
import {
  CheckBoxInput,
  FooterContent,
  TextNormalBlackFour,
} from '../cardWaitingRoomDetails/styles';

interface propsModalAcceptTermsWaitingRoom {
  message: iMessage;
}

const ModalAcceptTermsWaitingRoom: React.FC<propsModalAcceptTermsWaitingRoom> =
  ({ message }) => {
    const { active, actionOk, actionCancel, data } = message;

    const [acceptTerms, setAcceptTerms] = useState<boolean>(false);
    const [acceptRecording, setAcceptRecording] = useState<boolean>(false);

    const msgName = MessageOptions.acceptTermsWaitingRoom;

    const acceptTermsWaitingRoom = data?.acceptTermsWaitingRoom;

    return (
      <>
        {msgName === active && (
          <ContainerModal>
            <Container>
              <Header>
                <ConsultAsset />
              </Header>
              <Body>
                <ConsultInfoMessage>
                  <TextBoldTitle>
                    {translator('Estamos preparando o seu atendimento')}
                  </TextBoldTitle>
                  <TextNormal>
                    {translator('Sua consulta com o médico')}{' '}
                    {acceptTermsWaitingRoom?.professionalName}{' '}
                    {translator(
                      'começará em breve. Antes de ir para a sala de espera, você precisa declarar:',
                    )}
                  </TextNormal>
                </ConsultInfoMessage>
                <FooterContent>
                  <div>
                    <CheckBoxInput
                      type="checkbox"
                      checked={acceptTerms}
                      onChange={e => {
                        setAcceptTerms(e.target.checked);
                      }}
                    />
                  </div>
                  <TextNormalBlackFour>
                    {translator('Declaro que li e estou de acordo com os')}
                    <a
                      href="https://www.colgatepalmolive.com.mx/legal-privacy-policy"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {` ${translator('Termos de Serviço')} `}
                    </a>
                    <span>{translator('e')}</span>
                    <a
                      href="https://www.colgatepalmolive.com.mx/legal-privacy-policy"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {` ${translator('Políticas de Privacidade')}`}
                    </a>
                  </TextNormalBlackFour>
                </FooterContent>
                <FooterContent>
                  <div>
                    <CheckBoxInput
                      type="checkbox"
                      checked={acceptRecording}
                      onChange={e => {
                        setAcceptRecording(e.target.checked);
                      }}
                    />
                  </div>
                  <TextNormalBlackFour>
                    {translator(
                      'Estou ciente de que esta consulta poderá ser gravada.',
                    )}
                  </TextNormalBlackFour>
                </FooterContent>
              </Body>
              <Footer>
                <Button
                  variant="primary"
                  size="bigger"
                  onClick={actionOk}
                  disabled={!acceptTerms || !acceptRecording}
                >
                  {translator('Entrar na sala de espera')}
                </Button>
                <Button
                  variant="secundary"
                  size="bigger"
                  onClick={actionCancel}
                >
                  {translator('Voltar')}
                </Button>
              </Footer>
            </Container>
          </ContainerModal>
        )}
      </>
    );
  };

export default ModalAcceptTermsWaitingRoom;
