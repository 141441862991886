import React, { useEffect, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router';
import { iProfessional } from '~/domain/interfaces/models/Professional';
import { translator } from '~/presentation/components/i18n';
import InputKeyboardDate from '~/presentation/components/inputKeyboardDate';
import { Input, Select } from '~/presentation/components/UI';
import { schemaGeneralData } from '~/validation/validators/document/CreateClinicalDocSideBarValidator';
import { iListConsultant, iValidation } from '../interface';
import Navigator from '../Navigator';
import { Container, Form } from '../styles/StyledGeneralData';

export interface GeneralDataProps {
  consultant?: iListConsultant[];
  professional: iProfessional;
  next: (data: iValidation) => void;
  state?: iValidation;
}

interface iStateParams {
  appointmentId: string;
  professionalId: number;
  consultantId: number;
}

const GeneralData: React.FC<GeneralDataProps> = ({
  professional,
  next,
  state,
}): JSX.Element => {
  const stateParams = useLocation<iStateParams>().state;
  const [professionalId, setProfId] = useState<number | undefined>(
    stateParams.professionalId,
  );

  const { errors, handleSubmit, register, setValue } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    resolver: zodResolver(schemaGeneralData),
    defaultValues: {
      ...state,
      orgUnit: 1,
      org: 1,
    },
  });

  register('orgUnit');

  const onSubmit = handleSubmit(data => {
    next({ ...data });
  });

  useEffect(() => {
    register('professional');
    if (stateParams.professionalId) {
      setProfId(stateParams.professionalId);
      setValue('professional', stateParams.professionalId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateParams]);

  return (
    <Container>
      <Form onSubmit={onSubmit}>
        <Input
          name="org"
          label={`${translator('Instituição')}`}
          onChange={e => setValue('org', Number(e.target.value))}
          register={() => register('org')}
          error={Boolean(errors.org)}
          message={errors.org?.message}
          placeholder={state?.orgUnitName}
          disabled={state && state.orgUnitName !== ''}
          autoFocus
          required
        />

        <Select
          name="professional"
          defaultValue={state?.professional}
          label={`${translator('Profissional')}`}
          onChange={e => setValue('professional', Number(e.target.value))}
          register={() => register('professional')}
          value={professionalId}
          error={Boolean(errors.professional)}
          message={
            errors.professional?.message
              ? translator(errors.professional?.message)
              : ''
          }
          disabled={stateParams && Number(stateParams.professionalId) !== -1}
          required
        >
          <option value={-1}>{translator('Selecione um profissional')}</option>
          {professional.results.map(user => (
            <option value={Number(user.professional.id)}>
              {`${user.user.firstName} ${user.user.lastName}`}
            </option>
          ))}
        </Select>

        <InputKeyboardDate
          state={new Date()}
          setState={() => ''}
          label={`${translator('Data de emissão')}`}
          placeholder="DD/MM/AAAA"
          disabled
        />
        <div style={{ marginTop: '200px' }}>
          <Navigator />
        </div>
      </Form>
    </Container>
  );
};

export default GeneralData;
