import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import InputMask from 'react-input-mask';
import { Input, Select, Button } from '~/presentation/components/UI';
import {
  ContainerButtons,
  ContainerForm,
  FormItem,
  Container,
  Options,
} from './styles/StyledFilterAdministrator';

import { translator } from '~/presentation/components/i18n';
import { iStore } from '~/domain/interfaces/models';
import { makeReduxGetAllAdministrator } from '~/main/factories/usecases/administrator/GetAllAdministratorFactory';

const FilterAdministrator: React.FC = () => {
  const [administrator, setAdministrator] = useState('');
  const [identificateCpf, setIdentificateCpf] = useState<string>('');
  const [status, setStatus] = useState<string>('DEFAULT');

  const { selectUser } = useSelector((store: iStore) => store.auth);

  const handleFilter = () => {
    makeReduxGetAllAdministrator().getAll({
      pageSize: 9999,
      filter: {
        org: selectUser?.orgId,
        cpf: identificateCpf,
        name: administrator,
        status: status === 'DEFAULT' ? undefined : status,
        situation: ['ACTIVE', '2FAWAIT'],
      },
    });

    document.getElementById('close_filter')?.click();
  };

  const resetFilter = () => {
    setAdministrator('');
    setIdentificateCpf('');
    setStatus('DEFAULT');

    makeReduxGetAllAdministrator().getAll({
      pageSize: 9999,
      filter: {
        org: selectUser?.orgId,
        situation: ['ACTIVE', '2FAWAIT'],
      },
    });
  };

  return (
    <Container>
      <ContainerForm>
        <FormItem>
          <p>{`${translator('Nome')}`}</p>
          <Input
            id="input_administrator"
            value={administrator}
            onChange={e => setAdministrator(e.target.value)}
            placeholder={`${translator('Digite o nome do administrador')}`}
            className="hover-input"
          />
        </FormItem>
        {/* <FormItem>
          <p>{`${translator('Data de inclusão')}`}</p>
          <InputKeyBoardDate
            state={new Date()}
            setState={() => ''}
            placeholder={`${translator('Data')}`}
          />
        </FormItem> */}
        <FormItem>
          <p>{`${translator('CPF')}`}</p>
          <InputMask
            mask="999.999.999-99"
            onChange={e =>
              setIdentificateCpf(e.target.value.replace(/[^\d]+/g, '').trim())
            }
            value={identificateCpf}
          >
            <Input
              id="input_cpf"
              name="cpf"
              placeholder={`${translator('Digite o CPF do administrador')}`}
              className="hover-input"
            />
          </InputMask>
        </FormItem>
        <FormItem>
          <p>Status</p>
          <Select
            id="select_status"
            value={status}
            name="status"
            onChange={e => setStatus(e.target.value)}
            placeholder="Selecione"
          >
            <Options value="DEFAULT">Selecione</Options>
            <Options value="ONLINE">Online</Options>
            <Options value="OFFLINE">Offline</Options>
          </Select>
        </FormItem>
      </ContainerForm>
      <ContainerButtons>
        <Button
          id="btn_reset"
          onClick={() => resetFilter()}
          variant="secundary"
          size="medium"
          rounded
        >
          {`${translator('Redefinir')}`}
        </Button>
        <Button
          id="btn_search"
          onClick={() => handleFilter()}
          variant="primary"
          size="medium"
          autoFocus
          rounded
        >
          {`${translator('Pesquisar')}`}
        </Button>
      </ContainerButtons>
    </Container>
  );
};

export default FilterAdministrator;
