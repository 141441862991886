import React, { ComponentType } from 'react';
import { useSelector } from 'react-redux';
import * as datefns from 'date-fns';
import { compareAsc } from 'date-fns';
import { createIntlCache, createIntl } from 'react-intl';
import { iListRegisteredAppointment } from '../interface';
import {
  iAppointment,
  iAppointmentTypeState,
  iStore,
} from '~/domain/interfaces/models';
import { ownProps } from '../ListRegisteredAppointment';

import { getLocale } from '~/utils/getLocale';
import translations from '~/infra/i18n/locales';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

/**
 * Mapping of the appointment list data.
 *
 * @param Component component to connect.
 */
export function ConnectComponent<P>(
  Component: ComponentType<P & ownProps>,
): React.FC<P> {
  const Render: React.FC<P> = ({ ...rest }) => {
    const appointment = useSelector((store: iStore) => store.appointment);
    const { data: appointmentTypes } = useSelector(
      (store: iStore) => store.appointmentType,
    );

    /* eslint no-param-reassign: "error" */
    Component.defaultProps = {
      data: MapAppointmentData(appointment, appointmentTypes),
      ...(rest as P),
    };

    return <Component {...(rest as P & ownProps)} />;
  };

  return Render;
}

/**
 * component data mapper
 * @param appointment data.
 */
function makeTime(item: string, duration: number) {
  const time = new Date(item);
  const timeStart = new Date(time.getTime()).toLocaleTimeString('pt-BR', {
    hour12: false,
  });
  const timeEnd = new Date(
    time.getTime() + duration * 60000,
  ).toLocaleTimeString('pt-BR', { hour12: false });

  const startFormat = `${timeStart.split(':')[0]}:${timeStart.split(':')[1]}`;
  const endFormat = `${timeEnd.split(':')[0]}:${timeEnd.split(':')[1]}`;
  return { startFormat, endFormat };
}

const MapAppointmentData = (
  appointment: iAppointment,
  appointmentTypes: iAppointmentTypeState['data'],
): iListRegisteredAppointment[] => {
  const arr: iListRegisteredAppointment[] = [];
  const selectedDate = datefns.format(
    appointment.date instanceof Date
      ? appointment.date
      : new Date(appointment.date),
    'yyyy-MM-dd',
  );
  const appointments = appointment.resultsMap[selectedDate];

  if (appointments !== undefined)
    appointments?.forEach(item => {
      const date = item.appointment.scheduled;
      const { duration } = item.appointment;

      const typeIs = appointmentTypes.records?.find(
        type => type.id === item.appointment.type,
      );

      arr.push({
        participant: `${item.consultant?.firstName} ${item.consultant?.lastName}`,
        professional: `${item.professional?.firstName} ${item.professional?.lastName}`,
        srcParticipant: item.consultant?.avatar ? item.consultant?.avatar : '',
        srcProfessional: item.professional?.avatar
          ? item.professional?.avatar
          : '',
        specialty: item.specialty.name,
        timeEnd: makeTime(date, duration).endFormat,
        timeStart: makeTime(date, duration).startFormat,
        typeIs: typeIs?.msgKey
          ? intl.formatMessage({ id: typeIs?.msgKey })
          : '-',
        status: item.appointment.status,
        id: item.appointment.id,
        professionalStatus: item?.professional?.status,
        participantStatus: item?.consultant?.status,
        professionalId: item?.professional?.id,
        consultantId: item?.consultant?.id,
        consultantUserId: item?.consultant?.user,
        title: item?.appointment?.title ?? '',
        allowTranscription: item.appointment?.allowTranscription ?? false,
        skipWaitingRoom: typeIs?.msgKey
          ? ['APPTYPE_INTERCONSULTATION', 'APPTYPE_CLINIC_DISCUSSION'].includes(
              typeIs?.msgKey,
            )
          : false,
      });
    });

  return arr;
};

/**
 * Compare the two dates and return 1 if the first date is after the second, -1 if the first date is before the second or 0 if dates are equal.
 * @param date1 first Date.
 * @param date2 second Date.
 * @return boolean
 */
const validDate = (date1: Date, date2: Date): boolean => {
  return (
    compareAsc(
      new Date(date1.getFullYear(), date1.getMonth(), date1.getDate()),
      new Date(date2.getFullYear(), date2.getMonth(), date2.getDate()),
    ) === 0
  );
};
