/* eslint-disable react/jsx-curly-brace-presence */
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { iMessage } from '~/domain/interfaces/models/Message';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import { Button } from '~/presentation/components/UI';
import { ConsultAsset } from '~/presentation/base/icons';

import { translator } from '../i18n'; // TODO: check this component translation

import {
  Body,
  ConsultInfoContainer,
  ConsultInfoMessage,
  Container,
  ContainerModal,
  Footer,
  Header,
  ScrollContainer,
  TextBold,
  TextBoldTitle,
  TextNormal,
} from './styles/styles';
import ConsultDetailFragment from './ConsultDetailFragment';
import { iStore } from '~/domain/interfaces/models';
import { iDutyRequesters } from '~/domain/interfaces/models/Duty';
import { makeRemoteRefuseAppointmentOnCall } from '~/main/factories/usecases/onCall/RefuseAppointmentOnCallFactory';
import { makeReduxSetSpecialistStatusOnCall } from '~/main/factories/usecases/onCall/SetSpecialistStatusOnCallFactory';

interface propsModalNewAvailableInterconsult {
  message: iMessage;
}

const ModalNewAvailableInterconsult: React.FC<propsModalNewAvailableInterconsult> =
  ({ message }) => {
    const [professionalName, setProfessionalName] = useState(
      'Manoel de Souza Lopes',
    );
    const [professionalSpecialty, setProfessionalSpecialty] =
      useState('Cardiologia');
    const [patient, setPatient] =
      useState<
        iDutyRequesters['dutyRequesters']['records'][0]['requester']['info']
      >();
    const { dutyRequesters, professionalOnDuty } = useSelector(
      (state: iStore) => state.duty,
    );

    const { active, actionOk, actionCancel, data } = message;

    useEffect(() => {
      if (data) {
        const requester = dutyRequesters?.records?.find(
          item => item.requester.id === data.requester,
        );

        const specialist = professionalOnDuty?.records?.find(
          item => item.list.specialist.id === data.specialist,
        );

        if (data.type === 'SPECIALIST' && requester) {
          setProfessionalName(requester.requester.name);
        } else if (data.type === 'REQUESTER' && specialist) {
          setProfessionalName(specialist.list.specialist.name);
        }

        setPatient(requester?.requester?.info);
        setProfessionalSpecialty(data.onCall?.descr);
      }
    }, [data, dutyRequesters?.records, professionalOnDuty?.records]);

    const handleCancel = () => {
      if (data?.type === 'SPECIALIST')
        makeReduxSetSpecialistStatusOnCall().setSpecialistStatus({
          available: false,
        });

      makeRemoteRefuseAppointmentOnCall().refuseAppointment({
        onCallId: Number(data?.onCall?.id),
        requesterId: Number(data?.requester),
        specialistId: Number(data?.specialist),
      });
      actionCancel?.();
    };

    const onSubmit = () => {
      actionOk?.();
    };

    const renderSubtitle = useMemo(() => {
      return data.type === 'SPECIALIST'
        ? `O profissional ${professionalName} solicitou 
    uma interconsulta no plantão de ${professionalSpecialty} para discutir o 
    caso do paciente abaixo`
        : `O profissional ${professionalName} aceitou sua solicitação para discutir o caso do paciente abaixo:`;
    }, [professionalName, professionalSpecialty, data]);

    const msgName = MessageOptions.newAvailableInterconsult;

    return (
      <>
        {msgName === active && (
          <ContainerModal>
            <Container>
              <Header>
                <ConsultAsset />
              </Header>
              <Body>
                <ConsultInfoMessage>
                  <TextBoldTitle>
                    {translator('Interconsulta disponível!')}
                  </TextBoldTitle>
                  <TextNormal>{renderSubtitle}</TextNormal>
                </ConsultInfoMessage>
                <ConsultInfoContainer>
                  <TextBold>{translator('Ficha do paciente')}</TextBold>
                  <ScrollContainer>
                    <ConsultDetailFragment
                      label="Nome"
                      content={patient?.name}
                    />
                    <ConsultDetailFragment
                      label="Sexo"
                      content={patient?.sex === 'M' ? 'Masculino' : 'Feminino'}
                    />
                    <ConsultDetailFragment
                      label="Idade"
                      content={`${patient?.age ?? ''}`}
                    />
                    <ConsultDetailFragment
                      label="Situação"
                      content={patient?.situation}
                    />
                    {patient?.susCard && (
                      <ConsultDetailFragment
                        label="Cartão SUS"
                        content={patient?.susCard}
                      />
                    )}
                  </ScrollContainer>
                </ConsultInfoContainer>
              </Body>
              <Footer>
                <Button variant="primary" size="bigger" onClick={onSubmit}>
                  {translator('Entrar na consulta')}
                </Button>
                <Button
                  variant="secundary"
                  size="bigger"
                  onClick={() => {
                    handleCancel();
                  }}
                >
                  {translator('Dispensar')}
                </Button>
              </Footer>
            </Container>
          </ContainerModal>
        )}
      </>
    );
  };

export default ModalNewAvailableInterconsult;
