export const columns = [
  {
    id: 'professional',
    label: 'Nome do profissional',
  },
  {
    id: 'org',
    label: 'Unidade de Saúde',
  },
  {
    id: 'locale',
    label: 'Localidade',
  },
  {
    id: 'status',
    label: 'Status',
  },
];
