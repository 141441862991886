import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { JoinSpecialistOnCall as UsecaseRemoteJoinSpecialistOnCall } from '~/domain/usecases/onCall/remote';
// import { JoinSpecialistOnCall as UsecaseReduxJoinSpecialistOnCall } from '~/domain/usecases/onCall/redux';

import { RemoteJoinSpecialistOnCall } from '~/data/repository/onCall';
// import { ReduxJoinSpecialistOnCall } from '~/data/store/reducer/onCall/usecases';

/**
 * send request via API.
 */
export const makeRemoteJoinSpecialistOnCall =
  (): UsecaseRemoteJoinSpecialistOnCall =>
    new RemoteJoinSpecialistOnCall(
      makeApiUrl('/oncall/{onCallId}/specialists/{specialistId}'),
      makeHttpClient(),
    );

/**
 * send request via REDUX.
 */
/* export const makeReduxJoinSpecialistOnCall =
  (): UsecaseReduxJoinSpecialistOnCall =>
    new ReduxJoinSpecialistOnCall(); */
