import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { lastDayOfMonth } from 'date-fns';
import { IconPdfWhite } from '~/presentation/base/icons';
import { Button, Select } from '~/presentation/components/UI';
import { ownProps } from './interface';
import {
  ContainerPayment,
  ContentHeaders,
  ContentStatusPayment,
  DownloadInvoice,
  HeaderLeftPayment,
  HeaderRightPayment,
  RightStatusPayment,
  StatusPayment,
} from './styles/StyledAppointmentReport';
import { translator } from '../i18n';
import HealthyHeartIcon from '~/presentation/base/icons/healthyHeartIcon';
import { iStore } from '~/domain/interfaces/models';
import { TablePagination } from '../tablePagination';
import { makeReduxDataPagination } from '~/main/factories/usecases/dataPagination/SetDataFactory';
import { makeRemoteAppointmentReportByOrgUnit } from '~/main/factories/usecases/report/GetAppointmentReportByOrgUnitFactory';
import OrgUnitAppointments from './Lists/orgUnitAppointments';
import SpecialtyAppointments from './Lists/specialtyAppointments';
import { makeRemoteAppointmentReportBySpecialty } from '~/main/factories/usecases/report/GetAppointmentReportBySpecialtyFactory';
import { makeRemoteAppointmentReportPdf } from '~/main/factories/usecases/report/GetAppointmentReportPdfFactory';
import { AlertMessage } from '../messages/AlertMessage';

const now = new Date();

const twelveMonthsBeforeToday = [
  {
    label: 'Setembro 2023',
    value: '2023-09-01T04:00:00.000Z',
  },
  {
    label: 'Agosto 2023',
    value: '2023-08-01T04:00:00.000Z',
  },
  {
    label: 'Julho 2023',
    value: '2023-07-01T04:00:00.000Z',
  },
  {
    label: 'Junho 2023',
    value: '2023-06-01T04:00:00.000Z',
  },
  {
    label: 'Maio 2023',
    value: '2023-05-01T04:00:00.000Z',
  },
  {
    label: 'Abril 2023',
    value: '2023-04-01T04:00:00.000Z',
  },
  {
    label: 'Março 2023',
    value: '2023-03-01T04:00:00.000Z',
  },
  {
    label: 'Fevereiro 2023',
    value: '2023-02-01T04:00:00.000Z',
  },
  {
    label: 'Janeiro 2023',
    value: '2023-01-01T04:00:00.000Z',
  },
  {
    label: 'Dezembro 2022',
    value: '2022-12-01T04:00:00.000Z',
  },
  {
    label: 'Novembro 2022',
    value: '2022-11-01T04:00:00.000Z',
  },
  {
    label: 'Outubro 2022',
    value: '2022-10-01T04:00:00.000Z',
  },
  {
    label: 'Setembro 2022',
    value: '2022-09-01T04:00:00.000Z',
  },
];

const AppointmentReport: React.FC<ownProps> = () => {
  const [appointments, setAppointments] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [state, setState] = useState(appointments);
  const [visualization, setVisualization] = useState<'orgUnit' | 'specialty'>(
    'specialty',
  );

  const [filters, setFilters] = useState<any>({
    dataControl: {
      limit: 9999,
      offset: 0,
      paging: false,
    },
    timestamp: {
      begin: '2003-01-01T04:00:00.000Z',
      end: lastDayOfMonth(now).toISOString(),
    },
  });

  const { orgId, role } = useSelector((store: iStore) => store.auth.selectUser);
  const { dataPagination } = useSelector(
    (store: iStore) => store.dataPagination,
  );

  const downloadPdf = useCallback(() => {
    makeRemoteAppointmentReportPdf()
      .download({
        ...filters,
        view: visualization === 'orgUnit' ? 'ORGUNIT' : 'SPECIALTY',
      })
      .then(res => {
        const blob = new Blob([res], { type: 'application/pdf' });
        const urlToPdf = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = urlToPdf;
        a.download = 'transcription.pdf';
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        AlertMessage({
          type: 'success',
          message: 'Relatório baixado com sucesso',
        });
      })
      .catch(err => {
        // toast.error('Não foi possível realizar o download da gravação');
      });
  }, [filters, visualization]);

  useEffect(() => {
    setLoading(true);

    if (visualization === 'orgUnit')
      makeRemoteAppointmentReportByOrgUnit()
        .get(filters)
        .then(response => {
          setAppointments(response.records);
        })
        .catch(error => {
          console.log('error', error);
        })
        .finally(() => {
          setLoading(false);
        });

    if (visualization === 'specialty')
      makeRemoteAppointmentReportBySpecialty()
        .get(filters)
        .then(response => {
          setAppointments(response.records);
        })
        .catch(error => {
          console.log('error', error);
        })
        .finally(() => {
          setLoading(false);
        });
  }, [filters, orgId, role, visualization]);

  useEffect(() => {
    if (appointments.length === 0) {
      makeReduxDataPagination().set([]);
    }

    setState(appointments);
  }, [appointments]);

  return (
    <ContainerPayment>
      <ContentHeaders>
        <HeaderRightPayment id="receiptsHeader">
          <HealthyHeartIcon />
          <text>Relatório de atendimentos</text>
        </HeaderRightPayment>
        <HeaderLeftPayment />
      </ContentHeaders>
      <ContentStatusPayment>
        <StatusPayment>
          <RightStatusPayment>
            <Select
              label="Mês"
              width="250px"
              onChange={e => {
                const month = e.target.value;

                setFilters({
                  ...filters,
                  timestamp: {
                    begin: month,
                    end:
                      month === '2003-01-01T04:00:00.000Z'
                        ? lastDayOfMonth(now).toISOString()
                        : lastDayOfMonth(new Date(month)).toISOString(),
                  },
                });
              }}
              value={filters.timestamp.begin}
            >
              <option value="2003-01-01T04:00:00.000Z">Todos</option>
              {twelveMonthsBeforeToday.map(month => (
                <option key={month.value} value={month.value}>
                  {month.label}
                </option>
              ))}
            </Select>
            <Select
              label="Visualização"
              width="250px"
              onChange={e => {
                setVisualization(e.target.value as 'orgUnit' | 'specialty');
              }}
              value={visualization}
            >
              <option value="specialty">Por Especialidade</option>
              <option value="orgUnit">Por Unidade de Saúde</option>
            </Select>
          </RightStatusPayment>
        </StatusPayment>
        <DownloadInvoice>
          <Button icon={IconPdfWhite} onClick={downloadPdf}>
            Gerar PDF
          </Button>
        </DownloadInvoice>
      </ContentStatusPayment>
      <div
        style={{
          width: 'fit-content',
          marginBottom: '24px',
        }}
      >
        {state.length > 0 && state !== undefined ? (
          <TablePagination data={state} itemPerPage={10} />
        ) : (
          <div />
        )}
      </div>

      {visualization === 'specialty' ? (
        <SpecialtyAppointments dataList={dataPagination} loading={loading} />
      ) : (
        <OrgUnitAppointments dataList={dataPagination} loading={loading} />
      )}
    </ContainerPayment>
  );
};

export default AppointmentReport;
