import React, { useEffect, useState } from 'react';
import {
  IconCardiology,
  IconColoProctology,
  IconGastro,
  IconNeurology,
  IconOdontology,
  IconOftamology,
  IconPedriatry,
  IconPneumology,
  IconPsiquiatry,
} from '~/presentation/base/icons';
import { HeaderSimples } from '~/presentation/components/header';
import { Menu } from '~/presentation/components/menu';
import SpecialtyCard from '~/presentation/components/specialtyCard';
import { Cards, Container } from './styles/StyledSelectSpecialty';
import { makeRemoteListOnCall } from '~/main/factories/usecases/duty/ListOnCall';
import { ListOnCall } from '~/domain/usecases/duty/remote';

const SelectSpecialtyPage: React.FC = () => {
  const [specialty, setSpecialty] = useState<ListOnCall.Model['records']>();

  const specialtyOptions = [
    {
      icon: <IconCardiology />,
      name: 'Cardiologia',
    },
    {
      icon: <IconColoProctology />,
      name: 'Coloproctologia',
    },
    {
      icon: <IconGastro />,
      name: 'Gastroenterologia',
    },
    {
      icon: <IconOdontology />,
      name: 'Odontologia',
    },
    {
      icon: <IconOftamology />,
      name: 'Oftalmologia',
    },
    {
      icon: <IconPedriatry />,
      name: 'Pediatria',
    },
    {
      icon: <IconPneumology />,
      name: 'Pneumologia',
    },
    {
      icon: <IconPsiquiatry />,
      name: 'Psiquiatria',
    },
    {
      icon: <IconNeurology />,
      name: 'Neurologia',
    },
  ];

  useEffect(() => {
    makeRemoteListOnCall()
      .get({})
      .then(res => {
        setSpecialty(res.records);
      })
      .catch(console.error);
  }, []);

  return (
    <Container>
      <Menu />
      <HeaderSimples title="Escolha uma especialidade" />
      <Cards>
        {specialty?.map(specialtyItem => (
          <SpecialtyCard
            key={specialtyItem?.id}
            icon={specialtyItem?.logo}
            name={specialtyItem?.descr}
            id={specialtyItem?.id}
          />
        ))}
      </Cards>
    </Container>
  );
};

export default SelectSpecialtyPage;
