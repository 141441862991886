/* eslint-disable array-callback-return */
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { iClinicalDocs } from '~/presentation/roles/professional/pages/appointment/interface';
import { translator } from '../i18n';

import {
  Container,
  StatusContainer,
  Signed,
  DocListContainer,
} from './styles/StyledSidebarDocumentList';

import {
  IconSignedDocuments,
  IconNotSignedDocument,
} from '~/presentation/base/icons';

import { Button } from '../UI';
import { SidebarDocumentItem } from '.';
import { makeReduxGetAllClinicalDocs } from '~/main/factories/usecases/clinicalDocs/GetAllClinicalDocsFactory';
import { iStore } from '~/domain/interfaces/models';
import { MedicalCertificate } from '../sidebar/clinicalDocs/selectedDocument/medicalCertificate';
import { ExamRequest } from '../sidebar/clinicalDocs/selectedDocument/examRequest';
import { SimplePrescription } from '../sidebar/clinicalDocs/selectedDocument/simplePrescription';
import { SidebarEmpty } from '../componentEmpty';
import { getLocale } from '~/utils/getLocale';
import { iGuestData } from '~/domain/interfaces/models/GuestData';
import { makeReduxGetAllClinicalDocsByCode } from '~/main/factories/usecases/clinicalDocs/GetAllClinicalDocsByCodeFactory';

interface DocumentsListProps {
  type?: string;
  docs?: iClinicalDocs[];
  openFunction?: Function;
  handleCreate: Function;
  documentOpened?: DocumentType;
  setDocumentOpened?: Function;
}

interface iStateParams {
  appointmentId: string;
  guestData?: iGuestData;
}

enum DocumentType {
  NONE = 'NONE',
  ATESTADO_MEDICO = 'MEDICALCERTIFICATE',
  RECEITA_SIMPLES = 'BASIC',
  REQUISIÇÃO_DE_EXAMES = 'EXAMREQUEST',
}

const SidebarDocumentsList: React.FC<DocumentsListProps> = ({
  openFunction,
  handleCreate,
  documentOpened,
  setDocumentOpened,
}) => {
  const [signedDocs, setSignedDocs] = useState<iClinicalDocs[]>([]);
  const [unsignedDocs, setUnsignedDocs] = useState<iClinicalDocs[]>([]);
  const [docId, setDocId] = useState<number>(-1);
  const { role } = useSelector((store: iStore) => store.auth.selectUser);
  const { results } = useSelector((store: iStore) => store.clinicalDocs);
  const { appointmentId, guestData } = useLocation<iStateParams>().state;
  const { access } = useSelector((store: iStore) => store.auth);

  const locale = getLocale();

  const typeDoc = {
    BASIC: 'Receita simples',
    MEDICALCERTIFICATE: 'Atestado Médico',
    EXAMREQUEST: 'Requisição de exames',
  };

  const isConnected = useMemo(() => {
    return !!access.token?.length;
  }, [access]);

  const loadDocs = useCallback(() => {
    const code = guestData?.guest?.identification?.code;

    if (code) {
      makeReduxGetAllClinicalDocsByCode().getAll({
        code,
        pageSize: 9999,
      });

      return;
    }

    makeReduxGetAllClinicalDocs().getAll({
      filter: {
        appointment: Number(appointmentId),
        enabled: true,
      },
    });
  }, [appointmentId, guestData]);

  const handleOpen = useCallback(
    (docType: DocumentType, id: number) => {
      if (setDocumentOpened) setDocumentOpened(docType);
      setDocId(id);
    },
    [setDocumentOpened, setDocId],
  );

  // TODO: REFRESH DOCS

  const handleDelete = useCallback(() => {
    loadDocs();
  }, [loadDocs]);

  const renderSignedDocs = useMemo(() => {
    return signedDocs.length ? (
      signedDocs?.map(item => {
        let content = '';

        switch (item.type.name) {
          case 'BASIC':
            content = `${item.content.drugs?.map(drug => drug.name)}`;
            break;
          case 'MEDICALCERTIFICATE':
            content = `${new Date(item.document.sent).toLocaleDateString(
              locale,
            )} - ${item.content.periodo}`;
            break;
          case 'EXAMREQUEST':
            content = `${item.content.solicitacao?.map(
              requested => requested.exam,
            )}`;
            break;
          default:
            break;
        }

        return (
          <SidebarDocumentItem
            open={() => handleOpen(item.type.name as DocumentType, item.index)}
            id={item.index}
            type="clinical-documents"
            isSigned
            title={translator(typeDoc[item.type.name])}
            subTitle={content}
            deleteDocument={handleDelete}
          />
        );
      })
    ) : (
      <SidebarEmpty
        message={translator(
          'Seus documentos clínicos (receitas, atestados etc) aparecerão aqui. No momento você não tem nenhum',
        )}
      />
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signedDocs, openFunction]);

  const renderUnsignedDocs = useMemo(() => {
    return unsignedDocs.length ? (
      unsignedDocs?.map(item => {
        let content = '';

        switch (item.type.name) {
          case 'BASIC':
            content = `${item.content.drugs?.map(drug => drug.name)}`;
            break;
          case 'MEDICALCERTIFICATE':
            content = `${new Date(item.document.sent).toLocaleDateString(
              locale,
            )} - ${item.content.periodo}`;
            break;
          case 'EXAMREQUEST':
            content = `${item.content.solicitacao?.map(
              requested => requested.exam,
            )}`;
            break;
          default:
            break;
        }

        return (
          <SidebarDocumentItem
            open={() => handleOpen(item.type.name as DocumentType, item.index)}
            id={item.index}
            type="clinical-documents"
            title={translator(typeDoc[item.type.name])}
            subTitle={content}
            deleteDocument={handleDelete}
          />
        );
      })
    ) : (
      <SidebarEmpty
        message={translator(
          'Seus documentos clínicos (receitas, atestados etc) aparecerão aqui. No momento você não tem nenhum',
        )}
      />
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unsignedDocs, openFunction]);

  const renderOpenedDoc = useMemo(() => {
    switch (documentOpened) {
      case DocumentType.ATESTADO_MEDICO:
        return <MedicalCertificate docId={docId} />;
      case DocumentType.RECEITA_SIMPLES:
        return <SimplePrescription docId={docId} />;
      case DocumentType.REQUISIÇÃO_DE_EXAMES:
        return <ExamRequest docId={docId} />;
      default:
        break;
    }

    return null;
  }, [docId, documentOpened]);

  useEffect(() => {
    loadDocs();
  }, [appointmentId, loadDocs]);

  useEffect(() => {
    const signDocs: iClinicalDocs[] = [];
    const unsignDocs: iClinicalDocs[] = [];

    results.map(item => {
      if (!item.document) return;
      if (Number(appointmentId) !== item?.appointment?.id) return;

      const object = {
        document: {
          sent: item.document.sent,
        },
        signed: item.document.status === 'SIGNED',
        index: item.document.id,
        content: item.document.content,
        type: item.type,
      };

      if (item.document.status === 'SIGNED') {
        signDocs.push(object as iClinicalDocs);
      } else {
        unsignDocs.push(object as iClinicalDocs);
      }
    });

    setSignedDocs(signDocs);
    setUnsignedDocs(unsignDocs);
  }, [results]);

  return (
    <Container isOpen={documentOpened === DocumentType.NONE}>
      {documentOpened === DocumentType.NONE ? (
        <>
          {/* TODO: Atualizar com Role ACL */}
          {role === 'PRO' && isConnected && (
            <Button
              size="bigger"
              type="button"
              style={{ fontSize: '16px' }}
              onClick={() => handleCreate()}
            >
              {translator('Gerar novo documento')}
            </Button>
          )}
          <StatusContainer>
            <Signed isSigned>
              <IconSignedDocuments />
              {translator('Assinado')}
            </Signed>
          </StatusContainer>
          <DocListContainer id="signed_doc_list">
            {renderSignedDocs}
          </DocListContainer>
          <StatusContainer>
            <Signed>
              <IconNotSignedDocument />
              {translator('Pendente')}
            </Signed>
          </StatusContainer>
          <DocListContainer id="unsigned_doc_list">
            {renderUnsignedDocs}
          </DocListContainer>
        </>
      ) : (
        renderOpenedDoc
      )}
    </Container>
  );
};

export default SidebarDocumentsList;
