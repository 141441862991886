import io, { Socket } from 'socket.io-client';
import {
  SocketClient,
  SocketRequest,
  SocketResponse,
} from '~/data/protocols/socket';

import { SocketEvents } from '~/domain/interfaces/socketEvents';

import { HandlerEvents } from './HandlerEvents';
import { ChatEvents } from './ChatEvents';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import { makeRemoteStartAppointmentOnCall } from '~/main/factories/usecases/onCall/StartAppointmentOnCallFactory';
import { makeRemoteJoinAppointmentOnCall } from '~/main/factories/usecases/onCall/JoinAppointmentOnCallFactory';
import { closeModal } from '~/utils/closeModal';
import { History } from '~/main/routes';
import { iStore } from '~/domain/interfaces/models';
import { makeReduxGetDuties } from '~/main/factories/usecases/duty/GetDuties';
import { makeReduxGetProfessionalOnDuty } from '~/main/factories/usecases/duty/GetProfessionalsOnDuty';
import storeDev from '~/data/store';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import { makeReduxSetSpecialistStatusOnCall } from '~/main/factories/usecases/onCall/SetSpecialistStatusOnCallFactory';

class SocketIO implements SocketClient {
  private static Instance: SocketIO;

  private static Socket: typeof Socket;

  public static getInstance(): SocketIO {
    if (!SocketIO.Instance) {
      SocketIO.Instance = new SocketIO();
    }

    return SocketIO.Instance;
  }

  public getSocket = (): typeof Socket => {
    return SocketIO.Socket;
  };

  /**
   * connect to the server.
   */
  public connect = (token: string): void => {
    console.log('>>> Connect token: ', token);
    SocketIO.Socket = io(window.config.connection.backendUrl, {
      query: {
        token,
      },
    });

    this.listening();
  };

  /**
   * Disconnect to the server.
   */
  public disconnect = (): void => {
    SocketIO.Socket.disconnect();
  };

  /**
   * Emit event.
   */
  public emit = (data: SocketRequest): Promise<SocketResponse> => {
    console.log('>>> Emit event socket: ', data);
    return new Promise((resolve, reject) => {
      SocketIO.Socket.emit(
        data.event,
        data.body,
        (err: any, dataResponse: any) => {
          console.log('error: ', err);
          console.log('data: ', dataResponse);
          if (dataResponse) resolve({ body: dataResponse });
          if (err) reject(err);
        },
      );
    });
  };

  private listening = (): void => {
    console.log('>>> Listening socket: ', SocketIO.Socket);

    SocketIO.Socket.on('notification', (data: SocketEvents) => {
      console.log('>>> Receive: ', data);

      HandlerEvents(data);
    });

    SocketIO.Socket.on('ONCALL_PROFESSIONAL_STATUS', (data: any) => {
      console.log('>>> ONCALL_PROFESSIONAL_STATUS: ', data);
      makeReduxGetProfessionalOnDuty().get({
        onCallId: data.onCall?.id,
      });

      const store: iStore = storeDev.getState();
      const auth = store.auth.info;
      const { orgUnitId } = store.auth.selectUser;
      const professional = auth.professionals?.find(
        item => item.orgUnit.id === orgUnitId,
      );

      if (
        window.location.pathname === '/conf' &&
        data.specialist?.status === 'PAUSED' &&
        data.specialist?.professionalId === Number(professional?.id)
      ) {
        History.getHistory().replace(
          `/duty/${data.onCall?.descr?.toLocaleLowerCase()}`,
          {
            specialty: data.onCall?.descr,
            id: data.onCall?.id,
            leaveConf: true,
          },
        );

        AlertMessage({
          type: 'warning',
          message: 'O solicitante recusou a interconsulta.',
        });
      }
    });

    SocketIO.Socket.on('ONCALL_REQUESTER_STATUS', (data: any) => {
      console.log('>>> ONCALL_REQUESTER_STATUS: ', data);
      makeReduxGetDuties().get({
        onCallId: data.onCall?.id,
      });

      /* Verificar em reunião */
      /* if (data.requester?.status === 'DISCONNECTED') {
        const message = useSelector((state: iStore) => state.message);
        if (
          message.active === 'newAvailableInterconsult' &&
          message.data?.requester === data.requester?.professionalId
        )
          closeModal();
      } */
    });

    SocketIO.Socket.on('ONCALL_PROFESSIONAL_CALL', (data: any) => {
      console.log('>>> ONCALL_PROFESSIONAL_CALL: ', data);
      makeReduxActiveMessage().active({
        active: MessageOptions.newAvailableInterconsult,
        actionOk: () => {
          makeRemoteStartAppointmentOnCall()
            .startAppointment({
              onCallId: data.onCall?.id,
              specialistId: data.specialist?.professionalId,
              requesterId: data.requester?.professionalId,
            })
            .then(response => {
              History.getHistory().push('/conf', {
                appointmentId: response.appointmentId,
                onCall: {
                  sessionName: response.sessionName,
                  id: data.onCall?.id,
                  name: data.onCall?.descr,
                  specialist: data.specialist?.professionalId,
                  requester: data.requester?.professionalId,
                },
              });
            })
            .catch(err => {
              makeReduxSetSpecialistStatusOnCall().setSpecialistStatus({
                available: false,
              });
            });
          closeModal();
        },
        actionCancel: closeModal,
        data: {
          type: 'SPECIALIST',
          specialist: data.specialist?.professionalId,
          requester: data.requester?.professionalId,
          onCall: data.onCall,
        },
      });
    });

    SocketIO.Socket.on('ONCALL_REQUESTER_CALL', (data: any) => {
      console.log('>>> ONCALL_REQUESTER_CALL: ', data);
      makeReduxActiveMessage().active({
        active: MessageOptions.newAvailableInterconsult,
        actionOk: () => {
          makeRemoteJoinAppointmentOnCall()
            .joinAppointment({
              onCallId: data.onCall?.id,
              specialistId: data.specialist?.professionalId,
              requesterId: data.requester?.professionalId,
            })
            .then(response => {
              History.getHistory().push('/conf', {
                appointmentId: response.appointmentId,
                onCall: { sessionName: response.sessionName },
              });
            });
          closeModal();
        },
        actionCancel: closeModal,
        data: {
          type: 'REQUESTER',
          requester: data.requester?.professionalId,
          specialist: data.specialist?.professionalId,
          onCall: data.onCall,
        },
      });
    });

    SocketIO.Socket.on('ONCALL_PROFESISONAL_JOINED', (data: any) => {
      console.log('ONCALL_PROFESISONAL_JOINED: ', data);
    });

    SocketIO.Socket.on('ONCALL_REQUESTER_JOINED', (data: any) => {
      console.log('ONCALL_REQUESTER_JOINED: ', data);
    });

    SocketIO.Socket.on('chatMessage', (data: SocketEvents) => {
      console.log('>>> Receive: ', data);
      ChatEvents(data);
      // HandlerEvents(data);
    });
  };
}

export default SocketIO.getInstance();
