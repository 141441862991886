import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { createIntl, createIntlCache } from 'react-intl';
import axios from 'axios';
import Ready from './Ready';
import WaitingParticipant from './WaitingParticipant';

import {
  LeftWaitingRoom,
  JoinWaitingRoom,
} from '~/domain/usecases/waitingRoom/socket';

import { makeReduxUserQueueWaitingRoom } from '~/main/factories/usecases/waitingRoom/UserQueueWaitingRoomFactory';
import { makeSocketJoinWaitingRoom } from '~/main/factories/usecases/waitingRoom/JoinWaitingRoomFactory';
import { ConnectComponent } from './mapper/MapperWaitingRoom';
import { iStore } from '~/domain/interfaces/models';
import { History } from '~/main/routes';
import NewWaitingRoom from './NewWaitingRoom';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import { getLocale } from '~/utils/getLocale';
import translations from '~/infra/i18n/locales';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

export interface externalProps {
  role: 'PRO' | 'CON';
}

export interface ownProps {
  ActionRoom: LeftWaitingRoom;
  notification: {
    status: string;
    userId: number;
    appointmentId?: number;
  };
  waitingRoom?: {
    room: {
      firstName: string;
      lastName: string;
      userId: number;
    }[];
  };
}

interface iStateParams {
  appointmentId: string;
  consultantId: number;
  professionalId: number;
}

const WaitingRoom: React.FC<ownProps & externalProps> = ({
  ActionRoom,
  role,
  notification,
  waitingRoom,
}): JSX.Element => {
  const [state, setState] = useState(0);
  const stateParams = useLocation<iStateParams>().state;

  const userLogged = useSelector((store: iStore) => store.auth.info.user);
  const [waitingRoomData, setWaitingRoomData] =
    useState<JoinWaitingRoom.Model>();

  useEffect(() => {
    const room = makeSocketJoinWaitingRoom();

    room.join(
      {
        appointmentId: Number(stateParams.appointmentId),
        data: {
          role: userLogged!.mainRole!,
          user: userLogged!.id,
          queue: 1,
        },
      },
      (data: JoinWaitingRoom.Model) => {
        data.waitingRoom.users.forEach(el => {
          if (el.id !== userLogged?.id) {
            makeReduxUserQueueWaitingRoom().add({
              data: {
                appointment: parseInt(stateParams.appointmentId, 10),
                status: 'JOINED',
              },
              from: {
                userId: el.id,
                firstName: el.name,
                lastName: '',
              },
            });
          }
        });
        setWaitingRoomData(data);
      },
    );
  }, []);

  useEffect(() => {
    window.onpopstate = () => {
      leaveWaitingRoom();
      window.onpopstate = null;
    };
  }, [waitingRoomData]);

  useEffect(() => {
    switch (notification?.status) {
      case 'JOINED':
        if (
          notification.userId !== userLogged?.id &&
          notification.appointmentId === Number(stateParams.appointmentId)
        ) {
          if (role === 'CON') {
            axios
              .get(
                `${window.config.connection.backendUrl}/api/appointments/${stateParams.appointmentId}/waitingRooms`,
              )
              .then(res => {
                if (res.data.length) {
                  const hasProfessional = Boolean(
                    res.data.find((item: any) => item.role === 'PRO'),
                  );

                  // eslint-disable-next-line no-unused-expressions
                  hasProfessional ? setState(1) : setState(0);
                }
              })
              .catch(err => {
                AlertMessage({
                  message: intl.formatMessage({
                    id: 'Não foi possível obter informações da sala de espera, tente novamente',
                  }),
                  type: 'danger',
                });
              });

            return;
          }

          setState(1);
        }
        break;
      case 'LEFT':
        setState(0);
        break;
      default:
        break;
    }
  }, [notification, role]);

  useEffect(() => {
    if (
      waitingRoom &&
      waitingRoom?.room?.length > 1 &&
      waitingRoom?.room?.filter(item => {
        return item.userId !== userLogged?.id;
      })
    ) {
      if (role === 'CON') {
        axios
          .get(
            `${window.config.connection.backendUrl}/api/appointments/${stateParams.appointmentId}/waitingRooms`,
          )
          .then(res => {
            if (res.data.length) {
              const hasProfessional = Boolean(
                res.data.find((item: any) => item.role === 'PRO'),
              );

              // eslint-disable-next-line no-unused-expressions
              hasProfessional ? setState(1) : setState(0);
            }
          })
          .catch(err => {
            AlertMessage({
              message: intl.formatMessage({
                id: 'Não foi possível obter informações da sala de espera, tente novamente',
              }),
              type: 'danger',
            });
          });

        return;
      }

      setState(1);
    }
  }, [waitingRoom, waitingRoomData, role]);

  const left = () => {
    window.onpopstate = null;

    ActionRoom.left({
      appointmentId: Number(stateParams.appointmentId),
      waitingRoomUserId: Number(waitingRoomData?.waitingRoomUserId),
    });

    History.getHistory().goBack();
  };

  const leaveWaitingRoom = useCallback(() => {
    if (waitingRoomData !== undefined) {
      ActionRoom.left({
        appointmentId: Number(stateParams.appointmentId),
        waitingRoomUserId: Number(waitingRoomData?.waitingRoomUserId),
      });
    }
  }, [waitingRoomData]);

  return (
    <>
      <NewWaitingRoom
        role={role}
        ready={state === 1}
        left={left}
        appointmentId={stateParams.appointmentId}
        consultantId={stateParams.consultantId}
        professionalId={stateParams.professionalId}
        waitingRoomUserId={waitingRoomData?.waitingRoomUserId}
      />
    </>
  );
};

export default ConnectComponent(WaitingRoom);
