/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-indent */
import React, { useEffect, useMemo, useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import InputMask from 'react-input-mask';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { iMessage, iStore } from '~/domain/interfaces/models';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import { Appointment } from '~/domain/usecases/appointment/remote';
import { makeRemoteCreateAppointment } from '~/main/factories/usecases/appointment/CreateAppointmentFactory';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import {
  IconCalendarInput,
  IconCloseModal,
  IconNewCalendar,
  IconPerson,
} from '~/presentation/base/icons';
import { closeModal } from '~/utils/closeModal';
import { schemaInterconsultation } from '~/validation/ineterconsulta/interconsultavalidation';
import { Button, Select } from '../UI';
import Input from '../UI/input';
import InputKeyboardDate from '../inputKeyboardDate';
import {
  Box,
  BoxIcon,
  CheckboxContainer,
  CheckboxText,
  Container,
  Content,
  Divider,
  InformationTitle,
  InputContent,
  Label,
  ModalContainer,
  Required,
  SubTitle,
  Title,
} from './styles';
import { AlertMessage } from '../messages/AlertMessage';
import Checkbox from '../checkbox';
import {
  makeReduxGetAllProfessionals,
  makeRemoteGetAllProfessionals,
} from '~/main/factories/usecases/professional/GetAllProfessionalFactory';
import { makeRemoteGetAllRegionalDocs } from '~/main/factories/usecases/regionaldoc/GetAllRegionalDocs';
import { RegionalDoc } from '~/domain/usecases/regionaldoc/remote';
import { GetAllProfessional } from '~/domain/usecases/professionalList/remote';
import { iField } from '../register/PersonalData';

type Props = {
  message: iMessage;
};

const InstantAppointmentModal: React.FC<Props> = ({ message }) => {
  const [instantConsultation, setInstantConsultation] = useState<Appointment>(
    {} as Appointment,
  );
  const [disabled, setDisabled] = useState(false);
  const [regionalDocs, setRegionalDocs] = useState<RegionalDoc[]>([]);
  const [professionals, setProfessionals] = useState<
    GetAllProfessional.Model['results']
  >({} as GetAllProfessional.Model['results']);
  const [selectProfessional, setSelectProfessional] = useState<
    'INSERT' | 'SELECT'
  >('INSERT');
  const [field, setField] = useState<iField>({
    name: 'CPF',
    descr: 'Cadastro de Pessoa Física',
  });
  const [currentProfessional, setCurrentProfessional] = useState('');

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: zodResolver(schemaInterconsultation),
    defaultValues: {
      info: {
        name: '',
        birthDate: '',
        regType: '',
        regValue: '',
      },
      professionals: [
        {
          name: '',
          unit: '',
        },
      ],
    },
  });

  const { active } = message;

  const modalName = MessageOptions.instantConsultation;
  const isOpen = active === modalName;

  const { orgId, orgUnitId } = useSelector(
    (store: iStore) => store.auth.selectUser,
  );

  const id = useSelector((state: iStore) => state.auth.info.user?.id);

  const specialty = useSelector((state: iStore) => state.auth.info);

  const specialtyId = useMemo(() => {
    const idSpecialty = specialty?.professionals?.find(
      item => item.org.id === orgId,
    )?.professions.specialties[0].id;

    return idSpecialty;
  }, [orgId, specialty?.professionals]);

  const professionalId = useMemo(() => {
    const idProfessional = specialty?.professionals?.find(
      item => item.org.id === orgId,
    )?.id;

    return idProfessional;
  }, [orgId, specialty?.professionals]);

  const close = () => {
    reset();
    closeModal();
  };

  function transformDateFormat(inputDate: string): string {
    // Split the input date string into day, month, and year
    const [day, month, year] = inputDate.split('/');

    // Create a Date object with the given components
    const formattedDate = new Date(
      Number(year),
      Number(month) - 1,
      Number(day),
    );

    // Extract the components of the formatted date
    const formattedDay = String(formattedDate.getDate()).padStart(2, '0');
    const formattedMonth = String(formattedDate.getMonth() + 1).padStart(
      2,
      '0',
    ); // Month is zero-indexed
    const formattedYear = formattedDate.getFullYear();

    // Combine the components into the desired format
    const result = `${formattedYear}-${formattedMonth}-${formattedDay}`;

    return result;
  }

  const createInstantConsultation = () => {
    const selected = professionals.find(
      p => p.user.id === Number(watch('professionals[0].name')),
    );
    const name = `${selected?.user.firstName} ${selected?.user.lastName}`;
    // find unit name
    const unit = selected?.orgUnit.name;

    setDisabled(true);
    makeRemoteCreateAppointment()
      .create({
        org: orgId,
        orgUnit: orgUnitId,
        professional: professionalId,
        specialty: specialtyId,
        organizer: id,
        date: String(new Date()),
        professionals: [
          {
            id:
              selectProfessional === 'SELECT'
                ? Number(watch('professionals[0].name'))
                : undefined,
            name:
              selectProfessional === 'SELECT'
                ? undefined
                : watch('professionals[0].name'),
            unit:
              selectProfessional === 'SELECT'
                ? unit
                : watch('professionals[0].unit'),
          },
        ],
        info: {
          name: watch('info.name'),
          birthDate: transformDateFormat(watch('info.birthDate')),
          regType: watch('info.regType'),
          regValue: watch('info.regValue'),
        },
        service: 'INSTANTANEOUS',
      })
      .then(res => {
        setDisabled(false);
        setInstantConsultation({} as Appointment);
        makeReduxActiveMessage().active({
          active: MessageOptions.instantConsultationSuccess,
          link: res.links.otherProfessionals?.[0].link,
          data: {
            professional: id,
            appointmentId: res.id,
          },
        });
      })
      .catch(err => {
        AlertMessage({
          message: 'Falha ao criar interconsulta!',
          type: 'danger',
        });
        setDisabled(false);
      });
  };

  const defaultValueRegionalDocs = useMemo(() => {
    const doc = regionalDocs.find(item => item.name === watch('info.regType'));

    return doc;
  }, [regionalDocs, watch('info.regType')]);

  useEffect(() => {
    if (isOpen)
      makeRemoteGetAllRegionalDocs()
        .getAll({
          disablePagination: true,
          country: 'BRA',
        })
        .then(res => {
          setRegionalDocs(res.records);
        })
        .catch(err => {
          console.log('err: ', err);
        });
  }, [isOpen]);

  useEffect(() => {
    if (isOpen)
      makeRemoteGetAllProfessionals()
        .getAll({})
        .then(res => {
          setProfessionals(res.results);
        })
        .catch(err => {
          console.log('err: ', err);
          setProfessionals([]);
        });
  }, [isOpen]);

  useEffect(() => {
    if (defaultValueRegionalDocs) {
      setField({
        name: defaultValueRegionalDocs.name,
        descr: defaultValueRegionalDocs.descr,
        mask: defaultValueRegionalDocs.mask,
        id: defaultValueRegionalDocs.id,
        regex: defaultValueRegionalDocs.regex,
      });
    }
  }, [defaultValueRegionalDocs]);

  return isOpen ? (
    <ModalContainer>
      <Container onSubmit={handleSubmit(createInstantConsultation)}>
        <Box style={{ padding: '16px 24px 16px 24px' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: 8,
            }}
          >
            <Title>Interconsulta instantânea</Title>
            <BoxIcon onClick={closeModal}>
              <IconCloseModal />
            </BoxIcon>
          </div>
          <SubTitle>
            Preencha os dados abaixo para criar a interconsulta. É possível
            editá-los após a criação.
          </SubTitle>
        </Box>
        <Divider />
        <Content>
          <InformationTitle style={{ marginBottom: 22 }}>
            Informações do paciente
          </InformationTitle>
          <InputContent>
            <div>
              <div style={{ display: 'flex', marginBottom: 10 }}>
                <Label>Nome do paciente</Label>
                <Required>*</Required>
              </div>
              <Controller
                control={control}
                name="info.name"
                render={({ onChange, value }) => (
                  <Input
                    height="40px"
                    width="326px"
                    onChange={e => onChange(e.target.value)}
                    value={value}
                    error={
                      Boolean(errors.info?.name) &&
                      Boolean(errors.info?.name?.message)
                    }
                    message={
                      Boolean(errors.info?.name) &&
                      Boolean(errors.info?.name?.message)
                        ? String(errors.info?.name?.message)
                        : ''
                    }
                  />
                )}
              />
            </div>
            <div>
              <div style={{ display: 'flex', marginBottom: 10 }}>
                <Label>Data do nascimento</Label>
                <Required>*</Required>
              </div>
              <Controller
                control={control}
                name="info.birthDate"
                render={({ onChange, value }) => (
                  <InputMask
                    mask="99/99/9999"
                    name="birthdate"
                    className="birthdate"
                    value={value}
                    onChange={e => {
                      onChange(e.target.value.trim());
                    }}
                  >
                    <Input
                      height="40px"
                      width="140px"
                      // onChange={e => onChange(e.target.value)}
                      // value={value}
                      error={
                        Boolean(errors.info?.birthDate) &&
                        Boolean(errors.info?.birthDate?.message)
                      }
                      message={
                        Boolean(errors.info?.birthDate) &&
                        Boolean(errors.info?.birthDate?.message)
                          ? String(errors.info?.birthDate?.message)
                          : ''
                      }
                    />
                  </InputMask>
                )}
              />
            </div>
          </InputContent>
          <InputContent style={{ marginTop: 16 }}>
            <div>
              <div style={{ display: 'flex', marginBottom: 10 }}>
                <Label>Documento de identificação</Label>
                <Required>*</Required>
              </div>
              <div style={{ width: 202 }}>
                <Controller
                  control={control}
                  name="info.regType"
                  render={({ onChange, value }) => (
                    <Select
                      height="40px"
                      // width="264px"
                      onChange={e => {
                        onChange(e.target.value);
                        // clean fields
                        setValue('info.regValue', '');
                      }}
                      value={value}
                      error={
                        Boolean(errors.info?.regType) &&
                        Boolean(errors.info?.regType?.message)
                      }
                      message={
                        Boolean(errors.info?.regType) &&
                        Boolean(errors.info?.regType?.message)
                          ? String(errors.info?.regType?.message)
                          : ''
                      }
                    >
                      <option value="">Selecione</option>
                      {regionalDocs.length &&
                        regionalDocs.map(item => (
                          <option
                            id={`registerOption_${item.id}`}
                            value={item.name}
                          >
                            {item.name}
                          </option>
                        ))}
                    </Select>
                  )}
                />
              </div>
            </div>
            <div>
              <div style={{ display: 'flex', marginBottom: 10 }}>
                <Label>Número do documento</Label>
                <Required>*</Required>
              </div>
              <div style={{ width: 264 }}>
                <Controller
                  control={control}
                  name="info.regValue"
                  render={({ onChange, value }) => (
                    <>
                      {field?.mask === '99-99999999-9' ||
                      field?.mask === '999.999.999-99' ? (
                        <InputMask
                          mask={field?.mask}
                          name="regValue"
                          className="regValue"
                          value={value}
                          onChange={e => {
                            onChange(e.target.value.trim());
                          }}
                        >
                          <Input
                            height="40px"
                            width="264px"
                            error={
                              field?.mask?.replace(/[^a-zA-Z0-9*]/g, '')
                                .length !==
                              value.replace(/[^a-zA-Z0-9*]/g, '').length
                            }
                            message={
                              field?.mask?.replace(/[^a-zA-Z0-9*]/g, '')
                                .length !==
                              value.replace(/[^a-zA-Z0-9*]/g, '').length
                                ? 'Documento inválido'
                                : ''
                            }
                          />
                        </InputMask>
                      ) : (
                        <Input
                          height="40px"
                          width="264px"
                          onChange={e => onChange(e.target.value)}
                          value={value}
                          error={
                            Boolean(errors.info?.regValue) &&
                            Boolean(errors.info?.regValue?.message)
                          }
                          message={
                            Boolean(errors.info?.regValue) &&
                            Boolean(errors.info?.regValue?.message)
                              ? String(errors.info?.regValue?.message)
                              : ''
                          }
                        />
                      )}
                    </>
                  )}
                />
              </div>
            </div>
          </InputContent>
          <InformationTitle style={{ marginTop: 32, marginBottom: 22 }}>
            Informações do profissional convidado
          </InformationTitle>
          <div style={{ display: 'flex', gap: 16, marginBottom: 22 }}>
            <CheckboxContainer>
              <Checkbox
                checked={selectProfessional === 'INSERT'}
                onClick={() => {
                  setSelectProfessional('INSERT');
                  setCurrentProfessional('');
                  setValue('professionals[0].name', '');
                  setValue('professionals[0].regValue', '');
                  setValue('professionals[0].unit', '');
                }}
              />
              <CheckboxText>Inserir os dados do profissional</CheckboxText>
            </CheckboxContainer>
            <CheckboxContainer>
              <Checkbox
                checked={selectProfessional === 'SELECT'}
                onClick={() => {
                  setSelectProfessional('SELECT');
                  setValue('professionals[0].name', '');
                  setValue('professionals[0].regValue', '');
                  setValue('professionals[0].unit', '');
                }}
              />
              <CheckboxText>Selecionar profissional cadastrado</CheckboxText>
            </CheckboxContainer>
          </div>
          <InputContent>
            <div>
              <div style={{ display: 'flex', marginBottom: 10 }}>
                <Label>Nome do profissional</Label>
                <Required>*</Required>
              </div>
              <div style={{ width: 233 }}>
                <Controller
                  control={control}
                  name="professionals[0].name"
                  render={({ onChange, value }) =>
                    selectProfessional === 'INSERT' ? (
                      <Input
                        height="40px"
                        // width="326px"
                        onChange={e => onChange(e.target.value)}
                        value={value}
                        error={
                          Boolean(errors.professionals?.[0]?.name) &&
                          Boolean(errors.professionals?.[0]?.name?.message)
                        }
                        message={
                          Boolean(errors.professionals?.[0]?.name) &&
                          Boolean(errors.professionals?.[0]?.name?.message)
                            ? String(errors.professionals?.[0]?.name?.message)
                            : ''
                        }
                      />
                    ) : (
                      <Select
                        height="40px"
                        // width="326px"
                        onChange={e => {
                          onChange(e.target.value);
                          setCurrentProfessional(e.target.value);
                        }}
                        value={value}
                        error={
                          Boolean(errors.professionals?.[0]?.name) &&
                          Boolean(errors.professionals?.[0]?.name?.message)
                        }
                        message={
                          Boolean(errors.professionals?.[0]?.name) &&
                          Boolean(errors.professionals?.[0]?.name?.message)
                            ? String(errors.professionals?.[0]?.name?.message)
                            : ''
                        }
                      >
                        <option value="">Selecione</option>
                        {
                          // eslint-disable-next-line no-unused-expressions
                          professionals?.map(item => {
                            if (item?.user?.id !== id) {
                              return (
                                <option value={item?.professional?.id}>
                                  {`${item?.user?.firstName} ${item?.user?.lastName}`}
                                </option>
                              );
                            }
                          })
                        }
                      </Select>
                    )
                  }
                />
              </div>
            </div>
            <div>
              <div style={{ display: 'flex', marginBottom: 10 }}>
                <Label>Unidade de saúde</Label>
                <Required>*</Required>
              </div>
              <div style={{ width: 233 }}>
                <Controller
                  control={control}
                  name="professionals[0].unit"
                  render={({ onChange, value }) =>
                    selectProfessional === 'INSERT' ? (
                      <Input
                        height="40px"
                        width="233px"
                        onChange={e => onChange(e.target.value)}
                        value={value}
                        error={
                          Boolean(errors.professionals?.[0]?.unit) &&
                          Boolean(errors.professionals?.[0]?.unit?.message)
                        }
                        message={
                          Boolean(errors.professionals?.[0]?.unit) &&
                          Boolean(errors.professionals?.[0]?.unit?.message)
                            ? String(errors.professionals?.[0]?.unit?.message)
                            : ''
                        }
                      />
                    ) : (
                      <Select
                        height="40px"
                        width="233px"
                        onChange={e => onChange(e.target.value)}
                        value={value}
                        error={
                          Boolean(errors.professionals?.[0]?.unit) &&
                          Boolean(errors.professionals?.[0]?.unit?.message)
                        }
                        message={
                          Boolean(errors.professionals?.[0]?.unit) &&
                          Boolean(errors.professionals?.[0]?.unit?.message)
                            ? String(errors.professionals?.[0]?.unit?.message)
                            : ''
                        }
                      >
                        <option value="">Selecione</option>
                        {
                          // eslint-disable-next-line no-unused-expressions
                          professionals?.map(item => {
                            if (
                              item?.professional?.id ===
                              Number(currentProfessional)
                            ) {
                              return (
                                <option value={item.orgUnit.id}>
                                  {item.orgUnit.name}
                                </option>
                              );
                            }
                            return null;
                          })
                        }
                      </Select>
                    )
                  }
                />
              </div>
            </div>
          </InputContent>
        </Content>
        <Divider />
        <Box
          style={{
            backgroundColor: '#F8F8F8',
            borderBottomLeftRadius: 16,
            borderBottomRightRadius: 16,
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 16,
            }}
          >
            <Button
              variant="secundary"
              type="button"
              style={{ width: '233px' }}
              onClick={close}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              style={{ width: '233px' }}
              disabled={disabled}
            >
              Criar interconsulta
            </Button>
          </div>
        </Box>
      </Container>
    </ModalContainer>
  ) : null;
};

export default InstantAppointmentModal;
