import React, { useEffect, useState } from 'react';
import { createIntl, createIntlCache } from 'react-intl';
import { useSelector } from 'react-redux';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';
import {
  ChooseCertificate,
  Container,
  Content,
  ContentHeader,
  Header,
  Left,
  Pdf,
  SendPatient,
  Signed,
} from './styles/StyledDocumentView';

import { iClinicalDocsResults, iStore } from '~/domain/interfaces/models';
import { makeReduxUpdateClinicalDocs } from '~/main/factories/usecases/clinicalDocs/UpdateClinicalDocsFactory';
import { Health } from '~/presentation/base/icons';
import { More } from '~/presentation/components/UI/popover';
import { MediaViewer } from '~/presentation/components/mediaViewer';
import { OptionsClinicalDoc } from '~/presentation/components/optionsMore';
import { PageHeader } from '../header';
import { translator } from '../i18n';
import { Menu } from '../menu';
import { AlertMessage } from '../messages/AlertMessage';
import { Signature } from '../signature';
import { V4hSpin } from '../spin';
import { DocumentStatus } from '../statusList';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

interface ownProps {
  id: string;
  docId?: number;
}

interface certificateProps {
  certificateId: string;
  certificate: string;
}

const DocumentView: React.FC<ownProps> = ({ id }) => {
  const results = useSelector((store: iStore) => store.clinicalDocs.results);
  const [clinicalDoc, setClinicalDoc] = useState<iClinicalDocsResults>();
  const [isSigned, setIsSigned] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [date, setDate] = useState<string>();
  const [isDisplaying, setIsDisplaying] = useState(false);
  const [isSigning, setIsSigning] = useState(false); // TODO: Consertar estilização
  const [certificate, setCertificate] = useState<certificateProps>(
    {} as certificateProps,
  );

  const clinicalDocs = useSelector((store: iStore) => store.clinicalDocs);

  const select = clinicalDocs.results.filter(
    item => item.document?.id === Number(id),
  );

  const typeDoc = {
    BASIC: 'Receita simples',
    MEDICALCERTIFICATE: 'Atestado Médico',
    EXAMREQUEST: 'Requisição de exames',
  };

  useEffect(() => {
    console.log('id: ', id);
    if (results.length) {
      const tmp = results.filter(item => {
        return item.document!.id.toString() === `${id}`;
      });

      console.log('tmp: ', tmp);

      const dateFormated = tmp[0].document!.sent.split('T')[0].split('-');
      setDate(`${dateFormated[2]}/${dateFormated[1]}/${dateFormated[0]}`);
      setClinicalDoc(tmp[0]);
    }
  }, [results]);

  useEffect(() => {
    setIsSigned(clinicalDoc?.document?.status === 'SIGNED');

    setTimeout(() => {
      setIsReady(true);
    }, 1000);
  }, [clinicalDoc]);

  let dataToSend: any = {};

  const clinicalDocType = () => {
    const selectType = (type: number) => {
      if (type === 2) {
        return 2;
      }
      if (type === 3) {
        return 3;
      }

      return 1;
    };

    dataToSend = {
      docId: Number(id),
      type: selectType(select[0].type.id),
      content: {
        drugs: select[0].document?.content.drugs ?? [],
      },
      certificate: certificate.certificate,
      certificateId: certificate.certificateId,
    };

    if (select[0].type.id === 2) {
      dataToSend.content = {
        observacoes: select[0].document?.content.observacoes,
        periodo: select[0].document?.content.periodo,
      };
    }
    if (select[0].type.id === 3) {
      dataToSend.content = {
        solicitacao: select[0].document?.content.solicitacao || [],
        indicacaoClinica: select[0].document?.content.indicacaoClinica || [],
      };
    }

    try {
      makeReduxUpdateClinicalDocs().update(dataToSend);
      // makeReduxGetAllClinicalDocs().getAll({});
      // makeReduxGetClinicalDocs().getById({ docId: Number(id) });
    } catch (e) {
      console.error(e);
      AlertMessage({
        message: intl.formatMessage({
          id: 'Não foi possível assinar o documento.',
        }),
        type: 'danger',
      });
    }
  };
  return clinicalDoc ? (
    <Container>
      <Menu />
      <PageHeader title={translator('Documento clínico')} />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '8px 12px',
        }}
      >
        <div />
        <ContentHeader>
          <Health />
          <h1>
            {translator(
              typeDoc[clinicalDoc?.type?.name as keyof typeof typeDoc],
            )}
          </h1>
        </ContentHeader>
        <More
          content={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <OptionsClinicalDoc
              id={id}
              status={isSigned}
              changeDisplay={(state: boolean) => setIsDisplaying(state)}
              disableView
            />
          }
          positionY="top"
          positionX="right"
          rotate
        />
      </div>
      <Content>
        <Header>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <h2>{`${translator('Nome completo')}:`}</h2>
            <h1>{`${clinicalDoc?.patient.firstName} ${clinicalDoc?.patient.lastName}`}</h1>
          </div>

          <Signed>
            <DocumentStatus status={clinicalDoc.document?.status} />
          </Signed>
        </Header>
      </Content>
      <Content>
        <Left>
          <div>
            <h1>{`${translator('Criado em')} ${date}`}</h1>
          </div>
          {clinicalDoc.document?.sent && clinicalDoc.professional.firstName && (
            <SendPatient>
              <p>
                {`Assinado por ${clinicalDoc.professional.firstName} ${
                  clinicalDoc.professional.lastName
                } em: ${new Date(
                  clinicalDoc.document?.sent,
                ).toLocaleDateString()}`}
              </p>
            </SendPatient>
          )}
        </Left>
      </Content>
      {isReady && isDisplaying && (
        <ChooseCertificate>
          <div id="certificate">
            <Signature
              docId={Number(id)}
              changeSelected={(e: {
                certificateId: string;
                certificate: string;
              }) =>
                setCertificate({
                  ...certificate,
                  certificate: e.certificate,
                  certificateId: e.certificateId,
                })
              }
              onSignSubmit={clinicalDocType}
              isSigned={isSigned}
            />
          </div>
        </ChooseCertificate>
      )}

      {isSigning ? (
        <></>
      ) : (
        <Pdf>
          <MediaViewer
            id={id}
            metadata={{
              patientName: `${clinicalDoc.patient.firstName}_${clinicalDoc.patient.lastName}`,
            }}
          />
        </Pdf>
      )}
    </Container>
  ) : (
    <V4hSpin fullScreen background="white" />
  );
};

export default DocumentView;
