import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Skeleton } from '@material-ui/lab';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { addDays, subYears } from 'date-fns';
import {
  Body,
  Container,
  HeaderList,
  LockScroll,
  Wrapper,
} from './StyledSchedule';
import { Menu } from '~/presentation/components/menu';
import { HeaderSimples, PageHeader } from '~/presentation/components/header';
import { TablePagination } from '~/presentation/components/tablePagination';
import { translator } from '~/presentation/components/i18n';
import { makeReduxDataPagination } from '~/main/factories/usecases/dataPagination/SetDataFactory';
import { iStore } from '~/domain/interfaces/models';
import { SearchListAppointment } from '~/presentation/components/search-list';
import { FilterClinicalDocs } from '~/presentation/components/filter';
import ListHistoryAppointment from '~/presentation/components/ListHistoryAppointment';
import {
  DocumentCross,
  IconHealthyHeart,
  IconPdfWhite,
} from '~/presentation/base/icons';
import FilterAppointmentHistory from '~/presentation/components/filter/FilterAppointmentHistory';
import { Button } from '~/presentation/components/UI';
import { makeRemoteDownloadAppointmentReport } from '~/main/factories/usecases/professional/DownloadAppointmentReportFactory';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import { iHistoryFilter } from './interface';
import { History } from '~/main/routes';

type LocationState = {
  title: string;
  user: any;
};

const AppointmentHistory: React.FC = () => {
  const { loading } = useSelector((store: iStore) => store.clinicalDocs);
  const [state, setState] = useState<number[]>([]);
  const [filterHistory, setFilterHistory] = useState<iHistoryFilter>({
    statusIs: 'DEFAULT',
    consultant: '',
    modalityIs: 'DEFAULT',
    hasClinDoc: 'DEFAULT',
    hasSuppDoc: 'DEFAULT',
    professional: '',
    specialtyIs: 'DEFAULT',
  } as iHistoryFilter);
  const locationState = useLocation().state as LocationState;

  const { auth } = useSelector((store: iStore) => store);

  const { role, orgId, orgUnitId } = useSelector(
    (store: iStore) => store.auth.selectUser,
  );

  const handleDownload = () => {
    const now = new Date();
    const begin = subYears(now, 20);
    const end = addDays(now, 2);

    const consultantId = auth.info.consultants?.find(
      item => item.org?.id === orgId,
    )?.id;

    const professionalId = auth.info.professionals?.find(
      item => item.orgUnit.id === orgUnitId,
    )?.id;

    makeRemoteDownloadAppointmentReport()
      .download({
        query: { professionalId: Number(professionalId) },
        body: {
          dataControl: {
            limit: 9999,
          },
          timestamp: {
            begin: begin.toISOString(),
            end: end.toISOString(),
          },
          status:
            filterHistory?.statusIs !== 'DEFAULT'
              ? filterHistory?.statusIs
              : undefined,
          modality:
            filterHistory?.modalityIs !== 'DEFAULT'
              ? filterHistory?.modalityIs
              : undefined,
          // ...(filterHistory?.hasClinDoc && {
          //   hasClinDoc: filterHistory?.hasClinDoc === 'true',
          // }),
          // ...(filterHistory?.hasSuppDoc && {
          //   hasSupportDoc: filterHistory?.hasSuppDoc === 'true',
          // }),
          ...(role === 'CON' && {
            consultant: consultantId,
            specialty: Number(filterHistory?.specialtyIs),
          }),
        },
      })
      .then(res => {
        const blob = new Blob([res], { type: 'application/pdf' });
        const urlToPdf = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = urlToPdf;
        a.download = 'professional_appointment_report.pdf';
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        AlertMessage({
          type: 'success',
          message: 'Relatório baixado com sucesso',
        });
      })
      .catch(err => {
        console.log('erro: ', err);
      });
  };

  useEffect(() => {
    const data: string | any[] | ((prevState: number[]) => number[]) = [];
    if (data.length === 0) {
      makeReduxDataPagination().set([]);
    }
    setState(data);
  }, []);

  return (
    <Container>
      {/* <MenuDefault /> */}
      <LockScroll>
        <Menu />
        {locationState?.title ? (
          <PageHeader title={translator('Histórico de atendimentos')} />
        ) : (
          <HeaderSimples
            title={translator('Histórico de atendimentos')}
            icon={IconHealthyHeart}
          />
        )}
        <HeaderList>
          {loading ? (
            <Skeleton variant="rect" width="120px" />
          ) : (
            <>
              {state.length > 0 && state !== undefined ? (
                <TablePagination data={state} itemPerPage={10} />
              ) : (
                <div />
              )}
            </>
          )}
          <Wrapper>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '24px',
              }}
            >
              <SearchListAppointment>
                <FilterAppointmentHistory
                  filter={filterHistory}
                  handleSetFilter={setFilterHistory}
                />
              </SearchListAppointment>
              {role === 'PRO' && (
                <Button icon={IconPdfWhite} onClick={() => handleDownload()}>
                  Gerar PDF
                </Button>
              )}
            </div>
          </Wrapper>
        </HeaderList>
      </LockScroll>
      <Body>
        <ListHistoryAppointment />
      </Body>
    </Container>
  );
};

export default AppointmentHistory;
