import styled from 'styled-components';

export const Container = styled.div`
  background: ${props => props.theme.menuSubHeaderBackground};
  width: 100%;
  padding: 10px 40px 10px 40px;
  color: ${props => props.theme.menuSubHeaderColor};
  border-bottom: 1px solid ${props => props.theme.menuSubHeaderBorder};
  border-top: 1px solid ${props => props.theme.menuSubHeaderBorder};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.div`
  font-weight: 700;
`;
export const ButtonBack = styled.div``;

export const Left = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
`;

export const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

export const Right = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
`;

export const Description = styled.div`
  white-space: nowrap;
`;
