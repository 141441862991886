import React from 'react';

import * as Appointment from './appointment';
import Delete from './exclude';
import Success from './success';
import * as User from './user';

import ProfessionalsOnDutySidesheet from '~/presentation/pages/Duty/Sidesheet';
import CreateNewLayOver from '../createNewLayOver';
import AuditRecordDrawer from '../drawers/AuditRecord';
import ModalAcceptTermsWaitingRoom from '../modalAcceptTermsWaitingRoom';
import ModalExpiredFremium from '../modalExpiredFremium/ModalExpiredFremium';
import ModalIsProfessionalAvailable from '../modalIsProfessionalAvailable';
import ModalLeaveInterconsult from '../modalLeaveInterconsult';
import ModalNewAvailableInterconsult from '../modalNewAvailableInterconsult';
import ModalReadyWaitingRoom from '../modalReadyWaitingRoom';
import ModalRequestConsultation from '../modalRequestConsultation';
import ErrorMessage from './error';
import { ownProps } from './interface';
import { ConnectComponent } from './mapper/mapperMessage';
import EditShift from '../editShift';
import InstantAppointmentModal from '../instantAppointmentModal';
import InstantAppointmentSuccessModal from '../InstantAppointmentSuccessModal';

const Notifications: React.FC<ownProps> = ({
  children,
  message,
}): JSX.Element => {
  return (
    <>
      <Appointment.AppointmentReady message={message} />
      <Appointment.AppointmentConfirmStop message={message} />
      <Appointment.ConfirmCancellation message={message} />
      <Appointment.ScheduleExclude message={message} />
      <Appointment.SuccessfullyCreated message={message} />
      <Appointment.TermsOfUse message={message} />
      <Appointment.EditConsultCopyLink message={message} />
      <Appointment.InviteExternalProfessionals message={message} />
      <User.UserRegisterSuccess message={message} />
      <User.WaitingMessage message={message} />
      <User.WarningMessage message={message} />
      <User.UserLeaveSpecialty message={message} />
      <User.UserChangeRole message={message} />
      <User.UserAlreadyExistsOnSector message={message} />
      <User.UserDataFulfill message={message} />
      <User.UserEditDataError message={message} />
      <Delete message={message} />
      <Success message={message} />
      <ModalExpiredFremium message={message} />
      <ErrorMessage message={message} />
      <AuditRecordDrawer message={message} />
      <ProfessionalsOnDutySidesheet message={message} />
      <ModalRequestConsultation message={message} />
      <ModalNewAvailableInterconsult message={message} />
      <ModalIsProfessionalAvailable message={message} />
      <ModalLeaveInterconsult message={message} />
      <ModalAcceptTermsWaitingRoom message={message} />
      <ModalReadyWaitingRoom message={message} />
      <CreateNewLayOver message={message} />
      <EditShift message={message} />
      <InstantAppointmentModal message={message} />
      <InstantAppointmentSuccessModal message={message} />
      {children}
    </>
  );
};

export default ConnectComponent(Notifications);
