import { JoinAppointmentOnCall } from '~/domain/usecases/onCall/remote';
import {
  NotFound,
  BadRequestError,
  Forbidden,
  UnexpectedError,
  BadGateway,
} from '~/domain/errors';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import '~/infra/global/variables';

export class RemoteJoinAppointmentOnCall implements JoinAppointmentOnCall {
  private readonly url: string;

  private readonly httpClient: HttpClient<JoinAppointmentOnCall.Model>;

  constructor(url: string, httClient: HttpClient<JoinAppointmentOnCall.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  joinAppointment = async (
    params: JoinAppointmentOnCall.Params,
  ): Promise<JoinAppointmentOnCall.Model> => {
    const httpResponse = await this.httpClient.request({
      url: this.url
        .replace('{onCallId}', `${params.onCallId}`)
        .replace('{specialistId}', `${params.specialistId}`)
        .replace('{requesterId}', `${params.requesterId}`),
      method: 'post',
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.created:
      case HttpStatusCode.accepted:
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.unauthorized:
        throw new Forbidden();
      case HttpStatusCode.badGateway:
        throw new BadGateway();
      default:
        throw new UnexpectedError();
    }
  };
}
