import { enum as a, number, object, string } from 'zod';

export const schemaJoinOnCall = object({
  name: string().max(128).optional(),
  age: number().optional(),
  sex: a(['NONE', 'M', 'F']).optional(),
  situation: string()
    .max(4096, 'Você atingiu o limite de 4096 caracteres para este campo')
    .optional(),
  susCard: string().optional(),
  organization: string().optional(),
  uf: string().optional(),
  city: string().optional(),
  priority: a(['LOW', 'NORMAL', 'EMERGENCY']).optional(),
});
