import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { iStore } from '~/domain/interfaces/models';
import { ClinicalDocumentViewer } from '~/presentation/components/clinicalDocumentViewer';
import { PageHeader } from '~/presentation/components/header';
import { Menu } from '~/presentation/components/menu';
// import { translator } from '~/presentation/components/i18n';
import { Card } from '~/presentation/components/sessionInformation';
import { V4hSpin } from '~/presentation/components/spin';
// Import dos componentes a serem renderizados na nav
import { translator } from '~/presentation/components/i18n';
import {
  AppointmentDocs,
  EditingConsultant,
  MessageAvaliable,
  MessageCanceled,
} from '../../components/appointmentDetail';
import { AppointmentDetail, iClinicalDocs, iSupportDocs } from './interface';
import {
  MapAppointmentDetail,
  MapCLinicalDocuments,
  MapSupportingDocuments,
} from './mapper/map';
import {
  Body,
  Content,
  ContentComponents,
  Events,
  Header,
  LockScroll,
  NavContent,
  Session,
} from './styles/StyledService';

interface iStateParams {
  appointmentId: string;
}

const AppointmentDetails: React.FC = () => {
  const [infos, setInfos] = useState<AppointmentDetail>();
  const [clinicalDoc, setClinicalDoc] = useState<iClinicalDocs[]>([]);
  const [docsSupp, setDocsSupp] = useState<iSupportDocs[]>([]);

  const clinicalDocs = useSelector((store: iStore) => store.clinicalDocs);
  const mainRole = useSelector(
    (store: iStore) => store.auth.info.user?.mainRole,
  );

  async function handleGetInfo(id: number) {
    const res = await MapAppointmentDetail(id);
    const docsClincal = await MapCLinicalDocuments(id);
    const docsupp = await MapSupportingDocuments(id);

    setInfos(res);
    setClinicalDoc(docsClincal);
    setDocsSupp(docsupp);
  }

  const stateParams = useLocation<iStateParams>().state;

  useEffect(() => {
    handleGetInfo(Number(stateParams.appointmentId));
  }, [clinicalDocs]);

  const [openDoc, setOpenDoc] = useState<boolean>(false);

  console.log('infos', infos);
  console.log('clinicalDoc', clinicalDoc);

  const isInterconsultation = useMemo(() => {
    return (
      infos?.session.appointmentType?.msgKey === 'APPTYPE_INTERCONSULTATION'
    );
  }, [infos]);

  return (
    <Content>
      <LockScroll>
        <Header>
          <Menu />
          <PageHeader title={translator('Atendimento')} />
        </Header>
      </LockScroll>
      <Body>
        <Session>
          <Card
            isInterconsultation={isInterconsultation}
            extraInfo={infos && infos.info}
            user={infos && infos.participants}
            session={infos && infos.session}
          />
          {/* {mainRole !== 'ORG' && <DropDownEvents />} */}
        </Session>
        <Events>{/* <DropDownEvents /> */}</Events>
        {!infos && (
          <NavContent>
            <V4hSpin fullScreen={false} background="none" className="spin" />
          </NavContent>
        )}
        {infos && infos.session.status === 'AVAILABLE' && (
          <NavContent>
            <ContentComponents>
              <MessageAvaliable />
            </ContentComponents>
          </NavContent>
        )}
        {infos && infos.session.status === 'CANCELED' && (
          <NavContent>
            <ContentComponents>
              <MessageCanceled id={infos.participants.consultant.id!} />
            </ContentComponents>
          </NavContent>
        )}
        {
          infos &&
            (infos.session.status === 'ENDED' || isInterconsultation) &&
            (clinicalDocs.selected ? (
              <ClinicalDocumentViewer
                closeFunction={setOpenDoc}
                id={clinicalDocs.selected}
                appointmentId={Number(stateParams.appointmentId)}
              />
            ) : (
              <AppointmentDocs
                openFunction={setOpenDoc}
                clinicalDocs={clinicalDoc}
                supportingDocs={docsSupp}
                infos={infos}
              />
            ))

          // <ClinicalDocumentViewer id={0} />
        }
        {infos &&
          (infos.session.status === 'SCHEDULED' ||
            infos.session.status === 'CONFIRMED') &&
          !isInterconsultation && <EditingConsultant />}

        {infos &&
          infos.session.status === 'INPROGRESS' &&
          !isInterconsultation && (
            <NavContent>
              <ContentComponents>
                <EditingConsultant />
              </ContentComponents>
            </NavContent>
          )}
      </Body>
    </Content>
  );
};

export default AppointmentDetails;
