import styled from 'styled-components';

type ContainerProps = {
  hasButtonBack?: boolean;
};

export const Container = styled.div<ContainerProps>`
  background: ${props => props.theme.menuSubHeaderBackground};
  width: 100%;
  color: ${props => props.theme.menuSubHeaderColor};
  border-bottom: 1px solid ${props => props.theme.menuSubHeaderBorder};
  border-top: 1px solid ${props => props.theme.menuSubHeaderBorder};
  display: flex;
  justify-content: ${({ hasButtonBack }) =>
    hasButtonBack ? 'space-between' : 'center'};

  padding: 0.75rem 4.5rem;

  #center {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 50px;
    width: 50%;
  }

  #left {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  #right {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;

export const Title = styled.div`
  font-weight: 700;
  padding-right: 8px;
`;
