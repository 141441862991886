import React from 'react';
import { closeModal } from '~/utils/closeModal';
import { ButtonStyle } from './styles/StyledSimplePrescription';
import { Button } from '~/presentation/components/UI';
import { translator } from '~/presentation/components/i18n';

interface ownProps {
  back?: () => void;
  next?: () => void;
  isFinish?: boolean;
  cancel?: string;
  isDisable?: boolean;
  isDisableBack?: boolean;
}

const Navigator: React.FC<ownProps> = ({
  back,
  next,
  isFinish,
  isDisable,
  cancel,
  isDisableBack,
}) => {
  return (
    <ButtonStyle>
      <>
        <Button size="small" variant="secundary" rounded onClick={back}>
          Anterior
        </Button>
        <Button
          size="small"
          rounded
          type="submit"
          onClick={next}
          disabled={isDisable}
        >
          {translator('Próximo')}
        </Button>
      </>
    </ButtonStyle>
  );
};

export default Navigator;
