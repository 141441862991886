import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 14px;
`;

export const DataContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /* background: #00f; */
`;

export const DataContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  border 1px solid #EFEFEF;

  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  /* background: #f0f; */
`;

export const DataContentTitle = styled.div`
  width: 100%;
  padding: 12px 16px;
  background-color: #F4F7FE;
`;

export const ConsultInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 8px;

  svg {
    max-height: 14px;
    margin-bottom: 2px;
  }
`;

export const CardInfoContainer = styled.div`
  display: flex;
  /* height: 100%; */
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 16px 24px;

  @media (max-height: 768px) {
    padding: 8px 16px;
  }
`;

export const CardInfoContainerScroll = styled.div`
  max-height: 108px;
  min-height: 60px;
  height: 100%;
  overflow-y: auto;

  @media (max-height: 768px) {
    max-height: 80px;
  }


`;

// Start Text Components

export const TextBold = styled.p`
  font-weight: 600;
  font-size: 15px;
  line-height: 150%;
  color: #222529;
`;

export const TextBoldTitle = styled.p`
  font-weight: 700;
  font-size: 14px;
  color: #222529;
`;

export const TextNormal = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #818385;
`;

export const TextNormalBlackFour = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #62666c;
`;

export const TextBlue = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #004BA5;
`;
// End Text Components