import { CreateClinicalDocs } from '~/domain/usecases/clinicalDocs/remote';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import {
  InvalidCredentialsError,
  UnexpectedError,
  BadRequestError,
  NotFound,
} from '~/domain/errors';
import '~/infra/global/variables';

export class RemoteCreateClinicalDocs implements CreateClinicalDocs {
  private readonly url: string;

  private readonly httpClient: HttpClient<CreateClinicalDocs.Model>;

  constructor(url: string, httClient: HttpClient<CreateClinicalDocs.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  create = async (
    params: CreateClinicalDocs.Params,
  ): Promise<CreateClinicalDocs.Model> => {
    console.log('Params create clinical docs: ', params);
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: {
        appointment: params.appointment,
        org: params.org,
        orgUnit: params.orgUnit,
        professional: params.professional,
        specialty: params.specialty,
        patient: params.patient,
        type: params.type,
        content: params.content,
        certificate: params.certificate,
        signatureSource: params.signatureSource,
      },
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.created:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      default:
        throw new UnexpectedError();
    }
  };
}
