import { Dispatch } from 'redux';
import store from '~/data/store';
import { getProfessionalOnDuty } from '~/data/store/reducer/duty/actions/getProfessionalOnDuty';

import { iActionGetProfessionalOnDuty } from '~/domain/interfaces/redux/duty/getProfessionalOnDuty';

import { GetProfessionalOnDuty } from '~/domain/usecases/duty/redux';

export class ReduxGetProfessionalOnDuty implements GetProfessionalOnDuty {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  get = (params: GetProfessionalOnDuty.Params): iActionGetProfessionalOnDuty =>
    this.send(getProfessionalOnDuty(params));
}
