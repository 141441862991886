import { UpdateAppointment } from '~/domain/usecases/appointment/remote';
import {
  InvalidCredentialsError,
  UnexpectedError,
  BadRequestError,
} from '~/domain/errors';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import '~/infra/global/variables';

export class RemoteUpdateAppointment implements UpdateAppointment {
  private readonly url: string;

  private readonly httpClient: HttpClient<UpdateAppointment.Model>;

  constructor(url: string, httClient: HttpClient<UpdateAppointment.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  update = async (
    params: UpdateAppointment.Params,
  ): Promise<UpdateAppointment.Model> => {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${params.appointmentId}`,
      method: 'patch',
      body: {
        professional: params.professional,
        specialty: params.specialty,
        consultant: params.consultant,
        organizer: params.organizer,
        healthPlan: params.healthPlan,
        obs: params.obs,
        priority: params.priority,
        type: params.type,
        status: params.status,
        allowInvite: params.allowInvite,
        date: params.date,
        duration: params.duration,
        note: params.note,
        companions: params.companions,
        professionals: params.professionals,
        title: params.title,
        info: params.info,
        clinicalCondition: params.clinicalCondition,
        forwarding: params.forwarding,
      },
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      default:
        throw new UnexpectedError();
    }
  };
}
