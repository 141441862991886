import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { LeftSpecialistOnCall as UsecaseRemoteLeftSpecialistOnCall } from '~/domain/usecases/onCall/remote';
// import { LeftSpecialistOnCall as UsecaseReduxLeftSpecialistOnCall } from '~/domain/usecases/onCall/redux';

import { RemoteLeftSpecialistOnCall } from '~/data/repository/onCall';
// import { ReduxLeftSpecialistOnCall } from '~/data/store/reducer/onCall/usecases';

/**
 * send request via API.
 */
export const makeRemoteLeftSpecialistOnCall =
  (): UsecaseRemoteLeftSpecialistOnCall =>
    new RemoteLeftSpecialistOnCall(
      makeApiUrl('/oncall/{onCallId}/specialists/{specialistId}'),
      makeHttpClient(),
    );

/**
 * send request via REDUX.
 */
/* export const makeReduxLeftSpecialistOnCall =
  (): UsecaseReduxLeftSpecialistOnCall =>
    new ReduxLeftSpecialistOnCall(); */
