import React, { useEffect, useState, useCallback } from 'react';
import MUIRichTextEditor from 'mui-rte';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';

import { convertToRaw } from 'draft-js';
import { useLocation } from 'react-router-dom';
import EyeIcon from '~/presentation/base/icons/eye.svg';
import ClockIcon from '~/presentation/base/icons/clock.svg';
import { translator } from '../i18n';

import {
  ContainerNotes,
  ContentNotes,
  ContentNotesEdition,
  ContainerModal,
} from './styles/StyledWritingArea';
import { makeRemoteUpdateAppointment } from '~/main/factories/usecases/appointment/UpdateAppointmentFactory';
import { GetAppointmentById } from '~/domain/usecases/appointment/remote/GetAppointmentById';
import { makeRemoteGetAppointmentById } from '~/main/factories/usecases/appointment/GetAppointmentbyIdFactory ';
import { UpdateAppointment } from '~/domain/usecases/appointment/remote';

interface writingAreaProps {
  date?: Date;
  permission?: string;
  sidebar?: boolean;
  appointmentId?: number;
  handleSavingLoading?: (loading: boolean) => void;
}

interface iStoreParams {
  appointmentId: string;
}

const defaultTheme = createTheme();

Object.assign(defaultTheme, {
  overrides: {
    MUIRichTextEditor: {
      root: {
        width: '100%',
      },
      editorContainer: {
        width: '100%',
        padding: '10px',
        borderTop: '1px solid #7a7a7a',
      },
      editor: {
        width: '100%',
      },
      container: {
        width: '100%',
        padding: '0 10px',
      },
    },
  },
});

const WritingArea: React.FC<writingAreaProps> = ({
  sidebar,
  appointmentId,
  handleSavingLoading,
}) => {
  const { appointmentId: appointmentParamsId } =
    useLocation<iStoreParams>().state;
  const [notes, setNotes] = useState<string>('');
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');
  const [lastValue, setLastValue] = useState<string>('');

  const [timeStemp, setTimeStemp] = useState<Date>(new Date());

  useEffect(() => {
    makeRemoteGetAppointmentById()
      .getAppointmentById({
        id: Number(appointmentId ?? appointmentParamsId),
      })
      .then((response: GetAppointmentById.Model) => {
        console.log('response appointment: ', response.appointment);
        setNotes(response.appointment.note ?? null);
        setTimeStemp(new Date(response.appointment.noteUpdated));
      })
      .catch(e => console.log(e));
  }, [appointmentId, appointmentParamsId]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      console.log('DebouncedValue: ', value);

      setIsSaving(true);
      if (handleSavingLoading) handleSavingLoading(true);

      if (value !== lastValue) {
        makeRemoteUpdateAppointment()
          .update({
            appointmentId: Number(appointmentId ?? appointmentParamsId),
            note: value,
            // noteUpdated: new Date().toISOString(),
          })
          .then(response => {
            setTimeStemp(new Date());
            setIsSaving(false);
            setLastValue(value);
            if (handleSavingLoading) handleSavingLoading(false);
          })
          .catch(() => {
            setIsSaving(false);
            if (handleSavingLoading) handleSavingLoading(false);
          });
      }
    }, 3000);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentId, appointmentParamsId, value]);

  const saveWithInterval = useCallback(
    async (e: any) => {
      const rawValue = convertToRaw(e);
      const newValue = String(
        rawValue.blocks[0].text !== '' ? JSON.stringify(rawValue) : '',
      );

      if (appointmentId || (appointmentParamsId && newValue !== lastValue)) {
        console.log('Atualizando o value: ', newValue);
        setValue(newValue);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appointmentId, appointmentParamsId, lastValue],
  );

  return (
    <ContainerNotes sidebar={sidebar}>
      {!sidebar && (
        <>
          <ContentNotes>
            <p>
              <img src={EyeIcon} alt="EyeIcon" className="eye-icon" />
              <h5 className="text-info">
                {translator(
                  'Só você pode ver, editar e excluir a anotação do atendimento.',
                )}
              </h5>
            </p>
          </ContentNotes>
          <ContentNotesEdition>
            <img src={ClockIcon} alt="ClockIcon" className="clock-icon" />
            <p>
              <h5 className="text-info">
                {`${translator('Última edição')}: `}
                {timeStemp.toLocaleString()}
              </h5>
            </p>
          </ContentNotesEdition>
        </>
      )}
      <ContainerModal sidebar={sidebar}>
        <MuiThemeProvider theme={defaultTheme}>
          <MUIRichTextEditor
            toolbarButtonSize="small"
            label={
              sidebar
                ? translator('Escreva aqui as anotações sobre o paciente.')
                : translator('Escreva aqui')
            }
            // onSave={save}
            defaultValue={notes}
            controls={['bold', 'italic', 'underline', 'highlight', 'title']}
            onChange={e => saveWithInterval(e.getCurrentContent())}
          />
        </MuiThemeProvider>
      </ContainerModal>
    </ContainerNotes>
  );
};

export default WritingArea;
