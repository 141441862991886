import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Theme, createStyles, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { iStore } from '~/domain/interfaces/models';
import HeaderExpiration from '~/presentation/components/HeaderExpiration/HeaderExpiration';

import { ListAppointment } from '~/presentation/components/ListProfessional';
import { Popover } from '~/presentation/components/UI/popover';
import { Chat as Cchat } from '~/presentation/components/chat';
import { HeaderSimples } from '~/presentation/components/header';
import { translator } from '~/presentation/components/i18n';
import { Menu } from '~/presentation/components/menu';
import { PaginationByDate } from '~/presentation/components/paginationByDate';
import { SearchListAppointment } from '~/presentation/components/search-list';
import { withAccess } from '~/presentation/hooks/access';

import {
  IconCalendar,
  IconCalendarInterconsulta,
  IconCamInterconsulta,
  IconPlus,
} from '~/presentation/base/icons';
import Calendar from '~/presentation/components/calendar';

import { FilterAppointment } from '~/presentation/components/filter';

import { MessageOptions } from '~/domain/interfaces/redux/message';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import {
  Attendiment,
  Body,
  ButtonBig,
  Container,
  Drop,
  LockScroll,
  OptionText,
  OptionsBox,
  Space,
  Text,
} from './StyledAppointment';

interface ownProps {
  totalAppointment: number;
}

const Appointment: React.FC<ownProps> = ({ totalAppointment }): JSX.Element => {
  const Navigator = withAccess(Link);

  const message = useSelector((store: iStore) => store.message);

  const { orgs } = useSelector((store: iStore) => store.auth);

  const openInstantConsultationModal = useCallback(() => {
    makeReduxActiveMessage().active({
      active: MessageOptions.instantConsultation,
    });
  }, []);

  const expiration = useMemo(() => {
    const temp = orgs?.[0]?.expiration ?? null;

    if (temp) {
      return Math.floor(
        (new Date(temp).getTime() - new Date().getTime()) / (1000 * 3600 * 24),
      );
    }

    return temp;
  }, [orgs]);

  const NewAppointment = (
    <OptionsBox>
      <Navigator
        to={{
          pathname: '/appointment/new',
          state: {
            from: window.location.pathname.replaceAll('/', ''),
          },
        }}
        resourcers={['APPOINTMENT']}
        actions={['CREATE_ORG', 'CREATE_OWN', 'CREATE_ANY']}
        style={{ textDecoration: 'none' }}
      >
        {/* <Link to="/appointment/new" style={{ textDecoration: 'none' }}> */}
        <Space>
          <IconCalendarInterconsulta />
          <OptionText>{translator('Agendar atendimento')}</OptionText>
        </Space>
        {/* </Link> */}
      </Navigator>
      <Space onClick={openInstantConsultationModal}>
        <IconCamInterconsulta />
        <OptionText>{translator('Interconsulta instantânea')}</OptionText>
      </Space>
    </OptionsBox>
  );

  return (
    <Container
      style={{
        opacity:
          message.active !== 'none' && message.active !== 'professionalReady'
            ? '0.7'
            : '1',
      }}
    >
      <LockScroll>
        <Cchat />
        <Menu />
        <HeaderSimples
          title={translator('Agenda de atendimentos')}
          icon={IconCalendar}
          value={totalAppointment}
          valueDescription={translator('atendimentos neste dia')}
        />
      </LockScroll>

      {expiration && <HeaderExpiration daysUse={`${expiration} dias`} />}

      <Body>
        <div id="left">
          <Calendar />
          {/* <Chat>
            Chat
            <IconBalon>
              <IconChatBalon />
            </IconBalon>
          </Chat>
          <Inputs>
            <Input placeholder={`${translator('Pesquisar usuário')}`} />
          </Inputs>
          <HistoryComponent>
            <HistoryChat />
          </HistoryComponent> */}
        </div>
        <div id="right">
          <div id="header">
            <PaginationByDate />
            <SearchListAppointment>
              <FilterAppointment />
            </SearchListAppointment>
            {/* <Navigator
              to={{
                pathname: '/appointment/new',
                state: {
                  from: window.location.pathname.replaceAll('/', ''),
                },
              }}
              resourcers={['APPOINTMENT']}
              actions={['CREATE_ORG', 'CREATE_OWN', 'CREATE_ANY']}
            > */}
            <ButtonBig>
              {/* <Button
                  id="btn_create_appointment"
                  className="btnAction"
                  size="bigger"
                  rounded
                >
                  <Attendiment>
                    <IconPlus />
                  </Attendiment>
                  {translator('Novo agendamento')}
                </Button> */}
              <Popover
                content={NewAppointment}
                resourcers={['ONCALL']}
                originX="center"
                originY="bottom"
              >
                <Drop>
                  <Attendiment>
                    <IconPlus />
                  </Attendiment>
                  <Text>{translator('Novo agendamento')}</Text>
                </Drop>
              </Popover>
            </ButtonBig>
            {/* </Navigator> */}
          </div>
          <div id="list">
            <ListAppointment />
          </div>
        </div>
      </Body>
    </Container>
  );
};

export default Appointment;
