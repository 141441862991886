/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { iDutyRequesters } from '~/domain/interfaces/models/Duty';
import { AvatarImage } from '~/presentation/components/avatar-roles';
import { ContainerImage } from '~/presentation/components/avatar-roles/styles/styled';
import { columns } from './columns';
import {
  Container,
  ListHeader,
  ListHeaderTitle,
  ProfessionalBadge,
  ProfessionalBadgeInfo,
  ProfessionalBadgeName,
  ProfessionalBadgeRole,
  ProfessionalBadgeStatus,
  Table,
  TableContainer,
  Td,
  Th,
  Tr,
} from './styles';

type Row = iDutyRequesters['professionalOnDuty']['records'][0];

const ProfessionalDutyList: React.FC = () => {
  const { professionalOnDuty } = useSelector((store: iStore) => store.duty);

  return (
    <Container>
      <ListHeader>
        <ListHeaderTitle>Profissionais neste plantão</ListHeaderTitle>
      </ListHeader>
      <TableContainer>
        <Table>
          <thead>
            <tr>
              {columns.map(column => (
                <Th key={column.id}>{column.label}</Th>
              ))}
            </tr>
          </thead>
          <tbody>
            {professionalOnDuty?.records.map(row => (
              <TableRowItem key={row.list?.specialist?.id} row={row} />
            ))}
          </tbody>
        </Table>
      </TableContainer>
    </Container>
  );
};

type TableRowItemProps = {
  row: Row;
};

const TableRowItem: React.FC<TableRowItemProps> = ({ row, ...rest }) => {
  const { list } = row;
  const hasLocal = list.specialist.org.city || list.specialist.org.province;
  const locality = hasLocal
    ? `${list.specialist.org.city}-${list.specialist.org.province}`
    : 'Não informado';

  const createdFormatted = Intl.DateTimeFormat('pt-BR', {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  })
    .format(new Date(list.created))
    .replace(', ', ', às ');

  const labelStatus = {
    AVAILABLE: 'Disponível',
    MATCHING: 'Aguardando',
    BUSY: 'Em atendimento',
    PAUSED: 'Indisponível',
  };

  return (
    <Tr {...rest}>
      <Td>
        <ProfessionalBadge>
          <ContainerImage id="profile">
            <AvatarImage size="root" name={list.specialist.name} src="" />
          </ContainerImage>
          <ProfessionalBadgeInfo>
            <ProfessionalBadgeName>
              {list.specialist.name}
            </ProfessionalBadgeName>
            <ProfessionalBadgeRole>
              {list.specialist.name}
            </ProfessionalBadgeRole>
          </ProfessionalBadgeInfo>
        </ProfessionalBadge>
      </Td>
      <Td>{list.specialist.org.name}</Td>
      <Td>{locality}</Td>
      <Td>
        <ProfessionalBadgeStatus
          status={list.status as 'AVAILABLE' | 'MATCHING' | 'PAUSED' | 'BUSY'}
        >
          {
            labelStatus[
              list.status as 'AVAILABLE' | 'MATCHING' | 'PAUSED' | 'BUSY'
            ]
          }
        </ProfessionalBadgeStatus>
      </Td>
    </Tr>
  );
};

export default ProfessionalDutyList;
