/* eslint-disable react/react-in-jsx-scope */
import { useSelector } from 'react-redux';

import { iMessage, iStore } from '~/domain/interfaces/models';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import { IconClose } from '~/presentation/base/icons';
import { closeModal } from '~/utils/closeModal';
import {
  ProfessionalBadge,
  ProfessionalBadgeAvatar,
  ProfessionalBadgeInfo,
  ProfessionalBadgeName,
  ProfessionalBadgeRole,
  ProfessionalBadgeStatus,
  Table,
  Td,
  Th,
  Tr,
} from '../List/styles';
import {
  Container,
  Content,
  ListHeader,
  ListHeaderCloseButton,
  ListHeaderTitle,
} from './styles';

type ProfessionalsOnDutySidesheetProps = {
  message: iMessage;
};

const ProfessionalsOnDutySidesheet: React.FC<ProfessionalsOnDutySidesheetProps> =
  ({ message }) => {
    const records = useSelector(
      (store: iStore) => store.duty?.professionalOnDuty?.records,
    );
    const modalName = MessageOptions.professionalsOnDuty;
    const meIsOpen = modalName === message.active;

    const handleCloseModalWithEsc = (e: React.KeyboardEvent) => {
      if (e.key === 'Escape') {
        closeModal();
      }
    };

    const labelStatus = {
      AVAILABLE: 'Disponível',
      MATCHING: 'Aguardando',
      WAITING: 'Aguardando',
      BUSY: 'Em atendimento',
      PAUSED: 'Indisponível',
    };

    return meIsOpen ? (
      <Container tabIndex={0} onKeyUp={handleCloseModalWithEsc}>
        <Content>
          <ListHeader>
            <ListHeaderTitle>Profissionais neste plantão</ListHeaderTitle>
            <ListHeaderCloseButton onClick={closeModal}>
              <IconClose />
            </ListHeaderCloseButton>
          </ListHeader>
          <Table>
            <thead>
              <tr>
                <Th>Nome do profissional</Th>
                <Th>Status</Th>
              </tr>
            </thead>
            <tbody>
              {records?.map((item, index) => (
                <Tr key={item.list.specialist.id}>
                  <Td>
                    <ProfessionalBadge>
                      <ProfessionalBadgeAvatar
                        src={item.list.specialist.avatar}
                      />
                      <ProfessionalBadgeInfo>
                        <ProfessionalBadgeName>
                          {item.list.specialist.name}
                        </ProfessionalBadgeName>
                        <ProfessionalBadgeRole>
                          {item.list.specialist.onCall.descr}
                        </ProfessionalBadgeRole>
                      </ProfessionalBadgeInfo>
                    </ProfessionalBadge>
                  </Td>
                  <Td>
                    <ProfessionalBadgeStatus
                      status={
                        item.list.status as
                          | 'WAITING'
                          | 'AVAILABLE'
                          | 'MATCHING'
                          | 'PAUSED'
                          | 'BUSY'
                      }
                    >
                      {
                        labelStatus[
                          item.list.status as
                            | 'WAITING'
                            | 'AVAILABLE'
                            | 'MATCHING'
                            | 'PAUSED'
                            | 'BUSY'
                        ]
                      }
                    </ProfessionalBadgeStatus>
                  </Td>
                </Tr>
              ))}
            </tbody>
          </Table>
        </Content>
      </Container>
    ) : null;
  };

export default ProfessionalsOnDutySidesheet;
