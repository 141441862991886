/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/no-this-in-sfc */
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { createIntl, createIntlCache } from 'react-intl';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';
import { iEditConsultInformation, iUpdateAppointment } from './interface';
import {
  Body,
  Container,
  Title,
  WrapperButton,
} from './styles/StyledEditConsult';

import { UpdateAppointment as ReduxUpdateAppointment } from '~/domain/usecases/appointment/redux/UpdateAppointment';
import { UpdateAppointment as RemoteUpdateAppointment } from '~/domain/usecases/appointment/remote/UpdateAppointment';

import { translator } from '../i18n';

import { ConnectComponent, makeTime } from './mapper/MapperEditConsult';
import { filterAppointment } from './mapper/request';

import { DateHour } from '.';
import GeneralData from './GeneralData';

import { iStore } from '~/domain/interfaces/models';

import { makeRemoteGetAllAppointment } from '~/main/factories/usecases/appointment/GetAllAppointmentFactory';
import { makeReduxSetShowModal } from '~/main/factories/usecases/showModal/Set';
import {
  schema,
  schemaEditConsult,
} from '~/validation/validators/appointment/UpdateAppointmentValidator';
import { makeDateAndDuration } from '../../../utils/makeDateAndDuration';
import { AlertMessage } from '../messages/AlertMessage';
import ButtonSave from './ButtonSave';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

export interface ownProps {
  actionUpdate: RemoteUpdateAppointment | ReduxUpdateAppointment;
  editConsult: (e: number) => iEditConsultInformation;
}

interface iProps {
  key: string;
  value: string;
}

export interface iParamsTypes {
  appointmentId: string;
}

const EditConsult: React.FC<ownProps> = props => {
  const { results } = useSelector((store: iStore) => store.professional);

  const { role } = useSelector((store: iStore) => store.auth.selectUser);

  const stateParams = useLocation<iParamsTypes>().state;

  // const loading = useSelector((store:iStore) => store.)
  const [appointment, setAppointment] = useState<iUpdateAppointment>({});

  const methods = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    resolver: zodResolver(schemaEditConsult),
    defaultValues: {
      ...appointment,
    },
  });

  const disableButton = useMemo(() => {
    const { hourStart, hourEnd } = methods.getValues();

    if (!!hourStart?.length && !!hourEnd?.length) {
      return false;
    }

    return true;
  }, [methods]);

  useEffect(() => {
    makeRemoteGetAllAppointment()
      .getAll({
        filter: { appointmentId: Number(stateParams.appointmentId) },
      })
      .then(res => {
        const foundProfessional = results.find(
          item =>
            Number(item.professional.id) === res.results[0].professional?.id,
        );

        const result = {
          duration: res.results[0].appointment.duration,
          consultant: res.results[0].consultant!.id,
          professional: res.results[0].professional?.id,
          specialty: res.results[0].specialty.id,
          date: res.results[0].appointment.scheduled,
          hourEnd: makeTime(
            res.results[0].appointment.scheduled,
            res.results[0].appointment.duration,
          ).endFormat,
          hourStart: makeTime(
            res.results[0].appointment.scheduled,
            res.results[0].appointment.duration,
          ).startFormat,
          type: res.results[0].appointment!.type,
          serviceArea: foundProfessional?.profession.id ?? -1,
          title: res.results[0].appointment?.title ?? '',
        };

        methods.reset(result);
        setAppointment(result);
      });

    makeReduxSetShowModal().set({ appointment: false });
    filterAppointment(Number(stateParams.appointmentId));
  }, [stateParams.appointmentId]);

  useEffect(() => {
    const { hourStart, hourEnd } = methods.getValues();

    if (
      (appointment.hourStart && !hourStart) ||
      (appointment.hourEnd && !hourEnd)
    ) {
      if (!hourStart) methods.setValue('hourStart', appointment.hourStart);
      if (!hourEnd) methods.setValue('hourEnd', appointment.hourEnd);
    }
  }, [appointment.hourEnd, appointment.hourStart]);

  const updateAppointment = () => {
    let dataToSend: ReduxUpdateAppointment.Params =
      {} as ReduxUpdateAppointment.Params;

    setAppointment(prevState => {
      let hourEnd: string | undefined = prevState.hourEnd!;

      if (hourEnd.length > 5) {
        // Get HH:mm caracters from format YYYY-MM-DDTHH:mm:ss.sssZ

        hourEnd = hourEnd?.slice(11, 16);
      }

      const { start, duration } = makeDateAndDuration(
        prevState.hourStart!,
        hourEnd!,
        prevState.date!,
      );

      const isoString = start.toISOString();
      const isoStringWithTimezone = isoString.replace(
        '000Z',
        `${start.getTimezoneOffset()}Z`,
      );

      dataToSend = {
        appointmentId: Number(stateParams.appointmentId),
        date: isoStringWithTimezone,
        duration,
        professional: prevState.professional!,
        specialty: prevState.specialty!,
        consultant: prevState.consultant!,
        type: prevState.type!,
        title: prevState?.title || '',
        // TODO: Remover mock depois
        companions:
          prevState.email !== undefined
            ? [
                {
                  name: prevState.name !== undefined ? prevState.name : '',
                  email: prevState.email !== undefined ? prevState.email : '',
                  role: prevState.role !== undefined ? prevState.role : 'OTHER',
                  phone: prevState.phone !== undefined ? prevState.phone : '',
                },
              ]
            : [],
      };

      return prevState;
    });

    try {
      schema.parse(dataToSend);
      props.actionUpdate.update(dataToSend!);
    } catch (e) {
      AlertMessage({
        type: 'danger',
        message: intl.formatMessage({
          id: 'Falha ao tentar atualizar. Verifique os dados e tente novamente',
        }),
      });
    }
  };

  const menuOptions = [
    translator('Dados Gerais'),
    translator('Data e Hora'),
    // translator('Convidado'),
  ];

  function save(data: iUpdateAppointment) {
    setAppointment(prevState => ({ ...prevState, ...data }));
    updateAppointment();
  }

  const onSubmit = methods.handleSubmit(data => {
    makeReduxSetShowModal().set({ appointment: false });

    updateAppointment();
  });

  const handleChange = (data: iProps) => {
    methods.setValue(`${data.key}` as any, data.value);
    setAppointment({ ...appointment, [data.key]: data.value });
    // If the date,consultant or professional is changed then time is reset
    if (
      data.key === 'date' ||
      data.key === 'consultant' ||
      data.key === 'professional'
    ) {
      cleanTime(data);
    }
    if (data.key === 'date') {
      // cleanData(data);
    }
    if (data.key === 'serviceArea') {
      cleanNewServiceArea(data);
    }

    setAppointment(prv => {
      return prv;
    });
    // console.log('getValues', getValues());
  };
  const cleanData = (data: iProps) => {
    const currentData = appointment[`${data.key}` as keyof iUpdateAppointment];
    const newData = data.value;
    if (currentData !== newData) {
      setAppointment(prevState => ({
        ...prevState,
        ...{ consultant: -1 },
        ...{ professional: -1 },
        ...{ serviceArea: -1 },
        ...{ specialty: -1 },
      }));
      methods.setValue('consultant', -1);
      methods.setValue('professional', -1);
      methods.setValue('serviceArea', -1);
      methods.setValue('specialty', -1);
    }
  };
  const cleanNewServiceArea = (data: iProps) => {
    const currentData = appointment[`${data.key}` as keyof iUpdateAppointment];
    const newData = data.value;

    if (currentData !== newData) {
      setAppointment(prevState => ({
        ...prevState,
        ...{ specialty: -1 },
        ...{ professional: -1 },
      }));
      methods.setValue('specialty', -1);
      methods.setValue('professional', -1);
    }
  };
  const cleanTime = (data: iProps) => {
    const currentData = appointment[`${data.key}` as keyof iUpdateAppointment];
    const newData = data.value;

    if (currentData !== newData) {
      setAppointment(prevState => ({
        ...prevState,
        ...{ hourStart: '' },
        ...{ hourEnd: '' },
      }));
      methods.setValue('hourEnd', '');
      methods.setValue('hourStart', '');
    }
  };

  if (!appointment.date) return null;

  return (
    <Container>
      {/* <div id="header">
        <HeaderMenu
          setState={setActiveMenu}
          options={menuOptions}
          active={activeMenu}
        />
      </div> */}

      <Body>
        <Title>{menuOptions[0]}</Title>
        <FormProvider {...methods}>
          <form onSubmit={onSubmit}>
            <GeneralData
              watch={methods.watch}
              getValues={methods.getValues}
              state={appointment}
              setValue={methods.setValue}
              register={methods.register}
              handleChangeEditConsult={handleChange}
              errors={methods.errors}
            />

            <Title>{menuOptions[1]}</Title>
            <DateHour
              setValue={methods.setValue}
              register={methods.register}
              handleSubmit={methods.handleSubmit}
              control={methods.control}
              handleChangeEditConsult={handleChange}
              errors={methods.errors}
              appointmentId={stateParams.appointmentId}
              state={appointment}
            />

            <WrapperButton>
              <ButtonSave
                action={onSubmit}
                disable={disableButton || role === 'CON'}
              />
            </WrapperButton>
          </form>
        </FormProvider>
        {/* {activeMenu === 2 && <Guest state={appointment} save={save} />} */}
      </Body>
    </Container>
  );
};

export default ConnectComponent(EditConsult);
