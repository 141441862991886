/* eslint-disable react/jsx-one-expression-per-line */
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { iClinicalDocs, iStore } from '~/domain/interfaces/models';
import { GetClinicalDocs } from '~/domain/usecases/clinicalDocs/remote';
import { makeRemoteGetClinicalDocs } from '~/main/factories/usecases/clinicalDocs/GetClinicalDocsFactory';
import { makeReduxUpdateClinicalDocs } from '~/main/factories/usecases/clinicalDocs/UpdateClinicalDocsFactory';
import {
  IconNotSignedDocument,
  IconSignedDocuments,
} from '~/presentation/base/icons';
import { translator } from '~/presentation/components/i18n';
import { iCreateMedicalCertificate } from '~/presentation/components/medicalCertificate/interface';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import { Signature } from '~/presentation/components/signature';
import { getLocale } from '~/utils/getLocale';

import {
  Container,
  Header,
  IconHeader,
  IconHeaderRed,
  Content,
  ContentHeader,
  ContentHeaderText,
  Item,
  ItemHeader,
  DrugName,
  Posology,
  PosologyContent,
  ContentBody,
  ContentInfo,
  ContentHeaderInfo,
  ContentBodyText,
  ContentHeaderInfoText,
  StatusContainer,
  Signed,
} from './styles/StyledSimplePrescription';

interface SimplePrescriptionProps {
  docId: number;
}

const SimplePrescription: React.FC<SimplePrescriptionProps> = ({ docId }) => {
  const [clinicalDoc, setClinicalDoc] = useState<iClinicalDocs['results'][0]>(
    {} as iClinicalDocs['results'][0],
  );
  const [certificate, setCertificate] = useState<iCreateMedicalCertificate>(
    {} as iCreateMedicalCertificate,
  );

  const { role } = useSelector((store: iStore) => store.auth.selectUser);
  const { results } = useSelector((store: iStore) => store.clinicalDocs);

  const signDoc = useCallback(() => {
    const dataToSend: any = {
      appointment: clinicalDoc.appointment.id,
      docId,
      type: clinicalDoc.type.id,
      content: {
        drugs: clinicalDoc.document?.content.drugs ?? [],
      },
      certificate: certificate.certificate,
      certificateId: certificate.certificateId,
    };

    if (clinicalDoc.type.id === 2) {
      dataToSend.content = {
        observacoes: clinicalDoc.document?.content.observacoes,
        periodo: clinicalDoc.document?.content.periodo,
      };
    }
    if (clinicalDoc.type.id === 3) {
      dataToSend.content = {
        solicitacao: clinicalDoc.document?.content.solicitacao || [],
        indicacaoClinica: clinicalDoc.document?.content.indicacaoClinica || [],
      };
    }

    try {
      makeReduxUpdateClinicalDocs().update(dataToSend);
    } catch (e) {
      console.error(e);

      AlertMessage({
        message: translator('Não foi possível assinar o documento.'),
        type: 'danger',
      });
    }
  }, [certificate, clinicalDoc, docId]);

  const loadDocs = useCallback(() => {
    const found = results.find(item => item.document?.id === docId);

    if (found) setClinicalDoc(found);
  }, [docId, results]);

  useEffect(() => {
    loadDocs();
  }, [docId, loadDocs]);

  return (
    <Container>
      <Header isSigned={clinicalDoc?.document?.status === 'SIGNED'}>
        {clinicalDoc?.document?.status === 'SIGNED' ? (
          <IconHeader />
        ) : (
          <IconHeaderRed />
        )}
        {translator('Receita Simples')}
      </Header>
      {clinicalDoc?.document?.status !== 'SIGNED' && role === 'PRO' && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '10px',
          }}
        >
          <Signature
            docId={docId}
            appointmentId={clinicalDoc?.appointment?.id}
            isSigned={false}
            changeSelected={(e: {
              certificateId: string;
              certificate: string;
            }) =>
              // setCertificate({
              //   certificate: e.certificate,
              //   certificateId: e.certificateId,
              // })
              console.log('oi')
            }
            changeDisabled={(state: boolean) => console.log('state: ', state)}
            onSignSubmit={signDoc}
          />
        </div>
      )}
      <ContentInfo>
        <ContentHeaderInfo>
          <ContentHeaderInfoText>
            {`${translator('Profissional')}: `}
            <ContentBodyText bold>
              {`${clinicalDoc?.professional?.firstName} ${clinicalDoc?.professional?.lastName}`}
            </ContentBodyText>
          </ContentHeaderInfoText>
          <ContentHeaderInfoText>
            {`${translator('Paciente')}: `}
            <ContentBodyText bold>
              {`${clinicalDoc?.patient?.firstName} ${clinicalDoc?.patient?.lastName}`}
            </ContentBodyText>
          </ContentHeaderInfoText>
          <ContentHeaderInfoText>
            {`${translator('Data de emissão')}: ${new Date(
              String(clinicalDoc?.document?.sent),
            ).toLocaleDateString(getLocale())}`}
          </ContentHeaderInfoText>
        </ContentHeaderInfo>
      </ContentInfo>
      <Content>
        <ContentHeader>
          <ContentHeaderText>{translator('Droga')}</ContentHeaderText>
          <ContentHeaderText>{translator('Dosagem')}</ContentHeaderText>
        </ContentHeader>
        <ContentBody>
          {clinicalDoc?.document?.content?.drugs?.map((drug, index) => (
            <Item
              last={
                index ===
                Number(clinicalDoc?.document?.content?.drugs?.length) - 1
              }
            >
              <ItemHeader>
                <DrugName>{drug.name}</DrugName>
                <Posology>{drug.dosage}</Posology>
              </ItemHeader>
              <ContentHeaderText marginBottom>
                {translator('Posologia')}
              </ContentHeaderText>
              <PosologyContent>{drug.posology}</PosologyContent>
            </Item>
          ))}
        </ContentBody>
      </Content>
      <StatusContainer>
        <Signed isSigned={clinicalDoc?.document?.status === 'SIGNED'}>
          {clinicalDoc?.document?.status === 'SIGNED' ? (
            <>
              <IconSignedDocuments />
              {translator('Assinado')}
            </>
          ) : (
            <>
              <IconNotSignedDocument />
              {translator('Pendente')}
            </>
          )}
        </Signed>
      </StatusContainer>
    </Container>
  );
};

export default SimplePrescription;
