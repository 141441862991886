import React, { useState, forwardRef } from 'react';
import PhoneInput from 'react-phone-input-2';

import 'react-phone-input-2/lib/bootstrap.css'

import { Container, Label, InputContainer, Message } from './styles';

interface CustomPhoneInputProps {
  name?: string;
  label?: string;
  message?: string;
  width?: string;
  height?: string;
  error?: boolean;
  required?: boolean;
  register?: () => any;
  disabled?: boolean;
  gridArea?: string;
  value?: string;
  country?: string;
  preferredCountries?: string[];
  onChange?: (value: string) => void;
}

const CustomPhoneInput: React.ForwardRefRenderFunction<HTMLInputElement, CustomPhoneInputProps> = (
  {
    label,
    message,
    error = false,
    required = false,
    width = '100%',
    height = '48px',
    register,
    gridArea,
    value,
    country,
    preferredCountries,
    onChange,
    ...rest
  },
  ref,
) => {
  const [focus, setFocus] = useState<boolean>(false);

  const mTop = label ? '4px' : '0px';
  const mBottom = message ? '2px' : '0px';
  const { disabled } = rest;


  // if the component are use react-hook-form.
  if (register) {
    register();
    console.log('register', register)
  }

  let inputLabel = label;
  if (required) inputLabel = `${label}*`;
  if (!country) country = 'mx';

  return (
    <Container width="100%" gridArea={gridArea}>
      {label && <Label>{inputLabel}</Label>}
      <InputContainer
        // style={{ marginTop: mTop, marginBottom: mBottom }}
        height={height}
        focus={focus}
        disabled={disabled}
        error={error}
      >
        <PhoneInput
          country={country}
          disabled={disabled}
          value={value}
          onChange={onChange}
          preferredCountries={['br', 'mx']}
          inputStyle={{
            width,
            height,
            marginTop: mTop, 
            marginBottom: mBottom
          }}
          containerStyle={{ width: '100%' }}
          {...rest}
        />
        {/* {icon && <Logo className="icon" />} */}
        {/* {error && <IconErrorInput className="icon" />} */}
      </InputContainer>
      <Message error={error}>{message}</Message>
    </Container>
  );
};

export default forwardRef(CustomPhoneInput);
