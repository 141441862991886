/* eslint-disable react/react-in-jsx-scope */
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { GetHealthPlan } from '~/domain/usecases/healthPlan/Remote';
import { makeReduxGetAllAppointment } from '~/main/factories/usecases/appointment/GetAllAppointmentFactory';
import { makeReduxSetData } from '~/main/factories/usecases/appointment/SetDataFactory';
import { makeRemoteGetHealthPlan } from '~/main/factories/usecases/healthPlan/GetHealthPlan';

type Filters = {
  modality: Array<string | number>;
  priority: Array<string | number>;
  status: Array<string | number>;
  healthPlan: Array<string | number>;
};

type MyAppointmentsFilterContextData = {
  filters: Filters;
  currentDay: Date;
  healthPlans: GetHealthPlan.Model;
  isFilterActive: boolean;
  isFilterPopoverOpen: boolean;
  filtersBody: Partial<Filters>;
  hasAnyFilterSelected: boolean;
  setFilters: (filter: keyof Filters, value: string | number) => void;
  setIsFilterPopoverOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setHealthPlans: React.Dispatch<React.SetStateAction<GetHealthPlan.Model>>;
  handleApplyFilters: () => void;
  handleResetFilters: () => void;
  handleSelectAllHealthPlans: () => void;
  handleCancelNewFilters: () => void;
  setCurrentDay: (date: string) => void;
};

const filtersInitialState: Filters = {
  modality: [],
  priority: [],
  status: [],
  healthPlan: [],
};

const MyAppointmentsFilterContext =
  createContext<MyAppointmentsFilterContextData>(
    {} as MyAppointmentsFilterContextData,
  );

export const MyAppointmentsFilterProvider: React.FC = ({ children }) => {
  const [filters, setFilters] = useState<Filters>(filtersInitialState);
  const [filtersActive, setFiltersActive] =
    useState<Filters>(filtersInitialState);
  const [healthPlans, setHealthPlans] = useState<GetHealthPlan.Model>([]);

  const [isFilterActive, setIsFilterActive] = useState(false);
  const [isFilterPopoverOpen, setIsFilterPopoverOpen] = useState(false);

  const { date: appointmentDate } = useSelector(
    (store: iStore) => store.appointment,
  );

  const currentDay = useMemo(() => {
    return appointmentDate ? new Date(appointmentDate) : new Date();
  }, [appointmentDate]);

  const hasAnyFilterSelected = useMemo(() => {
    return Object.values(filters).some(filter => filter.length > 0);
  }, [filters]);

  const info = useSelector((store: iStore) => store.auth.info);
  const { orgId } = useSelector((store: iStore) => store.auth.selectUser);

  const professionalId = info.professionals?.find(
    item => item.org.id === orgId,
  )?.id;

  const filtersBody = useMemo(() => {
    return Object.entries(filtersActive).reduce((acc, [key, value]) => {
      if (value.length > 0) {
        return { ...acc, [key]: value };
      }
      return acc;
    }, {});
  }, [filtersActive]);

  const handleSetFilters = useCallback(
    (filter: keyof typeof filters, value: string | number) => {
      const exists = filters[filter].includes(value);

      if (isFilterActive) setFiltersActive(filters);

      if (exists) {
        const newValues = filters[filter].filter(v => v !== value);
        setFilters({ ...filters, [filter]: newValues });
        return;
      }
      setFilters({ ...filters, [filter]: [...filters[filter], value] });
    },
    [filters, isFilterActive],
  );

  const handleCloseModalAfterApplyFilters = useCallback(() => {
    setTimeout(() => setIsFilterPopoverOpen(false), 500);
  }, []);

  const handleApplyFilters = useCallback(() => {
    setIsFilterActive(hasAnyFilterSelected);

    currentDay.setHours(0, 0, 0, 0);
    const endOfDay = new Date(currentDay);
    endOfDay.setHours(23, 59, 99, 999);

    makeReduxGetAllAppointment().getAll({
      filter: {
        scheduleFrom: currentDay.toISOString(),
        scheduleTo: endOfDay.toISOString(),
        org: orgId,
        professional: professionalId,
        // verify if is necessary to send the filters
        ...filtersBody,
      },
      orderAttribute: 'scheduled',
    });
    setFiltersActive(filters);
    handleCloseModalAfterApplyFilters();
  }, [
    currentDay,
    filters,
    filtersBody,
    handleCloseModalAfterApplyFilters,
    hasAnyFilterSelected,
    orgId,
    professionalId,
  ]);

  const handleResetFilters = useCallback(() => {
    setFilters(filtersInitialState);

    const startOfCurrentDay = new Date(currentDay);
    startOfCurrentDay.setHours(0, 0, 0, 0);
    const endOfCurrentDay = new Date(currentDay);
    endOfCurrentDay.setHours(23, 59, 99, 999);

    makeReduxGetAllAppointment().getAll({
      filter: {
        scheduleFrom: startOfCurrentDay.toISOString(),
        scheduleTo: endOfCurrentDay.toISOString(),
        org: orgId,
        professional: professionalId,
      },
    });

    setFiltersActive(filtersInitialState);
    setIsFilterActive(false);

    handleCloseModalAfterApplyFilters();
  }, [currentDay, handleCloseModalAfterApplyFilters, orgId, professionalId]);

  const handleCancelNewFilters = useCallback(() => {
    setFilters(filtersActive);
    if (Object.keys(filtersActive).length === 0) {
      setIsFilterActive(false);
    }
  }, [filtersActive]);

  const handleSelectAllHealthPlans = useCallback(() => {
    setFilters({
      ...filters,
      healthPlan: healthPlans.map(hp => hp.id),
    });
  }, [filters, healthPlans, setFilters]);

  const getHealthPlans = useCallback(async () => {
    try {
      const response = await makeRemoteGetHealthPlan().get({
        limit: 9999,
      });

      setHealthPlans(response);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const setCurrentDay = useCallback((date: string) => {
    makeReduxSetData().set({ date });
  }, []);

  useEffect(() => {
    getHealthPlans();
  }, [getHealthPlans]);

  return (
    <MyAppointmentsFilterContext.Provider
      value={{
        filters,
        currentDay,
        healthPlans,
        isFilterActive,
        filtersBody,
        hasAnyFilterSelected,
        isFilterPopoverOpen,
        setIsFilterPopoverOpen,
        setFilters: handleSetFilters,
        setHealthPlans,
        handleApplyFilters,
        handleResetFilters,
        handleSelectAllHealthPlans,
        handleCancelNewFilters,
        setCurrentDay,
      }}
    >
      {children}
    </MyAppointmentsFilterContext.Provider>
  );
};

export const useMyAppointmentsFilter = (): MyAppointmentsFilterContextData => {
  const context = useContext(MyAppointmentsFilterContext);
  if (!context) {
    throw new Error(
      'useMyAppointmentsFilter must be used within an MyAppointmentsFilterProvider',
    );
  }
  return context;
};

export default MyAppointmentsFilterContext;
