import styled, { css } from 'styled-components';

export const ContainerModal = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  z-index: 3;
`;

export const Container = styled.div`
  width: 600px;
  height: fit-content;
  border-radius: 10px;
  border: 1px solid #dedede;
  background: #fdfdfd;
  overflow-y: auto;
`;

export const ContentForm = styled.form`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const HeaderModal = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    color: #222529;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    font-size: 16px;
  }

  svg {
    cursor: pointer;
    path {
      stroke: #222529;
    }
  }
`;

export const InlineForms = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const TextAreaForm = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  h3 {
    color: #444a51;
    font-size: 14px;
    margin-bottom: 12px;
  }

  textarea {
    resize: none;
    width: 400px;
    height: 120px;
    border-radius: 6px;
    border: 1px solid #b4b7b9;
    background: #fdfdfd;
    padding: 12px;

    ::placeholder {
      color: #818385;
    }
  }
`;

export const MessageError = styled.span`
  color: red;
  font-size: 12px;
`;

export const PriorityForm = styled.div`
  display: flex;
  width: 40%;
  height: 76px;
  flex-direction: column;
  justify-content: space-between;

  h3 {
    color: #444a51;
    font-size: 14px;
  }

  div {
    display: flex;
    gap: 12px;
  }
`;

interface PriorityButtonProps {
  priority: string;
  selected: boolean;
}

export const PriorityButton = styled.button<PriorityButtonProps>`
  border-radius: 6px;
  border: 1px solid #b4b7b9;
  background: #fdfdfd;
  padding: 12px;

  ${({ priority, selected }) => {
    if (selected) {
      switch (priority) {
        case 'LOW':
          return css`
            border-color: #519a08;
            background: #f6ffed;
          `;
        case 'NORMAL':
          return css`
            border-color: #e65a0b;
            background: #fff0e5;
          `;
        case 'EMERGENCY':
          return css`
            border-color: #dd0404;
            background: #ffefef;
          `;
        default:
          return css`
            border-color: #519a08;
            background: #f6ffed;
          `;
      }
    } else {
      return css`
        border-color: #b4b7b9;
        background: #f8f8f8;
      `;
    }
  }}
`;
