import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const Grid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 12px;
`;

export const Form = styled.form`
  /* display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 12px; */

  /* display: grid;
  grid-template-columns: 1fr 1fr; 
  grid-template-areas:
    'period period period period' 
    'input1 input2' 
    'buttons buttons'; 
  grid-gap: 20px;  */
`;
