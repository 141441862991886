/* eslint-disable consistent-return */
import React, { useCallback, useState } from 'react';
import axios from 'axios';
import { Container, Popover, Option, Text } from './styles/optionsMore';
import {
  IconPdf,
  IconCloud,
  IconEmail,
  IconHome,
  IconDelete,
} from '~/presentation/base/icons';
import { makeRemoteGetByDownloadSupportDocs } from '~/main/factories/usecases/supportDoc/GetByDownloadSupportDocsFactory';
import { SendMail } from '~/presentation/components/messages/email/SendMail';
import { History } from '~/main/routes';
import { makeRemoteDeleteSupportDocs } from '~/main/factories/usecases/supportDoc/DeleteSupportDocsFactory';
import { translator } from '../i18n';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import { closeModal } from '~/utils/closeModal';

interface ownProps {
  supportDocID?: number;
  extension?: string;
}

const OptionsSupportDoc: React.FC<ownProps> = ({ supportDocID, extension }) => {
  const id = supportDocID || 1;
  const [urlPdf, setUrl] = useState<string>();

  async function downloadFile() {
    if (id === 0) {
      alert('Selecione um arquivo');
      return;
    }

    if (Number.isNaN(id)) {
      alert('Selecione um arquivo');
      return;
    }

    const { url } = await makeRemoteGetByDownloadSupportDocs().get({
      docId: Number(id),
    });

    setUrl(url);

    axios
      .get(url, {
        headers: { 'Content-Type': 'application/octet-stream' },
        responseType: 'blob',
      })
      .then(response => {
        const link = document.createElement('a');
        const urlToDownload = window.URL.createObjectURL(response.data);
        const name = 'FILE';
        link.href = urlToDownload;
        link.download = name;
        link.click();
      })
      .catch(err => {
        console.error('Falha ao realizar download do arquivo.', err);
      });
  }

  const handleViewDetails = async () => {
    const { url } = await makeRemoteGetByDownloadSupportDocs().get({
      docId: Number(id),
    });

    window.open(
      url,
      '_blank', // <- This is what makes it open in a new window.
    );
  };

  const pending = translator('Excluir documento');
  const confirmExclude = translator('Você deseja excluir o documento?');

  const handleDelete = useCallback(() => {
    makeReduxActiveMessage().active({
      active: 'delete',
      title: pending,
      content: confirmExclude,
      actionOk: () => {
        makeRemoteDeleteSupportDocs().delete({
          docId: id,
        });
        closeModal();
      },
      actionCancel: closeModal,
    });
  }, [confirmExclude, id, pending]);

  const shareFile = ({ message }: any) => {
    // return <SendMail message={message} />;
  };

  return (
    <Container>
      <Popover>
        {/* {extension === 'pdf' && (
          <Option
            onClick={() =>
              History.getHistory().push('/supportdoc/detail', {
                docId: id,
                from: window.location.pathname.replaceAll('/', ''),
              })
            }
          >
            <IconPdf />
            <Text>Visualizar PDF</Text>
          </Option>
        )} */}

        <Option onClick={handleViewDetails}>
          <IconPdf />
          <Text>Visualizar</Text>
        </Option>

        <Option id="btn_download" onClick={() => downloadFile()}>
          <IconCloud />
          <Text>Baixar</Text>
        </Option>
        {/* TODO: SUPPORTING CLINICAL RECORDS DELETE */}
        {/* <Option onClick={handleDelete}>
          <IconDelete />
          <Text>Deletar</Text>
        </Option> */}
        {/* <Option>
          <IconHome />
          <Text>Acesso para farmácia</Text>
        </Option> */}
        {/* <Option>
          <IconEmail />
          <Text>Reenviar por e-mail</Text>
        </Option> */}
      </Popover>
    </Container>
  );
};

export default OptionsSupportDoc;
