import React from 'react';
import { createIntl, createIntlCache } from 'react-intl';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import translations from '~/infra/i18n/locales';
import { makeReduxDeleteClinicalDocs } from '~/main/factories/usecases/clinicalDocs/DeleteClinicalDocsFactory';
import { makeRemoteGetClinicalDocsByDownload } from '~/main/factories/usecases/clinicalDocs/GetClinicalDocsByDownloadFactory';
import { makeRemoteSendClinicalDocs } from '~/main/factories/usecases/clinicalDocs/SendClinicalDocs';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import { makeRemoteDeleteSupportDocs } from '~/main/factories/usecases/supportDoc/DeleteSupportDocsFactory';
import {
  IconCloud,
  IconDelete,
  IconEmail,
  IconPdf,
  IconSignedDocuments,
  IconSignatureDoc,
} from '~/presentation/base/icons';
import { closeModal } from '~/utils/closeModal';
import { getLocale } from '~/utils/getLocale';
import { translator } from '../i18n';
import { AlertMessage } from '../messages/AlertMessage';
import { Container, Option, Popover, Text } from './styles/optionsMore';
import { makeReduxSelectOne } from '~/main/factories/usecases/clinicalDocs/SelectOneFactory';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

interface ownProps {
  id?: string;
  status?: boolean;
  changeDisplay?: (data: boolean) => any;
  disableView?: boolean;
}

const OptionsClinicalDoc: React.FC<ownProps> = ({
  id,
  status,
  changeDisplay,
  disableView = false,
}) => {
  const { role } = useSelector((store: iStore) => store.auth.selectUser);
  const userEmail = useSelector(
    (store: iStore) => store.consultant.results?.[0]?.user?.email,
  );

  const pendingDocument = translator('Excluir documento pendente?');
  const confirmExclude = translator('Deseja realmente excluir este documento?');

  const downloadFile = async () => {
    console.log('Valor do id: ', id);

    if (id === undefined) {
      alert(
        intl.formatMessage({
          id: 'Selecione um arquivo',
        }),
      );
      return;
    }

    if (Number.isNaN(id)) {
      alert(
        intl.formatMessage({
          id: 'Selecione um arquivo',
        }),
      );
      return;
    }

    const data = await makeRemoteGetClinicalDocsByDownload().getByDownload({
      docId: parseInt(`${id}`, 10),
    });

    const link = document.createElement('a');
    const urlToDownload = window.URL.createObjectURL(data);
    const name = intl.formatMessage({
      id: 'receituario',
    });

    link.href = urlToDownload;
    link.download = name;
    link.click();
  };

  const deleteFile = async () => {
    if (id !== undefined) {
      makeRemoteDeleteSupportDocs().delete({ docId: parseInt(id, 10) });
    }
  };

  const deleteDoc = (docId: number) => {
    try {
      makeReduxDeleteClinicalDocs().delete({
        docId,
      });
    } catch (e) {
      AlertMessage({
        message: intl.formatMessage({
          id: 'Ocorreu um erro ao excluir o documento clínico',
        }),
        type: 'danger',
      });
    }
    closeModal();
  };

  function resendEmail() {
    console.log(userEmail);
    try {
      if (!userEmail) throw new Error('Email não encontrado');

      makeRemoteSendClinicalDocs().post({
        docId: String(id),
        email: [String(userEmail)],
      });
    } catch (e) {
      AlertMessage({
        message: intl.formatMessage({
          id: 'Ocorreu um erro ao reenviar o e-mail, tente novamente mais tarde',
        }),
        type: 'danger',
      });
    }
  }

  function handleDoc(idDoc: number) {
    makeReduxSelectOne().select({
      id: idDoc,
    });
  }

  return (
    <Container>
      <Popover>
        {!disableView && status && (
          <Option
            id="btn_preview"
            onClick={() =>
              window.open(`/clinicaldoc/display?docId=${id}`, '_blank')
            }
          >
            <IconPdf />
            <Text>{translator('Visualizar PDF')}</Text>
          </Option>
        )}
        {status && (
          <Option id="btn_download" onClick={() => downloadFile()}>
            <IconCloud />
            <Text>{translator('Baixar')}</Text>
          </Option>
        )}

        {status !== undefined && changeDisplay && !status && role !== 'CON' && (
          <Option onClick={() => changeDisplay(true)} id="btn_sign_doc">
            <IconSignedDocuments />
            <Text>{translator('Assinar documento')}</Text>
          </Option>
        )}
        {/* <Option>
          <IconHome />
          <Text>Acesso para farmácia</Text>
        </Option> */}
        {status && userEmail && (
          <Option onClick={resendEmail} id="btn_resend_email">
            <IconEmail />
            <Text>{translator('Reenviar por e-mail')}</Text>
          </Option>
        )}
        {!status && role !== 'CON' && (
          <>
            <Option
              onClick={() => {
                makeReduxActiveMessage().active({
                  active: 'delete',
                  title: pendingDocument,
                  content: confirmExclude,
                  actionOk: () => deleteDoc(Number(id)),
                  actionCancel: closeModal,
                });
              }}
              id="btn_delete_document"
            >
              <IconDelete id="btn_delete" />
              <Text>{translator('Excluir')}</Text>
            </Option>
            {role !== 'ADM' && role !== 'ORG' && (
              <Option
                onClick={() => {
                  handleDoc(Number(id));
                }}
                id="btn_sign_document"
              >
                <IconSignatureDoc id="btn_sign" />
                <Text>{translator('Assinar')}</Text>
              </Option>
            )}
          </>
        )}
      </Popover>
    </Container>
  );
};

export default OptionsClinicalDoc;
