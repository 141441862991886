/* eslint-disable no-plusplus */
/* eslint-disable no-loop-func */
/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import * as datefns from 'date-fns';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FormControl, InputLabel, MenuItem, Divider } from '@material-ui/core';
import {
  add,
  format,
  isAfter,
  isEqual,
  parse,
  parseISO,
  isBefore,
} from 'date-fns';
import { useSelector } from 'react-redux';
import { Select } from '../UI';

import { Container, Form, Content } from './styles/StyledDateAndTime';

import { translator } from '../i18n';
import InputKeyboardDate from '../inputKeyboardDate';
import { iRegisterAppointment } from './interface';

import { Navigator } from '../register/Navigator';

import { schemaDateAndTime } from '~/validation/validators/appointment/CreateAppointmentValidator';
import { iStore } from '~/domain/interfaces/models';
import { makeDuration } from '~/utils/makeDurationAndTime';

interface ownProps {
  next: (data: iRegisterAppointment) => any;
  back: (data: iRegisterAppointment) => any;
  state: iRegisterAppointment;

  getTime: (e: string) => typeArrayTime[];
}
export interface typeArrayTime {
  time: string;
  timeFormat: string;
  disabled: boolean;
}
const DateAndTime: React.FC<ownProps> = ({
  state,
  next,
  getTime,
  back,
}): JSX.Element => {
  const {
    errors,
    handleSubmit,
    getValues,
    setValue,
    register,
    control,
    watch,
  } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    resolver: zodResolver(schemaDateAndTime),
    defaultValues: {
      ...state,
    },
  });
  const labelTimeEnd = translator('Hora fim');
  const labelTimeStart = translator('Hora início');
  const selectDate = useSelector((store: iStore) =>
    datefns.format(
      store.appointment.date instanceof Date
        ? store.appointment.date
        : new Date(store.appointment.date),
      'yyyy-MM-dd',
    ),
  );
  const auth = useSelector((store: iStore) => store.auth.info);
  const { role } = useSelector((store: iStore) => store.auth.selectUser);

  const [selectedFilterDate, setSelectedFilterDate] =
    useState<string>(selectDate);
  const [timesEnd, setTimesEnd] = useState<typeArrayTime[]>([]);
  const [defaultStart, setDefaultStart] = useState<string>(
    state.hourStart || '',
  );
  const [defaultEnd, setDefaultEnd] = useState<string>(state.hourEnd || '');
  const [dateIsoString, setDate] = useState<string>(state?.date || '');
  const [oldDate, setOldDate] = useState<string>(state.date || '');

  const [times, setTimes] = useState<typeArrayTime[]>([
    ...getTime(dateIsoString),
  ]);

  const appointments = useSelector(
    (store: iStore) => store.appointment.resultsMap[selectedFilterDate],
  );

  const onSubmit = handleSubmit(data => {
    next({ ...data });
  });

  const handleChange = (event: Date) => {
    const isoString = event.toISOString();
    const isoStringWithTimezone = isoString.replace(
      '000Z',
      `${event.getTimezoneOffset()}Z`,
    );

    setDate(isoStringWithTimezone);
  };
  const onBack = () => back({ ...getValues() });

  useEffect(() => {
    if (state.hourStart === state.hourEnd) {
      setValue('hourStart', '');
      setValue('hourEnd', '');
    }
    // The function getTime has been moved to connect to work correctly
    // with the persistence of the screen change [SM]
    setTimes([...getTime(dateIsoString)]);
    setUpAvaiableTime();

    // the initial value defaultStart and defaultEnd  are the same
    // ,as soon as changed start time the timesEnd is also changed [SM]
    if (defaultStart !== defaultEnd) {
      setUpTimesEnd(defaultStart);
    }

    // if the date changed -> reset times [SM]
    if (dateIsoString !== oldDate) {
      setOldDate(dateIsoString);
      const newDate = new Date();
      setDefaultStart(newDate.toISOString());
      setTimesEnd([]);
      setValue('hourStart', '');
      setValue('hourEnd', '');
    }
    // // set undefined inital value to work well with zod [SM]
    // if (dateIsoString !== oldDate) {
    //   setValue('hourStart', undefined);
    //   setValue('hourEnd', undefined);
    // }
  }, [dateIsoString, selectedFilterDate]);

  const setUpAvaiableTime = () => {
    appointments?.forEach(item => {
      // if the user is an organizer then an additional filter to
      // check if the professional or consultant has time blocking
      if (role === 'ORG') {
        const isProfessional = item.professional?.id === state.professional;
        const isConsultant = item.consultant?.id === state.consultant;
        if (!isProfessional && !isConsultant) {
          return;
        }
      }

      const dateFromAppointment = new Date(item.appointment.scheduled);
      const dateFromInput = new Date(dateIsoString);
      // filter dateInput and dateAppointment
      if (
        isEqual(
          new Date(
            dateFromAppointment.getFullYear(),
            dateFromAppointment.getMonth(),
            dateFromAppointment.getDate(),
          ),
          new Date(
            dateFromInput.getFullYear(),
            dateFromInput.getMonth(),
            dateFromInput.getDate(),
          ),
        )
      ) {
        const { dateStartIsoString, dateEndIsoString } = makeDuration(
          item.appointment.scheduled,
          item.appointment.duration,
        );

        setTimes(prevState =>
          prevState.map(el => {
            let dateAppointment = new Date(item.appointment.scheduled);
            const timeFromArray = new Date(el.time);
            dateAppointment = new Date(
              dateAppointment.getFullYear(),
              dateAppointment.getMonth(),
              dateAppointment.getDate(),
              timeFromArray.getHours(),
              timeFromArray.getMinutes(),
            );

            const dateFromAppointmentString = new Date(
              dateAppointment.getFullYear(),
              dateAppointment.getMonth(),
              dateAppointment.getDate(),
              timeFromArray.getHours(),
              timeFromArray.getMinutes(),
            ).toISOString();

            const dateFromAppointmentFormattedIsoString =
              dateFromAppointmentString.replace(
                '000Z',
                `${dateFromAppointment.getTimezoneOffset()}Z`,
              );

            if (isBefore(new Date(el.time), new Date())) {
              return { ...el, disabled: true };
            }

            const isAvaibleAppointment =
              item.appointment.status === 'CANCELED' ||
              item.appointment.status === 'ENDED';

            if (isAvaibleAppointment) return el;
            // Filter time from interval
            if (
              (isAfter(
                new Date(dateFromAppointmentFormattedIsoString),
                new Date(dateStartIsoString),
              ) ||
                isEqual(
                  new Date(dateFromAppointmentFormattedIsoString),
                  new Date(dateStartIsoString),
                )) &&
              isBefore(
                new Date(dateFromAppointmentFormattedIsoString),
                new Date(dateEndIsoString),
              ) &&
              !el.disabled
            ) {
              return { ...el, disabled: true };
            }

            return el;
          }),
        );
      }
    });
  };

  const setUpTimesEnd = (event: string) => {
    const timeStart = new Date(event);
    let breakMap = false;
    const timeEnd: typeArrayTime[] = [];

    for (let i = 0; i < times.length; i++) {
      if (isEqual(new Date(times[i].time), timeStart)) {
        breakMap = true;
      }
      if (
        isAfter(new Date(times[i].time), timeStart) &&
        !times[i].disabled &&
        breakMap
      ) {
        timeEnd.push(times[i]);
      }
      if (times[i].disabled && breakMap) {
        // if exist appointment then add start time appointment in array timesEnd [SM]
        let lastTime = { ...times[i] };
        lastTime = { ...lastTime, disabled: false };
        timeEnd.push(lastTime);
        break;
      }
    }
    setTimesEnd([...timeEnd]);
  };

  useEffect(() => {
    if (watch('date')) {
      setSelectedFilterDate(
        datefns.format(new Date(watch('date')), 'yyyy-MM-dd'),
      );
    }
  }, [watch]);

  return (
    <Container>
      <Form onSubmit={onSubmit}>
        <Content>
          <Controller
            render={({ value, onChange }) => (
              <InputKeyboardDate
                width="53%"
                state={value}
                setState={(date: Date) => {
                  handleChange(date);
                  onChange(date?.toISOString());
                }}
                name="date"
                autofocus
                error={Boolean(errors.date)}
                message={errors.date?.message}
                label={translator('Data do atendimento')}
                minDate={new Date()}
              />
            )}
            name="date"
            control={control}
            defaultValue={state.date}
            label={translator('Data do atendimento')}
            minDate={new Date()}
          />
          <Select
            id="input_hourStart"
            width="160px"
            defaultValue={state.hourStart}
            value={defaultStart}
            onChange={e => {
              setUpTimesEnd(e.target.value);
              setDefaultStart(e.target.value);
              setValue('hourStart', e.target.value as string);
              setValue('hourEnd', '');
              setDefaultEnd('');
            }}
            register={() => register('hourStart')}
            error={Boolean(errors.hourStart)}
            message={
              errors?.hourStart?.message
                ? translator(errors?.hourStart?.message)
                : ''
            }
            label={labelTimeStart}
            required
            name="hourStart"
          >
            <option id="option_0" value="">
              {labelTimeStart}
            </option>
            {times.map((item: typeArrayTime, index) => (
              <option
                id={`option_${index}`}
                disabled={item.disabled}
                value={item.time}
                style={{
                  backgroundColor: item.disabled ? '#C9C9C9' : '#fff',
                }}
              >
                {item.timeFormat}
              </option>
            ))}
          </Select>
          <Select
            label={labelTimeEnd}
            defaultValue={state.hourEnd}
            value={defaultEnd}
            width="160px"
            id="input_hourFinish"
            onChange={e => {
              setDefaultEnd(e.target.value);
              setValue('hourEnd', e.target.value as string);
            }}
            register={() => register('hourEnd')}
            name="hourEnd"
            error={Boolean(errors.hourEnd)}
            message={
              errors?.hourEnd?.message
                ? translator(errors?.hourEnd?.message)
                : ''
            }
            required
          >
            <option id="option_0" value="">
              {labelTimeEnd}
            </option>
            {timesEnd.map((item: typeArrayTime, index) => (
              <option
                id={`option_${index}`}
                disabled={item.disabled}
                value={item.time}
              >
                {item.timeFormat}
              </option>
            ))}
          </Select>
        </Content>

        <Navigator back={onBack} />
      </Form>
    </Container>
  );
};

export default DateAndTime;
