import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { createIntl, createIntlCache } from 'react-intl';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';
import { iClinicalDocsResults, iStore } from '~/domain/interfaces/models';
import { makeReduxUpdateClinicalDocs } from '~/main/factories/usecases/clinicalDocs/UpdateClinicalDocsFactory';
import { History } from '~/main/routes';
import Header from '../clinicalDocumentViewer/Header';
import { ChooseCertificate } from '../clinicalDocumentViewer/styles/StyledClinicalDocumentViewer';
import { PageHeader } from '../header';
import { translator } from '../i18n';
import { Menu } from '../menu';
import { AlertMessage } from '../messages/AlertMessage';
import InfoUserCard from '../sessionInformation/InfoUserCard';
import { SignDoc } from '../signature';
import { StickyHeadTable } from '../Table';
import { ClinicalDocDetails } from './interface';
import { MapConsultantDetails } from './mapper/map';
import {
  HeaderDiv,
  Body,
  Content,
  NavContent,
  Session,
  TextAreaDoc,
} from './styles/StyledSpecialPrescriptionView';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

interface ownProps {
  id: string;
  docId?: number;
}

interface documentContent {
  drug?: string;
  posology?: string;
  dosage?: string;
}

interface certificateProps {
  certificateId: string;
  certificate: string;
  signatureSource?: string;
}

interface iStateParams {
  docId: number;
}

interface Map {
  [key: string]: string | undefined;
}

const SpecialPrescriptionView: React.FC<ownProps> = ({ id, docId }) => {
  const results = useSelector((store: iStore) => store.clinicalDocs.results);
  const clinicalDocs = useSelector((store: iStore) => store.clinicalDocs);
  const { role } = useSelector((store: iStore) => store.auth.selectUser);

  const [info, setInfo] = useState<ClinicalDocDetails>();
  const [clinicalDoc, setClinicalDoc] = useState<iClinicalDocsResults>();
  const [content, setContent] = useState<documentContent[]>([]);
  const [isSigned, setIsSigned] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [certificate, setCertificate] = useState<certificateProps>(
    {} as certificateProps,
  );

  const columns: string[] = [
    translator('Droga'),
    translator('Posologia'),
    translator('Dosagem'),
  ];

  const typeDoc: Map = {
    BASIC: translator('Receita simples'),
    MEDICALCERTIFICATE: translator('Atestado Médico'),
    EXAMREQUEST: translator('Requisição de exames'),
  };

  useEffect(() => {
    handleGetInfo(Number(id));
  }, [clinicalDocs]);

  useEffect(() => {
    if (results.length) {
      const tmp = results.filter(item => {
        return item.document?.id === Number(id);
      });

      setClinicalDoc(tmp[0]);
    }
  }, [results]);

  useEffect(() => {
    setIsSigned(clinicalDoc?.document?.status === 'SIGNED');

    setTimeout(() => {
      setIsReady(true);
    }, 1000);
  }, [clinicalDoc]);

  const handleGetInfo = async (data: number) => {
    if (clinicalDoc?.document?.content?.drugs) {
      setContent(
        clinicalDoc.document.content.drugs.map(item => ({
          drug: item.name,
          posology: item.posology,
          dosage: item.dosage,
        })),
      );
    }

    MapConsultantDetails(data)
      .then(result => {
        setInfo(result);
      })
      .catch(err => {
        if (err.name === 'NotFound') History.getHistory().push('/clinicaldocs');

        AlertMessage({
          message: intl.formatMessage({
            id: 'Não foi possível obter informações do documento clínico, tente novamente',
          }),
          type: 'danger',
        });
      });
  };

  const clinicalDocType = () => {
    if (!clinicalDoc) return;

    let dataToSend: any = {};

    const selectType = (type: number) => {
      if (type === 2) {
        return 2;
      }
      if (type === 3) {
        return 3;
      }

      return 1;
    };

    if (certificate.signatureSource === 'VIDAAS') {
      dataToSend = {
        docId: Number(id),
        type: selectType(clinicalDoc?.type.id),
        content: {
          drugs: clinicalDoc?.document?.content.drugs ?? [],
        },
        signatureSource: certificate.signatureSource,
      };
    } else {
      dataToSend = {
        docId: Number(id),
        type: selectType(clinicalDoc?.type.id),
        content: {
          drugs: clinicalDoc?.document?.content.drugs ?? [],
        },
        certificate: certificate.certificate,
        certificateId: certificate.certificateId,
      };
    }

    if (clinicalDoc?.type.id === 2) {
      dataToSend.content = {
        observacoes: clinicalDoc?.document?.content.observacoes,
        periodo: clinicalDoc?.document?.content.periodo,
      };
    }
    if (clinicalDoc?.type.id === 3) {
      dataToSend.content = {
        solicitacao: clinicalDoc?.document?.content.solicitacao || [],
        indicacaoClinica: clinicalDoc?.document?.content.indicacaoClinica || [],
      };
    }

    try {
      makeReduxUpdateClinicalDocs().update(dataToSend);
    } catch (e) {
      console.error(e);
      AlertMessage({
        message: intl.formatMessage({
          id: 'Não foi possível assinar o documento.',
        }),
        type: 'danger',
      });
    }
  };

  const messageToDisplay =
    typeDoc[String(clinicalDoc?.type?.name)] ?? 'Receita Simples';

  return (
    <Content>
      <HeaderDiv>
        <Menu />
        <PageHeader title={`${translator(messageToDisplay)}`} />
      </HeaderDiv>
      <Body>
        <Session>
          <InfoUserCard user={info && info.user} />
        </Session>
        <NavContent>
          <Header
            user={info && info.user}
            type={clinicalDoc ? typeDoc[clinicalDoc?.type?.name] : undefined}
            isSigned={isSigned}
            sendDate="02/07/2021"
            clinicalDoc={clinicalDoc}
          />
          {isReady && !isSigned && role === 'PRO' && (
            <ChooseCertificate>
              <div id="certificate-div">
                <SignDoc
                  docId={Number(id)}
                  changeSelected={(e: {
                    certificateId: string;
                    certificate: string;
                    signatureSource?: string;
                  }) =>
                    setCertificate({
                      ...certificate,
                      certificate: e.certificate,
                      certificateId: e.certificateId,
                      signatureSource: e.signatureSource,
                    })
                  }
                  onSignSubmit={clinicalDocType}
                  isSigned={isSigned}
                />
              </div>
            </ChooseCertificate>
          )}
          {clinicalDoc?.type.id === 1 && (
            <StickyHeadTable
              columns={columns}
              rows={content ?? []}
              bold={['drug', 'dosage']}
            />
          )}
          {clinicalDoc?.type.id === 2 && (
            <TextAreaDoc>
              {translator('Atesto que o(a) Sr(a).')}
              <strong>{`${info?.user.name} `}</strong>
              {translator(
                'encontra-se sob meus cuidados profissionais necessitando de afastamento de suas atividades pelo período de',
              )}
              {` ${clinicalDoc?.document?.content.periodo}.`}
              <div id="obs" className="observacoes">
                {translator('Observações:')}
              </div>
              {`${clinicalDoc.document?.content.observacoes}`}
            </TextAreaDoc>
          )}
          {clinicalDoc?.type.id === 3 && (
            <TextAreaDoc>
              <strong>{translator('Indicação clínica:')}</strong>
              {` ${clinicalDoc?.document?.content.indicacaoClinica}`}
              <div id="obs" className="observacoes">
                {translator('Solicito:')}
              </div>
              {translator('Requisito o exame')}
              {` ${
                clinicalDoc.document?.content.solicitacao &&
                clinicalDoc.document.content.solicitacao[0].exam
              } `}
              {translator('para o paciente pois o mesmo está com sintomas.')}
            </TextAreaDoc>
          )}
        </NavContent>
      </Body>
    </Content>
  );
};

export default SpecialPrescriptionView;
