export default {
  Organizador: 'Organizer',
  Paciente: 'Patient',
  Profissional: 'Professional',
  Convidado: 'Guest',
  Atendimentos: 'Appointments',
  Administrador: 'Administrator',
  'Documentos clínicos': 'Clinical records',
  'Agenda de atendimentos': 'Appointments schedule',
  'Perfil do usuário': 'User profile',
  'Detalhes do atendimento': 'Appointment details',
  'Documento clínico': 'Clinical record',
  'Pacientes cadastrados': 'Registered patients',
  'Profissionais cadastrados': 'Registered professionals',
  'Organizadores cadastrados': 'Registered organizers',
  'Agendar novo atendimento': 'Schedule new appointment',
  'Cadastrar novo documento clínico': 'Insert new clinical record',
  'Cadastrar novo participante': 'Register new patient',
  'Cadastrar novo profissional': 'Register new professional',
  'Cadastrar novo organizador': 'Register new organizer',
  Cancelar: 'Cancel',
  Voltar: 'Back',
  Próximo: 'Next',
  Enviar: 'Send',
  'Enviar e concluir': 'Send and close',
  'Sim, cancelar': 'Yes, cancel',
  'Não cancelar': "Don't cancel",
  'Excluir usuário': 'Delete user',
  'Novo paciente': 'New patient',
  'Novo profissional': 'New professional',
  'Novo organizador': 'New organizer',
  'Novo documento': 'New document',
  'Novo agendamento': 'New appointment',
  Vencimento: 'Due date',
  Disponível: 'Available',
  Cancelado: 'Canceled',
  Finalizado: 'Finished',
  Agendado: 'Scheduled',
  'Em andamento': 'In progress',
  'Em aberto': 'Opened',
  Confirmado: 'Confirmed',
  'Documento não enviado': 'Record not sent',
  'Dados perfil': 'Profile',
  'Mudar senha': 'Change password',
  Permissão: 'Permission',
  'Excluir conta': 'Delete account',
  Sair: 'Exit',
  'Aviso importante': 'Warning!',
  'De acordo com a legislação, a remoção da sua conta removerá todos os seus dados exceto aqueles relacionados aos atendimentos médicos.':
    'According to the legislation, the removal of your account will remove all your data except those related to medical appointments.',
  'Estou ciente que a exclusão dos dados é irreversível':
    'I am aware that data deletion is irreversible',
  'Dados profissionais': 'Professional information',
  Profissão: 'Profession',
  Salvando: 'Saving...',
  'Salvar alterações': 'Save changes',
  'Nova senha': 'New password',
  'Digite nova senha': 'Enter new password',
  'Senha atual': 'Current password',
  'Repita nova senha': 'Repeat new password',
  'Digite novamente a nova senha': 'Re-enter new password',
  'Dados do perfil': 'Profile',
  'Dados gerais': 'General data',
  'Enviar documento por e-mail': 'Send document by e-mail',
  'Dados Pessoais': 'Personal data',
  'Dados Profissionais': 'Professional data',
  'Selecione sua profissão': 'Select your profession',
  Médico: 'Doctor',
  'Digite apenas letras maiúsculas e minúsculas e espaço.':
    'Enter only uppercase and lowercase letters and space.',
  Dentista: 'Dentist',
  Contatos: 'Contacts',
  Status: 'Status',
  Ações: 'Actions',
  Período: 'Period',
  Tipo: 'Type',
  'Documentos de apoio': 'Supporting clinical records',
  Atendimento: 'Appointment',
  Retorno: 'Returning patient',
  Emergency: 'Emergency',
  Gravado: 'Recorded',
  'Cancelar atendimento?': 'Cancel appointment?',
  'Deseja realmente cancelar o atendimento: ': 'Confirm cancel appointment: ',
  'Nome completo': 'Full name',
  'Seu primeiro nome': 'Name',
  'Seu segundo nome': 'Last name',
  'Digite seu nome': 'Name',
  'Digite seu sobrenome': 'Surname',
  'Digite o nome do convidado': 'Guest name',
  Gênero: 'Gender',
  Masculino: 'Man',
  Feminino: 'Woman',
  Cpf: 'Social Security',
  Rg: 'ID',
  'Nome da mãe': "Mother's name",
  Especialidade: "Doctor's specialty",
  'Digite sua especialidade': 'Insert your specialty',
  'Data do Atendimento': 'Appointment date',
  'Selecione uma data disponível': 'Select an available date',
  'Hora início': 'Start time',
  'Hora fim': 'End time',
  Selecione: 'Select',
  'Tipo de Atendimento': "Type of doctor's Appointment",
  Convidados: 'Guests',
  'Digite o(s) email(s) para convidar': 'Enter email(s) for the invitation',
  'Um Link estará disponível após a confirmação do agendamento.':
    'A link will be available after confirming the appointment.',
  'Data de nascimento': 'Date of birth',
  Endereço: 'Address',
  Cep: 'ZIP',
  Número: 'Number',
  Complemento: 'Apt/floor/house',
  Bairro: 'Neighborhood',
  UF: 'State',
  Cidade: 'City',
  Contato: 'Contact',
  'E-mail': 'E-mail',
  Senha: 'Password',
  'Telefone celular': 'Mobile phone',
  'Telefone alternativo': 'Phone',
  'Telefone celular 1': 'Mobile phone 1',
  'Telefone celular 2': 'Mobile phone 2',
  Salvar: 'Save',
  'Digite o seu email': 'Enter email',
  'Digite sua Senha': 'Enter password',
  Código: 'Code',
  'Digite o código do atendimento': 'Enter the service code',
  'Sua relação familiar com o paciente':
    'Enter your relationship with the patient',
  'Selecione uma opção': 'Choose an option',
  Anterior: 'Back',
  'Termos de uso': 'Terms of use',
  'Política de privacidade': 'Privacy policy',
  Assinado: 'Record signed',
  Pendente: 'Pending',
  'Lista de Documentos clínicos': 'List of clinical records',
  'Lista de Documentos de apoio': 'Supporting assessment records',
  Tamanho: 'Size',
  Data: 'Date',
  Cod: 'Cod',
  Assinatura: 'Signature',
  Hora: ' Hour',
  Origem: 'Origin',
  CPF: 'Social Security',
  'Profissional da saúde': 'Health professional',
  'Registro de conselho': 'Professional ID',
  'Data de inclusão': 'Issue date',
  Idade: 'Age',
  Início: 'Start',
  Fim: 'End',
  'Iniciar atendimento': 'Start patient visit',
  Detalhes: 'Details',
  'Acessar farmácia': 'Access drugstore',
  'Ver perfil': 'Profile',
  'Editar setor': 'Edit sector',
  'Baixar documento': 'Download record',
  'Ir para sala de espera': "Access doctor's waiting room",
  'Compartilhar por email': 'Share by email',
  'Novo documento clínico': 'New clinical record',
  'Não foi possível obter informações do documento clínico. Tente novamente':
    'An error occurred while retrieving the record information. Try again.',
  Painel: 'Dashboard',
  'Motivo do afastamento': 'Reason for patient excuse',
  Observações: 'Assessments',
  'Tempo de afastamento': 'Period of patient excuse',
  'Excluir usuario': 'Erase user',
  'Cancelar atendimento': 'Cancel appointment',
  'Finalizar atendimento': 'Finish appointment',
  'Editar especialidade': 'Edit specialty',
  'Excluir especialidade': 'Exclude specialty',
  'Copiar link de atendimento': 'Copy link',
  'Reenviar email': 'Resend email',
  'Enviar convite por Whatsapp': 'Send Whatsapp invitation',
  'Enviar convite': 'Send invite',
  'Convites Enviados': 'Invitations sent',
  'Convidar por Link': 'Invite by link',
  'Excluir documento': 'Exclude document',
  Avulso: 'Separate',
  Simples: 'Basic',
  'Atestado Médico': 'Doctor Note',
  'Requisição de exame': 'Exam Request',
  'Relatório médico': 'Medical report',
  'Status do atendimento': 'Appointment status',
  'Participantes da Consulta': 'Appointment participants',
  'Dados do paciente': 'Patient data',
  Nome: 'Name',
  anos: 'years',
  'Pronto para participar?': 'Ready to join?',
  'Ações do atendimento': 'Appointment actions',
  'Para reagendar ou alterar dados do atendimento, você precisa editar o atendimento nos campos ao lado.':
    'To reschedule or change the appointment, please edit the side fields information.',
  'Atendimento cancelado': 'Service canceled',
  'Dados do profissional de saúde': 'Health professional data',
  'Registro no conselho profissional':
    'Professional ID registration information',
  'Data e Hora': 'Date and Time',
  Profissionais: 'Professionals',
  Concluir: 'Finish',
  'Paciente em questão': 'Patient',
  'Selecione um paciente': 'Select a patient',
  'Convidar paciente para participar': 'Invite a patient to participate',
  'Selecione uma especialidade': 'Select a speciality',
  'Data do atendimento': 'Appointment Date',
  'Hora de início': 'Start time',
  'Hora do fim': 'End time',
  'Selecione um profissional': 'Select a professional',
  'Convidar profissional externo': 'Invite an external professional to join',
  'Nome do Profissional': "Professional's name",
  'Digite o e-mail do profissional': "Enter professional's email",
  'E-mail do profissional': "Professional's email",
  Add: 'Add',
  'Lista de profissionais participantes': 'Pofessionals participating list',
  'Digite seu CEP': 'Enter your ZIP code',
  'Digite seu estado': 'State',
  Estado: 'State',
  'Digite sua cidade': 'City',
  'Digite seu país': 'Country',
  País: 'Country',
  'Digite seu bairro': 'Neighborhood',
  'Rua Av. Ladeira...': 'Address',
  'Número da residência': 'Street number',
  'Digite um complemento': 'Apt/floor/house',
  'Seu nome completo': 'Enter your full name',
  'Nome Completo': 'Full name',
  'Cadastro de Pessoa Física': 'Individual registration',
  'Número da identidade': 'Enter your identity number',
  'Selecione seu Gênero': 'Choose your gender',
  Outros: 'Others',
  'DD/MM/AAAA': 'MM/DD/YYYY',
  'Data de Nascimento': 'Birth date',
  'Nome da sua mãe': "Your mother's name",
  'Digite uma senha pessoal': 'Password',
  'Senha de Acesso': 'Access password',
  'Confirme a senha': 'Confirm your password',
  'Repita a senha de acesso': 'Repeat password',
  'Insira o Código de atendimento que você recebeu por e-mail.':
    'Check your e-mail and enter the code.',
  'Pai/Mãe': 'Father/Mother',
  'Irmã(o)': 'Sibling',
  'Filho(a)': 'Son/Daughter',
  'Esposa(o)': 'Spouse',
  'Avô/Avó': 'Grandma/Grandpa',
  'Amigo(a)': 'Friend',
  'Outro parente': 'Another relative',
  'Não listado': 'Not listed',
  Ativo: 'Active',
  Fraca: 'Weak',
  Médio: 'Medium',
  Forte: 'Strong',
  'Senha de': 'Password of',
  segurança: 'security',
  Avançar: 'Next',
  Cadastro: 'Record',
  'Selecione um papel': 'Select a role',
  'Permissões concedidas': 'Permissions granted',
  Descrição: 'Description',
  Franquia: 'Franchise',
  Papel: 'Role',
  '+Adicionar': '+Add',
  Adicionar: 'Add',
  'Profissional de saúde': 'Health professional',
  'Última edição': 'Last edition',
  'Profissionais participantes': 'Professionals participating',
  'Não foi possível carregar o arquivo': 'The file could not be loaded',
  'Dados Gerais': 'General records',
  'Acesso à Receita': 'Access to Prescription',
  Acessar: 'Acess',
  'Digite o código da receita': 'Enter the prescription code',
  'Digite a senha da receita': 'Enter the password for the prescription',
  'Insira o CÓDIGO e SENHA disponíveis na receita do paciente':
    'Insert the CODE and PASSWORD printed in the patient prescription',
  'para visualizar o arquivo assinado pelo médico':
    'to view the health record signed by the doctor',
  'Criado em': 'Created in',
  'Acesso para farmácia': 'Pharmacy access',
  'Receita Especial': 'Special prescription',
  'Validador ICP Brasil': 'ICP Brasil Validator',
  'Baixar PDF': 'Download PDF',
  'Um link estará disponível após confirmação do agendamento':
    'A link will be available after the appointment confirmation',
  'Digite o(s) e-mail(s) para convidar': 'Enter email(s) to invite',
  'Selecione um tipo': 'Select a type',
  'Tipo de serviço': 'Type of appointment',
  'Backup em nuvem': 'Cloud backup',
  'Reconhecimento facial': 'Facial recognition',
  'Este recurso não está disponível no seu pacote de serviços atual.':
    'This feature is not available in your current service pack. Ask your service provider for terms and conditions.',
  'desde o último mês': 'since last month',
  'Atendimentos realizados': 'List of appointments realized',
  Pago: 'Paid',
  Utilizados: 'Used',
  Unidade: 'Units',
  Proteção: 'Protection',
  Prescrições: 'Prescriptions',
  Vigência: 'Good through',
  Mês: 'Month',
  Hoje: 'Today',
  Domingo: 'Sunday,',
  'Segunda-Feira': 'Monday,',
  'Terça-Feira': 'Tuesday,',
  'Quarta-Feira': 'Wednesday,',
  'Quinta-Feira': 'Thursday,',
  'Sexta-Feira': 'Friday,',
  Sábado: 'Saturday,',
  ' de Janeiro': ' January',
  ' de Fevereiro': ' February',
  ' de Março': ' March',
  ' de Maio': ' May',
  ' de Abril': ' April',
  ' de Junho': ' June',
  ' de Julho': ' July',
  ' de Agosto': ' August',
  ' de Setembro': ' September',
  ' de Outubro': ' October',
  ' de Novembro': ' November',
  ' de Dezembro': ' December',
  'Receita simples': 'Simple recipe',
  'Solicite a farmácia que acesse o endereço':
    'Request the pharmacy to access the address',
  'para baixar sua receita digital.': 'to download your digital prescription.',
  'Utilize o código e senha para acessar':
    'Use the code and password to access',
  'Código:': 'Code:',
  'Senha:': 'Password:',
  'Ver QR Code': 'View QR Code',
  'Código e Senha': 'Code and Password',
  'Acesse o documento': 'Access the document',
  'via o QR code do documento': "via the document's QR code",
  Perfil: 'Profile',
  Pacientes: 'Patients',
  'Profissionais de saúde': 'Health professionals',
  Organizações: 'Organizations',
  Painéis: 'Panels',
  'Log de atividade': 'Activity log',
  'Serviços e faturamentos': 'Services and billing',
  'Faturas e pagamentos': 'Invoices and Payments',
  'Itens da fatura': 'Invoice Items',
  'Ocultar todos os detalhes': 'Hide all details',
  'Status de pagamento': 'Payment status',
  Setores: 'Sectors',
  Agenda: 'Schedule',
  Usuários: 'Users',
  Administração: 'Administration',
  Serviços: 'Services',
  '(Paconte On Demand)': '(On Demand Package)',
  'Selecione um mês': 'Select a month',
  'Limpar todas': 'Clear all',
  'Ocultar notificações': 'Hide notification',
  'criou um atendimento': 'created a new appointment',
  'cancelou um atendimento': 'canceled an appointment',
  'confirmou o atendimento': 'confirmed the appointment',
  'atualizou um atendimento': 'updated the appointment',
  'agendou o atendimento': 'appointment scheduled',
  'deletou um atendimento': 'appointment deleted',
  'criou um atendimento com profissional acompanhante':
    'appointment created with a third professional',
  'entrou na sala de espera': 'joined the waiting room',
  'saiu da sala de espera': 'left the waiting room',
  'Nova mensagem': 'New message',
  'Menu de Serviços': 'Services Menu',
  'Mês de competência': 'Month of reference',
  'Reconhecimentos realizados': 'Recognitions performed',
  'Prescrições geradas': 'Prescriptions generated',
  'Atendimentos preservados': 'Preserved services',
  'O valor total faturado será exibido depois que a fatura completa for fechada.':
    'The total amount will be displayed after the completed service in the invoice is closed.',
  'Total estimado': 'Estimated Total',
  'Valor unitário': 'Unit value',
  'Valor total': 'Amount',
  Notificações: 'Notifications',
  'Últimas conversas': 'Last conversations',
  'Selecione um tipo de documento clínico':
    'Select a type of clinical document',
  'Selecione um certificado': 'Select a certificate',
  'Receita Simples': 'Simple Prescription',
  'Receita Antimicrobiano': 'Antimicrobial Prescription',
  'Requisição de Exames': 'Exam Referral Form',
  'Requisição de exames': 'Exam Referral Form',
  Instituição: 'Institution',
  'Assinar Documento': 'Sign form',
  'Data de emissão': 'Issue Date',
  Droga: 'Medication',
  'Drogas Inseridas': 'inserted medications',
  'Após preencher os campos, clique em “Adicionar” para incluir a medicação na prescrição da receita':
    'After filling in the fields, click "Add" to include the medication in the receipt prescription.',
  Dosagem: 'Dosage',
  'Adicionar >>': 'Add >>',
  Posologia: 'Posology',
  'Certificado Digital': 'Digital Certificate',
  'Excluir Receita': 'Delete Prescription',
  'Enviar ao Paciente': 'Send To Patient',
  'Tipo de documento': 'Record Type',
  'Informações gerais': 'General information',
  Prescrição: 'Prescription',
  'Assinatura do documento': 'Record signature',
  Pesquisar: 'Search',
  'Buscar por...': 'Search for...',
  'Pesquisar usuário': 'Search user',
  Redefinir: 'Reset',
  Qualquer: 'Any',
  Gravados: 'Recorded',
  'Documentos Clínicos': 'Clinical records',
  'Digite o nome do administrador': 'Enter the administrator name',
  'Digite o CPF do administrador': 'Enter an administrator Social Security',
  'Digite o código do paciente': 'Enter an patient code',
  'Digite o nome do profissional': 'Enter the professional name',
  'Digite o nome do paciente': 'Enter the patient name',
  'Digite o nome de um organizador': 'Enter the organizer name',
  'Digite o CPF do organizador': 'Enter an organizer Social Security',
  'Digite o CPF do paciente': 'Enter an patient Social Security',
  'Selecione uma data': 'Select a date',
  Ontem: 'Yesterday',
  'Últimos 7 dias': 'Last 7 days',
  'Últimos 30 dias': 'Last 30 days',
  'Últimos 90 dias': 'Last 90 days',
  Inicio: 'Start',
  'Em progresso': 'In progresss',
  'Digite o nome de um médico': "Enter doctor's name",
  'atendimentos neste dia': 'scheduled appointments on this day',
  'O paciente foi informado que': 'The patient was informed that',
  'você está na sala de espera': 'you are in the waiting room',
  'Aguarde o início do atendimento': 'Wait for the appointment to start',
  'nesta sala de espera': 'in this waiting room',
  'Deixar sala': 'Leave room',
  'O paciente está pronto': 'The patient is ready',
  'Você já pode iniciar o atendimento': "You can now start the patient's visit",
  'clicando em avançar': 'by clicking next',
  Cadastrados: 'Registered',
  'Eventos do atendimento': 'Service events',
  Anotações: 'Notes',
  'Documentos clínicos gerados no atendimento':
    'Clinical documents generated in the service',
  'Gerar novo documento': 'Create new document',
  Gravação: 'Recordings',
  Chat: 'Chat',
  'Gravações do atendimento': 'Appointment recordings',
  'Novo documento de apoio': 'New supporting record',
  'Antes de entrar na sala de espera': 'Before entering the waiting room',
  'Você precisa concordar com os termos de uso da':
    'You need to agree to the terms of use for the',
  'plataforma e também concordar que o atendimento poderá':
    'platform and also agree that the appointment may',
  'ser gravado mediante escolha do médico.':
    "`be recorded upon the doctor's clinical decision.`",
  'Aceito os': 'I accept the',
  'termos de uso.': 'terms of use.',
  'Aceito que o atendimento poderá ser gravado.':
    'I accept that the appointment can be recorded.',
  'O médico foi informado que': 'The doctor was informed that',
  'Aguarde nesta tela.': 'Wait here...',
  'Você será avisado quando o': 'You will be notified when the',
  'médico estiver pronto': 'doctor is ready',
  'Entrar na consulta': 'Join the appointment',
  'Deixar a sala': 'Leave the room',
  'O médico está pronto': 'The doctor is ready',
  'O médico já está na sala de atendimento':
    'The doctor is already in the room',
  'Você já pode iniciar seu atendimento':
    "You can now start your doctor's visit",
  'Insira seu nome completo.': 'Enter your full name.',
  'CPF inválido.': 'Invalid Social Security.',
  'RG inválido.': 'Invalid ID.',
  'RG inválido, existe(m) dígitos faltando':
    'Invalid ID, there are some missing numbers',
  'RG inválido, número de dígitos ultrapassou o limite':
    'Invalid ID, exceeded digit(s) limit',
  'Email inválido.': 'Invalid Email.',
  'Telefone inválido.': 'Invalid Phone.',
  'CEP inválido.': 'Invalid ZIP.',
  'CEP inválido, existe(m) dígitos faltando':
    'Invalid zip code, missing digit(s)',
  'CEP inválido, número de dígitos ultrapassou o limite':
    'Invalid zip code, exceeded digit limit',
  'Data de nascimento é obrigatório.': 'Birth date is required.',
  'Data inválida.': 'Invalid Date.',
  'Nome da mãe é obrigatório.': 'Mother name is required.',
  'UF inválido.': 'Invalid State.',
  'UF inválido, existe(m) caracteres faltando':
    'Invalid State, missing characters',
  'UF inválido, número de caracteres ultrapassados':
    'Invalid State, exceeded characters',
  'Insira o nome da sua cidade.': 'City.',
  'Insira o nome do seu bairro.': 'Neighborhood.',
  'Insira o seu endereço.': 'Address.',
  'Insira o número de sua residência': 'Enter your home number',
  'Número inválido, ultrapassou o limite': 'Invalid number, exceeded limit',
  'Consultante inválido ou não encontrado.': 'Invalid or not found consultant.',
  'Profissional inválido ou não encontrado.':
    'Professional invalid or not found.',
  'Especialidade inválida ou não encontrada': 'Speciality invalid or not found',
  'Opção inválida': 'Invalid option',
  'Data inválida': 'Invalid date',
  'Hora de ínicio incorreta': 'Incorrect start time',
  'Hora final incorreta': 'Incorrect end time',
  'Senha inválida': 'Invalid password',
  'Insira o nome da droga': 'Enter the name of the medication',
  'Insira a dosagem': 'Enter the dosage',
  'Insira a posologia da medicação': 'Enter the dosage of the medication',
  'Insira o tempo de afastamento': 'Enter the period of excuse ',
  'Insira uma indicação clínica.': 'Enter a clinical indication.',
  'Exames requisitados': 'requested exams',
  'Requisito os seguintes exames': 'I require the following exams',
  'Indicação Clínica': 'Clinical treatment indication',
  'Insira o número do seu documento profissional':
    'Enter your professional ID license number',
  'Insira a UF do seu documento profissional':
    'Enter your professional ID license state',
  'Insira a data de emissão do seu documento profissional':
    'Enter the ID expiration date',
  'Selecione a área de atendimento': 'Select the area',
  'Falha no servidor, tente novamente mais tarde.':
    'Problem connecting to the server. Try again later.',
  'Seus agendamentos aparecerão aqui. Não há itens a serem exibidos no momento.':
    'Your appointments will appear here. You do not have any appointment for the selected date.',
  'Assinar agora': 'Sign',
  'Selecione seu certificado digital e finalize a criação do documento agora.':
    'Select your digital certificate and finish the generation of the document.',
  'Assinar depois': 'Sign later',
  'Este documento clinico será listado como Documento pendente e não será enviado ao paciente até que a assinatura seja finalizada.':
    'This medical record will be listed as a Pending Record and will not be sent to the patient until the signature is finalized.',
  'Você não tem nenhum documento associado ao atendimento.':
    'There is no records associated with the appointment.',
  'Você não tem nenhuma gravação.': 'You do not have any recorded content.',
  'Antes de iniciar': 'Before you start',
  'Não foi possível desativar a câmera.':
    'The camera could not be deactivated.',
  'Recomendamos que entre com o microfone e câmera ligados.':
    'We recommend that you access the room with the microphone and camera on.',
  'Aceite os termos de uso e de gravação para iniciar.':
    'You have to accept the recording terms of use to start.',
  DESATIVADA: 'DISABLED',
  'Entrar com:': 'Login with:',
  Ligado: 'On',
  Desligado: 'Off',
  Ligada: 'On',
  Desligada: 'Off',
  DESLIGADA: 'CAM OFF',
  'Câmera desligada': 'Camera off',
  'termos de uso': 'terms of use',
  'Aceito que o atendimento poderá ser gravado':
    'I accept that the appointment may be recorded.',
  'Participar do atendimento': 'Enter the appointment room',
  'Sair do atendimento': 'Leave appointment room',
  'Esqueceu sua senha?': 'Forgot your password?',
  Entrar: 'Join',
  'Bem-vindo, doutor(a)': 'Welcome, doctor',
  'Realize o login para iniciar o atendimento':
    'Login to start the appointment',
  'do paciente': 'of the patient',
  'marcado para as': 'scheduled to',
  'Recuperar senha': 'Recover password',
  'Insira seu e-mail no campo abaixo e lhe enviaremos um link para redefinir sua senha.':
    'Enter your email in the field below and well send you a link to reset your password.',
  'um link para alteração de senha': 'a link to change your password',
  'Digite e confirme sua nova senha nos campos abaixo.':
    'Enter and confirm your new password in the fields below.',
  'Atualmente você está como': 'You are currently as',
  'Acesso para convidado': 'Guest access',
  'Você irá participar como convidado do atendimento do(a) Paciente':
    "You will be joining a doctor's visit as a guest to the Patient",
  'Identifique-se': 'Identify yourself:',
  'Insira seus dados para entrar no atendimento':
    'Enter your data to join the appointment',
  em: 'in',
  'Para alterar sua função, selecione o papel em uma organização':
    'To change your role, select it in an organization',
  'Defina seu papel na organização desejada.':
    'Define your role in the selected organization.',
  Confirmar: 'Confirm',
  Carregando: 'Loading',
  'Seus documentos clínicos (receitas, atestados etc.) aparecerão aqui. No momento você não tem nenhum':
    "Your clinical records (prescriptions, doctor's notes etc.) will appear here. At the moment you don't have any",
  'Primeiro atendimento': 'First appointment',
  Nenhum: 'None',
  'Os pacientes cadastrados aparecerão aqui. No momento não há nenhum':
    'Registered patients will appear here. Currently no patients to list',
  'Os profissionais cadastrados aparecerão aqui. No momento não há nenhum':
    'Registered professionals will appear here. Currently no professionals to list',
  'Os organizadores cadastrados aparecerão aqui. No momento não há nenhum':
    'Registered organizers will appear here. Currently no organizers to list',
  'Os administradores cadastrados aparecerão aqui. No momento não há nenhum':
    'Registered administrators will appear here. Currently no admins to list',
  Convênio: 'Health Insurance plan',
  'Salvando...': 'Saving...',
  'Registro (conselho regulador)': 'Professional ID registration number',
  Especialidades: 'Specialties',
  'Registro do conselho': 'Professional ID registration',
  Documento: 'Document',
  'Cadastrar novo administrador': 'Register new administrator',
  'Criar novo setor': 'Create new sector',
  Em: 'In',
  'Deseja agendar um novo atendimento?': 'Schedule a new appointment?',
  'Novo atendimento': 'New appointment',
  às: 'at',
  'anos e': 'years and',
  meses: 'months',
  'Editar consulta': 'Edit appointment',
  'Links e Convidados': 'Links and Guests',
  'Área do atendimento': 'Appointment area',
  'Adicionar convidado': 'Add guest',
  'Digite o e-mail ou o número do celular para convidar':
    'Enter email or mobile number to send the invitation',
  interno: 'internal',
  externo: 'external',
  Participante: 'Participant',
  'Data do envio': 'Send date',
  Ação: 'Action',
  'Só você pode ver, editar e excluir a anotação do atendimento.':
    'Only you can view, edit and delete the appointment.',
  'Escreva aqui': 'Write here',
  'Administradores cadastrados': 'Registered administrators',
  'Novo administrador': 'New administrator',
  Inativo: 'Inactive',
  Suspenso: 'Suspended',
  Realizados: 'Finished',
  'Não foi possível assinar o documento.':
    'It was not possible to sign the record.',
  'Enviado ao paciente:': 'Sent to patient:',
  'Atesto que o(a) Sr(a).': 'I certify that you.',
  'encontra-se sob meus cuidados profissionais necessitando de afastamento de suas atividades pelo período de':
    'are under my professional care and in need to be excused from your activities for the period of',
  'Observações:': 'Note',
  'Indicação clínica:': 'Clinical assessment:',
  'Solicito:': 'I request:',
  'Requisito o exame': 'I request the exam',
  'para o paciente pois o mesmo está com sintomas.':
    'to the patient because he has symptoms.',
  'Selecione um arquivo': 'Select a file',
  receituario: 'prescription',
  'Ocorreu um erro ao excluir o documento clínico':
    'An error occurred while deleting the clinical record',
  'Ocorreu um erro ao reenviar o e-mail, tente novamente mais tarde':
    'An error occurred while resending the e-mail. Try again later.',
  'Visualizar PDF': 'View PDF',
  Baixar: 'Download',
  'Assinar documento': 'Sign record',
  'Reenviar por e-mail': 'Resend by email',
  Excluir: 'Delete',
  'Adicionar profissional externo': 'Add external professional',
  'profissional interno': 'Internal Professional',
  'profissional externo': 'External Professional',
  'Adicione uma ou mais especialidades para concluir o cadastro':
    'Add one or more specialties to complete the registration',
  'Suas especialidades aparecerão aqui': 'Your specialties will appear here',
  Aguarde: 'Wait',
  'será atendido(a) em alguns instantes':
    'you will see the doctor in a few moments',
  'O atendimento será iniciado assim que o médico e paciente estiverem na sala.':
    'The appointment will start as soon as the doctor and patient are in the room.',
  'Cadastro realizado com sucesso!': 'Registration successful!',
  'Enviamos um email de confirmação para': 'We send a confirmation email to',
  'Falta pouco: para habilitar sua conta, acesse a mensagem enviada e clique no link de confirmação.':
    'We are almost there...to enable your account, access the message we sent you and click on the confirmation link.',
  OK: 'OK',
  'Sair sem salvar': 'Exit without saving',
  'Se você sair agora, as alterações feitas': 'If you logout the changes',
  'não serão salvas.': 'will not be saved.',
  'Para salvá-las, clique no botão Salvar antes de sair.':
    'To save your changes, click on the Save button before exiting.',
  'Voltar para edição': 'Back to editing',
  'Alterações salvas com sucesso!': 'Changes saved!',
  'Não excluir': "Don't delete",
  'Sim, excluir': 'Yes, delete',
  'Atendimento agendado com sucesso!': 'Appointment scheduled',
  'Link para o profissional': 'Link to the professional',
  'Link para o paciente': 'Link to patient',
  'O atendimento foi cancelado. Consulte o médico ou paciente para mais informações.':
    'The appointment was cancelled. Consult the doctor or patient for more information.',
  'WhatsApp (opcional)': 'WhatsApp (optional)',
  'Deseja finalizar o atendimento?': 'Do you want to finish the appointment?',
  'Caso opte por não finalizar o atendimento agora, você poderá finalizá-lo posteriormente.':
    'If you decide not to finish the appointment now, you can finish it later.',
  'Não finalizar': "Don't finish",
  'Sim, finalizar agora': 'Yes, finish now',
  'Pronto!': 'Done',
  'Você já pode participar do atendimento': 'You can now join the appointment',
  'Clique em entrar para iniciar': 'Click login to start',
  Copiar: 'Copy',
  'Copiado!': 'Copied!',
  'Excluir setor?': 'Delete sector?',
  'Você deseja excluir o documento?': 'Do you want to delete the record?',
  'Excluir documento pendente?': 'Delete pending record?',
  'Deseja realmente excluir este documento?':
    'Do you really want to delete this record?',
  'Atendimento cancelado com sucesso!': 'Appointment canceled successfully!',
  'Documento clínico criado com sucesso!':
    'Clinical document created successfully!',
  'Documento clínico enviado com sucesso!':
    'Clinical document sent successfully!',
  'Documento assinado com sucesso!': 'Document successfully signed!',
  'Usuario excluido com sucesso!': 'User deleted successfully!',
  'Modelos de documentos clínicos': 'Clinical Document Types',
  'Documentos suportados para envio: jpeg, png, pdf e exe.':
    'Supported documents for submission: jpeg, png, pdf and exe.',
  'Lista de documentos de apoio do atendimento':
    'List of support documents for the service',
  'Novo setor': 'New sector',
  Setor: 'Sector',
  'Total de pacientes': 'Total patients',
  'Total de profissionais': 'Total professionals',
  'Armazenamento este mês': 'Storage this month',
  'Setor excluido com sucesso!': 'Successfully deleted sector!',
  'Não foi possível excluir setor, tente novamente.':
    'No se pudo borrar el sector. Vuelve a intentarlo.',
  'Credenciais inválidas!': 'Invalid credentials!',
  'Falha ao realizar login!': 'Login failed!',
  'Documento enviado com sucesso!': 'Document sent successfully!',
  'Falha ao registrar usuário.': 'Failed to register user.',
  'E-mail ou CPF já utilizado.': 'Email or CPF already used.',
  'Criação de conta confirmada!': 'Confirmed account creation!',
  'Recuperação de senha bem-sucedida!': 'Successful password recovery!',
  'Email enviado com sucesso!': 'Email successfully sent!',
  'O agendamento já foi confirmado.':
    'The schedule has already been confirmed.',
  'Agendamento confirmado com sucesso.': 'Appointment confirmed successfully.',
  'Falha ao confirmar o atendimento.': 'Failed to confirm service.',
  'Falha ao cancelar o atendimento.': 'Failed to cancel call.',
  'Falha ao criar agendamento!': 'Failed to create schedule!',
  'Falha ao remover agendamento.': 'Failed to remove schedule.',
  'Falha ao tentar atualizar agendamento.': 'Failed to try to update schedule.',
  'Token expirado! Efetue o login novamente para trocar papéis.':
    'Expired token! Log in again to switch roles.',
  'Falha ao criar documento clínico.': 'Failed to create clinical document.',
  'Falha ao remover documento clínico.': 'Failed to remove clinical document.',
  'Falha ao assinar documento clínico.': 'Failure to sign clinical document.',
  'Falha ao fazer upload do arquivo.': 'Failed to upload file.',
  'Não foi possível atualizar o usuário. Você digitou os campos corretamente?':
    'Unable to update user. Did you type the fields correctly?',
  'Falha interna no servidor, tente novamente em minutos.':
    'Internal server failure, please try again in minutes.',
  'entrou no atendimento de data': 'entered the date service',
  'entrou no atendimento': 'entered the service',
  'Falha ao adicionar agendamento a lista de atendimentos!':
    'Failed to add schedule to service list!',
  'Não foi possível carregar as conversas': 'Could not load conversations',
  'Digite sua senha atual.': 'Enter your current password.',
  'Confirmação de senha incorreta.': 'Incorrect password confirmation.',
  'Falha ao tentar atualizar. Verifique os dados e tente novamente':
    'Failed to attempt to update. Check the data and try again',
  'Falha na busca dos estados': 'Failed to search for states',
  'Falha na busca das cidades': 'Failed to search cities',
  'Link copiado para área de transferência': 'Link copied to clipboard',
  'Convite enviado com sucesso!': 'Invitation sent successfully!',
  'Falha ao enviar convite!': 'Failed to send invitation!',
  'Convite reenviado com sucesso!': 'Invitation successfully resent!',
  'Falha ao reenviar convite!': 'Failed to resend invitation!',
  'Não é possível enviar o documento antes de assinado!':
    'It is not possible to send the document before it is signed!',
  'Não foi possível atualizar especialidades, tente novamente':
    'Unable to update specials, try again',
  'Especialidades atualizadas com sucesso!':
    'Specialties updated successfully!',
  'Não foi possível atualizar especialidades, tente novamente.':
    'Unable to update specials, please try again.',
  'É preciso adicionar pelo menos um profissional':
    'You must add at least one professional',
  'Você precisa adicionar uma especialidade.': 'You need to add a specialty.',
  'Erro ao criar o profissional. Tente novamente.':
    'Error creating the professional. Try again.',
  'Não foi possivel encontrar nenhum certificado':
    'Could not find any certificate',
  'Atualize os dados da sua prescrição.': 'Update your prescription data.',
  'Erro ao confirmar agendamento. Você realmente agendou um horário?':
    'Error confirming appointment. Did you really make an appointment?',
  'Não foi possível entrar na conferência, tente novamente':
    'Could not join conference, try again',
  'Você realmente solicitou a criação de conta?':
    'Did you actually request to create an account?',
  'Digite e confirme sua nova senha.': 'Enter and confirm your new password.',
  'Você realmente é um novo usuário?': 'Are you really a new user?',
  'Código não encontrado.': 'Code not found.',
  'Não foi possível obter informações da sala de espera, tente novamente':
    'Unable to get waiting room information, try again',
  'Preencha os campos corretamente.': 'Fill in the fields correctly.',
  'Email inválido! Você já está cadastrado no sistema?':
    'Invalid email! Are you already registered in the system?',
  'Você realmente solicitou a alteração de senha?':
    'Did you actually request a password change?',
  'Não foi possível atualizar imagem do setor.':
    'Unable to update sector image.',
  'Setor editado com sucesso!': 'Sector successfully edited!',
  'Setor criado com sucesso!': 'Sector created successfully!',
  'Não foi possível criar setor.': 'Unable to create sector.',
  'Voltar para a listagem de setores': 'Back to sector list',
  'Já existe um setor com esse nome.':
    'There is already a sector with that name.',
  'Não foi possível atualizar setor.': 'Unable to update sector.',
  'Não foi possível associar imagem ao setor.':
    'It was not possible to associate image with the sector.',
  'Seu vídeo está sendo baixado!': 'Your video is downloading!',
  'Erro ao fazer download!': 'Error downloading!',
  'Diminuir nota': 'Collapse note',
  'Expandir nota': 'Expand note',
  'Escreva aqui as anotações sobre o paciente.':
    'Write notes about the patient here.',
  'Salvamento automático': 'Auto save',
  'Preenchimento de dados': 'Filling data',
  'Identificamos que o usuário já está cadastrado na plataforma. Deseja preencher automaticamente os campos com os seus dados?':
    'We identified that the user is already registered on the platform. Do you want to automatically fill in the fields with your data?',
  Atenção: 'Caution',
  'O usuário já está cadastrado neste setor.':
    'The user is already registered in this sector.',
  'O usuário já está cadastrado nesta organização.':
    'The user is already registered in this organization.',
  'É necessário que pelo menos um setor seja selecionado.':
    'It is required that at least one sector is selected.',
  'Seu atendimento com qualidade e segurança.':
    'Your service with quality and safety.',
  'Digite o email cadastrado': 'Enter the registered email',
  'Digite sua senha': 'Enter your password',
  'Lembrar-me': 'Remember me',
  ou: 'or',
  'Criar conta gratuita': 'Create free account',
  'Conheça mais sobre o serviço': 'Learn more about the service',
  'Campo obrigatório': 'Required field',
  'Políticas de privacidade': 'Privacy policy',
  e: 'and',
  'Título do atendimento': 'Appointment title',
  'Auditoria de ações': 'Action audit',
  Usuário: 'User',
  'Detalhes da auditoria': 'Audit details',
  Evento: 'Event',
  Grupo: 'Group',
  'Detalhes da operação': 'Operation details',
  'Data e hora': 'Date and time',
  Nível: 'Level',
  'Objeto da ação': 'Action object',
  ID: 'ID',
  'Histórico de atendimentos': 'Appointment history',
  'Registro de ações': 'Action record',
  'Unidade de saúde': 'Health unit',
  Modalidade: 'Modality',
  APPTYPE_NEWAPPOINTMENT: 'New consultation',
  APPTYPE_FIRSTAPPOINTMENT: 'First consultation',
  APPTYPE_RETURN: 'Return',
  APPTYPE_TRAINING: 'Training',
  APPTYPE_CLINIC_DISCUSSION: 'Case discussion',
  APPTYPE_INTERCONSULTATION: 'Interconsultation',
  Assinar: 'Sign',
  a: 'to',
  'Fazer Login': 'Login',
  Versão: 'Version',
  'Todos os direitos reservados.': 'All rights reserved.',
  Observação: 'Note',
  'Gerar documento com a transcição do atendimento:':
    'Generate document with the appointment transcription:',
  Sim: 'Yes',
  Não: 'No',
  'O atendimento será iniciado assim que médico e paciente estiverem na sala.':
    'The appointment will start as soon as the doctor and patient are in the room',
  'Sala de espera Virtual': 'Virtual waiting room',
  'O paciente já está aguardando.': 'The patient is already waiting.',
  'Clique em "Entrar na sala" para iniciar a consulta.':
    'Click "Join the room" to start the appointment.',
  'Entrar na sala': 'Join the room',
  'Informações da consulta': 'Appointment information',
  'Clique em "Entrar na sala" para ser atendido agora.':
    'Click "Join the room" to be seen now.',
  'Passar a vez': 'Pass the turn',
  paciente: 'patient',
  profissional: 'professional',
  'já está aguardando': 'is already waiting.',
  'Chegou a sua vez!': 'It is your turn!',
  Configurações: 'Settings',
  'Agenda do dia': 'Today schedule',
  'Agendar atendimento': 'Schedule appointment',
  'Interconsulta instantânea': 'Instant interconsultation',
  'País de atuação': 'Country of practice',
  'Selecione o país de atuação': 'Select the country of practice',
  'Documento de identificação': 'Identification document',
  'Selecione um documento': 'Select a document',
  'Cadastrar novo paciente': 'Register new patient',
  'Modalidade presencial': 'In person modality',
  'Modalidade on-line': 'Online modality',
  'Confirmando a criação de conta': 'Confirming account creation',
  '● A senha deve ter no mínimo 8 caracteres, 1 letra maiúscula, 1 letra minúscula, 1 número e 1 caractere especial.':
    '● The password must be at least 8 characters, 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character.',
  Plantão: 'On call',
  'Entrar no plantão': 'Enter the on call',
  'Solicitar interconsulta': 'Request interconsultation',
  'Seja bem-vindo à sala de espera virtual. Sua consulta começará em breve e você será notificado quando chegar a sua vez.':
    'Welcome to the virtual waiting room. Your appointment will start soon and you will be notified when it is your turn.',
  'O profissional foi avisado que você está esperando. Aproveite este momento para ajustar sua câmera e microfone.':
    'The professional has been notified that you are waiting. Take this time to adjust your camera and microphone.',
  'O profissional está pronto': 'The professional is ready',
  'Você será redirecionado para a tela pré-consulta.':
    'You will be redirected to the pre-appointment screen.',
  'Você pode interagir com as mídias acima enquanto espera!':
    'You can interact with the media above while you wait!',
  'Seu atendimento se iniciará em breve.': 'Your appointment will start soon.',
  'Enquanto isso, você pode interagir com os conteúdos da sala de espera.':
    'In the meantime, you can interact with the contents of the waiting room.',
  'Copiar ou receber link por e-mail': 'Copy or receive link by e-mai',
  'Deseja receber este conteúdo pelo seu e-mail? Você também pode copiá-lo para a área de transferência.':
    'Do you want to receive this content by e-mail? You can also copy it to the clipboard.',
  'Copiar link': 'Copy link',
  'Receber por e-mail': 'Receive by e-mail',
  'Adicione imagens, áudios, vídeos, ou documentos. Tamanho máximo: 500 MB.':
    'Add images, audios, videos, or documents. Maximum size: 500 MB.',
  'Erro ao enviar documento, tente novamente mais tarde.':
    'Error sending document, try again later.',
  'Estamos preparando o seu atendimento': 'We are preparing your appointment',
  'Sua consulta com o médico': 'Your appointment with the doctor',
  'começará em breve. Antes de ir para a sala de espera, você precisa declarar:':
    'will start soon. Before going to the waiting room, you need to declare:',
  'Declaro que li e estou de acordo com os':
    'I declare that I have read and agree with the',
  'Estou ciente de que esta consulta poderá ser gravada.':
    'I am aware that this consultation may be recorded.',
  'Entrar na sala de espera': 'Enter the waiting room',
  'Senha é obrigatória': 'Password is required',
  'E-mail inválido': 'Invalid email',
  'E-mail é obrigatório': 'Email is required',
  'Você deve inserir um email válido ou um nome de usuário de login':
    'You must enter a valid email or a login username',
};
