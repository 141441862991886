import { call, put } from 'redux-saga/effects';
import { createIntl, createIntlCache } from 'react-intl';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';
import {
  UpdateFailed,
  UpdateSuccess,
} from '~/data/store/reducer/clinicalDocs/actions/update';
import { SignFailed } from '~/data/store/reducer/clinicalDocs/actions/sign';
import { iActionUpdate } from '~/domain/interfaces/redux/clinicalDocs/update';
import { SignClinicalDocs } from '~/domain/usecases/clinicalDocs/redux/SignClinicalDocs';
import {
  GetAllClinicalDocs,
  GetClinicalDocs,
} from '~/domain/usecases/clinicalDocs/remote';
import { Sign } from '~/domain/usecases/deamon/remote';
import { makeReduxGetAllClinicalDocs } from '~/main/factories/usecases/clinicalDocs/GetAllClinicalDocsFactory';
import { makeRemoteGetClinicalDocs } from '~/main/factories/usecases/clinicalDocs/GetClinicalDocsFactory';
import { makeRemoteUpdateClinicalDocsBySign } from '~/main/factories/usecases/clinicalDocs/UpdateClinicalDocsBySignFactory';
import { makeRemoteUpdateClinicalDocs } from '~/main/factories/usecases/clinicalDocs/UpdateClinicalDocsFactory';
import { makeRemoteSignDocument } from '~/main/factories/usecases/daemon/Sign';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import { iStore } from '~/domain/interfaces/models';
import storeDev from '../..';
import { translator } from '~/presentation/components/i18n';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

export function* onUpdate(action: iActionUpdate): Generator {
  const remoteUpdateClinicalDocs = makeRemoteUpdateClinicalDocs();

  try {
    yield call(remoteUpdateClinicalDocs.patch, action.payload);

    if (
      action.payload.certificate !== undefined &&
      action.payload.certificateId !== undefined
    ) {
      try {
        yield SignDoc({
          docId: action.payload.docId,
          certificate: action.payload.certificate,
          certificateId: String(action.payload.certificateId),
          appointmentId: action.payload.appointment,
        });
      } catch (e) {
        console.error(e);
        yield put(SignFailed());
      }
    }
    yield onUpdateSuccess(action);
    const SignLedgerResponse = SignDocumentWithLedger({
      docId: action.payload.docId,
      certificate: action.payload.certificate!,
      certificateId: String(action.payload.certificateId),
      appointmentId: action.payload.appointment,
    });
  } catch (e) {
    console.error('error: ', e);

    yield put(UpdateFailed());
  }

  yield 10;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onUpdateSuccess(action: iActionUpdate) {
  if (action.payload.signatureSource === 'VIDAAS') {
    makeRemoteGetClinicalDocs()
      .get({
        docId: action.payload.docId,
      })
      .then(res => {
        const url = res?.dataToSign;
        localStorage.setItem('clinicalDocId', String(action.payload.docId));
        window.location.href = url;
      })
      .catch(e => console.log(e));
  }

  if (
    !window.location.pathname.includes('/conf') &&
    action.payload.signatureSource !== 'VIDAAS'
  )
    window.location.reload();
}

export function onUpdateFailed() {
  console.log('>> UpdateClinicalDocs - Error <<');

  AlertMessage({
    type: 'danger',
    message: intl.formatMessage({ id: 'Falha ao criar documento clínico.' }),
  });
}

function* SignDocumentWithLedger(payload: SignClinicalDocs.Params) {
  const remoteGetClinicalDocs = makeRemoteGetClinicalDocs();
  const remoteSignClinicalDocs = makeRemoteSignDocument();

  if (!payload.docId) throw new Error();

  const response: GetClinicalDocs.Model = yield call(
    remoteGetClinicalDocs.get,
    {
      docId: payload.docId,
    },
  );
  console.log('2. response GetClinicalDocs', response);

  console.log('enviando...', {
    certId: payload.certificateId,
    algorithm: 'SHA256',
    dataToSignArray: [
      {
        dataToSign: response.dataToSign,
        key: 1,
      },
    ],
  });

  const responseSignDocument: Sign.Model = yield call(
    remoteSignClinicalDocs.sign,
    {
      certId: payload.certificateId,
      algorithm: 'SHA256',
      dataToSignArray: [
        {
          dataToSign: response.dataToSign,
          key: 1,
        },
      ],
    },
  );
  console.log('3. response SignDocument DEAMON', responseSignDocument);

  return responseSignDocument;
}

function* SendSignatureValueBackend(
  payload: SignClinicalDocs.Params,
  data: Sign.Model,
) {
  const remoteSendSignatureValueBackend = makeRemoteUpdateClinicalDocsBySign();

  if (!payload.docId) throw new Error();

  yield call(remoteSendSignatureValueBackend.patchBySign, {
    docId: payload.docId,
    signatureValue: data[0].signature,
  });
  console.log('4. response Send Signature Value to Backend');
}

function* SignDoc(payload: SignClinicalDocs.Params) {
  // const remoteCreateClinicalDocs = makeRemoteUpdateClinicalDocs();
  // const remoteGetClinicalDocs = makeRemoteGetClinicalDocs();
  const remoteSignClinicalDocs = makeRemoteSignDocument();
  const remoteSendSignatureValueBackend = makeRemoteUpdateClinicalDocsBySign();

  const store: iStore = storeDev.getState();
  const { orgId } = store.auth.selectUser;

  // console.log('# Sign doc payload: ', payload);

  if (!payload.docId) throw new Error();

  const responseGetClinicalDoc: GetClinicalDocs.Model = yield call(
    getDocDelay,
    {
      docId: payload.docId,
    },
  );

  // console.log('#1. Response GetClinicalDocs', responseGetClinicalDoc);

  const signClinicalDocsBody: Sign.Params = {
    certId: payload.certificateId,
    algorithm: 'SHA256',
    dataToSignArray: [
      {
        dataToSign: responseGetClinicalDoc.dataToSign,
        key: 1,
      },
    ],
  };

  // console.log('#Enviando...', signClinicalDocsBody);

  const responseSignDocument: Sign.Model = yield call(
    remoteSignClinicalDocs.sign,
    signClinicalDocsBody,
  );
  // console.log('#2. Response SignDocument DEAMON', responseSignDocument);

  // 3 passo //

  // console.log('#Enviando para o backend: ', {
  //   docId: payload.docId,
  //   signatureValue: responseSignDocument[0].signature,
  // });

  yield call(remoteSendSignatureValueBackend.patchBySign, {
    docId: payload.docId,
    signatureValue: responseSignDocument[0].signature,
  });
  // console.log('#3. Response Send Signature Value to Backend');

  // 4 passo //
  yield call(getAllDocDelay, {
    filter: {
      appointment: payload.appointmentId,
      org: orgId,
      enabled: true,
    },
  });

  AlertMessage({
    message: intl.formatMessage({ id: 'Documento assinado com sucesso!' }),
    type: 'success',
  });
}

async function getAllDocDelay(data: GetAllClinicalDocs.Params): Promise<void> {
  const reduxGetAllClinicalDocs = makeReduxGetAllClinicalDocs();

  await new Promise<void>((resolve, reject) => {
    setTimeout(async () => {
      reduxGetAllClinicalDocs.getAll(data?.filter?.appointment ? data : {});
      resolve();
    }, 6000);
  });
}

async function getDocDelay(
  data: GetClinicalDocs.Params,
): Promise<GetClinicalDocs.Model> {
  const remoteGetClinicalDocs = makeRemoteGetClinicalDocs();

  const response: GetClinicalDocs.Model = await new Promise(
    (resolve, reject) => {
      setTimeout(async () => {
        remoteGetClinicalDocs
          .get(data)
          .then(result => resolve(result))
          .catch(e => reject(e));
      }, 6000);
    },
  );

  console.log('<><><> getDocDelay: ', response);

  return response;
}
