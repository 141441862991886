import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import InputMask from 'react-input-mask';

import { useSelector } from 'react-redux';
import { Select } from '~/presentation/components/UI';
import Input from '../UI/input';

import { Container, Form, FormContainer } from './style/StyledPersonalData';
import { translator } from '../i18n';
// import { iRegisterParticipant } from './interface';
import { iRegisterProfessional } from '../registerProfessional/interface';
import { iRegisterOrganizer } from '../registerOrganizer/interface';
import { iRegisterParticipant } from '../registerParticipant/interface';
import { iRegisterManager } from '../registerAdm/interface';

import { Navigator } from './Navigator';

import { schemaPersonalData } from '~/validation/validators/user/CreateUserValidator';
import History from '~/main/routes/History';
import { validateCpf } from '~/utils/validCpf';
import { makeRemoteGetAllUsers } from '~/main/factories/usecases/users/GetAllUsersFactory';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import { closeModal } from '~/utils/closeModal';
import { iStore } from '~/domain/interfaces/models';
import { makeRemoteGetAllRegionalDocs } from '~/main/factories/usecases/regionaldoc/GetAllRegionalDocs';
import {
  RegionalDoc,
  RegionalDocCountry,
} from '~/domain/usecases/regionaldoc/remote';
import { makeRemoteGetAllRegionalCountries } from '~/main/factories/usecases/regionaldoc/GetAllRegionalCountries';

interface ownProps {
  next: (
    data:
      | iRegisterParticipant
      | iRegisterProfessional
      | iRegisterManager
      | iRegisterOrganizer,
  ) => any;
  setIsFound?: (isFound: boolean) => void;
  isFound?: boolean;
  state?: iRegisterParticipant;
  cancel?: string;
  roleToRegister: 'PRO' | 'CON' | 'ORG' | 'ADM';
}

export interface iField {
  name: string;
  descr: string;
  regex?: string;
  mask?: string;
  id?: number;
}

const PersonalData: React.FC<ownProps> = ({
  next,
  state,
  setIsFound,
  isFound,
  cancel,
  roleToRegister,
}): JSX.Element => {
  const [userFound, setUserFound] = useState<any>();
  const [regionalDocs, setRegionalDocs] = useState<RegionalDoc[]>([]);
  const [countries, setCountries] = useState<RegionalDocCountry[]>([]);
  const [field, setField] = useState<iField>({
    name: 'CPF',
    descr: 'Cadastro de Pessoa Física',
  });
  const { orgId, orgUnitId } = useSelector(
    (store: iStore) => store.auth.selectUser,
  );
  const orgs = useSelector((store: iStore) => store.auth.orgs);

  const {
    errors,
    handleSubmit,
    register,
    setValue,
    getValues,
    control,
    watch,
  } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    resolver: zodResolver(schemaPersonalData),
    defaultValues: {
      countrySelected: 'BRA',
      ...state,
    },
  });
  const [selectedCountry, setSelectedCountry] = useState<string>(watch('countrySelected') ?? 'BRA');


  const onSubmit = handleSubmit(data => {
    next(userFound ?? { ...data });
  });

  function goBack() {
    History.back();
  }

  const onChangeDate = (value: string) => {
    const birthDate = value.trim();

    setValue('birthdate', birthDate);

    setForm({ ...form, birthdate: birthDate });
  };

  const [form, setForm] = useState<iRegisterParticipant>({
    ...getValues(),
  });

  const handleConfirm = useCallback(
    response => {
      let alreadyRegisteredSectors: number[] = [];

      if (roleToRegister === 'PRO')
        alreadyRegisteredSectors = response.results[0].professionals.map(
          (item: any) => item.orgUnit.id,
        );
      if (roleToRegister === 'ORG')
        alreadyRegisteredSectors = response.results[0].organizers.map(
          (item: any) => item.orgUnit.id,
        );

      setValue(
        'fullName',
        `${response.results[0].user.firstName} ${response.results[0].user.lastName}`,
      );
      const birthdate = response.results[0].user.birthdate?.split('-');
      setValue(
        'birthdate',
        birthdate?.length
          ? `${birthdate?.[2]}/${birthdate?.[1]}/${birthdate?.[0]}`
          : '',
      );
      setValue('gender', response.results[0].user.gender);
      setValue('motherName', response.results[0].user.motherName);

      setForm({ ...getValues() });
      setIsFound?.(true);
      setUserFound({
        ...response.results[0].user,
        fullName: `${response.results[0].user.firstName} ${response.results[0].user.lastName}`,
        birthdate: `${birthdate[2]}/${birthdate[1]}/${birthdate[0]}`,
        phone2: response.results[0].user.phone2 ?? '',
        phone3: response.results[0].user.phone3 ?? '',
        address: {
          ...response.results[0].address,
        },
        alreadyRegisteredSectors,
      });
    },
    [getValues, roleToRegister, setIsFound, setValue],
  );

  const handleFillData = useCallback(async () => {
    try {
      if (!validateCpf(getValues().cpf)) throw new Error('CPF inválido');

      const response = await makeRemoteGetAllUsers().getAll({
        filter: {
          cpf: getValues().cpf.replace(/[^a-zA-Z0-9*]/g, ''),
        },
      });

      if (response.results.length > 0) {
        const org = orgs.find(o => o.id === orgId);

        const alreadyRegisteredSectorsProfessional =
          response.results[0].professionals.map(item => item.orgUnit.id);
        const alreadyRegisteredSectorsOrganizer =
          response.results[0].organizers.map(item => item.orgUnit.id);

        if (
          (roleToRegister === 'CON' &&
            response.results[0].consultants?.find(
              item => item.org?.id === orgId,
            )) ||
          (roleToRegister === 'ADM' &&
            response.results[0].administrators?.find(
              item => item.org?.id === orgId,
            )) ||
          (roleToRegister === 'PRO' &&
            !org?.orgUnits.filter(
              item => !alreadyRegisteredSectorsProfessional.includes(item.id),
            ).length) ||
          (roleToRegister === 'ORG' &&
            !org?.orgUnits.filter(
              item => !alreadyRegisteredSectorsOrganizer.includes(item.id),
            ).length)
        ) {
          makeReduxActiveMessage().active({
            active: MessageOptions.userAlreadyExists,
            actionOk: () => {
              setValue('cpf', '');
              setForm({ ...form, cpf: '' });

              closeModal();
            },
            actionCancel: () => {
              setValue('cpf', '');
              setForm({ ...form, cpf: '' });

              closeModal();
            },
          });
        } else {
          makeReduxActiveMessage().active({
            active: MessageOptions.userDataFulfill,
            actionOk: () => {
              handleConfirm(response);
              closeModal();
            },
            actionCancel: () => {
              setValue('cpf', '');
              setForm({ ...form, cpf: '' });

              closeModal();
            },
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  }, [getValues, orgs, roleToRegister, orgId, setValue, form, handleConfirm]);

  const handleOnChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    onChange: Function,
  ) => {
    const cpf = e.target.value.trim();

    onChange(cpf);

    // setValue('cpf', cpf);

    setForm({ ...form, cpf });

    if (field.name === 'CPF') handleFillData();
    if (isFound) setIsFound?.(false);
  };

  const defaultValueRegionalDocs = useMemo(() => {
    const doc = regionalDocs.find(
      item => item.id === Number(getValues().documentId),
    );

    return doc;
  }, [regionalDocs, getValues().documentId]);

  useEffect(() => {
    setValue('countrySelected', selectedCountry);
    makeRemoteGetAllRegionalDocs()
      .getAll({
        disablePagination: true,
        country: selectedCountry,
      })
      .then(res => {
        setRegionalDocs(res.records);
      })
      .catch(err => {
        console.log('err: ', err);
      });
  }, [selectedCountry]);

  useEffect(() => {
    if (defaultValueRegionalDocs) {
      setField({
        name: defaultValueRegionalDocs.name,
        descr: defaultValueRegionalDocs.descr,
        mask: defaultValueRegionalDocs.mask,
        id: defaultValueRegionalDocs.id,
        regex: defaultValueRegionalDocs.regex,
      });
    }
  }, [defaultValueRegionalDocs]);

  useEffect(() => {
    makeRemoteGetAllRegionalCountries()
      .getAll({
        disablePagination: true,
      })
      .then(res => {
        setCountries(res.records);
      })
      .catch(err => {
        console.log('err: ', err);
      });
  }, []);

  return (
    <Container>
      <FormContainer>
        <Select
          id="registerCountry"
          name="country"
          className="countrySelected"
          defaultValue={watch('countrySelected') || state?.countrySelected}
          placeholder="Selecione o país de atuação"
          label="País de atuação"
          onChange={e => {
            setSelectedCountry(e.target.value);
            setValue('countrySelected', e.target.value);
          }}
          value={selectedCountry}
          register={() => register('countrySelected')}
          required
        >
          {countries.length === 0 && (
            <>
              <option id="registerCountryOptionDefault" value="BRA">
                Brasil
              </option>
              <option id="registerCountryOptionDefault" value="MEX">
                México
              </option>
            </>
          )}

          {countries.length &&
            countries.map(item => (
              <option
                id={`registerCountryOption_${item.country}`}
                value={item.country}
              >
                {item.country}
              </option>
            ))}
        </Select>
        <Select
          id="registerDocument"
          name="document"
          defaultValue={
            defaultValueRegionalDocs
              ? JSON.stringify(defaultValueRegionalDocs)
              : 'DEFAULT'
          }
          value={JSON.stringify(defaultValueRegionalDocs)}
          placeholder="Selecione o documento de identificação"
          label={translator('Documento de identificação')}
          onChange={e => {
            if (e.target.value === 'DEFAULT' || !e.target.value) return;

            const data: RegionalDoc = JSON.parse(e.target.value);

            setValue('documentId', String(data.id));
            setForm({ ...form, cpf: '' });
          }}
          register={() => register('documentId')}
          error={Boolean(errors.documentId)}
          message={
            errors.documentId?.message &&
            'Selecione um documento de identificação'
          }
          required
        >
          <option id="registerOption" value="DEFAULT">
            {translator('Selecione um documento')}
          </option>

          {regionalDocs.length &&
            regionalDocs.map(item => (
              <option
                id={`registerOption_${item.id}`}
                value={JSON.stringify(item)}
              >
                {item.name}
              </option>
            ))}
        </Select>
        {console.log('##field: ', field.mask)}
        <Controller
          control={control}
          name="cpf"
          rules={{
            required: 'Campo obrigatório',
          }}
          render={({ onChange }) => (
            <>
              {field?.mask ? (
                <>
                  <InputMask
                    mask={field?.mask}
                    name="cpf"
                    defaultValue={state?.cpf}
                    ref={() => register('cpf')}
                    onChange={e => handleOnChange(e, onChange)}
                    value={form?.cpf}
                  >
                    <Input
                      id="registerCpf"
                      placeholder={field.descr}
                      label={field.name}
                      error={
                        field?.mask?.replace(/[^a-zA-Z0-9*]/g, '').length !==
                        watch('cpf').replace(/[^a-zA-Z0-9*]/g, '').length
                      }
                      message={
                        field?.mask?.replace(/[^a-zA-Z0-9*]/g, '').length !==
                        watch('cpf').replace(/[^a-zA-Z0-9*]/g, '').length
                          ? 'Documento inválido'
                          : ''
                      }
                      required
                    />
                  </InputMask>
                </>
              ) : (
                <>
                  <Input
                    id="registerCpf"
                    name="cpf"
                    placeholder={field.descr}
                    disabled={!defaultValueRegionalDocs?.id}
                    label={field.name}
                    value={form?.cpf}
                    error={Boolean(errors.cpf)}
                    message={
                      errors?.cpf?.message
                        ? translator(errors?.cpf?.message)
                        : ''
                    }
                    defaultValue={state?.cpf}
                    ref={() => register('cpf')}
                    onChange={e => handleOnChange(e, onChange)}
                    required
                  />
                </>
              )}
            </>
          )}
        />
      </FormContainer>
      <Form onSubmit={onSubmit}>
        {/* <div className="fullName"> */}
        <Input
          id="registerFullName"
          name="fullName"
          gridArea="fullname"
          className="fullName"
          disabled={isFound}
          placeholder={translator('Seu nome completo')}
          label={translator('Nome Completo')}
          value={form.fullName}
          defaultValue={state?.fullName}
          onChange={e => {
            setValue('fullName', e.target.value.trim());
            setForm({ ...form, fullName: e.target.value });
          }}
          register={() => register('fullName')}
          error={Boolean(errors.fullName)}
          message={
            errors?.fullName?.message
              ? translator(errors?.fullName?.message)
              : ''
          }
          pattern="[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$"
          title={translator(
            'Digite apenas letras maiusculas e minúsculas e espaço.',
          )}
          required
          autoFocus
        />
        {/* </div> */}

        {/* <Input
          id="registerRg"
          name="rg"
          defaultValue={state?.rg}
          register={() => register('rg')}
          onChange={e => setValue('rg', e.target.value.trim())}
          placeholder={translator('Número da identidade')}
          label="RG"
          // pattern="[0-9]+$"
          error={Boolean(errors.rg)}
          message={errors?.rg?.message ? translator(errors?.rg?.message) : ''}
          required
        /> */}
        <Select
          id="registerGender"
          name="gender"
          className="gender"
          value={form.gender}
          disabled={isFound}
          defaultValue={state?.gender}
          placeholder={translator('Selecione seu Gênero')}
          label={translator('Gênero')}
          onChange={e => {
            setValue('gender', e.target.value);
            setForm({
              ...form,
              gender: e.target.value as 'MALE' | 'FEMALE' | 'OTHER',
            });
          }}
          register={() => register('gender')}
          required
        >
          <option id="registerOptionMan" value="MALE">
            {translator('Masculino')}
          </option>
          <option id="registerOptionWoman" value="FEMALE">
            {translator('Feminino')}
          </option>
          <option id="registerOptionOthers" value="OTHER">
            {translator('Outros')}
          </option>
        </Select>

        <InputMask
          mask="99/99/9999"
          name="birthdate"
          className="birthdate"
          disabled={isFound}
          value={form.birthdate}
          defaultValue={state?.birthdate}
          ref={() => register('birthdate')}
          onChange={e => {
            onChangeDate(e.target.value.trim());
          }}
        >
          <Input
            gridArea="birthdate"
            id="registerBirthDate"
            placeholder={translator('DD/MM/AAAA')}
            label={translator('Data de Nascimento')}
            error={Boolean(errors.birthdate)}
            message={
              errors?.birthdate?.message
                ? translator(errors?.birthdate?.message)
                : ''
            }
            required
          />
        </InputMask>
        {/* <div className="motherName"> */}
        <Input
          gridArea="mothername"
          id="registerMotherName"
          name="motherName"
          className="motherName"
          disabled={isFound}
          value={form.motherName}
          defaultValue={state?.motherName || getValues().motherName}
          pattern="[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$"
          placeholder={translator('Nome da sua mãe')}
          label={translator('Nome da mãe')}
          onChange={e => {
            setValue('motherName', e.target.value.trim());
            setForm({ ...form, motherName: e.target.value });
          }}
          register={() => register('motherName')}
          error={Boolean(errors.motherName)}
          message={
            errors?.motherName?.message
              ? translator(errors?.motherName?.message)
              : ''
          }
        />
        {/* </div> */}
        {field?.name === 'RG' || !field?.mask ? (
          <Navigator />
        ) : (
          <Navigator
            isDisable={
              field?.mask?.replace(/[^a-zA-Z0-9*]/g, '').length !==
              watch('cpf').replace(/[^a-zA-Z0-9*]/g, '').length
            }
          />
        )}
      </Form>
    </Container>
  );
};

export default PersonalData;
