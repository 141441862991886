/* eslint-disable no-nested-ternary */
import React from 'react';

import { useSelector } from 'react-redux';
import { AvatarImage } from '../avatar-roles';
import { ContainerImage } from '../avatar-roles/styles/styled';
import { translator } from '../i18n'; // TODO: check this component translation
import ConsultDetailFragment from './ConsultDetailFragment';
import {
  Body,
  ConsultInfoContainer,
  ConsultInfoItem,
  ConsultInfoItemGroup,
  Container,
  Header,
  TextBold,
  TextNormal,
  UserInfoContainer,
} from './styles/Styled';
import { GetDutyRequesters } from '~/domain/usecases/duty/remote';
import { Button } from '../UI';
import { makeRemoteLeftRequesterOnCall } from '~/main/factories/usecases/onCall/LeftRequesterOnCallFactory';
import { History } from '~/main/routes';
import { iStore } from '~/domain/interfaces/models';

interface WaitingRoomPatientDetailsCardProps {
  props?: string;
  info?: GetDutyRequesters.Model['records'][0]['requester'];
  isRequester?: boolean;
}

const WaitingRoomPatientDetailsCard: React.FC<WaitingRoomPatientDetailsCardProps> =
  ({ info, isRequester, ...rest }) => {
    const hasLocal = info?.info.uf || info?.info?.city;
    const locality = hasLocal
      ? `${info?.info?.city} - ${info?.info?.uf}`
      : 'Não informado';

    const auth = useSelector((store: iStore) => store.auth.info);
    const { orgUnitId } = useSelector((store: iStore) => store.auth.selectUser);
    const professional = auth.professionals?.find(
      item => item.orgUnit.id === orgUnitId,
    );

    const priorityMapper = (priority: string) => {
      switch (priority) {
        case 'LOW':
          return 'Baixa';
        case 'NORMAL':
          return 'Normal';
        case 'EMERGENCY':
          return 'Urgência';
        default:
          return 'Não informado';
      }
    };

    return (
      <Container>
        <Header>
          {/* <ContainerImage id="profile">
            <AvatarImage size="root" name={info?.name} src={info?.avatar} />
          </ContainerImage>
          <UserInfoContainer>
            <TextBold>{info?.name}</TextBold>
            <TextNormal>
              {`${info?.org ?? ''}`}
              {hasLocal ? `, ${locality}` : ''}
            </TextNormal>
          </UserInfoContainer> */}
          {isRequester ? 'Minha solicitação' : 'Detalhes da solicitação'}
        </Header>
        <Body>
          <ConsultInfoContainer withBorder>
            <ConsultInfoItem>
              <TextBold>{info?.info?.name || 'Não informado'}</TextBold>
              <TextNormal>
                {`${info?.info?.organization || ''} ${
                  info?.info?.organization && hasLocal ? ', ' : ''
                }`}
                {hasLocal ? `${locality}` : ''}
              </TextNormal>
            </ConsultInfoItem>

            <ConsultInfoItem>
              <TextNormal>
                {info?.info?.situation || 'Não informado'}
              </TextNormal>
            </ConsultInfoItem>
          </ConsultInfoContainer>
          <ConsultInfoContainer withBorder={isRequester}>
            <ConsultInfoItemGroup>
              <ConsultInfoItem>
                <TextBold>
                  {info?.info?.organization || 'Não informado'}
                </TextBold>
                <TextNormal>Unidade de saúde</TextNormal>
              </ConsultInfoItem>
              <ConsultInfoItem>
                <TextBold>
                  {hasLocal ? `${locality}` : 'Não informado'}
                </TextBold>
                <TextNormal>Cidade</TextNormal>
              </ConsultInfoItem>
            </ConsultInfoItemGroup>
            <ConsultInfoItem>
              <TextBold>{info?.info?.susCard || 'Não informado'}</TextBold>
              <TextNormal>Cartão SUS</TextNormal>
            </ConsultInfoItem>
          </ConsultInfoContainer>
          {isRequester && (
            <ConsultInfoContainer>
              <ConsultInfoItemGroup>
                <ConsultInfoItem>
                  <TextBold>{priorityMapper(info?.priority || '')}</TextBold>
                  <TextNormal>Nível de prioridade</TextNormal>
                </ConsultInfoItem>
                <ConsultInfoItem>
                  <TextBold>{info?.waitingtime || 'Não informado'}</TextBold>
                  <TextNormal>Tempo estimado de espera</TextNormal>
                </ConsultInfoItem>
              </ConsultInfoItemGroup>
              <Button
                variant="primary"
                size="bigger"
                color="#DD0404"
                hover="#FD3939"
                onClick={() => {
                  if (info?.id)
                    makeRemoteLeftRequesterOnCall()
                      .leftRequester({
                        onCallId: Number(info?.onCall.id),
                        requesterId: info?.id,
                      })
                      .catch(() => {
                        console.log('error');
                      });
                  History.getHistory().goBack();
                }}
              >
                Cancelar solicitação
              </Button>
            </ConsultInfoContainer>
          )}
        </Body>
      </Container>
    );
  };
export default WaitingRoomPatientDetailsCard;
