import {
  GetAppointmentById,
  GetDetailsInstantAppointment,
} from '~/domain/usecases/appointment/remote';

export const AppointmentsInstantaneousToById = (
  item: GetDetailsInstantAppointment.Model,
): GetAppointmentById.Model => {
  const formattedItem: GetAppointmentById.Model = {
    appointment: {
      allowInvite: true,
      allowTranscription: true,
      canceledAt: '',
      duration: item?.appointment?.duration,
      durationReal: 0,
      id: item?.appointment?.id,
      modality: 'REMOTE',
      note: '',
      obs: '',
      org: 0,
      orgUnit: 0,
      scheduled: item?.appointment?.scheduled,
      priority: '',
      started: '',
      status: 'INPROGRESS',
      type: 0,
      guestLink: '',
      service: item?.appointment?.service ?? 'INSTANTANEOUS',
      clinicalCondition: item?.appointment?.clinicalCondition,
      forwarding: item?.appointment?.forwarding,
      info: {
        age: item?.externalConsultant?.age,
        city: item?.externalConsultant?.city,
        name: item?.externalConsultant?.name,
        organization: item?.externalConsultant?.unit,
        regType: item?.externalConsultant?.regType,
        regValue: item?.externalConsultant?.regValue,
        sex: item?.externalConsultant?.sex,
        situation: item?.externalConsultant?.clinicalCondition,
        susCard: '',
        uf: item?.externalConsultant?.UF,
      },
      noteUpdated: '',
    },
    priority: {
      descr: item?.priority?.descr,
      id: item?.priority?.id,
      msgKey: item?.priority?.msgKey,
      client: item?.priority?.client,
    },
    specialty: {
      id: 0,
      name: '',
    },
    consultant: {
      avatar: item?.consultant?.avatar ?? '',
      email: '',
      firstName: item?.consultant?.firstName,
      id: item?.consultant?.id,
      lastName: item?.consultant?.lastName,
      phone: '',
      status: 'ONLINE',
      user: 0,
      access: '',
      joined: '',
      birthdate: item?.externalConsultant?.birthDate,
    },
    professional: {
      avatar: item?.professional?.avatar ?? '',
      docProf: '',
      docProfIssued: '',
      docProfUF: '',
      firstName: item?.professional?.firstName,
      id: item?.professional?.id,
      lastName: item?.professional?.lastName,
      status: 'ONLINE',
      user: 0,
    },
    healthPlan: {
      id: 0,
      name: '',
    },
    appointmentType: item?.appointmentType,
    companions: [],
    links: {} as GetAppointmentById.Model['links'],
    organizer: {} as GetAppointmentById.Model['organizer'],
    otherProfessionals: item?.otherProfessionals,
    waitingRoom: {} as GetAppointmentById.Model['waitingRoom'],
  };

  return formattedItem || ({} as GetAppointmentById.Model);
};
