import { createGlobalStyle } from 'styled-components';
import { defaultTheme } from '../themes';

export default createGlobalStyle`

  * {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    outline: 0;

    a {
      text-decoration: none;
    }
  }

  html,
  body,
  #root {
    width: 100%;
    height: 100%;
    background: ${props => 'c4c4c4'};
    font-family: 'Roboto', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
    }
  }

  #root {
    width: 100%;
    height: 100%;
    display: flex;
    /* justify-content: center;
    align-items: center; */
  }

  /** SCROLLBAR **/
  ::-webkit-scrollbar {
    width: 8px;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-track {
    background: ${props => `${defaultTheme.background}`};
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${props => `${defaultTheme.typographyLightGray}`};
    border-radius: 10px;

  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${props => `${defaultTheme.secundary}`};
  }

  .dzu-previewStatusContainer {
    display: none !important;
  }

  .dzu-previewFileName {
    display: none !important;
  }

  .pagination {
    .page-item {
      .page-link {
        color: #004BA5 !important;
        border: 1px solid #004BA5 !important;

        &:focus {
          box-shadow: none !important;
        }
      }
    }

    .page-item.active {
      .page-link {
        color: #fff !important;
        background-color: #004BA5 !important;
        border: 1px solid #004BA5 !important;

        &:focus {
          box-shadow: none !important;
        }
      }
    }
  }
`;
