import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { ChangeProfessionalStatusOnCall as UsecaseRemoteChangeProfessionalStatusOnCall } from '~/domain/usecases/onCall/remote';
// import { ChangeProfessionalStatusOnCall as UsecaseReduxChangeProfessionalStatusOnCall } from '~/domain/usecases/onCall/redux';

import { RemoteChangeProfessionalStatusOnCall } from '~/data/repository/onCall';
// import { ReduxChangeProfessionalStatusOnCall } from '~/data/store/reducer/onCall/usecases';

/**
 * send request via API.
 */
export const makeRemoteChangeProfessionalStatusOnCall =
  (): UsecaseRemoteChangeProfessionalStatusOnCall =>
    new RemoteChangeProfessionalStatusOnCall(
      makeApiUrl('/oncall/{onCallId}/specialists/{specialistId}'),
      makeHttpClient(),
    );

/**
 * send request via REDUX.
 */
/* export const makeReduxChangeProfessionalStatusOnCall =
  (): UsecaseReduxChangeProfessionalStatusOnCall =>
    new ReduxChangeProfessionalStatusOnCall(); */
