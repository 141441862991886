import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 45em;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  background-color: #fff;

  border: 1px solid #dedede;
  overflow: hidden;
`;

export const ListHeader = styled.div`
  display: flex;
  width: 100%;
  padding: 1rem;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;

  border-bottom: 1px solid #dedede;
`;

export const ListHeaderTitle = styled.h1`
  color: #222529;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const SwitchButtonLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;

  color: #222529;
  text-align: right;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  margin-bottom: 0;

  user-select: none;
  gap: 0.6rem;
`;

export const TableContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const Th = styled.th`
  color: #3d4349;

  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  padding: 0.8125rem 1rem;

  align-items: center;
  gap: 0.5rem;

  background: #f8f8f8;
  border-bottom: 1px solid #dedede;

  cursor: pointer;

  &:hover {
    background-color: #ecedee;
  }
`;

export const Td = styled.td`
  color: #3d4349;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  padding: 0.75rem 1rem;

  border-bottom: 1px solid #dedede;
`;

type TrProps = {
  active?: boolean;
};

export const Tr = styled.tr.attrs({
  tabIndex: 0,
})<TrProps>`
  background-color: #fdfdfd;
  transition: background-color 0.2s ease;
  position: relative;

  &:hover {
    background: #ecedee;
  }

  &:focus-visible {
    outline: 2px solid #3d94fd;

    box-shadow: 0px 0px 12px 0px rgba(58, 138, 234, 0.5);

    z-index: 1;
  }

  ${({ active }) =>
    active &&
    css`
      background: #f2f8ff;

      &:hover {
        background: #eef6ff;
      }

      &:focus-visible {
        &:after {
          display: none;
        }
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 0.25rem;
        height: 100%;
        background: #3d94fd;
      }
    `}
`;

export const ProfessionalBadge = styled.span`
  display: inline-flex;
  align-items: flex-start;
  justify-content: center;
  gap: 0.75rem;

  & #profile {
    margin: 0;
  }
`;

export const ProfessionalBadgeAvatar = styled.img`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  object-fit: cover;

  background-color: #dedede;
`;

export const ProfessionalBadgeInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const ProfessionalBadgeName = styled.span`
  color: #3d4349;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const ProfessionalBadgeRole = styled.span`
  color: #62666c;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

type ProfessionalBadgeStatusProps = {
  status: 'WAITING' | 'AVAILABLE' | 'MATCHING' | 'PAUSED' | 'BUSY';
};

export const ProfessionalBadgeStatus = styled.div<ProfessionalBadgeStatusProps>`
  color: #62666c;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  width: fit-content;

  border-radius: 0.38rem;
  padding: 0.5rem 1rem;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 0.375rem;
  border: 1px solid #818385;
  background: #f8f8f8;

  ${({ status }) =>
    status === 'MATCHING' &&
    css`
      border-radius: 0.375rem;
      border: 1px solid #3d94fd;
      background: #f2f8ff;
      color: #0565d9;
    `}
`;

interface PriorityProps {
  priority: 'EMERGENCY' | 'NORMAL' | 'LOW';
}

export const Priority = styled.div<PriorityProps>`
  display: flex;
  align-items: center;
  gap: 8px;

  .status {
    width: 6px;
    height: 6px;
    border-radius: 50%;

    ${({ priority }) => {
      switch (priority) {
        case 'EMERGENCY':
          return css`
            background-color: #dd0404;
          `;

        case 'NORMAL':
          return css`
            background-color: #f49404;
          `;

        case 'LOW':
          return css`
            background-color: #519a08;
          `;

        default:
          return css`
            background-color: #519a08;
          `;
      }
    }}
  }
`;
