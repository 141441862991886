/* eslint-disable react/react-in-jsx-scope */
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { WaitingRoomPatientDetailsCard } from '~/presentation/components/waitingRoomPatientDetailsCard';
import PatientDutyList from './List';
import { Wrapper } from './styles';

type Info = {
  name: string;
  avatar: string;
  org: string;
  city: string;
  province: string;
  sex: 'F' | 'M';
  age: number;
  situation: string;
  susCard: string;
};

const DutyPage: React.FC = () => {
  const [selectedRequester, setSelectedRequester] = useState<number | null>(
    null,
  );

  const { dutyRequesters } = useSelector((store: iStore) => store.duty);

  const info = useMemo(() => {
    const requester = dutyRequesters?.records?.find(
      requesterItem => requesterItem.order === selectedRequester,
    );

    if (requester) {
      return requester.requester;
    }
    return undefined;
  }, [dutyRequesters?.records, selectedRequester]);

  return (
    <Wrapper>
      <PatientDutyList
        selectedRequester={selectedRequester}
        setSelectedRequester={setSelectedRequester}
      />
      {!!selectedRequester && <WaitingRoomPatientDetailsCard info={info} />}
    </Wrapper>
  );
};

export default DutyPage;
