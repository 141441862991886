import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  min-width: 360px;
  max-width: 360px;
  min-height: 232px;

  background-color: #fdfdfd;
  border: 1px solid #dedede;
  border-radius: 10px;
`;

// Start Header Components
export const Header = styled.div`
  width: 100%;
  column-gap: 10px;
  padding: 12px 16px 15px;
  border-bottom: 1px solid #dedede;
  font-size: 18px;
  font-weight: 600;
`;

export const UserInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const UserImg = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 100%;
`;
// End Header Components

// Start Body Components
export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  /* border-bottom: 1px solid #DEDEDE; */
`;

interface ConsultInfoContainerProps {
  withBorder?: boolean;
}

export const ConsultInfoContainer = styled.div<ConsultInfoContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  row-gap: 20px;
  margin-bottom: 10px;
  width: 100%;

  padding: 12px 16px 16px;
  border-bottom: ${({ withBorder }) =>
    withBorder ? '1px solid #dedede' : 'none'};
`;

export const ConsultInfoItem = styled.div``;

export const ConsultInfoItemGroup = styled.div`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
`;

export const ConsultInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 8px;

  svg {
    max-height: 14px;
    margin-bottom: 2px;
  }
`;
// End Body Components

// Start Footer Components
export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
`;
// End Footer Components

// Start Text Components
export const TextBold = styled.p`
  font-weight: 600;
  font-size: 15px;
  line-height: 150%;
  color: #222529;
`;

export const TextNormal = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #818385;
`;

export const TextNormalBlackFour = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #62666c;
`;
// End Text Components
