import { DownloadAppointmentReport } from '~/domain/usecases/professionalList/remote';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import {
  InvalidCredentialsError,
  UnexpectedError,
  NotFound,
  Forbidden,
} from '~/domain/errors';

export class RemoteDownloadAppointmentReport
  implements DownloadAppointmentReport
{
  private readonly url: string;

  private readonly httpClient: HttpClient<DownloadAppointmentReport.Model>;

  constructor(
    url: string,
    httpClient: HttpClient<DownloadAppointmentReport.Model>,
  ) {
    this.url = url;
    this.httpClient = httpClient;
  }

  download = async (
    params: DownloadAppointmentReport.Params,
  ): Promise<DownloadAppointmentReport.Model> => {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${String(
        params.query.professionalId,
      )}/appointments/report`,
      method: 'post',
      responseType: 'blob',
      body: params.body,
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      case HttpStatusCode.forbidden:
        throw new Forbidden();
      case HttpStatusCode.serverError:
        throw new UnexpectedError();
      default:
        throw new UnexpectedError();
    }
  };
}
