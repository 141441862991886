import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: ${props => props.theme.background};
  overflow: hidden;
`;

export const Cards = styled.div`
  margin: 24px 50px;
  width: 100%;
  display: flex;
  gap: 16px;
  align-items: center;
  flex-wrap: wrap;
`;

export const LockScroll = styled.div`
  position: sticky;
  top: 0;
  z-index: 2;
`;
