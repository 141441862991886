import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { StartAppointmentOnCall as UsecaseRemoteStartAppointmentOnCall } from '~/domain/usecases/onCall/remote';
// import { StartAppointmentOnCall as UsecaseReduxStartAppointmentOnCall } from '~/domain/usecases/onCall/redux';

import { RemoteStartAppointmentOnCall } from '~/data/repository/onCall';
// import { ReduxStartAppointmentOnCall } from '~/data/store/reducer/onCall/usecases';

/**
 * send request via API.
 */
export const makeRemoteStartAppointmentOnCall =
  (): UsecaseRemoteStartAppointmentOnCall =>
    new RemoteStartAppointmentOnCall(
      makeApiUrl('/oncall/{onCallId}/specialists/{specialistId}/attend/{requesterId}/START'),
      makeHttpClient()
    );

/**
 * send request via REDUX.
 */
/* export const makeReduxStartAppointmentOnCall =
  (): UsecaseReduxStartAppointmentOnCall =>
    new ReduxStartAppointmentOnCall(); */
