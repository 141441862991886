import React, { useState, KeyboardEvent } from 'react';
import { useSelector } from 'react-redux';
import { IconFilter, IconSearch } from '~/presentation/base/icons';
import { Container } from './styles/StyledSearchList';

import Dropdown from '../drop-down';
import { makeReduxGetAllAdministrator } from '~/main/factories/usecases/administrator/GetAllAdministratorFactory';
import { iStore } from '~/domain/interfaces/models';
import { translator } from '../i18n';

interface SearchProps {
  name?: string;
  children: JSX.Element[] | JSX.Element;
  icon?: JSX.Element;
}

const SearchListAdministrator: React.FC<SearchProps> = ({ children }) => {
  const [administrator, setAdministrator] = useState('');

  const { selectUser } = useSelector((store: iStore) => store.auth);

  const handleSubmit = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      makeReduxGetAllAdministrator().getAll({
        pageSize: 9999,
        filter: {
          org: selectUser?.orgId,
          name: administrator,
          situation: ['ACTIVE', '2FAWAIT'],
        },
      });
    }
  };

  return (
    <Container onSubmit={() => handleSubmit}>
      <IconSearch id="btn_searchFilter" />
      <input
        id="input_search"
        placeholder={`${translator('Buscar por...')}`}
        value={administrator}
        onChange={e => setAdministrator(e.target.value)}
        onKeyPressCapture={handleSubmit}
      />
      <Dropdown label="Filtros" icon={<IconFilter />}>
        {children}
      </Dropdown>
    </Container>
  );
};
export default SearchListAdministrator;
