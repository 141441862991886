import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { JoinOnCall as UsecaseJoinOnCall } from '~/domain/usecases/duty/remote';
import { RemoteJoinOnCall } from '~/data/repository/duty';

/**
 * send request via API.
 */
export const makeRemoteJoinOnCall = (): UsecaseJoinOnCall =>
  new RemoteJoinOnCall(
    makeApiUrl('/oncall/{onCallId}/requesters/{requesterId}'),
    makeHttpClient(),
  );
