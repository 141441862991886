import styled from 'styled-components';

export const ContainerAvatars = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 500px;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 5px;
  .paciente {
    display: flex;
    flex-direction: row-reverse;
  }
  .organizer {
    display: flex;
    flex-direction: row;
  }
`;

export const ContainerImage = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
`;

export const Name = styled.span`
  font-size: 16px;
  color: #f00;
  font-weight: bold;
  text-transform: capitalize;
`;

export const Roles = styled.span`
  font-size: 14px;
  color: ${props => props.theme.menuHeaderColor};
`;

export const ContainerInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;
