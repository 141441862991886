import { call, put } from 'redux-saga/effects';
import { createIntl, createIntlCache } from 'react-intl';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';

import { iActionCreate } from '~/domain/interfaces/redux/clinicalDocs/create';
import { CreateClinicalDocs } from '~/domain/usecases/clinicalDocs/remote/CreateClinicalDocs';
import {
  createSuccess,
  createFailed,
} from '~/data/store/reducer/clinicalDocs/actions/create';

import { makeRemoteCreateClinicalDocs } from '~/main/factories/usecases/clinicalDocs/CreateClinicalDocsFactory';

import { AlertMessage } from '~/presentation/components/messages/AlertMessage';

import {
  GetClinicalDocs,
  GetAllClinicalDocs,
} from '~/domain/usecases/clinicalDocs/remote';
import { Sign } from '~/domain/usecases/deamon/remote';
import { SignClinicalDocs } from '~/domain/usecases/clinicalDocs/redux/SignClinicalDocs';

import { SignFailed } from '~/data/store/reducer/clinicalDocs/actions/sign';

import { makeRemoteGetClinicalDocs } from '~/main/factories/usecases/clinicalDocs/GetClinicalDocsFactory';
import { makeReduxGetAllClinicalDocs } from '~/main/factories/usecases/clinicalDocs/GetAllClinicalDocsFactory';
import { makeRemoteUpdateClinicalDocsBySign } from '~/main/factories/usecases/clinicalDocs/UpdateClinicalDocsBySignFactory';
import { makeRemoteSignDocument } from '~/main/factories/usecases/daemon/Sign';
import { iStore } from '~/domain/interfaces/models';
import storeDev from '../..';
import { translator } from '~/presentation/components/i18n';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

export function* onCreate(action: iActionCreate) {
  const remoteCreateClinicalDocs = makeRemoteCreateClinicalDocs();

  try {
    const response: CreateClinicalDocs.Model = yield call(
      remoteCreateClinicalDocs.create,
      action.payload,
    );

    console.log(response);
    if (
      action.payload.certificate !== undefined &&
      action.payload.certificateId !== undefined
    ) {
      try {
        yield SignDoc({
          docId: response.id,
          certificate: action.payload.certificate,
          certificateId: String(action.payload.certificateId),
          appointmentId: action.payload.appointment,
        });
      } catch (e) {
        console.error(e);
        yield put(SignFailed());
      }
    }

    const signDocWithVidaas = action?.payload?.signDocWithVidaas;
    yield put(
      createSuccess({
        ...response,
        sidebar: action.payload?.sidebar,
        goBackToListDocument: action.payload?.goBackToListDocument,
        signDocWithVidaas: signDocWithVidaas
          ? (id: number) => signDocWithVidaas(id)
          : undefined,
      }),
    );

    const SignLedgerResponse = SignDocumentWithLedger({
      docId: response.id,
      certificate: action.payload.certificate!,
      certificateId: String(action.payload.certificateId),
      appointmentId: action.payload.appointment,
    });
  } catch (e) {
    console.error('error: ', e);

    yield put(createFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onCreateSuccess() {
  console.log('##oi');
}

export function onCreateFailed() {
  AlertMessage({
    type: 'danger',
    message: intl.formatMessage({ id: 'Falha ao criar documento clínico.' }),
  });
}

function* SignDocumentWithLedger(payload: SignClinicalDocs.Params) {
  const remoteGetClinicalDocs = makeRemoteGetClinicalDocs();
  const remoteSignClinicalDocs = makeRemoteSignDocument();

  if (!payload.docId) throw new Error();

  const response: GetClinicalDocs.Model = yield call(
    remoteGetClinicalDocs.get,
    {
      docId: payload.docId,
    },
  );
  const responseSignDocument: Sign.Model = yield call(
    remoteSignClinicalDocs.sign,
    {
      certId: payload.certificateId,
      algorithm: 'SHA256',
      dataToSignArray: [
        {
          dataToSign: response.dataToSign,
          key: 1,
        },
      ],
    },
  );

  return responseSignDocument;
}

function* SendSignatureValueBackend(
  payload: SignClinicalDocs.Params,
  data: Sign.Model,
) {
  const remoteSendSignatureValueBackend = makeRemoteUpdateClinicalDocsBySign();

  if (!payload.docId) throw new Error();

  yield call(remoteSendSignatureValueBackend.patchBySign, {
    docId: payload.docId,
    signatureValue: data[0].signature,
  });
}

function* SignDoc(payload: SignClinicalDocs.Params) {
  // const remoteCreateClinicalDocs = makeRemoteUpdateClinicalDocs();
  // const remoteGetClinicalDocs = makeRemoteGetClinicalDocs();
  const remoteSignClinicalDocs = makeRemoteSignDocument();
  const remoteSendSignatureValueBackend = makeRemoteUpdateClinicalDocsBySign();

  const store: iStore = storeDev.getState();
  const { orgId } = store.auth.selectUser;

  // console.log('# Sign doc payload: ', payload);

  if (!payload.docId) throw new Error();

  const responseGetClinicalDoc: GetClinicalDocs.Model = yield call(
    getDocDelay,
    {
      docId: payload.docId,
    },
  );
  // console.log('#1. Response GetClinicalDocs', responseGetClinicalDoc);

  const signClinicalDocsBody: Sign.Params = {
    certId: payload.certificateId,
    algorithm: 'SHA256',
    dataToSignArray: [
      {
        dataToSign: responseGetClinicalDoc.dataToSign,
        key: 1,
      },
    ],
  };

  // console.log('#Enviando...', signClinicalDocsBody);

  const responseSignDocument: Sign.Model = yield call(
    remoteSignClinicalDocs.sign,
    signClinicalDocsBody,
  );
  // console.log('#2. Response SignDocument DEAMON', responseSignDocument);

  // 3 passo //

  // console.log('#Enviando para o backend: ', {
  //   docId: payload.docId,
  //   signatureValue: responseSignDocument[0].signature,
  // });

  yield call(remoteSendSignatureValueBackend.patchBySign, {
    docId: payload.docId,
    signatureValue: responseSignDocument[0].signature,
  });
  // console.log('#3. Response Send Signature Value to Backend');

  // 4 passo //
  yield call(getAllDocDelay, {
    filter: {
      appointment: payload.appointmentId,
      org: orgId,
      enabled: true,
    },
  });

  AlertMessage({
    type: 'success',
    message: intl.formatMessage({ id: 'Documento assinado com sucesso!' }),
  });
}

async function getAllDocDelay(data: GetAllClinicalDocs.Params): Promise<void> {
  const reduxGetAllClinicalDocs = makeReduxGetAllClinicalDocs();

  await new Promise<void>((resolve, reject) => {
    setTimeout(async () => {
      reduxGetAllClinicalDocs.getAll(data?.filter?.appointment ? data : {});
      resolve();
    }, 6000);
  });
}

async function getDocDelay(
  data: GetClinicalDocs.Params,
): Promise<GetClinicalDocs.Model> {
  const remoteGetClinicalDocs = makeRemoteGetClinicalDocs();

  const response: GetClinicalDocs.Model = await new Promise(
    (resolve, reject) => {
      setTimeout(async () => {
        remoteGetClinicalDocs
          .get(data)
          .then(result => resolve(result))
          .catch(e => reject(e));
      }, 6000);
    },
  );

  console.log('<><><> getDocDelay: ', response);

  return response;
}
