import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import {
  BadGateway,
  Forbidden,
  UnexpectedError,
  BadRequestError,
  NotFound,
  InvalidCredentialsError,
} from '~/domain/errors';
import { UpdateInstantAppointment } from '~/domain/usecases/appointment/remote';

export class RemoteUpdateInstantAppointment
  implements UpdateInstantAppointment
{
  private readonly url: string;

  private readonly httpClient: HttpClient<UpdateInstantAppointment.Model>;

  constructor(
    url: string,
    httClient: HttpClient<UpdateInstantAppointment.Model>,
  ) {
    this.url = url;
    this.httpClient = httClient;
  }

  updateInstantAppointment = async (
    params: UpdateInstantAppointment.Params,
  ): Promise<UpdateInstantAppointment.Model> => {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}${params.appointmentId}/otherProfessionals/${params?.otherProfessionalId}`,
      method: 'patch',
      body: {
        name: params.name,
        unit: params.unit,
        regType: params.regType,
        regValue: params.regValue,
        appointment: params.appointment,
        externalConsultant: params.externalConsultant,
        phone: params.phone,
        email: params.email,
        specialty: params.specialty,
        uf: params.uf,
      },
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.noContent:
        return 'OI';
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      case HttpStatusCode.forbidden:
        throw new Forbidden();
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.badGateway:
        throw new BadGateway();
      default:
        throw new UnexpectedError();
    }
  };
}
