import { JoinOnCall } from '~/domain/usecases/duty/remote';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import {
  InvalidCredentialsError,
  UnexpectedError,
  BadRequestError,
  Forbidden,
  NotFound,
  Conflict,
} from '~/domain/errors';
import '~/infra/global/variables';

export class RemoteJoinOnCall implements JoinOnCall {
  private readonly url: string;

  private readonly httpClient: HttpClient<JoinOnCall.Model>;

  constructor(url: string, httClient: HttpClient<JoinOnCall.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  create = async (params: JoinOnCall.Params): Promise<JoinOnCall.Model> => {
    let url = `${this.url.replace('{onCallId}', String(params.onCallId))}`;
    url = url.replace('{requesterId}', String(params.requesterId));
    const httpResponse = await this.httpClient.request({
      url,
      method: 'post',
      body: params,
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.forbidden:
        throw new Forbidden();
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.conflict:
        throw new Conflict();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      default:
        throw new UnexpectedError();
    }
  };
}
