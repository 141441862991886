import { makeApiUrl, makeHttpClient } from '~/main/factories/http';

import { DownloadAppointmentReport as UsecaseRemoteDownloadAppointmentReport } from '~/domain/usecases/professionalList/remote';

import { RemoteDownloadAppointmentReport } from '~/data/repository/professionalList';

/**
 * send request via API.
 */
export const makeRemoteDownloadAppointmentReport =
  (): UsecaseRemoteDownloadAppointmentReport =>
    new RemoteDownloadAppointmentReport(
      makeApiUrl('/professionals'),
      makeHttpClient(),
    );
