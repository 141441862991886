import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: flex-start;
  justify-content: space-around;

  margin: 1.5rem 0;
  padding: 0 3.125rem;

  gap: 1rem;
`;
