import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { LeftRequesterOnCall as UsecaseRemoteLeftRequesterOnCall } from '~/domain/usecases/onCall/remote';
// import { LeftRequesterOnCall as UsecaseReduxLeftRequesterOnCall } from '~/domain/usecases/onCall/redux';

import { RemoteLeftRequesterOnCall } from '~/data/repository/onCall';
// import { ReduxLeftRequesterOnCall } from '~/data/store/reducer/onCall/usecases';

/**
 * send request via API.
 */
export const makeRemoteLeftRequesterOnCall =
  (): UsecaseRemoteLeftRequesterOnCall =>
    new RemoteLeftRequesterOnCall(
      makeApiUrl('/oncall/{onCallId}/requesters/{requesterId}'),
      makeHttpClient(),
    );

/**
 * send request via REDUX.
 */
/* export const makeReduxLeftRequesterOnCall =
  (): UsecaseReduxLeftRequesterOnCall =>
    new ReduxLeftRequesterOnCall(); */
