import { object, string, array } from 'zod';

export const schemaInterconsultation = object({
  info: object({
    name: string().nonempty({ message: 'Nome é obrigatório' }),
    birthDate: string().nonempty({
      message: 'Data é obrigatória',
    }),
    regType: string().nonempty({
      message: 'Tipo de registro é obrigatório',
    }),
    regValue: string().nonempty({ message: 'Registro é obrigatório' }),
  }),
  professionals: array(
    object({
      name: string().nonempty({ message: 'Nome é obrigatório' }),
      unit: string().nonempty({ message: 'Unidade de saúde é obrigatório' }),
    }),
  ),
});
