/* eslint-disable object-shorthand */
import React, { useEffect, useState } from 'react';
import * as datefns from 'date-fns';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { addDays, subYears } from 'date-fns';
import { Input, Select, Button } from '~/presentation/components/UI';
import { getAppointmentsByDate } from '~/utils/getAppointmentsByDate';
import {
  Container,
  ContainerButtons,
  ContainerForm,
  FormItem,
  ClinicalDocs,
  ContainerDocs,
  FormGroup,
  Options,
} from './styles/StyledFilterAppointment';

import { translator } from '~/presentation/components/i18n';
import { iStore } from '~/domain/interfaces/models';
import { makeReduxGetAllAppointmentType } from '~/main/factories/usecases/appointmentType/GetAppointmentType';
// import { makeReduxListAppointment } from '~/main/factories/usecases/appointment/ListAppointmentFactory';
import { iFilterProps } from '~/presentation/pages/AppointmentHistory/interface';
import { makeReduxAppointmentFiltered } from '~/main/factories/usecases/appointment/AppointmentFiltered';
import { makeReduxListAppointment } from '~/main/factories/usecases/appointment/ListAppointmentFactory';
import InputKeyboardDate from '../inputKeyboardDate';

type LocationState = {
  user: any;
};

type AppointmentDate = {
  begin: Date;
  end: Date;
};

const FilterAppointmentHistory: React.FC<iFilterProps> = ({
  filter,
  handleSetFilter,
}) => {
  const [appDate, setAppDate] = useState<AppointmentDate>({
    begin: new Date(),
    end: addDays(new Date(), 1),
  });
  const { auth, appointment } = useSelector((store: iStore) => store);
  const specialties = useSelector((store: iStore) => store.specialty.results);

  const { role, orgId, orgUnitId } = useSelector(
    (store: iStore) => store.auth.selectUser,
  );

  const location = useLocation();

  const consultantId = auth.info.consultants?.find(
    item => item.org?.id === orgId,
  )?.id;

  const professionalId = auth.info.professionals?.find(
    item => item.orgUnit.id === orgUnitId,
  )?.id;

  const organizerId = auth.info.organizers?.find(
    item => item.orgUnit.id === orgUnitId,
  )?.id;

  const dateInit = new Date();
  const scheduleFrom = datefns.format(dateInit, 'yyyy-MM-dd');
  const scheduleTo = datefns.format(datefns.addDays(dateInit, 2), 'yyyy-MM-dd');

  const defaultFilter = {
    scheduleTo,
    org: orgId,
  };

  const handleFilter = () => {
    // const dateInit = new Date();
    // const scheduleTo = datefns.format(
    //   datefns.addDays(dateInit, 2),
    //   'yyyy-MM-dd',
    // );

    const now = new Date();
    const begin = subYears(now, 20);
    const end = addDays(now, 2);

    makeReduxAppointmentFiltered().post({
      dataControl: {
        limit: 100,
      },
      timestamp: {
        begin: begin.toISOString(),
        end: end.toISOString(),
      },
      status: filter.statusIs !== 'DEFAULT' ? filter.statusIs : undefined,
      modality: filter.modalityIs !== 'DEFAULT' ? filter.modalityIs : undefined,
      ...(role === 'CON' && {
        consultant: consultantId,
        professionalName: filter?.professional?.length
          ? filter.professional
          : undefined,
        specialty: Number(filter.specialtyIs),
      }),
      ...(role === 'PRO' && {
        professional: professionalId,
        consultantName: filter?.consultant?.length
          ? filter.consultant
          : undefined,
      }),
      ...(role === 'ORG' && {
        organizer: organizerId,
      }),
    });

    document.getElementById('close_filter')?.click();
  };

  const resetFilter = () => {
    handleSetFilter({
      consultant: '',
      professional: '',
      hasClinDoc: 'DEFAULT',
      hasSuppDoc: 'DEFAULT',
      modalityIs: 'DEFAULT',
      specialtyIs: 'DEFAULT',
      statusIs: 'DEFAULT',
    });

    switch (role) {
      case 'CON':
        makeReduxListAppointment().list({
          body: {
            orderAttribute: 'scheduled',
            orderType: 'DESC',
            filter: {
              org: orgId,
              consultant: consultantId,
              status: ['ENDED', 'CANCELED'],
            },
          },
        });
        break;

      case 'PRO':
        makeReduxListAppointment().list({
          body: {
            orderAttribute: 'scheduled',
            orderType: 'DESC',
            filter: {
              ...defaultFilter,
              org: orgId,
              professional: professionalId,
              status: ['ENDED', 'CANCELED'],
            },
          },
        });
        break;

      case 'ORG':
      case 'ADM': {
        const { user } = location.state as LocationState;
        makeReduxListAppointment().list({
          body: {
            orderAttribute: 'scheduled',
            orderType: 'DESC',
            filter: {
              status: ['ENDED', 'CANCELED'],
              ...(user.role === 'CON' && { consultant: Number(user.cod) }),
              ...(user.role === 'PRO' && { professional: Number(user.cod) }),
              ...defaultFilter,
            },
          },
        });
        break;
      }
      default:
        break;
    }
  };

  useEffect(() => {
    makeReduxGetAllAppointmentType().list({});
  }, []);

  return (
    <Container>
      <ContainerForm
        onSubmit={e => {
          e.preventDefault();
          handleFilter();
        }}
      >
        {role === 'ORG' && (
          <FormItem>
            <p>A partir de</p>
            <InputKeyboardDate
              state={appDate.begin}
              setState={(date: Date) => setAppDate({ ...appDate, begin: date })}
              minDate={new Date()}
              placeholder="DD/MM/AAAA"
              endAdornment
            />
          </FormItem>
        )}
        {role === 'ORG' && (
          <FormItem>
            <p>Até</p>
            <InputKeyboardDate
              state={appDate.end < appDate.begin ? appDate.begin : appDate.end}
              setState={(date: Date) => setAppDate({ ...appDate, end: date })}
              minDate={new Date()}
              placeholder="DD/MM/AAAA"
              endAdornment
            />
          </FormItem>
        )}
        {role === 'ORG' && (
          <FormItem>
            <p>Est. de Saúde</p>
            <Select
              id="select_status"
              value={filter.statusIs}
              onChange={e =>
                handleSetFilter({ ...filter, statusIs: e.target.value })
              }
            >
              <Options id="state_1" value="DEFAULT">
                {translator('Todos')}
              </Options>
              {/* <Options id="state_1" value="CANCELED">
                {translator('Cancelado')}
              </Options>
              <Options id="state_2" value="ENDED">
                {translator('Finalizado')}
              </Options> */}
            </Select>
          </FormItem>
        )}
        {role === 'ORG' && (
          <FormItem>
            <p>Serviço</p>
            <Select
              id="select_status"
              value={filter.service}
              onChange={e =>
                handleSetFilter({ ...filter, service: e.target.value })
              }
            >
              <Options id="state_1" value="DEFAULT">
                {translator('Todos')}
              </Options>
              <Options id="state_1" value="CONSULTANTION">
                {translator('Plantão Virtual')}
              </Options>
              <Options id="state_2" value="INSTANTANEOUS">
                {translator('Interconsulta inst.')}
              </Options>
              <Options id="state_3" value="ONCALL">
                {translator('Agendado')}
              </Options>
            </Select>
          </FormItem>
        )}
        {role !== 'ORG' && (
          <FormItem>
            <p>Status</p>
            <Select
              id="select_status"
              value={filter.statusIs}
              onChange={e =>
                handleSetFilter({ ...filter, statusIs: e.target.value })
              }
              placeholder={`${translator('Qualquer')}`}
            >
              <Options id="state_1" value="DEFAULT">
                {translator('Todos')}
              </Options>
              <Options id="state_1" value="CANCELED">
                {translator('Cancelado')}
              </Options>
              <Options id="state_2" value="ENDED">
                {translator('Finalizado')}
              </Options>
            </Select>
          </FormItem>
        )}
        {role === 'CON' && (
          <FormItem>
            <p>{`${translator('Especialidade')}`}</p>
            <Select
              id="select_especialty"
              value={filter.specialtyIs}
              placeholder={`${translator('Especialidade')}`}
              onChange={e =>
                handleSetFilter({ ...filter, specialtyIs: e.target.value })
              }
            >
              <Options id="specialty_0" value="DEFAULT">
                {`${translator('Todas')}`}
              </Options>
              {specialties.map(item => (
                <Options
                  id={`specialty_${item.specialty?.id}`}
                  value={item.specialty?.id}
                >
                  {item.specialty?.name}
                </Options>
              ))}
            </Select>
          </FormItem>
        )}
        {role === 'CON' && (
          <FormItem>
            <p>{`${translator('Profissional')}`}</p>
            <Input
              id="input_professional"
              placeholder={`${translator('Digite o nome do profissional')}`}
              className="hover-input"
              value={filter.professional}
              autoFocus
              onChange={e =>
                handleSetFilter({ ...filter, professional: e.target.value })
              }
            />
          </FormItem>
        )}
        {role === 'PRO' && (
          <FormItem>
            <p>{`${translator('Paciente')}`}</p>
            <Input
              id="input_patient"
              placeholder={`${translator('Digite o nome do paciente')}`}
              className="hover-input"
              value={filter.consultant}
              autoFocus={role === 'PRO' || false}
              onChange={e =>
                handleSetFilter({ ...filter, consultant: e.target.value })
              }
            />
          </FormItem>
        )}
        {role !== 'ORG' && (
          <FormItem>
            <p>{`${translator('Modalidade')}`}</p>
            <Select
              id="select_modality"
              value={filter.modalityIs}
              placeholder={`${translator('Modalidade')}`}
              onChange={e =>
                handleSetFilter({ ...filter, modalityIs: e.target.value })
              }
            >
              <Options id="modality_1" value="DEFAULT">
                {`${translator('Todos')}`}
              </Options>
              <Options id="modality_2" value="REMOTE">
                {`${translator('Remoto')}`}
              </Options>
              <Options id="modality_3" value="INPERSON">
                {`${translator('Presencial')}`}
              </Options>
            </Select>
          </FormItem>
        )}
        {role !== 'ORG' && (
          <FormItem>
            <p>{`${translator('Documentos de apoio')}`}</p>
            <Select
              id="select_support_doc"
              value={`${filter.hasSuppDoc}`}
              placeholder={`${translator('Documentos de apoio')}`}
              onChange={e =>
                handleSetFilter({ ...filter, hasSuppDoc: e.target.value })
              }
            >
              <Options id="support_doc_1" value="">
                {`${translator('Todos')}`}
              </Options>
              <Options id="support_doc_2" value="true">
                {`${translator('Sim')}`}
              </Options>
              <Options id="support_doc_3" value="false">
                {`${translator('Não')}`}
              </Options>
            </Select>
          </FormItem>
        )}
        {role === 'CON' && (
          <FormItem>
            <p>{`${translator('Documentos clínicos')}`}</p>
            <Select
              id="select_clinical_doc"
              value={`${filter.hasClinDoc}`}
              placeholder={`${translator('Documentos clínicos')}`}
              onChange={e =>
                handleSetFilter({ ...filter, hasClinDoc: e.target.value })
              }
            >
              <Options id="clinical_doc_1" value="">
                {`${translator('Todos')}`}
              </Options>
              <Options id="clinical_doc_2" value="true">
                {`${translator('Sim')}`}
              </Options>
              <Options id="clinical_doc_3" value="false">
                {`${translator('Não')}`}
              </Options>
            </Select>
          </FormItem>
        )}
        {/* <FormItem>
          <p>{`${translator('Período')}`}</p>
          <FormGroup>
            <InputKeyBoardDate
              id="input_date"
              defaultValue="dd/MM/yyyy"
              state={created}
              setState={setCreated}
              placeholder="Data"
            />
            <InputKeyBoardDate
              id="input_date"
              defaultValue="dd/MM/yyyy"
              state={endAt}
              setState={setEndAt}
              placeholder={`${translator('Fim')}`}
            />
          </FormGroup>
        </FormItem> */}
      </ContainerForm>
      <ContainerButtons>
        <Button
          rounded
          variant="secundary"
          size="medium"
          onClick={() => resetFilter()}
        >
          {`${translator('Redefinir')}`}
        </Button>
        <Button
          id="btn_search"
          // resourcers={['PARTICIPANT', 'APPOINTMENT']}
          // actions={['READ_OWN']}
          rounded
          variant="primary"
          size="medium"
          onClick={() => handleFilter()}
          autoFocus
        >
          {`${translator('Pesquisar')}`}
        </Button>
      </ContainerButtons>
    </Container>
  );
};

export default FilterAppointmentHistory;
