import { createIntl, createIntlCache } from 'react-intl';
import translations from '~/infra/i18n/locales';
import { makeRemoteGetAppointmentById } from '~/main/factories/usecases/appointment/GetAppointmentbyIdFactory ';
import { makeRemoteLinksAppointment } from '~/main/factories/usecases/appointment/LinksAppointmentFactory';
import { makeRemoteGetAppointmentType } from '~/main/factories/usecases/appointmentType/GetAppointmentType';
import { makeRemoteGetAllClinicalDocs } from '~/main/factories/usecases/clinicalDocs/GetAllClinicalDocsFactory';
import { makeRemoteGetAllSupportDocs } from '~/main/factories/usecases/supportDoc/GetAllSupportDocsFactory';
import { getLocale } from '~/utils/getLocale';
import {
  AppointmentDetail,
  ExtraInfo,
  Priority,
  iClinicalDocs,
  iLinks,
  iOtherProfessionals,
  iParticipants,
  iSession,
  iSupportDocs,
  iUser,
} from '../interface';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

export const MapAppointmentDetail = async (
  appointmentID: number,
): Promise<AppointmentDetail> => {
  const locale = getLocale();

  const detail = await makeRemoteGetAppointmentById().getAppointmentById({
    id: appointmentID,
  });

  const links = await makeRemoteLinksAppointment()
    .links({
      appointmentId: String(appointmentID),
    })
    .catch(err => console.log('err: ', err));

  const appointmentTypes = await makeRemoteGetAppointmentType().list({});

  const cancelDate = new Date(detail.appointment.canceledAt)
    .toLocaleDateString(locale)
    .split('/');

  const cancelHour = new Date(detail.appointment.canceledAt).toLocaleTimeString(
    locale,
  );

  const date = new Date(detail.appointment.scheduled)
    .toLocaleDateString(locale)
    .split('/');

  const month = [
    'JAN',
    'FEV',
    'MAR',
    'ABR',
    'MAI',
    'JUN',
    'JUL',
    'AGO',
    'SET',
    'OUT',
    'NOV',
    'DEZ',
  ];

  function countMonths() {
    const item = detail.consultant.birthdate?.split('T')[0];
    const today = Date.now();
    const birthDate = new Date(item);

    const diffTime = Math.abs(today - Number(birthDate));
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    const age = Math.floor(diffDays / 365);
    let months = ((diffDays / 365) % 1) * 12;
    months = Math.trunc(months);

    return age
      ? `${age} ${intl.formatMessage({
          id: 'anos e',
        })}  ${months} ${intl.formatMessage({ id: 'meses' })}`
      : '-';
  }

  function makeTime() {
    const item = detail.appointment.scheduled;
    const time = new Date(item);
    const timeStart = new Date(time.getTime()).toLocaleTimeString('pt-BR', {
      hour12: false,
    });

    const { duration } = detail.appointment;
    const timeEnd = new Date(
      time.getTime() + duration * 60000,
    ).toLocaleTimeString('pt-BR', { hour12: false });

    const startFormat = `${timeStart.split(':')[0]}:${timeStart.split(':')[1]}`;
    const endFormat = `${timeEnd.split(':')[0]}:${timeEnd.split(':')[1]}`;
    return `${startFormat} - ${endFormat}`;
  }

  const appointmentType = appointmentTypes.find(
    item => item.id === detail.appointment.type,
  );

  console.log('appointmentTypes: ', appointmentTypes);

  const session: iSession = {
    status: detail.appointment.status,
    appointmentType,
    forwarding: detail.appointment.forwarding,
    clinicalCondition: detail.appointment.clinicalCondition,
    note: detail.appointment.note,
    noteUpdated: detail.appointment.noteUpdated,
    service: detail.appointment.service,
    title: `${
      appointmentType?.msgKey
        ? intl.formatMessage({ id: appointmentType?.msgKey })
        : ''
    } - ${detail.specialty.name}`,
    day: date[0],
    mounth: month[Number(date[1]) - 1],
    timeInterval: makeTime(),
    cancelDate: `${intl.formatMessage({ id: 'em' })} ${cancelDate[0]}/${
      cancelDate[1]
    }/${cancelDate[2]} ${intl.formatMessage({ id: 'às' })} ${cancelHour}`,
  };

  const user: iUser = {
    address: detail.consultant.address?.street?.length
      ? detail.consultant.address?.street
      : '-',
    id: detail.consultant?.id,
    user: detail.consultant.user,
    contact: detail.consultant?.phone?.length ? detail.consultant?.phone : '-',
    email: detail.consultant?.email?.length ? detail.consultant?.email : '-',
    src: detail.consultant.avatar,
    name: detail.consultant?.firstName?.length
      ? `${detail.consultant?.firstName} ${detail.consultant?.lastName}`
      : '-',
    age: countMonths(),
  };

  const participants: iParticipants = {
    consultant: user,
    professional: {
      id: detail.professional.id!,
      user: detail.professional.user,
      name: detail.professional.firstName?.length
        ? `${detail.professional.firstName} ${detail.professional.lastName}`
        : '-',
      specialty: detail.specialty.name!,
      recordAdvice: `${detail.professional?.docProf} - ${detail.professional?.docProfUF}`,
      src: detail.professional.avatar!,
      email: detail.professional.email?.length
        ? detail.professional.email!
        : '-',
      phone: detail.professional.phone!,
    },
    companions: detail.companions,
    otherProfessionals: detail.otherProfessionals as iOtherProfessionals[],
  };

  const info: ExtraInfo = {
    age: detail?.appointment?.info?.age,
    city: detail?.appointment?.info?.city,
    name: detail?.appointment?.info?.name,
    organization: detail?.appointment?.info?.organization,
    sex: detail?.appointment?.info?.sex,
    susCard: detail?.appointment?.info?.susCard,
    uf: detail?.appointment?.info?.uf,
  };

  const priority: Priority = {
    client: detail.priority?.client,
    id: detail.priority?.id,
    msgKey: detail.priority?.msgKey,
    descr: detail.priority?.descr,
  };

  return {
    user,
    info,
    session,
    priority,
    participants,
    links: links ? { ...links, generic: detail.links.guest } : ({} as iLinks),
  };
};

export const MapCLinicalDocuments = async (
  appointmentID: number,
): Promise<iClinicalDocs[]> => {
  const docs = await makeRemoteGetAllClinicalDocs().getAll({
    filter: {
      appointment: appointmentID,
      enabled: true,
    },
  });

  const arr: iClinicalDocs[] = [];

  docs.results.forEach(item => {
    arr.push({
      document: {
        sent: item.document.sent,
      },
      signed: item.document.status === 'SIGNED',
      index: item.document.id,
      content: item.document.content,
      type: item.type,
    });
  });

  return arr;
};

export const MapSupportingDocuments = async (
  appointmentID: number,
): Promise<iSupportDocs[]> => {
  const docs = await makeRemoteGetAllSupportDocs().getAll({
    appointment: appointmentID,
  });

  const arr: iSupportDocs[] = [];

  docs.results.forEach(item => {
    arr.push({
      document: {
        id: item.document.id,
        size: item.document.size,
        token: item.document.token,
        sent: item.document.sent,
      },
      sender: {
        id: item.sender.id,
        name: `${item.sender.firstName} ${item.sender.lastName}`,
      },
      type: {
        id: item.type.id,
        name: item.type.name,
      },
    });
  });

  return arr;
};
