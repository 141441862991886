/* eslint-disable react/react-in-jsx-scope */
import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { iStore } from '~/domain/interfaces/models';
import { makeReduxGetProfessionalOnDuty } from '~/main/factories/usecases/duty/GetProfessionalsOnDuty';
import { WaitingRoomPatientDetailsCard } from '~/presentation/components/waitingRoomPatientDetailsCard';
import ProfessionalDutyList from './List';
import { Wrapper } from './styles';

interface ownProps {
  onCallId: number;
}

const DutyRequesterPage: React.FC<ownProps> = ({ onCallId }) => {
  const { dutyRequesters } = useSelector((store: iStore) => store.duty);
  const auth = useSelector((store: iStore) => store.auth.info);
  const { orgUnitId } = useSelector((store: iStore) => store.auth.selectUser);
  const professional = auth.professionals?.find(
    item => item.orgUnit.id === orgUnitId,
  );

  const history = useHistory();

  const info = useMemo(() => {
    const dutyRequesterInfo = dutyRequesters?.records?.find(
      dutyRequester => dutyRequester.requester.id === professional?.id,
    );

    return dutyRequesterInfo;
  }, [dutyRequesters, professional]);

  const loadProfessionalsOnDuty = useCallback(() => {
    makeReduxGetProfessionalOnDuty().get({
      onCallId,
    });
  }, []);

  useEffect(() => {
    loadProfessionalsOnDuty();
  }, [loadProfessionalsOnDuty]);

  return (
    <Wrapper>
      <ProfessionalDutyList />
      <WaitingRoomPatientDetailsCard isRequester info={info?.requester} />
    </Wrapper>
  );
};

export default DutyRequesterPage;
