/* eslint-disable import/no-anonymous-default-export */
export default {
  background: '#FFFFFF',
  backgroundWhiteIce: '#E5E5E5',
  backgroundSurfaces: '#F8F9FA',
  backgroundByVideo: '#00316C',
  primary: '#1756E8',
  primaryDark: '#063DBD',
  primaryLight: '#2668FF',
  secundary: '#005BC8',
  secundaryDark: '#004BA5',
  secundaryLight: '#006EF2',
  typographyDarkGray: '#7A7A7A',
  typographyDark: '#1C1413',
  typographyLightGray: '#C9C9C9',
  buttonTypographyHover: '#ffffff',
  highlight: '#52C8FA',
  statusNo: '#EC5C52',
  statusYes: '#5BB974',
  statusNone: '#EFEFEF',
  hover: '#F4F7FE',
  press: '#CEDAF2',
  statusSucess: '#5BB974',
  statusSucessBack: '#D3F4CE',
  statusCancel: '#9A0000',
  statusCancelBack: '#FD867D',
  statusFinished: '#7A7A7A',
  stautsFinishedBack: '#EFEFEF',
  statusScheduled: '#11AEF8',
  statusScheduledBack: '#CEDAF2',
  statusProgress: '#FFFFFF',
  statusProgressBack: '#42ABC2',
  header: '#004BA5',
  typography: '#7A7A7A',
  typographyLight: '#C9C9C9',
  primaryLighter: '#2668FF',
  menuHeaderBackground: '#FCFCFC',
  menuHeaderBorder: '#D8D8D8',
  menuHeaderColor: '#515151',
  menuSubHeaderBackground: '#F8F8F8',
  menuHeaderDropdownBackground: '#F0F0F0',
  menuHeaderDropdownColor: '#656A6E',
  menuSubHeaderColor: '#7A7A7A',
  menuSubHeaderBorder: '#D8D8D8',
};
