import { differenceInMinutes } from 'date-fns';

interface ownProps {
  start: Date;
  end: Date;
  duration: number;
}

/**
 * function to transform @type string in @type Date
 * @param timeStart start appointment
 * @param timeEnd end appointment
 * @param date schedule appointment
 * @param duration duration appointment
 */
export const makeDateAndDuration = (
  timeStart: string | Date,
  timeEnd: string | Date,
  date: string,
  duration?: number,
): ownProps => {
  const start = new Date(date);
  const end = new Date(date);

  let splitTimeStart;
  let splitTimeEnd;
  let durationReturn = duration;

  if (timeStart && timeEnd) {
    if (timeStart instanceof Date) {
      splitTimeStart = timeStart.toLocaleTimeString().split(':');
    } else if (timeStart.toString().includes('T')) {
      splitTimeStart = timeStart.split('T')[1].split(':');
    } else {
      splitTimeStart = timeStart.split(':');
    }

    start.setHours(Number(splitTimeStart[0]));
    start.setMinutes(Number(splitTimeStart[1]));

    if (timeEnd instanceof Date) {
      splitTimeEnd = timeEnd.toLocaleTimeString().split(':');
    } else if (timeEnd.toString().includes('T')) {
      splitTimeEnd = timeEnd.split('T')[1].split(':');
    } else {
      splitTimeEnd = timeEnd.split(':');
    }

    end.setHours(Number(splitTimeEnd[0]));
    end.setMinutes(Number(splitTimeEnd[1]));

    durationReturn = differenceInMinutes(end.getTime(), start.getTime());
  } else if (durationReturn) {
    end.setMinutes(start.getMinutes() + durationReturn);
  }

  return { start, end, duration: durationReturn || 0 };
};
