import { zodResolver } from '@hookform/resolvers/zod';
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router';
import { iProfessional } from '~/domain/interfaces/models';
import { translator } from '~/presentation/components/i18n';
import InputKeyboardDate from '~/presentation/components/inputKeyboardDate';
import { Button, Input, Select } from '~/presentation/components/UI';
import { schemaPrescriptionData } from '~/validation/validators/document/MedicalCertificateSideBarValidator';
import { iCreateMedicalCertificate, iListConsultant } from '../interface';
import {
  Container,
  Content,
  Form,
  Navigation,
} from '../styles/StyledGeneralData';

export interface ownProps {
  consultant: iListConsultant[];
  next: (data: iCreateMedicalCertificate) => any;
  professional: iProfessional;
  state?: iCreateMedicalCertificate;
  back?: () => (void);
}

interface iStateParams {
  appointmentId: string;
  professionalId: number;
  consultantId: number;
}

const PrescriptionData: React.FC<ownProps> = ({
  next,
  back,
  state,
}): JSX.Element => {

  const { errors, handleSubmit, register, setValue } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    resolver: zodResolver(schemaPrescriptionData),
    defaultValues: {
      ...state,
    },
  });

  const onSubmit = handleSubmit(data => {
    next({ ...data });
  });

  return (
    <Container>
      <Content>
        <Form onSubmit={onSubmit}>
          <Input
            id="input_periodo"
            name="periodo"
            defaultValue={state?.periodo}
            placeholder="Digite dias ou turno"
            label={`${translator('Tempo de afastamento')}`}
            onChange={e => {
              setValue('periodo', e.target.value);
            }}
            register={() => register('periodo')}
            error={Boolean(errors.periodo)}
            message={
              errors?.periodo?.message
                ? translator(errors?.periodo?.message)
                : ''
            }
            autoFocus
          />

          <p>{`${translator('Observações')}`}</p>
          <textarea
            id="text_area_observacoes"
            name="observacoes"
            ref={register}
            placeholder={`${translator('Motivo do afastamento')}`}
            defaultValue={state?.observacoes}
            onChange={e => {
              setValue('observacoes', e.target.value);
            }}
          />
        </Form>
        <Navigation>
          <Button variant="secundary" onClick={back} size="small" rounded>
            {translator('Voltar')}
          </Button>
          <Button variant="primary" onClick={onSubmit} size="small" rounded>
            {translator('Próximo')}
          </Button>
        </Navigation>
      </Content>
    </Container>
  );
};

export default PrescriptionData;
