import styled from 'styled-components';

// Start Component Interface/Type/const -----------------------------
type StatusType = 'Em andamento' | 'Aguardando' | 'Cancelado' | 'Agendado';

interface IPatientStatusTagText {
  status?: StatusType;
}

const statusTagColorMap = {
  'Em andamento': {
    background: '#DFEEFA',
    text: '#0A5D9E',
  },
  Aguardando: {
    background: '#E8F6EC',
    text: '#116427',
  },
  Cancelado: {
    background: '#FDE8E8',
    text: '#A31313',
  },
  Agendado: {
    background: '#FFDEC7',
    text: '#C25200',
  },
};
// End Component Interface/Type/const -----------------------------

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 576px;
  width: 100%;
  max-width: 428px;
  /* min-width: 428px; */

  border: 1px solid #dedede;
  border-radius: 8px;
`;

// Start Card Header -----------------------------
export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  width: 100%;
  /* min-height: 35%; */

  border-bottom: 1px solid #dedede;
`;

export const PatientInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 8px;
`;

export const AvatarContainer = styled.div`
  margin-right: 16px;
`;

export const PatientInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  /* height: 100%; */
  /* width: 100%; */
`;

export const BtnsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  width: 100%;
  gap: 24px;
`;
// End Card Header -----------------------------
// Start Card Body -----------------------------

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 35%;
  width: 100%;
  padding: 16px;

  border-bottom: 1px solid #dedede; ;
`;

export const BodyContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

// End Card Body -----------------------------
// Start Card Footer -----------------------------

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 30%;
  width: 100%;
  padding: 16px;

  /* border-bottom: 1px solid #dedede;  */
`;

export const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
`;

export const TextBox = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  height: 89px;
  width: 100%;

  padding: 8px;

  border: 1px solid #dedede;
  border-radius: 8px;
`;

// End Card Footer -----------------------------
// Start Card Text -----------------------------

interface ITextContainerProps {
  isTitle?: boolean;
  isRed?: boolean;
  margin?: string;
}

export const Text = styled.span`
  font-size: 14px;
  margin-bottom: 4px;
`;

export const TextBolded = styled.span<ITextContainerProps>`
  font-weight: 500;
  font-size: 14px;
  margin-bottom: ${props => (props.isTitle ? '8px' : '0px')};
  color: ${props => (props.isRed ? '#FD3939' : '#000')};

  margin-left: ${({ margin }) => margin ?? '4px'};
`;

export const TextLight = styled.span`
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 4px;
  color: #626b7d;
`;

export const PatientStatusTagText = styled.div<IPatientStatusTagText>`
  display: flex;
  flex-direction: row;
  place-content: center;

  background: ${props =>
    props.status && statusTagColorMap[props.status]
      ? statusTagColorMap[props.status]?.background
      : '#000'};
  color: ${props =>
    props.status && statusTagColorMap[props.status]
      ? statusTagColorMap[props.status].text
      : '#FFF'};

  width: 115px;
  border-radius: 4px;
  padding: 4px;

  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
`;
// Start Card Text -----------------------------
// Start Card Buttons -----------------------------

export const MoreOptionsMaskBtn = styled.div`
  border: 1px solid #dedede;
  border-radius: 8px;

  .MuiIconButton-root {
    padding: 8px;
    border-radius: 8px;
  }
`;
// End Card Buttons -----------------------------

// Start PopOver Buttons ------------------------------

export const PopOverContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  min-width: 206px;
  /* padding: 16px 0; */

  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border: 1px solid #dedede;
  border-radius: 6px;
`;

interface IPopOverBtnProps {
  isRed?: boolean;
}

export const PopOverOptions = styled.div<IPopOverBtnProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  gap: 8px;

  width: 100%;

  padding: 16px;

  span {
    font-size: 14px;
    color: ${props => (props.isRed ? '#FD3939' : '#000')};
  }

  &:hover {
    background: #f5f5f5;
  }
`;
// End PopOver Buttons --------------------------------

export const TextoTitleFinal = styled.div`
  color: #222529;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  margin-bottom: 16px;
`;

export const ContainerMain = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 428px;
  /* min-width: 428px; */
`;

export const EmptyStateRight = styled.div`
  color: #818385;
  text-align: center;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 576px;
  border: 1px solid #dedede;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;

  span {
    width: 214px;
    white-space: break-spaces;
  }
`;
