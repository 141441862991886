import styled, { css } from 'styled-components';

import { Link as ThisLink } from 'react-router-dom';

import { withAccess } from '~/presentation/hooks/access';

interface iconProps {
  icon?: boolean;
}

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${props => props.theme.menuHeaderBackground};
  width: 100%;

  #logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    margin-left: 20px;
  }
`;

export const Link = styled(ThisLink)`
  text-decoration: none !important;
`;

export const LeftItem = styled.div`
  max-width: 1fr;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const RightItem = styled.div`
  max-width: 1fr;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 40px;
  align-items: center;

  svg {
    cursor: pointer;
    filter: invert(52%) sepia(0%) saturate(0%) hue-rotate(176deg)
      brightness(91%) contrast(92%);
  }
`;

export const Item = withAccess(styled.button`
  padding: 15px 10px;
  font-size: 15px;
  background: transparent;
  cursor: pointer;
  border: none;
  color: white;

  :hover {
    background: white;
    color: #004ba5;
    transition: background 0.5s;
  }
`);

export const Image = styled.img``;

const Options = css<iconProps>`
  height: 100%;
  padding: 20px 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  color: ${props => props.theme.menuHeaderColor};

  svg {
    margin-left: ${props => (props.icon ? '10px' : '0px')};
    filter: invert(28%) sepia(1%) saturate(0%) hue-rotate(169deg)
      brightness(97%) contrast(80%);
  }

  :hover {
    background: ${props => props.theme.menuHeaderBorder};
  }
`;

export const Schedule = withAccess(styled.div`
  ${Options}
  svg {
    color: black;
  }
`);
export const Patient = withAccess(styled.div`
  ${Options}
`);
export const ClinicalDocs = withAccess(styled.div`
  ${Options}
`);

export const Users = withAccess(styled.div`
  ${Options}
  svg {
    color: black;
  }
`);

export const Adm = withAccess(styled.div`
  ${Options}
`);

export const AppointmentHistory = withAccess(styled.div`
  ${Options}
`);

export const Duty = withAccess(styled.div`
  ${Options}
`);

export const Drop = withAccess(styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  color: ${props => props.theme.menuHeaderDropdownColor};
  background: ${props => props.theme.menuHeaderDropdownBackground};
  cursor: pointer;
  border: none;
  font-weight: 500;

  :hover {
    background: ${props => props.theme.menuHeaderBorder};
  }
`);

export const UserOptions = styled.div`
  button {
    width: 100%;
    border: none;
    font-size: 16px;
  }
`;
