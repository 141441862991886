/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState } from 'react';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { Health } from '~/presentation/base/icons';
import { StepMagic, Step } from '~/presentation/components/steps';
import { Button } from '~/presentation/components/UI/buttons';
import Prescription, { ownProps as PrescriptionProps } from './Prescription';
import { Signature } from '~/presentation/components/signature';
import {
  Body,
  Container,
  Content,
  Header,
  ContainerButton,
} from './styles/StyledSimplePrescription';
import SimplePrescriptionView from './SimplePrescriptionView';

import GeneralData, { ownProps as GeneralDataProps } from './GeneralData';

import { CreateClinicalDocs as ReduxCreateClinicalDocs } from '~/domain/usecases/clinicalDocs/redux/CreateClinicalDocs';
import { CreateClinicalDocs as RemoteCreateClinicalDocs } from '~/domain/usecases/clinicalDocs/remote/CreateClinicalDocs';

import { ConnectComponent } from './mapper/MapperSimplePrescription';

import { iValidation, iPrescription } from './interface';
import { makeReduxCreateClinicalDocs } from '~/main/factories/usecases/clinicalDocs/CreateClinicalDocsFactory';
import { iStore } from '~/domain/interfaces/models';
import { translator } from '../i18n';
import { makeRemoteGetClinicalDocs } from '~/main/factories/usecases/clinicalDocs/GetClinicalDocsFactory';
import { History } from '~/routes';
import PatientData from '../PatientDataClinicalDocuments';
import { makeRemoteCreateForeignClinicalDocs } from '~/main/factories/usecases/clinicalDocs/CreateForeignClinicalDocsFactory';
import { CreateForeignClinicalDocs } from '~/domain/usecases/clinicalDocs/remote';

export interface Props {
  basicPrescription: RemoteCreateClinicalDocs | ReduxCreateClinicalDocs;
}

export type ownProps = Props & GeneralDataProps;

interface iStateParams {
  appointmentId: string;
  professional: string;
  consultant: string;
}

const SimplePrescription: React.FC<ownProps> = (props): JSX.Element => {
  const [crement, setCrement] = useState<number>(1);
  const [drugs, setDrugs] = useState<iPrescription[]>([]);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [updateId, setUpdateId] = useState<number>(0);

  const stateParams = useLocation<iStateParams>().state;

  const { loading } = useSelector((store: iStore) => store.clinicalDocs);
  const { auth } = useSelector((store: iStore) => store);
  const { role, orgUnitId, orgId } = useSelector(
    (store: iStore) => store.auth.selectUser,
  );

  const orgUnitName = orgUnitId
    ? auth.info.professionals?.find(item => item.orgUnit.id === orgUnitId)
        ?.orgUnit.name
    : '';

  const specialty =
    role === 'PRO'
      ? auth.info.professionals?.find(item => item.orgUnit.id === orgUnitId)
          ?.professions.specialties[0].id
      : -1;

  const [basicPrescription, setBasicPrescription] = useState<iValidation>({
    professional:
      stateParams && stateParams.professional
        ? Number(stateParams.professional)
        : -1,
    patient: {
      gender: '',
    },
    drugs: [],
    orgUnitName,
  });

  function handleBar(number: number) {
    if (crement + number > 4 || crement + number < 1) {
      setCrement(crement);
      createBasicPrescription();
    } else {
      setCrement(crement + number);
    }
  }
  const data = {
    isSigned: 'Assinado',
    name: 'Danilo',
  };

  let dataToSend: RemoteCreateClinicalDocs.Params = {};

  const createBasicPrescription = () => {
    setDrugs(prevState =>
      prevState.map(item => {
        delete item.id;
        return item;
      }),
    );
    // VINCULADO A UM ATENDIMENTO
    if (stateParams && stateParams.appointmentId !== undefined) {
      dataToSend = {
        appointment: Number(stateParams.appointmentId),
        type: 1,
        content: {
          drugs,
        },
        signatureSource: basicPrescription?.signatureSource ?? undefined,
        certificate:
          basicPrescription?.signatureSource === 'VIDAAS'
            ? undefined
            : basicPrescription.certificate,
        certificateId:
          basicPrescription?.signatureSource === 'VIDAAS'
            ? undefined
            : basicPrescription.certificateId,
      };

      try {
        if (basicPrescription.signatureSource === 'VIDAAS') {
          if (basicPrescription.situation === 'NOT-REGISTERED') {
            makeRemoteCreateForeignClinicalDocs()
              .createForeign({
                body: {
                  type: 1,
                  certificate:
                    basicPrescription?.signatureSource === 'VIDAAS'
                      ? undefined
                      : basicPrescription.signatureSource,
                  content: {
                    drugs,
                  },
                  patient: {
                    age: Number(basicPrescription?.patient?.age),
                    fullname: basicPrescription?.patient?.fullname!,
                    gender: basicPrescription?.patient
                      ?.gender as CreateForeignClinicalDocs.Params['body']['patient']['gender'],
                  },
                  signatureSource:
                    basicPrescription.signatureSource ?? undefined,
                  org: orgId,
                  orgUnit: orgUnitId ?? undefined,
                  professional: basicPrescription.professional,
                  specialty,
                },
              })
              .catch(e => {
                console.log('##error: ', e);
              })
              .then(response => {
                console.log('##response: ', response);
                makeRemoteGetClinicalDocs()
                  .get({ docId: Number(response?.id) })
                  .then(res => {
                    const url = res?.dataToSign;
                    localStorage.setItem('clinicalDocId', String(response?.id));
                    // window.open(url, '_blank');
                    window.location.href = url;
                  })

                  .catch(e => console.log(e));
              });
            return;
          }
          makeReduxCreateClinicalDocs().create({
            ...dataToSend,
            signDocWithVidaas: (id: number) => {
              makeRemoteGetClinicalDocs()
                .get({ docId: id })
                .then(res => {
                  const url = res?.dataToSign;
                  localStorage.setItem('clinicalDocId', String(id));
                  // window.open(url, '_blank');
                  window.location.href = url;
                })

                .catch(e => console.log(e));
            },
          });
        } else {
          makeReduxCreateClinicalDocs().create(dataToSend);
        }
      } catch (e) {
        console.error(e);
      }
      // NÃO VINCULADO A UM ATENDIMENTO
    } else {
      dataToSend = {
        org: orgId,
        orgUnit: orgUnitId,
        patient:
          basicPrescription.situation === 'NOT-REGISTERED'
            ? {
                fullname: basicPrescription?.patient?.fullname!,
                gender: basicPrescription?.patient?.gender!,
                age: basicPrescription?.patient?.age!,
              }
            : basicPrescription?.patient?.id,
        professional: basicPrescription.professional,
        type: 1,
        content: {
          drugs,
        },
        certificate:
          basicPrescription?.signatureSource === 'VIDAAS'
            ? undefined
            : basicPrescription.certificate,
        certificateId:
          basicPrescription?.signatureSource === 'VIDAAS'
            ? undefined
            : basicPrescription.certificateId,
        specialty,
        signatureSource: basicPrescription.signatureSource ?? undefined,
      };

      try {
        if (basicPrescription?.signatureSource === 'VIDAAS') {
          if (basicPrescription.situation === 'NOT-REGISTERED') {
            makeRemoteCreateForeignClinicalDocs()
              .createForeign({
                body: {
                  type: 1,
                  certificate:
                    basicPrescription?.signatureSource === 'VIDAAS'
                      ? undefined
                      : basicPrescription.signatureSource,
                  content: {
                    drugs,
                  },
                  patient: {
                    age: Number(basicPrescription?.patient?.age),
                    fullname: basicPrescription?.patient?.fullname!,
                    gender: basicPrescription?.patient
                      ?.gender as CreateForeignClinicalDocs.Params['body']['patient']['gender'],
                  },
                  signatureSource:
                    basicPrescription.signatureSource ?? undefined,
                  org: orgId,
                  orgUnit: orgUnitId ?? undefined,
                  professional: basicPrescription.professional,
                  specialty,
                },
              })
              .catch((e: any) => {
                console.log('##error: ', e);
              })
              .then(response => {
                console.log('##response: ', response);
                makeRemoteGetClinicalDocs()
                  .get({ docId: Number(response?.id) })
                  .then(res => {
                    const url = res?.dataToSign;
                    localStorage.setItem('clinicalDocId', String(response?.id));
                    // window.open(url, '_blank');
                    window.location.href = url;
                  })

                  .catch(e => console.log(e));
              });
            return;
          }
          makeReduxCreateClinicalDocs().create({
            ...dataToSend,
            signDocWithVidaas: (id: number) => {
              makeRemoteGetClinicalDocs()
                .get({ docId: id })
                .then(res => {
                  const url = res?.dataToSign;
                  localStorage.setItem('clinicalDocId', String(id));
                  // window.open(url, '_blank');
                  window.location.href = url;
                })

                .catch(e => console.log(e));
            },
          });
        } else {
          if (basicPrescription.situation === 'NOT-REGISTERED') {
            makeRemoteCreateForeignClinicalDocs()
              .createForeign({
                body: {
                  type: 1,
                  certificate:
                    basicPrescription?.signatureSource === 'VIDAAS'
                      ? undefined
                      : basicPrescription.signatureSource,
                  content: {
                    drugs,
                  },
                  patient: {
                    age: Number(basicPrescription?.patient?.age),
                    fullname: basicPrescription?.patient?.fullname!,
                    gender: basicPrescription?.patient
                      ?.gender as CreateForeignClinicalDocs.Params['body']['patient']['gender'],
                  },
                  signatureSource: undefined,
                  org: orgId,
                  orgUnit: orgUnitId ?? undefined,
                  professional: basicPrescription.professional,
                  specialty,
                },
              })
              .then(() => {
                History.goBack();
              })
              .catch((err: any) => {
                console.error(err);
              });

            return;
          }

          makeReduxCreateClinicalDocs().create(dataToSend);
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  function next(dados: iValidation) {
    setBasicPrescription({ ...basicPrescription, ...dados });
    handleBar(1);
  }

  function back(dados: iValidation) {
    setBasicPrescription({ ...basicPrescription, ...dados });
    handleBar(-1);
  }

  return (
    <Container>
      <Content>
        <Health />
        <h1>{`${translator('Receita simples')}`}</h1>
      </Content>
      {crement !== 4 ? (
        <Header>
          <StepMagic current={crement}>
            <Step title={`${translator('Informações gerais')}`} />
            <Step title={`${translator('Dados do paciente')}`} />
            <Step title={`${translator('Prescrição')}`} />
            <Step title={`${translator('Assinatura do documento')}`} />
          </StepMagic>
        </Header>
      ) : null}
      <Body>
        {crement === 1 && (
          <GeneralData
            professional={props.professional}
            consultant={props.consultant}
            next={next}
            state={basicPrescription}
          />
        )}
        {crement === 2 && (
          <PatientData
            state={basicPrescription}
            consultant={props.consultant}
            next={next}
            back={back}
          />
        )}
        {crement === 3 && (
          <Prescription
            arrId={updateId}
            setArrId={setUpdateId}
            next={next}
            back={back}
            state={basicPrescription}
            items={drugs}
            setItems={setDrugs}
          />
        )}
        {crement === 4 && (
          <Signature
            changeSelected={(e: {
              certificateId: string;
              certificate: string;
              signatureSource?: string;
            }) =>
              setBasicPrescription({
                ...basicPrescription,
                certificate: e.certificate,
                certificateId: e.certificateId,
                signatureSource: e.signatureSource,
              })
            }
            changeDisabled={(state: boolean) => setDisableButton(state)}
            onSignSubmit={createBasicPrescription}
          />
        )}
        {crement === 5 && (
          <SimplePrescriptionView isSigned={data.isSigned} name={data.name} />
        )}
      </Body>
      {crement === 4 ? (
        <ContainerButton>
          <Button
            id="button_back"
            rounded
            variant="secundary"
            onClick={() => handleBar(-1)}
            size="small"
          >
            {`${translator('Voltar')}`}
          </Button>
          <Button
            id="button_conclude"
            rounded
            size="small"
            onClick={createBasicPrescription}
            disabled={loading || disableButton}
          >
            {`${translator('Concluir')}`}
          </Button>
        </ContainerButton>
      ) : null}
    </Container>
  );
};

export default ConnectComponent(SimplePrescription);
