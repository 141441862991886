export enum MessageTypes {
  SET_DATA = '@message/SET_DATA',
  MESSAGE_ACTIVE = '@message/MESSAGE_ACTIVE',
}

export enum MessageOptions {
  none = 'none',
  appointmentCreate = 'appointmentCreate',
  appointmentConfirmCancel = 'appointmentConfirmCancel',
  appointmentUpdateSuccess = 'appointmentUpdateSuccess',
  appointmentDelete = 'appointmentDelete',
  appointmentCanceled = 'appointmentCanceled',
  userCreateSuccess = 'userCreateSuccess',
  userUpdateSuccess = 'userUpdateSuccess',
  userConfirmCancel = 'userConfirmCancel',
  userDeleteSuccess = 'userDeleteSuccess',
  userWaitingRoomMessage = 'userWaitingRoomMessage',
  userSendMail = 'userSendMail',
  userChangeRole = 'userChangeRole',
  userDataFulfill = 'userDataFulfill',
  userEditDataError = 'userEditDataError',
  userAlreadyExists = 'userAlreadyExists',
  clinicalDocsCreateSuccess = 'clinicalDocsCreateSuccess',
  clinicalDocsSendByEmailSuccess = 'clinicalDocsSendByEmailSuccess',
  docSignedSuccessMessageSuccess = 'docSignedSuccessMessageSuccess',
  docSignedMessage = 'docSignedMessage',
  appointmentStop = 'appointmentStop',
  appointmentStopFailed = 'appointmentStopFailed',
  appointmentStopSuccess = 'appointmentStopSuccess',
  leaveSpecialty = 'leaveSpecialty',
  termsOfUse = 'termsOfUse',
  deleteDocument = 'deleteDocument',
  copyConsultLink = 'copyConsultLink',
  inviteExternalProfessionals = 'inviteExternalProfessionals',
  deleteOrgUnit = 'deleteOrgUnit',
  viewMediaModal = 'viewMediaModal',
  delete = 'delete',
  success = 'success',
  error = 'error',
  ready = 'ready',
  user = 'user',
  ImageModal = 'ImageModal',
  addingMediaModal = 'addingMediaModal',
  cancelEditModal = 'cancelEditModal',
  professionalReady = 'professionalReady',
  expiredFremium = 'expiredFremium',
  viewAudit = 'viewAudit',
  professionalsOnDuty = 'professionalsOnDuty',
  requestConsultation = 'requestConsultation',
  newAvailableInterconsult = 'newAvailableInterconsult',
  isProfessionalAvailable = 'isProfessionalAvailable',
  leaveInterconsult = 'leaveInterconsult',
  acceptTermsWaitingRoom = 'acceptTermsWaitingRoom',
  readyWaitingRoom = 'readyWaitingRoom',
  createShifts = 'createShifts',
  editShifts = 'editShifts',
  instantConsultation = 'instantConsultation',
  instantConsultationSuccess = 'instantConsultationSuccess',
}

export type iMessageOptions = keyof typeof MessageOptions;
