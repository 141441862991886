import { zodResolver } from '@hookform/resolvers/zod';
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router';
import { iProfessional } from '~/domain/interfaces/models';
import { translator } from '~/presentation/components/i18n';
import InputKeyboardDate from '~/presentation/components/inputKeyboardDate';
import { Button, Input, Select } from '~/presentation/components/UI';
import { schemaGeneralData } from '~/validation/validators/document/MedicalCertificateSideBarValidator';
import { iCreateMedicalCertificate, iListConsultant } from '../interface';
import {
  Container,
  Content,
  Form,
  Navigation,
} from '../styles/StyledGeneralData';

export interface ownProps {
  consultant: iListConsultant[];
  next: (data: iCreateMedicalCertificate) => any;
  professional: iProfessional;
  state?: iCreateMedicalCertificate;
}

interface iStateParams {
  appointmentId: string;
  professionalId: number;
  consultantId: number;
}

const GeneralData: React.FC<ownProps> = ({
  next,
  professional,
  state,
}): JSX.Element => {
  const stateParams = useLocation<iStateParams>().state;

  const [professionalId, setProfId] = useState<number | undefined>(
    stateParams.professionalId,
  );


  const { errors, handleSubmit, register, setValue } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    resolver: zodResolver(schemaGeneralData),
    defaultValues: {
      ...state,
    },
  });

  const onSubmit = handleSubmit(data => {
    console.log("sub proximo ", data)
    next({ ...data });
  });

  useEffect(() => {
    register('professional');
    if (stateParams.professionalId) {
      setProfId(stateParams.professionalId);
      setValue('professional', stateParams.professionalId);
    }
  }, [stateParams]);

  return (
    <Container>
      <Content>
        <Form onSubmit={onSubmit}>
          <Input
            id="input_instituition"
            label={`${translator('Instituição')}`}
            onChange={e => setValue('org', Number(e.target.value))}
            name="org"
            register={() => register('org')}
            error={Boolean(errors.org)}
            message={errors?.org?.message}
            placeholder={state?.orgUnitName}
            disabled={state && state.orgUnitName !== ''}
            autoFocus
          />
          <Select
            key={professionalId}
            id="select_professional"
            label={`${translator('Profissional')}`}
            onChange={e => {
              setProfId(Number(e.target.value));
              setValue('professional', Number(e.target.value));
            }}
            value={professionalId}
            name="professional"
            register={() => register('professional')}
            error={Boolean(errors.professional)}
            message={
              errors?.professional?.message
                ? translator(errors?.professional?.message)
                : ''
            }
            disabled={Boolean(professionalId && professionalId !== -1)}
          >
            <option value="selectPatient">
              {translator('Selecione um profissional')}
            </option>
            {professional?.results?.map(user => (
              <option
                key={user.professional.id}
                value={Number(user.professional.id)}
              >
                {`${user.user.firstName} ${user.user.lastName}`}
              </option>
            ))}
          </Select>
          <InputKeyboardDate
            state={new Date()}
            setState={() => ''}
            label={`${translator('Data de emissão')}`}
            placeholder="DD/MM/AAAA"
            disabled
          />
        </Form>
        <Navigation justifyEnd>
          <Button variant="primary" onClick={onSubmit} size="medium" rounded>
            {translator('Próximo')}
          </Button>
        </Navigation>
      </Content>
    </Container>
  );
};

export default GeneralData;
