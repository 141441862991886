import React, { useCallback, useState } from 'react';
import { Popover } from 'react-tiny-popover';
import { useSelector } from 'react-redux';
import {
  IconArrowLeftAgenda,
  IconArrowRightAgenda,
  IconCalendarAgenda,
  IconFilterAgenda,
} from '../../base/icons';
import Input from '../UI/input';
import Select from '../UI/select';
import { closeModal } from '~/utils/closeModal';
import { useMyAppointmentsFilter } from '~/presentation/hooks/filters/myAppointments';
import FilterMyAppointments from '../filter/FilterMyAppointments';
import {
  Box,
  ButtonFilter,
  Container,
  DateText,
  IconBox,
  LeftSide,
  RightSide,
  Visualization,
} from './styles';
import { makeReduxGetAllAppointment } from '~/main/factories/usecases/appointment/GetAllAppointmentFactory';
import { iStore } from '~/domain/interfaces/models';
import { makeReduxSetData } from '~/main/factories/usecases/appointment/SetDataFactory';

interface MyAppointmentInfoHeaderProps {
  getStatus: Function;
  getCurrentDay: Function;
}

const MyAppointmentInfoHeader: React.FC<MyAppointmentInfoHeaderProps> = ({
  getStatus,
  getCurrentDay,
}) => {
  const { auth } = useSelector((store: iStore) => store);

  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);

  const [consultant, setConsultant] = useState('');
  const { orgId } = useSelector((store: iStore) => store.auth.selectUser);

  const professionalId = auth.info.professionals?.find(
    item => item.orgUnit?.id === auth.selectUser?.orgUnitId,
  )?.id;

  const {
    isFilterActive,
    isFilterPopoverOpen,
    handleCancelNewFilters,
    setIsFilterPopoverOpen,
    currentDay,
  } = useMyAppointmentsFilter();

  const handleClosePopover = useCallback(() => {
    setIsFilterPopoverOpen(false);
    handleCancelNewFilters();
  }, [handleCancelNewFilters, setIsFilterPopoverOpen]);

  const handleCloseByEsc = (e: React.KeyboardEvent) => {
    if (e.key === 'Escape') setIsFilterPopoverOpen(false);
  };

  const objOptions: Record<string, string> = {
    'Pacientes agendados': 'SCHEDULED',
    'Pacientes atendidos': 'ENDED',
    'Todos os pacientes': '',
  };

  const options = [
    'Todos os pacientes',
    'Pacientes agendados',
    'Pacientes atendidos',
  ];

  function getBackToCurrentDay() {
    getCurrentDay(new Date());
  }

  function getPreviousDate(date: Date) {
    const previousDate = new Date(date);

    // Subtract one day
    previousDate.setDate(previousDate.getDate() - 1);

    // Check if the previous date is the first day of the month
    if (previousDate.getDate() === 1) {
      // If it's the first day of the month, go back to the last day of the previous month
      previousDate.setMonth(previousDate.getMonth() - 1);
      previousDate.setDate(
        new Date(
          previousDate.getFullYear(),
          previousDate.getMonth() + 1,
          0,
        ).getDate(),
      );
    }

    return previousDate;
  }

  function formatToCustomDate(isoDateString: Date) {
    const dateObject = new Date(isoDateString);

    const dateFormater = new Intl.DateTimeFormat('pt-BR', {
      day: 'numeric',
      month: 'long',
    });
    return dateFormater.format(dateObject);
  }

  const handlePrevious = () => {
    const previousDate = getPreviousDate(currentDay);
    getCurrentDay(previousDate);

    makeReduxSetData().set({
      date: previousDate.toISOString(),
    });
  };

  const handleNext = () => {
    const nextDate = new Date(currentDay);
    nextDate.setDate(currentDay.getDate() + 1);
    getCurrentDay(nextDate);

    makeReduxSetData().set({
      date: nextDate.toISOString(),
    });
  };

  const handleSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      makeReduxGetAllAppointment().getAll({
        filter: {
          professional: professionalId,
          consultantName: consultant,
          org: orgId,
        },
      });
    }
  };

  return (
    <Container>
      <LeftSide>
        <Visualization>Visualização: </Visualization>
        <div style={{ width: '195px', marginRight: 21 }}>
          <Select
            height="40px"
            width="100%"
            fontSize="14px"
            borderLeft={false}
            onChange={e => getStatus(objOptions[e.target.value])}
            backgroundIcon
          >
            {options.map(item => (
              <option value={item}>{item}</option>
            ))}
          </Select>
        </div>
        <div style={{ width: '336px', marginRight: 0 }}>
          <Input
            id="input_search"
            height="40px"
            width="100%"
            placeholder="Pesquisar"
            value={consultant}
            onChange={e => setConsultant(e.target.value)}
            onKeyPressCapture={handleSubmit}
          />
        </div>
        <IconBox>
          <Popover
            onClickOutside={handleClosePopover}
            isOpen={isFilterPopoverOpen}
            align="end"
            positions={['bottom']}
            content={<FilterMyAppointments />}
          >
            <ButtonFilter
              type="button"
              isFilterActive={isFilterActive}
              onKeyUp={handleCloseByEsc}
              onClick={() => {
                setIsFilterPopoverOpen(!isFilterPopoverOpen);
              }}
            >
              <IconFilterAgenda />
            </ButtonFilter>
          </Popover>
        </IconBox>
      </LeftSide>
      <RightSide>
        <Box
          style={{ marginRight: 16, padding: '11px 24px' }}
          onClick={getBackToCurrentDay}
        >
          Hoje
        </Box>
        <div
          style={{
            display: 'flex',
            gap: 24,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box style={{ padding: 8 }}>
            <IconCalendarAgenda />
          </Box>
          <Box style={{ padding: 8 }} onClick={handlePrevious}>
            <IconArrowLeftAgenda />
          </Box>
          <DateText>{formatToCustomDate(currentDay)}</DateText>
          <Box style={{ padding: 8 }} onClick={handleNext}>
            <IconArrowRightAgenda />
          </Box>
        </div>
      </RightSide>
    </Container>
  );
};

export default MyAppointmentInfoHeader;
