import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { DeleteClinicalDocs as UseCaseRemoteDeleteClinicalDocs } from '~/domain/usecases/clinicalDocs/remote';
import { DeleteClinicalDocs as UsecaseReduxDeleteClinicalDocs } from '~/domain/usecases/clinicalDocs/redux';

import { RemoteDeleteClinicalDocs } from '~/data/repository/clinicalDocs';
import { ReduxDeleteClinicalDocs } from '~/data/store/reducer/clinicalDocs/usecases';

export const makeRemoteDeleteClinicalDocs = (): UseCaseRemoteDeleteClinicalDocs =>
  new RemoteDeleteClinicalDocs(makeApiUrl('/clinicalDocs'), makeHttpClient());

export const makeReduxDeleteClinicalDocs = (): UsecaseReduxDeleteClinicalDocs =>
  new ReduxDeleteClinicalDocs();
