/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { StepMagic, Step } from '~/presentation/components/steps';
import { translator } from '~/presentation/components/i18n';

import { V4hSpin } from '~/presentation/components/spin';

import { Address, Contacts, PersonalData } from '../register';

import { Container, Header, Body } from './style/StyledRegisterParticipant';

import { CreateConsultant as ReduxCreateConsultant } from '~/domain/usecases/consultant/redux';

import { iRegisterParticipant } from './interface';

import { schema } from '~/validation/validators/user/CreateUserValidator';

import { iStore } from '~/domain/interfaces/models';
import {
  makeReduxCreateConsultant,
  makeRemoteCreateConsultant,
} from '~/main/factories/usecases/consultant/CreateConsultantFactory';
import Sectors from './Sectors';
import { makeRemoteGetAllUsers } from '~/main/factories/usecases/users/GetAllUsersFactory';

const RegisterParticipant: React.FC = () => {
  const selectUser = useSelector((store: iStore) => store.auth.selectUser);

  const [crement, setCrement] = useState<number>(1);
  const [submit, setSubmit] = useState<boolean>(false);
  const [isFound, setIsFound] = useState<boolean>(false);
  const [user, setUser] = useState<iRegisterParticipant>({
    login: '',
    fullName: '',
    email: '',
    phone: '',
    cpf: '',
    documents: [],
    avatar: '',
    motherName: '',
    birthdate: '',
    address: undefined,
    gender: 'MALE',
    mainRole: 'CON',
  });

  const org = {
    org: selectUser?.orgId || 0,
    orgUnit: selectUser?.orgUnitId || 0,
  };

  useEffect(() => {
    if (submit) createUser();
  }, [submit]);

  const FINAL_STEP = 3;

  const loading = useSelector((store: iStore) => store.users.loading);

  function handleBar(number: number) {
    console.log('crement: ', crement);
    console.log('number: ', number);
    console.log('FINAL_STEP: ', FINAL_STEP);

    if (crement + number > FINAL_STEP) {
      setSubmit(true);
      return;
    }

    setSubmit(false);
    setCrement(crement + number);
  }

  const createUser = async () => {
    const birthDateBack = user!.birthdate!.split('/');

    const splitName = user.fullName!.split(' ');
    const firstName = splitName?.splice(0, 1).join('');
    const lastName = splitName?.join(' ');

    const now = new Date();

    if (user?.sectors?.length) {
      const userAlreadyExists = await makeRemoteGetAllUsers().getAll({
        filter: { cpf: user.cpf },
      });

      const dataToSend: ReduxCreateConsultant.Params = userAlreadyExists.results
        ?.length
        ? {
            user: userAlreadyExists.results[0].user.id,
            org: org.org,
          }
        : {
            org: org.org,
            profile: {
              firstName,
              lastName,
              email: user.email!,
              phone: user.phone!,
              phone2: user.phone2,
              phone3: user.phone3,
              avatar: user.avatar,
              motherName: user.motherName?.length ? user.motherName : undefined,
              gender: user.gender!,
              birthdate: `${birthDateBack[2]}-${birthDateBack[1]}-${birthDateBack[0]}`,
            },
            documents: [
              {
                documentId: Number(user.documentId),
                value: String(user.cpf),
                expires: new Date(
                  now.getFullYear() + 10,
                  now.getMonth(),
                  now.getDate(),
                ),
                issued: now,
                issuer: `${firstName} ${lastName}`,
              },
            ],
            address: user.address
              ? {
                  zipcode: user.address!.zipcode!,
                  uf: user.address!.uf!,
                  city: user.address!.city!,
                  country: 'BRA',
                  neighborhood: user.address!.neighborhood!,
                  street: user.address!.street!,
                  number: user.address!.number!,
                  complement: user.address!.complement!,
                }
              : undefined,
          };

      try {
        if (!userAlreadyExists.results?.length) schema.parse(dataToSend);

        makeRemoteCreateConsultant()
          .create(dataToSend)
          .then(res => {
            if (user.sectors && user.sectors.length > 1) {
              user.sectors.map((sector, index) => {
                if (index !== 0) {
                  makeReduxCreateConsultant().create({
                    user: res.id,
                    org: org.org,
                    orgUnit: sector.id,
                  });
                }
              });
            }
          })
          .catch(err => {
            console.log('err: ', err);
          });
      } catch (e) {
        console.error(e);
      }
    } else {
      const dataToSend: ReduxCreateConsultant.Params = {
        user: isFound ? user.id : undefined,
        org: org.org,
        profile: isFound
          ? undefined
          : {
              firstName,
              lastName,
              email: user.email!,
              phone: user.phone!,
              phone2: user.phone2,
              phone3: user.phone3,
              avatar: user.avatar,
              motherName: user.motherName?.length ? user.motherName : undefined,
              gender: user.gender!,
              birthdate: `${birthDateBack[2]}-${birthDateBack[1]}-${birthDateBack[0]}`,
            },
        documents: isFound
          ? undefined
          : [
              {
                documentId: Number(user.documentId),
                value: String(user.cpf),
                expires: new Date(
                  now.getFullYear() + 10,
                  now.getMonth(),
                  now.getDate(),
                ),
                issued: now,
                issuer: `${firstName} ${lastName}`,
              },
            ],
        // eslint-disable-next-line no-nested-ternary
        address: isFound
          ? undefined
          : user.address
          ? {
              zipcode: user.address!.zipcode!,
              uf: user.address!.uf!,
              city: user.address!.city!,
              country: 'BRA',
              neighborhood: user.address!.neighborhood!,
              street: user.address!.street!,
              number: user.address!.number!,
              complement: user.address!.complement!,
            }
          : undefined,
      };

      try {
        const validate = schema.parse(dataToSend);
        console.log('validate: ', validate);
        makeReduxCreateConsultant().create(dataToSend);
      } catch (e) {
        console.error(e);
      }
    }
  };

  function next(data: iRegisterParticipant) {
    setUser({ ...user, ...data });
    handleBar(1);
  }

  function back(data: iRegisterParticipant) {
    setUser({ ...user, ...data });
    handleBar(-1);
  }

  return (
    <Container>
      <Header>
        <StepMagic current={crement}>
          <Step title={translator('Dados Pessoais')} />
          <Step title={translator('Contatos')} />
          <Step title={translator('Endereço')} />
        </StepMagic>
      </Header>

      <Body>
        {crement === 1 && (
          <PersonalData
            state={user}
            next={next}
            cancel="/participant"
            isFound={isFound}
            setIsFound={setIsFound}
            roleToRegister="CON"
          />
        )}
        {crement === 2 && (
          <Contacts
            state={user}
            next={next}
            back={back}
            isFound={isFound}
            cancel="/participant"
          />
        )}
        {crement === 3 && (
          <Address
            state={user}
            next={next}
            back={back}
            isFound={isFound}
            cancel="/participant"
          />
        )}
        {/* {crement === 4 && <Sectors state={user} next={next} back={back} />} */}
        {loading && <V4hSpin fullScreen />}
      </Body>
    </Container>
  );
};

export default RegisterParticipant;
