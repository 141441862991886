import React, { useEffect, useState } from 'react';

import { useLocation } from 'react-router';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { Container, Form } from './styles/StyledGeneralData';

import { Input, Select } from '~/presentation/components/UI';
import InputKeyboardDate from '~/presentation/components/inputKeyboardDate';
import { translator } from '~/presentation/components/i18n';

import { iListConsultant, iCreateMedicalCertificate } from './interface';

import { Navigator } from './Navigator';

import { schemaGeneralData } from '~/validation/validators/document/CreateClinicalDocValidator';

import { iProfessional } from '~/domain/interfaces/models/Professional';

// import { iStateParams } from '~/presentation/components/clinicalDoc/ClinicalDoc';
import { Patient } from '../menu/styles/StyledMenu';

export interface ownProps {
  consultant: iListConsultant[];
  next: (data: iCreateMedicalCertificate) => any;
  professional: iProfessional;
  state?: iCreateMedicalCertificate;
}

interface iStateParams {
  professional: string;
  consultant: string;
}

const GeneralData: React.FC<ownProps> = ({
  next,
  consultant,
  professional,
  state,
}): JSX.Element => {
  const auth = useSelector((store: iStore) => store.auth.info);
  // const [professionalId, setProfId] = useState<number | undefined>(
  //   state?.professional,
  // );
  // const [consultantId, setConId] = useState<number | undefined>(state?.patient);

  const stateParams = useLocation<iStateParams>().state;

  const { errors, handleSubmit, register, setValue, getValues } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    resolver: zodResolver(schemaGeneralData),
    defaultValues: {
      ...state,
    },
  });

  const onSubmit = handleSubmit(data => {
    next({ ...data });
  });

  return (
    <Container>
      <Form onSubmit={onSubmit}>
        <Input
          id="input_instituition"
          label={`${translator('Instituição')}`}
          onChange={e => setValue('org', Number(e.target.value))}
          name="org"
          register={() => register('org')}
          error={Boolean(errors.org)}
          message={errors?.org?.message}
          placeholder={state?.orgUnitName}
          disabled={state && state.orgUnitName !== ''}
          autoFocus
        />

        <Select
          key={state?.professional}
          id="select_professional"
          label={`${translator('Profissional')}`}
          onChange={e => {
            setValue('professional', Number(e.target.value));
          }}
          defaultValue={state?.professional}
          name="professional"
          register={() => register('professional')}
          error={Boolean(errors.professional)}
          message={
            errors?.professional?.message
              ? translator(errors?.professional?.message)
              : ''
          }
          disabled={state && state.professional !== -1}
        >
          <option value="selectPatient">
            {translator('Selecione um profissional')}
          </option>
          {professional.results.map(user => (
            <option
              key={user.professional.id}
              value={Number(user.professional.id)}
            >
              {`${user.user.firstName} ${user.user.lastName}`}
            </option>
          ))}
        </Select>

        {/* <Select
          key={consultantId}
          id="select_patient"
          label={`${translator('Paciente')}*`}
          onChange={e => {
            setValue('patient', Number(e.target.value));
          }}
          defaultValue={consultantId}
          name="patient"
          register={() => register('patient')}
          error={Boolean(errors.patient)}
          message={
            errors?.patient?.message ? translator(errors?.patient?.message) : ''
          }
          disabled={
            stateParams &&
            stateParams?.consultant !== undefined &&
            stateParams?.consultant !== ''
          }
        >
          <option value="selectPatient">
            {translator('Selecione um paciente')}
          </option>
          {consultant.map(consult => (
            <option key={consult.id} value={consult.id}>
              {`${consult.firstName} ${consult.lastName}`}
            </option>
          ))}
        </Select> */}

        <InputKeyboardDate
          state={new Date()}
          setState={() => ''}
          label={`${translator('Data de emissão')}`}
          placeholder="DD/MM/AAAA"
          disabled
        />

        <Navigator />
      </Form>
    </Container>
  );
};

export default GeneralData;
