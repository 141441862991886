import { Dispatch } from 'redux';
import store from '~/data/store';
import { getDutyRequesters } from '~/data/store/reducer/duty/actions/getDutyRequesters';

import { iActionGetDutyRequesters } from '~/domain/interfaces/redux/duty/getDutyRequesters';

import { GetDutyRequesters } from '~/domain/usecases/duty/redux';

export class ReduxGetDutyRequesters implements GetDutyRequesters {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  get = (params: GetDutyRequesters.Params): iActionGetDutyRequesters =>
    this.send(getDutyRequesters(params));
}
