/* eslint-disable react/jsx-curly-brace-presence */
import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { iMessage } from '~/domain/interfaces/models/Message';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import {
  GetCitiesByUf as RemoteGetCitiesByUf,
  GetUfs as RemoteGetUfs,
} from '~/domain/usecases/externalServices/remote';
import { makeRemoteJoinOnCall } from '~/main/factories/usecases/duty/JoinOnCall';
import { makeRemoteGetCitiesByUf } from '~/main/factories/usecases/externalServices/GetCitiesByUf';
import { makeRemoteGetUfs } from '~/main/factories/usecases/externalServices/GetUfs';
import { History } from '~/main/routes';
import { IconCloseButtonModal } from '~/presentation/base/icons';
import { Button, Input, Select } from '~/presentation/components/UI';
import { schemaJoinOnCall } from '~/validation/validators/duty/joinOnCallValidator';
import { AlertMessage } from '../messages/AlertMessage';
import {
  Container,
  ContainerModal,
  ContentForm,
  HeaderModal,
  InlineForms,
  MessageError,
  PriorityButton,
  PriorityForm,
  TextAreaForm,
} from './styles/StyledModalRequestConsultation';

interface propsModalRequestConsultation {
  message: iMessage;
}

export type JoinOnCallBody = {
  name?: string;
  age?: number;
  sex?: 'NONE' | 'M' | 'F';
  situation?: string;
  susCard?: string;
  organization?: string;
  uf?: string;
  city?: string;
  priority?: 'LOW' | 'NORMAL' | 'EMERGENCY';
};

const ModalRequestConsultation: React.FC<propsModalRequestConsultation> = ({
  message,
}) => {
  const [ufs, setUfs] = useState<RemoteGetUfs.Model>([]);
  const [cities, setCities] = useState<RemoteGetCitiesByUf.Model>([]);
  const auth = useSelector((store: iStore) => store.auth.info);
  const { role, orgId, orgUnitId } = useSelector(
    (store: iStore) => store.auth.selectUser,
  );
  const professional = auth.professionals?.find(
    item => item.orgUnit.id === orgUnitId,
  );

  const { active, actionOk, actionCancel, data } = message;

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    reset,
    clearErrors,
    control,
    formState: { errors, isValid },
  } = useForm<JoinOnCallBody>({
    mode: 'onChange',
    shouldFocusError: true,
    resolver: zodResolver(schemaJoinOnCall),
    defaultValues: {
      sex: 'NONE',
      priority: 'LOW',
    },
  });

  const handleCreateJoinOnCall = () => {
    if (professional?.id)
      makeRemoteJoinOnCall()
        .create({
          onCallId: data?.onCallId,
          requesterId: professional?.id,
          ...getValues(),
          sex: getValues().sex === 'NONE' ? undefined : getValues().sex,
        })
        .then(() => {
          AlertMessage({
            message: 'Solicitação enviada com sucesso!',
            type: 'success',
          });
          reset();
        })
        .catch(() => {
          AlertMessage({
            message: 'Falha ao enviar solicitação!',
            type: 'danger',
          });
        });
  };

  const handleCancel = () => {
    clearErrors();
    reset();
    actionCancel?.();
  };

  const onSubmit = () => {
    // handleCreateJoinOnCall();
    History.getHistory().push(`/duty/me`, {
      specialty: data?.specialty,
      id: data?.onCallId,
      formValues: { ...getValues() },
    });
    reset();
    actionOk?.();
  };

  function handleKeyPress(e: any) {
    console.log(e.key);
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  }

  useEffect(() => {
    makeRemoteGetUfs()
      .getUfs({})
      .then(res => {
        setUfs(res);
        setValue('uf', res[0]?.nome);
      })
      .catch(err => {
        console.log('err: ', err);
      });
  }, []);

  const ufSelected = watch('uf');

  useEffect(() => {
    if (ufSelected) {
      makeRemoteGetCitiesByUf()
        .getCitiesByUf({ uf: ufSelected })
        .then(res => {
          setCities(res);
          setValue('city', res[0]?.nome);

          const findCity = res.find(
            city => city.nome === getValues()?.city,
          )?.nome;

          if (findCity) setValue('city', findCity, { shouldValidate: true });
        })
        .catch(console.error);
    }
  }, [getValues, setValue, ufSelected]);

  const msgName = MessageOptions.requestConsultation;
  return (
    <>
      {msgName === active && (
        <ContainerModal>
          <Container>
            <ContentForm onSubmit={handleSubmit(onSubmit)}>
              <HeaderModal>
                <h3>Solicitar interconsulta</h3>
                <IconCloseButtonModal onClick={handleCancel} />
              </HeaderModal>

              <InlineForms>
                <Controller
                  control={control}
                  name="name"
                  render={({ value }) => (
                    <Input
                      label="Nome do paciente"
                      name="name"
                      value={value}
                      width="45%"
                      placeholder="Digite seu nome"
                      onChange={e =>
                        setValue('name', e.target.value, {
                          shouldValidate: true,
                        })
                      }
                      error={Boolean(errors.name)}
                      autoFocus
                      message={errors?.name?.message}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="sex"
                  render={({ value }) => (
                    <Select
                      label="Sexo"
                      value={value}
                      width="35%"
                      onChange={e => {
                        setValue('sex', e.target.value, {
                          shouldValidate: true,
                        });
                      }}
                      error={Boolean(errors.sex)}
                    >
                      <option value="NONE">Escolher sexo</option>
                      <option value="M">Masculino</option>
                      <option value="F">Feminino</option>
                    </Select>
                  )}
                />
                <Controller
                  control={control}
                  name="age"
                  render={({ value }) => (
                    <Input
                      label="Idade"
                      type="number"
                      width="20%"
                      value={value}
                      name="age"
                      onChange={e =>
                        setValue('age', Number(e.target.value), {
                          shouldValidate: true,
                        })
                      }
                      register={() => register('age')}
                      error={Boolean(errors.age)}
                    />
                  )}
                />
                {errors?.age && (
                  <MessageError>{errors?.age?.message}</MessageError>
                )}
              </InlineForms>
              <InlineForms>
                <Controller
                  control={control}
                  name="organization"
                  render={({ value }) => (
                    <Input
                      label="Unidade de Saúde"
                      placeholder="ex.: Hospital Albert Einstein"
                      name="organization"
                      width="45%"
                      value={value}
                      onChange={e =>
                        setValue('organization', e.target.value, {
                          shouldValidate: true,
                        })
                      }
                      error={Boolean(errors.organization)}
                    />
                  )}
                />
                {ufs && ufs.length > 0 && (
                  <Controller
                    control={control}
                    name="uf"
                    render={({ value }) => (
                      <Select
                        name="uf"
                        label="UF"
                        width="25%"
                        value={value}
                        defaultValue=""
                        onChange={e => {
                          if (!e.target.value) {
                            setCities([]);
                            setValue('uf', undefined);
                            setValue('city', undefined);
                            return;
                          }
                          setValue('uf', e.target.value.trim(), {
                            shouldValidate: true,
                          });
                        }}
                        error={Boolean(errors.uf)}
                        placeholder="Estado"
                      >
                        <option value={undefined}>{''}</option>
                        {ufs &&
                          ufs.length > 0 &&
                          ufs.map(uf => (
                            <option key={uf.id} value={uf.sigla}>
                              {uf.sigla}
                            </option>
                          ))}
                      </Select>
                    )}
                  />
                )}
                <Controller
                  control={control}
                  name="city"
                  render={({ value }) => (
                    <Select
                      name="city"
                      label="Cidade"
                      width="30%"
                      value={value}
                      onChange={e =>
                        setValue('city', e.target.value.trim(), {
                          shouldValidate: true,
                        })
                      }
                      error={Boolean(errors.city)}
                      placeholder="Cidade"
                    >
                      <option value={undefined}>{''}</option>
                      {cities &&
                        cities.length > 0 &&
                        cities.map(city => (
                          <option key={city.id} value={city.nome}>
                            {city.nome}
                          </option>
                        ))}
                    </Select>
                  )}
                />
              </InlineForms>
              <TextAreaForm>
                <h3>Quadro clínico</h3>
                <Controller
                  control={control}
                  name="situation"
                  rules={{
                    maxLength: {
                      value: 4096,
                      message:
                        'Você atingiu o limite de 4096 caracteres para este campo.',
                    },
                  }}
                  render={({ value }) => (
                    <>
                      <textarea
                        style={{
                          border: errors.situation && '1px solid red',
                          width: '100%',
                        }}
                        placeholder="Descrição dos sintomas do paciente e justificativa para a interconsulta."
                        name="situation"
                        value={value}
                        onChange={e =>
                          setValue('situation', e.target.value, {
                            shouldValidate: true,
                          })
                        }
                      />
                      {errors?.situation && (
                        <MessageError>
                          {errors?.situation?.message}
                        </MessageError>
                      )}
                    </>
                  )}
                />
              </TextAreaForm>
              <InlineForms>
                <Controller
                  control={control}
                  name="susCard"
                  render={({ value }) => (
                    <InputMask
                      mask="999-9999-9999-9999"
                      onChange={e =>
                        setValue(
                          'susCard',
                          e.target.value.replace(/[^\d]+/g, '').trim(),
                          {
                            shouldValidate: true,
                          },
                        )
                      }
                      value={value}
                      width="42%"
                    >
                      <Input name="susCard" label="Cartão SUS" />
                    </InputMask>
                  )}
                />
                <PriorityForm>
                  <h3>Prioridade</h3>
                  <Controller
                    control={control}
                    name="priority"
                    render={({ value }) => (
                      <div>
                        <PriorityButton
                          type="button"
                          priority="LOW"
                          selected={value === 'LOW'}
                          onClick={() =>
                            setValue('priority', 'LOW', {
                              shouldValidate: true,
                            })
                          }
                        >
                          Baixa
                        </PriorityButton>
                        <PriorityButton
                          type="button"
                          priority="NORMAL"
                          selected={value === 'NORMAL'}
                          onClick={() =>
                            setValue('priority', 'NORMAL', {
                              shouldValidate: true,
                            })
                          }
                        >
                          Média
                        </PriorityButton>
                        <PriorityButton
                          type="button"
                          priority="EMERGENCY"
                          selected={value === 'EMERGENCY'}
                          onClick={() => {
                            console.log('... erros: ', errors);
                            setValue('priority', 'EMERGENCY', {
                              shouldValidate: true,
                            });
                          }}
                        >
                          Alta
                        </PriorityButton>
                      </div>
                    )}
                  />
                </PriorityForm>
              </InlineForms>
              <Button
                type="submit"
                onKeyPress={e => handleKeyPress(e)}
                disabled={!isValid}
              >
                Enviar Solicitação
              </Button>
            </ContentForm>
          </Container>
        </ContainerModal>
      )}
    </>
  );
};

export default ModalRequestConsultation;
