import React from 'react';
import { BrowserRouter, Route, Router, Switch } from 'react-router-dom';
import RoutesTest from '~/examples/Routes';
import { CreateUsersConfirmationFactory } from '~/main/factories/pages/createUsersConfirmation';
import { LoginFactory } from '~/main/factories/pages/login';
import { PasswordFactory } from '~/main/factories/pages/restorePass';
import { SelectSpecialtyFactory } from '~/main/factories/pages/selectSpecialty';
import { ValidateRestorePassFactory } from '~/main/factories/pages/validateRestorePass';
import FreemiumRegister from '~/presentation/pages/FreemiumRegister';
import { PageNotFound, Unauthorized } from '~/presentation/pages/errors/index';
import NewWaitingRoom from '~/presentation/roles/professional/pages/waitingRoom/NewWaitingRoom';
import { History } from '.';
import DutyFactory from '../factories/pages/Duty/DutyFactory';
import DutyRequesterFactory from '../factories/pages/DutyRequester/DutyRequesterFactory';
import { MakeShiftsFactory } from '../factories/pages/Shifts';
import { MakeModalTestFactory } from '../factories/pages/test';
import RoutesAppointment from './RoutesAppointment';
import RoutesClinicalDocs from './RoutesClinicalDocs';
import RoutesConf from './RoutesConf';
import RoutesGuest from './RoutesGuest';
import RoutesUser from './RoutesUser';

const Routes: React.FC = () => {
  return (
    <BrowserRouter>
      <Router history={History.getHistory()}>
        <Switch>
          <Route exact path="/" component={LoginFactory} />

          <Route exact path="/register" component={FreemiumRegister} />
          <Route exact path="/test-room" component={NewWaitingRoom} />

          <Route
            exact
            path="/Error"
            component={() => <div>Erro no login</div>}
          />

          <Route exact path="/duty/" component={SelectSpecialtyFactory} />
          <Route exact path="/duty/me" component={DutyRequesterFactory} />
          <Route exact path="/duty/create" component={SelectSpecialtyFactory} />
          <Route exact path="/duty/:specialty" component={DutyFactory} />

          <Route exact path="/shifts" component={MakeShiftsFactory} />

          {RoutesAppointment}
          {RoutesUser}
          {RoutesGuest}
          {RoutesTest}
          {RoutesClinicalDocs}
          {RoutesConf}
          <Route path="/password/recovery" component={PasswordFactory} />
          <Route
            path="/user/confirmation"
            component={CreateUsersConfirmationFactory}
          />
          <Route
            path="/password/validate"
            component={ValidateRestorePassFactory}
          />
          <Route exact path="/401unauthorized" component={Unauthorized} />
          <Route exact path="/:language" component={LoginFactory} />

          <Route exact path="/modals/:id" component={MakeModalTestFactory} />

          <Route component={PageNotFound} />
        </Switch>
      </Router>
    </BrowserRouter>
  );
};

export default Routes;
