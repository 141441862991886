import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { JoinAppointmentOnCall as UsecaseRemoteJoinAppointmentOnCall } from '~/domain/usecases/onCall/remote';
// import { JoinAppointmentOnCall as UsecaseReduxJoinAppointmentOnCall } from '~/domain/usecases/onCall/redux';

import { RemoteJoinAppointmentOnCall } from '~/data/repository/onCall';
// import { ReduxJoinAppointmentOnCall } from '~/data/store/reducer/onCall/usecases';

/**
 * send request via API.
 */
export const makeRemoteJoinAppointmentOnCall =
  (): UsecaseRemoteJoinAppointmentOnCall =>
    new RemoteJoinAppointmentOnCall(
      makeApiUrl('/oncall/{onCallId}/specialists/{specialistId}/attend/{requesterId}/JOIN'),
      makeHttpClient()
    );

/**
 * send request via REDUX.
 */
/* export const makeReduxJoinAppointmentOnCall =
  (): UsecaseReduxJoinAppointmentOnCall =>
    new ReduxJoinAppointmentOnCall(); */
