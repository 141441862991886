import React, { useMemo } from 'react';
import { translator } from '../i18n';

import { Content, Item, Label, Subtitle } from './styles/StyledNavigationBar';

type Props = {
  active: string | number;
  clickLabel: Function;
  style?: React.CSSProperties & { itemWidth?: string };
};

interface PropsWithoutSubtitles extends Props {
  hasSubtitle?: false;
  labels: string[];
}

interface PropsWithSubtitles extends Props {
  hasSubtitle?: true;
  labels: { label: string; subtitle: string }[];
}

type BarProps = PropsWithoutSubtitles | PropsWithSubtitles;

const NavigationBar: React.FC<BarProps> = ({
  labels,
  active,
  clickLabel,
  style,
  hasSubtitle,
}) => {
  const renderItems = useMemo(() => {
    if (hasSubtitle) {
      return (labels as { label: string; subtitle: string }[]).map(
        ({ label, subtitle }, idx) => (
          <Item
            id={`${label.toLowerCase().replaceAll(' ', '_')}`}
            onClick={() => clickLabel(idx)}
            active={active === idx}
            style={{ flexDirection: 'column' }}
          >
            <Label>{translator(label)}</Label>
            <Subtitle>{subtitle}</Subtitle>
          </Item>
        ),
      );
    }

    return labels.map(label => (
      <Item
        id={`${label.toString().toLowerCase().replaceAll(' ', '_')}`}
        onClick={() => clickLabel(label)}
        active={active === label}
      >
        <Label>{typeof label === 'string' ? translator(label) : label}</Label>
      </Item>
    ));
  }, [active, clickLabel, hasSubtitle, labels]);

  return <Content style={style}>{renderItems}</Content>;
};

export default NavigationBar;
