import React, { useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
// import { v4 as uuid } from './uuid';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import { IconCloseButtonModal } from '~/presentation/base/icons';
import { schemaProfessionalExternal } from '~/validation/validators/appointment/ExternalProfessional';
import { Button, Input } from '../../UI';
import CustomPhoneInput from '../../UI/phone-input'
import { ownProps } from '../interface';
import {
  Body,
  Container,
  Content,
  Footer,
  Header,
} from './styles/StyledInviteExternalProfessionals';
import { translator } from '../../i18n';

interface InviteExternalProfessionalsProps {
  name: string;
  specialty: string;
  email: string;
  id: number;
  type: string;
  phone: string;
}

const InviteExternalProfessionals: React.FC<ownProps> = ({ message }) => {
  const [professionalExternal, setExternalProfessional] =
    useState<InviteExternalProfessionalsProps>({
      email: '',
      name: '',
      phone: '',
      id: 0,
      specialty: '',
      type: 'external',
    });

  const { errors, handleSubmit, register, setValue, getValues, unregister } =
    useForm({
      mode: 'onSubmit',
      shouldFocusError: true,
      resolver: zodResolver(schemaProfessionalExternal),
      defaultValues: {
        email: '',
        name: '',
        phone: '',
        specialty: '',
        type: 'external',
      },
    });

  const submit = handleSubmit((data: InviteExternalProfessionalsProps) => {
    setExternalProfessional({
      email: '',
      name: '',
      phone: '',
      id: 1,
      specialty: '',
      type: 'external',
    });
    setValue('email', '');
    setValue('name', '');
    setValue('specialty', '');
    setValue('phone', '');

    if (
      actionOk &&
      typeof incrementCardId === 'number' &&
      handleIncrementCardId
    ) {
      actionOk();
      handleUpdateValue?.({
        ...data,
        type: 'EXTERNAL',
        cardId: incrementCardId + 1,
      });
      handleZodUpdateValue?.({
        ...data,
        type: 'EXTERNAL',
        cardId: incrementCardId + 1,
      });
      handleIncrementCardId(incrementCardId + 1);
    }
  });

  const msgName = MessageOptions.inviteExternalProfessionals;

  const {
    active,
    actionCancel,
    actionOk,
    handleUpdateValue,
    handleZodUpdateValue,
    handleIncrementCardId,
    incrementCardId,
  } = message;

  return (
    <>
      {msgName === active ? (
        <Container>
          <Content onSubmit={submit}>
            <Header>
              <h2>{translator('profissional externo')}</h2>
              <IconCloseButtonModal
                onClick={actionCancel}
                style={{ cursor: 'pointer' }}
              />
            </Header>
            <Body>
              <Input
                label={translator('Nome do Profissional')}
                placeholder={translator('Digite o nome do profissional')}
                name="name"
                onChange={e => {
                  setValue('name', e.target.value);
                  setExternalProfessional({
                    ...professionalExternal,
                    [e.target.name]: e.target.value,
                  });
                }}
                register={() => register('name')}
                value={professionalExternal.name}
                error={Boolean(errors.name)}
                message={errors.name?.message || ''}
                required
              />
              <Input
                label={translator('Especialidade')}
                name="specialty"
                placeholder={translator('Digite sua especialidade')}
                onChange={e => {
                  setValue('specialty', e.target.value);
                  setExternalProfessional({
                    ...professionalExternal,
                    [e.target.name]: e.target.value,
                  });
                }}
                register={() => register('specialty')}
                value={professionalExternal.specialty}
                error={Boolean(errors.specialty)}
                message={errors.specialty?.message || ''}
                required
              />
              <Input
                label={translator('E-mail')}
                name="email"
                placeholder={translator('Digite o e-mail do profissional')}
                onChange={e => {
                  setValue('email', e.target.value);
                  setExternalProfessional({
                    ...professionalExternal,
                    [e.target.name]: e.target.value,
                  });
                }}
                register={() => register('email')}
                value={professionalExternal.email}
                error={Boolean(errors.email)}
                message={errors.email?.message || ''}
                required
              />
              <InputMask
                mask=""
                name="phone"
                ref={() => register('phone')}
                value={professionalExternal.phone}
                onChange={e => {
                  const phone = e.target.value.replace(/[^\d]+/g, '').trim();
                  setValue('phone', phone);
                  setExternalProfessional({
                    ...professionalExternal,
                    [e.target.name]: phone,
                  });
                }}
              >
                {/* <Input
                  id="registerPhone"
                  name="phone"
                  placeholder=""
                  label={translator('WhatsApp (opcional)')}
                  error={Boolean(errors.phone)}
                  message={errors.phone?.message || ''}
                /> */}
                <div>
                  <CustomPhoneInput
                    name="phone"
                    country="br"
                    preferredCountries={['br', 'mx']}
                    label={translator('WhatsApp (opcional)')}
                    error={Boolean(errors.phone)}
                    message={errors.phone?.message || ''}
                    register={() => register('phone')}
                    value={professionalExternal.phone}
                  />
                </div>
              </InputMask>
              <Footer>
                <Button
                  id="btn_addExternalProfessional"
                  variant="primary"
                  height="48px"
                  type="submit"
                  rounded
                >
                  {translator('Adicionar')}
                </Button>
                <Button
                  id="button_cancel"
                  variant="secundary"
                  height="48px"
                  onClick={actionCancel}
                  color="#ec5c52"
                  rounded
                >
                  {translator('Cancelar')}
                </Button>
              </Footer>
            </Body>
          </Content>
        </Container>
      ) : null}
    </>
  );
};

export default InviteExternalProfessionals;
