import { iOnCall } from '~/domain/interfaces/models/OnCall';
import { OnCallTypes } from '~/domain/interfaces/redux/onCall/types';
import { OnCallActions } from './actions';

export const initialState: iOnCall = {
  available: false,
};

const reducer = (state = initialState, action: OnCallActions): iOnCall => {
  switch (action.type) {
    case OnCallTypes.SETSPECIALISTSTATUS:
      return action.payload;
    default:
      return state;
  }
};

export default reducer;
