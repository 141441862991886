import { takeLatest } from 'redux-saga/effects';
import { onCreate, onCreateSuccess, onCreateFailed } from './Create';
import { onGetAllFailed, onGetAllSuccess, onGetAll } from './getAll';
import {
  onGetAllByCodeFailed,
  onGetAllByCodeSuccess,
  onGetAllByCode,
} from './getAllByCode';
import { SupportTypes } from '~/domain/interfaces/redux/supportDoc/types';

const tasks = [
  takeLatest(SupportTypes.CREATE, onCreate),
  takeLatest(SupportTypes.CREATE_SUCCESS, onCreateSuccess),
  takeLatest(SupportTypes.CREATE_FAILED, onCreateFailed),

  takeLatest(SupportTypes.GETALL, onGetAll),
  takeLatest(SupportTypes.GETALL_SUCCESS, onGetAllSuccess),
  takeLatest(SupportTypes.GETALL_FAILED, onGetAllFailed),

  takeLatest(SupportTypes.GETALL_BY_CODE, onGetAllByCode),
  takeLatest(SupportTypes.GETALL_BY_CODE_SUCCESS, onGetAllByCodeSuccess),
  takeLatest(SupportTypes.GETALL_BY_CODE_FAILED, onGetAllByCodeFailed),
];

export default tasks;
