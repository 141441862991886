import styled from 'styled-components';

export const Container = styled.form`
  width: 530px;
  /* height: 568px;   */

  border-radius: 16px;
  border: 1px #dedede solid;
  background-color: white;
`;

export const ModalContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 10;
  background: rgba(0, 0, 0, 0.5);
`;

export const Required = styled.div`
  color: #dd0404;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 400;
  word-wrap: break-word;
`;

export const BoxIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 32px;
  height: 32px;

  border-radius: 5px;
  border: 1px #dedede solid;

  cursor: pointer;
`;

export const Label = styled.div`
  color: #222529;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 500;
  word-wrap: break-word;
`;

export const Space = styled.div``;

export const Box = styled.div`
  padding: 16px 0 16px 24px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  padding: 24px 0 24px 24px;
`;

export const Title = styled.div`
  color: #222529;
  font-size: 18px;
  font-family: Roboto;
  font-weight: 600;
  word-wrap: break-word;
`;

export const SubTitle = styled.div`
  color: #62666c;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 400;
  word-wrap: break-word;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;

  background-color: #dedede;
`;

export const InformationTitle = styled.div`
  color: #636d77;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 400;
  word-wrap: break-word;
`;

export const InputContent = styled.div`
  width: 100%;

  display: flex;

  gap: 16px;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const CheckboxText = styled.div`
  color: #222529;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 400;
  word-wrap: break-word;

  margin-left: 8px;
`;
