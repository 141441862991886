import React, { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import InputMask from 'react-input-mask';

import Input from '../UI/input';
import CustomPhoneInput from '../UI/phone-input';

import { translator } from '../i18n';

import { Container, Form } from './style/StyledContacts';
import { iRegisterParticipant } from './interface';
import { schemaContact } from '~/validation/validators/user/CreateUserValidator';

import { Navigator } from './Navigator';
import { getEmailSuccess } from '~/data/store/reducer/users/actions/getEmail';
import { makeReduxGetAllUsers } from '~/main/factories/usecases/users/GetEmailFactory';

interface ownProps {
  next: (data: iRegisterParticipant) => any;
  back: (data: iRegisterParticipant) => any;
  isFound?: boolean;
  state?: iRegisterParticipant;
  cancel?: string;
  setUser?: (user: iRegisterParticipant) => void;
}

const Contacts: React.FC<ownProps> = ({
  back,
  next,
  state,
  cancel,
  isFound = false,
  setUser,
}): JSX.Element => {
  const { errors, handleSubmit, register, setValue, getValues } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    resolver: zodResolver(schemaContact),
    defaultValues: {
      ...state,
    },
  });

  const onSubmit = handleSubmit(data => {
    next({ ...data });
    makeReduxGetAllUsers().get({ email: data.email });
  });

  const onBack = () => back({ ...getValues() });

  return (
    <Container>
      {console.log('values: ', getValues())}
      <Form onSubmit={onSubmit}>
        <Input
          id="registerEmail"
          disabled={isFound}
          name="email"
          placeholder="usuario@email.com"
          label="E-mail"
          defaultValue={state?.email}
          onChange={e => {
            getEmailSuccess({ email: e.target.value });
            setValue('email', e.target.value.trim());
          }}
          register={() => register('email')}
          error={Boolean(errors.email)}
          message={
            errors?.email?.message ? translator(errors?.email?.message) : ''
          }
          autoFocus
          required
        />
        <InputMask
          mask=""
          name="phone"
          defaultValue={state?.phone}
          ref={() => register('phone')}
          onChange={e => {
            const phone = e.target.value.replace(/[^\d]+/g, '').trim();
            setValue('phone', phone);
          }}
          disabled={isFound}
          required
        >
          <div style={{height: '48px'}}>
            <CustomPhoneInput
              name="phone"
              country="mx"
              preferredCountries={['br', 'mx']}
              label={translator('Telefone celular')}
              error={Boolean(errors.phone)}
              message={
                errors?.phone?.message ? translator(errors?.phone?.message) : ''
              }
              register={() => register('phone')}
              value={state?.phone}
              // onChange={e => {
              //   const phone = e.replace(/[^\d]+/g, '').trim();
              //   setValue('phone', phone);
              // }}
            />
          </div>
        </InputMask>

        <InputMask
          mask=""
          name="phone2"
          defaultValue={state?.phone2}
          ref={() => register('phone2')}
          onChange={e => {
            const phone2 = e.target.value.replace(/[^\d]+/g, '').trim();
            setValue('phone2', phone2);
          }}
          disabled={isFound}
        >
          <div style={{height: '48px'}}>
            <CustomPhoneInput
              name="phone2"
              country="mx"
              value={state?.phone2}
              register={() => register('phone2')}
              preferredCountries={['br', 'mx']}
              label={translator('Telefone alternativo')}
              error={Boolean(errors.phone)}
              message={errors?.phone2?.message}
              // onChange={e => {
              //   const phone2 = e.replace(/[^\d]+/g, '').trim();
              //   setValue('phone', phone2);
              // }}
            />
          </div>
        </InputMask>
        <Navigator back={onBack} cancel={cancel} />
      </Form>
    </Container>
  );
};

export default Contacts;
