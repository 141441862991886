import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { EditIcon } from '~/presentation/base/icons';
import {
  Container,
  ContentHeader,
  HeaderTitle,
  Body,
  Header,
  Card,
  CardTitle,
  Data,
  NotesDiv,
  NotesCard,
  InterconsultTextArea,
} from './styles/StyledInterconsultData';
import { GetAppointmentById } from '~/domain/usecases/appointment/remote';
import { makeReduxUpdateAppointment } from '~/main/factories/usecases/appointment/UpdateAppointmentFactory';
import Input from '../../UI/input';
import { translator } from '../../i18n';
import { Button } from '../../UI';
import { HeaderCloseIcon } from '../styles/sidebarStyles';
import { makeReduxGetAllConsultant } from '~/main/factories/usecases/consultant/GetAllConsultant';
import { iStore } from '~/domain/interfaces/models';
import { makeRemoteUpdateInstantAppointment } from '~/main/factories/usecases/appointment/UpdateInstantAppointmentFactory';

interface iStoreParams {
  appointmentId: string;
}

interface participantsParams {
  fullNameConsultant?: string;
  fullNameProfessional?: string;
  dnv?: string;
  unit?: string;
  regValue?: string;
  professionalRegValue?: string;
  regType?: string;
  clinicalCondition?: string;
  forwarding?: string;
}

export interface ownProps {
  handleSavingLoading?: (loading: boolean) => void;
  handleClose: () => void;
  appointment: GetAppointmentById.Model;
}

const InterconsultData: React.FC<ownProps> = props => {
  const appointmentData = props.appointment;

  const { access } = useSelector((store: iStore) => store.auth);

  const [isEditingConsultant, setIsEditingConsultant] =
    useState<boolean>(false);
  const [isEditingProfessional, setIsEditingProfessional] =
    useState<boolean>(false);

  const [data, setData] = useState<participantsParams>({
    clinicalCondition: appointmentData?.appointment?.clinicalCondition,
    fullNameConsultant: appointmentData?.appointment?.info?.name,
    fullNameProfessional: appointmentData?.otherProfessionals?.[0]?.name,
    forwarding: appointmentData?.appointment?.forwarding,
    regType: appointmentData?.appointment?.info?.regType,
    regValue: appointmentData?.appointment?.info?.regValue,
    professionalRegValue: appointmentData?.otherProfessionals?.[0]?.regValue,
    unit: appointmentData?.otherProfessionals?.[0]?.unit,
    dnv: appointmentData?.appointment?.info?.susCard,
  });

  const appointmentId = appointmentData?.appointment?.id;
  const professionalId = appointmentData.otherProfessionals?.[0]?.id;

  const isConnected = useMemo(() => {
    return !!access.token?.length;
  }, [access]);

  const canEditProfessional = useMemo(
    () => !appointmentData?.otherProfessionals?.[0]?.professional,
    [appointmentData?.otherProfessionals],
  );

  const handleSubmit = () => {
    if (isConnected)
      makeReduxUpdateAppointment().update({
        appointmentId: Number(appointmentId),
        info: {
          name: data?.fullNameConsultant,
          regValue: data?.regValue,
          regType: data?.regType,
        },
        professionals: [
          {
            id: Number(professionalId),
            name: data?.fullNameProfessional,
            unit: data?.unit,
            regValue: data?.regValue,
          },
        ],
        clinicalCondition: data?.clinicalCondition ?? '',
        forwarding: data?.forwarding ?? '',
      });

    if (!isConnected)
      makeRemoteUpdateInstantAppointment().updateInstantAppointment({
        appointmentId: Number(appointmentId),
        otherProfessionalId: Number(professionalId),
        name: data?.fullNameProfessional,
        unit: data?.unit,
        regValue: data?.professionalRegValue,
        appointment: {
          clinicalCondition: data?.clinicalCondition,
          forwarding: data?.forwarding,
        },
        externalConsultant: {
          name: data?.fullNameConsultant,
          regValue: data?.regValue,
          regType: data?.regType,
        },
      });

    setIsEditingConsultant(false);
    setIsEditingProfessional(false);
  };

  const handleSubmitOnEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') handleSubmit();
  };

  useEffect(() => {
    makeReduxGetAllConsultant().getAll({});
  }, []);

  return (
    <Container>
      <ContentHeader>
        <HeaderTitle>Informações</HeaderTitle>
        <HeaderCloseIcon onClick={props.handleClose} />
      </ContentHeader>
      <Body>
        <Card>
          <CardTitle>
            {translator('Dados do paciente')}

            <div>
              <EditIcon
                id="edit_specialty"
                style={{ cursor: 'pointer' }}
                onClick={() => setIsEditingConsultant(prev => !prev)}
              />
            </div>
          </CardTitle>
          {isEditingConsultant ? (
            <div className="gap-div">
              <Input
                id="input_interconsultData"
                name="consultantName"
                label="Nome do paciente:"
                placeholder="Insira o nome do paciente"
                value={data.fullNameConsultant}
                onChange={e => {
                  setData({ ...data, fullNameConsultant: e.target.value });
                }}
                onKeyPressCapture={handleSubmitOnEnter}
              />

              <Input
                id="input_interconsultData"
                name="consultantDNV"
                label={`${appointmentData?.appointment?.info?.regType}`}
                placeholder={`Insira o ${appointmentData?.appointment?.info?.regType} do paciente:`}
                value={data.regValue}
                onChange={e => {
                  setData({ ...data, regValue: e.target.value });
                }}
                onKeyPressCapture={handleSubmitOnEnter}
              />
            </div>
          ) : (
            <div className="gap-div">
              <Data>
                <span>Nome: </span>
                <span>{data.fullNameConsultant}</span>
              </Data>
              <Data>
                <span>{`${data.regType}:`}</span>
                <span>{data.regValue}</span>
              </Data>
            </div>
          )}
        </Card>
        <Card>
          <CardTitle>
            {translator('Dados do profissional')}

            {canEditProfessional && (
              <div>
                <EditIcon
                  id="edit_specialty"
                  style={{ cursor: 'pointer' }}
                  onClick={() => setIsEditingProfessional(prev => !prev)}
                />
              </div>
            )}
          </CardTitle>
          {isEditingProfessional ? (
            <div className="gap-div">
              <Input
                id="input_interconsultData"
                name="consultantName"
                label="Nome do profissional:"
                placeholder="Insira o nome do profissional"
                value={data.fullNameProfessional}
                onChange={e => {
                  setData({ ...data, fullNameProfessional: e.target.value });
                }}
                onKeyPressCapture={handleSubmitOnEnter}
              />

              <Input
                id="input_interconsultData"
                name="professionalUnit"
                label="Unidade:"
                placeholder="Insira a Unidade do profissional"
                value={data.unit}
                onChange={e => {
                  setData({ ...data, unit: e.target.value });
                }}
                onKeyPressCapture={handleSubmitOnEnter}
              />

              <Input
                id="input_interconsultData"
                name="professionalDocument"
                label="Registro:"
                placeholder="Insira o Registro do profissional"
                value={data.professionalRegValue}
                onChange={e => {
                  setData({ ...data, professionalRegValue: e.target.value });
                }}
                onKeyPressCapture={handleSubmitOnEnter}
              />
            </div>
          ) : (
            <div className="gap-div">
              <Data>
                <span>Nome: </span>
                <span>{data.fullNameProfessional}</span>
              </Data>
              <Data>
                <span>Unidade:</span>
                <span>{data?.unit ?? '-'}</span>
              </Data>
              <Data>
                <span>Registro: </span>
                <span>{data?.professionalRegValue ?? '-'}</span>
              </Data>
            </div>
          )}
        </Card>

        <NotesDiv>
          <Header>Quadro clínico</Header>
          <NotesCard>
            <InterconsultTextArea
              value={data?.clinicalCondition}
              onChange={e =>
                setData({ ...data, clinicalCondition: e.target.value })
              }
            />
          </NotesCard>
          <Header>Encaminhamento do atendimento</Header>
          <NotesCard>
            <InterconsultTextArea
              value={data?.forwarding}
              onChange={e => setData({ ...data, forwarding: e.target.value })}
            />
          </NotesCard>
        </NotesDiv>
        <div className="send-data">
          <Button type="submit" variant="primary" onClick={handleSubmit}>
            Enviar
          </Button>
        </div>
      </Body>
    </Container>
  );
};

export default InterconsultData;
