import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { createIntl, createIntlCache } from 'react-intl';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';
import { CreateUser as RemoteCreateUser } from '~/domain/usecases/users/remote';
import { makeReduxCreateUser } from '~/main/factories/usecases/users/CreateUserFactory';
import { iStore } from '~/domain/interfaces/models';
import LogoHeader from '~/presentation/base/icons/v4hAtendimento.svg';
import { translator } from '~/presentation/components/i18n';
import {
  Address,
  Contacts,
  iRegisterParticipant,
  PasswordRegister,
  PersonalData,
} from '~/presentation/components/register';
import { Step, StepMagic } from '~/presentation/components/steps';
import { Button } from '~/presentation/components/UI';
import { V4hSpin } from '~/presentation/components/spin';
import { schema } from '~/validation/validators/user/CreateUserValidator';
import { Body, Container, Footer, Header } from './styles';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import { makeReduxCreateConsultant } from '~/main/factories/usecases/consultant/CreateConsultantFactory';
import { makeReduxCreateOrganizer } from '~/main/factories/usecases/organizer/CreateOrganizerFactory';
import { makeReduxCreateProfessional } from '~/main/factories/usecases/professional/CreateProfessionalFactory';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

const RegisterUser: React.FC = (props): JSX.Element => {
  const [password, setPassword] = useState<string>('');
  const [crement, setCrement] = useState<number>(1);
  const [passconfirm, setPassConfirm] = useState<string>('');
  const [submit, setSubmit] = useState<boolean>(false);
  const { loading } = useSelector((store: iStore) => store.users);
  const [user, setUser] = useState<iRegisterParticipant>({
    login: '',
    fullName: '',
    email: '',
    password: '',
    phone: '',
    phone2: '',
    phone3: '',
    cpf: '',
    documents: [],
    avatar: '',
    motherName: '',
    birthdate: '',
    gender: 'MALE',
    address: undefined,
    mainRole: 'CON',
  });

  // makeReduxCreateConsultant
  // makeReduxCreateOrganizer
  // makeReduxCreateProfessional

  const FINAL_STEP = 4;

  function handleBar(number: number) {
    if (crement + number > FINAL_STEP) {
      setSubmit(true);
      return;
    }
    setSubmit(false);
    setCrement(crement + number);
  }

  const createUser = () => {
    if (password !== '' && passconfirm !== '' && password === passconfirm) {
      const birthDateBack = user!.birthdate!.split('/');

      const dataToSend: RemoteCreateUser.Params = {
        // login: user.fullName!.split(' ').join(''),
        firstName: user.fullName!.split(' ')[0],
        lastName:
          user.fullName!.split(' ')[user.fullName!.split(' ').length - 1],
        email: user.email!,
        password: password!,
        phone: user.phone!,
        phone2: user.phone2,
        phone3: user.phone3,
        documents: user.documents,
        avatar: user.avatar,
        motherName: user.motherName?.length ? user.motherName : undefined,
        birthdate: `${birthDateBack[2]}-${birthDateBack[1]}-${birthDateBack[0]}`,
        gender: user.gender!,
        address: user.address
          ? {
              city: user.address!.city!,
              complement: user.address!.complement!,
              country: 'BRA',
              neighborhood: user.address!.neighborhood!,
              number: user.address!.number!,
              street: user.address!.street!,
              uf: user.address!.uf!,
              zipcode: user.address!.zipcode!,
            }
          : undefined,
        mainRole: 'CON',
      };

      try {
        const validate = schema.parse(dataToSend);
        console.log('validate user: ', validate);
        makeReduxCreateUser().create(dataToSend);
      } catch (e) {
        console.error(e);
      }
    } else {
      AlertMessage({
        type: 'danger',
        message: intl.formatMessage({ id: 'Preencha os campos corretamente.' }),
      });
    }
  };

  function next(data: iRegisterParticipant) {
    setUser({ ...user, ...data });
    handleBar(1);
  }

  function back(data: iRegisterParticipant) {
    setUser({ ...user, ...data });
    handleBar(-1);
  }

  return (
    <Container>
      <img
        src={LogoHeader}
        alt="Logo V4H atendimento"
        width="200px"
        height="90px"
      />
      <h2>{translator('Cadastro')}</h2>
      <Header>
        <StepMagic current={crement}>
          <Step title={translator('Dados Pessoais')} />
          <Step title={translator('Contatos')} />
          <Step title={translator('Endereço')} />
          <Step title={translator('Senha')} />
        </StepMagic>
      </Header>
      <Body>
        {crement === 1 && (
          <PersonalData state={user} next={next} roleToRegister="CON" />
        )}
        {crement === 2 && <Contacts state={user} next={next} back={back} />}
        {crement === 3 && <Address state={user} next={next} back={back} />}
        {crement === 4 && (
          <PasswordRegister
            change={next}
            password={password}
            setPassword={setPassword}
            setPassConfirm={setPassConfirm}
            passConfirm={passconfirm}
          />
        )}
      </Body>

      {crement === 4 && (
        <Footer>
          <Button
            id="registerForward"
            size="small"
            className="btnAction"
            variant="secundary"
            rounded
            onClick={() => {
              handleBar(-1);
            }}
          >
            {translator('Anterior')}
          </Button>
          <Button
            id="registerBack"
            size="small"
            className="btnAction"
            rounded
            disabled={loading}
            onClick={createUser}
          >
            {loading ? 'Salvando...' : 'Concluir'}
          </Button>
        </Footer>
      )}
      {/* {loading && <V4hSpin fullScreen />} */}
    </Container>
  );
};

export default RegisterUser;
