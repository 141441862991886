import { iAppointment } from '~/domain/interfaces/models';
import { AppointmentFiltered } from '~/domain/usecases/appointment/redux/AppointmentFiltered';

export const AppointmentsFilteredToResultsDTO = (
  rawData: AppointmentFiltered.Model,
): iAppointment['results'] => {
  const formattedData = rawData.records?.map(item => {
    const formattedItem: iAppointment['results'][0] = {
      appointment: {
        allowInvite: item?.appointment?.allowInvite,
        allowTranscription: item?.appointment?.allowTranscription,
        canceledAt: item?.appointment?.canceledAt,
        duration: item?.appointment?.duration,
        durationReal: item?.appointment?.durationReal,
        id: item?.appointment?.id,
        modality: item?.appointment?.modality,
        note: item?.appointment?.note ?? '',
        obs: item?.appointment?.obs,
        org: item?.appointment?.org?.id,
        orgUnit: item?.appointment?.orgUnit,
        priority: item?.appointment?.priority,
        scheduled: item?.appointment?.scheduled,
        started: item?.appointment?.started,
        status: item?.appointment
          .status as iAppointment['results'][0]['appointment']['status'],
        type: item?.appointment?.type as any,
        clinicalDocCount: item?.clinicalDocCount,
        companionCount: item?.companionCount,
        enabled: item?.appointment?.enabled,
        guestLink: item?.appointment?.guestLink,
        otherProfessionalsCount: item?.otherProfCount,
        supportDocCount: item?.supportDocCount,
        title: item?.appointment?.title,
      },
      priority: {
        descr: item?.priority?.descr,
        id: item?.priority?.id,
        msgKey: item?.priority?.msgKey,
        client: item?.priority?.client,
      },
      specialty: {
        id: item?.professional?.specialty?.id,
        name: item?.professional?.specialty?.name,
      },
      consultant: {
        avatar: item?.consultant?.avatar ?? '',
        birthDate: item?.consultant?.birthDate,
        email: item?.consultant?.email,
        firstName: item?.consultant?.firstName,
        id: item?.consultant?.id,
        lastAppointment: item?.consultant?.lastAppointment,
        lastName: item?.consultant?.lastName,
        phone: item?.consultant?.phone,
        status: item?.consultant?.status as any,
        user: item?.consultant?.user,
        access: item?.consultant?.access,
        joined: item?.consultant?.joined,
      },
      professional: {
        avatar: item?.professional?.avatar ?? '',
        docProf: item?.professional?.docProf,
        docProfIssued: item?.professional?.docProfIssued,
        docProfUF: item?.professional?.docProfUF,
        firstName: item?.professional?.firstName,
        id: item?.professional?.id,
        lastName: item?.professional?.lastName,
        status: item?.professional?.status as any,
        user: item?.professional?.user,
      },
      healthPlan: {
        id: item?.healthPlan?.id,
        name: item?.healthPlan?.name,
      },
      index: {
        index: rawData.metadata.offset,
        page: rawData.metadata.total,
      },
    };

    return formattedItem;
  });

  return formattedData || [];
};
