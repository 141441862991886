/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { StepMagic, Step } from '~/presentation/components/steps';

import { translator } from '~/presentation/components/i18n';

import { PersonalData, Address, Contacts } from '../register';
import Sectors from './Sectors';

import { Container, Header, Body } from './style/StyledRegisterUser';

import { CreateOrganizer as ReduxCreateOrganizer } from '~/domain/usecases/organizer/redux';
import { makeReduxCreateOrganizer } from '~/main/factories/usecases/organizer/CreateOrganizerFactory';
import { V4hSpin } from '~/presentation/components/spin';
import { iStore } from '~/domain/interfaces/models';

import { schema } from '~/validation/validators/user/CreateUserValidator';

import { iRegisterManager } from './interface';
import { makeRemoteGetAllUsers } from '~/main/factories/usecases/users/GetAllUsersFactory';

const RegisterManager: React.FC = props => {
  const selectUser = useSelector((store: iStore) => store.auth.selectUser);

  const org = {
    org: selectUser?.orgId || 0,
    orgUnit: selectUser?.orgUnitId || 0,
  };

  const [crement, setCrement] = useState<number>(1);
  const [submit, setSubmit] = useState<boolean>(false);
  const [isFound, setIsFound] = useState<boolean>(false);
  const [user, setUser] = useState<iRegisterManager>({
    fullName: '',
    email: '',
    login: '',
    cpf: '',
    documents: [],
    phone: '',
    phone2: '',
    phone3: '',
    birthdate: '',
    avatar: '',
    motherName: '',
    address: undefined,
    gender: 'MALE',
    mainRole: 'ORG',
    organizer: { org: org.org, orgUnit: org.orgUnit },
  });

  useEffect(() => {
    if (submit) createUser();
  }, [submit]);

  const FINAL_STEP = selectUser.role === 'ADM' ? 4 : 3;

  function handleBar(number: number) {
    if (crement + number > FINAL_STEP) {
      setSubmit(true);
      return;
    }

    setSubmit(false);
    setCrement(crement + number);
  }

  const loading = useSelector((store: iStore) => store.users.loading);

  const createUser = async () => {
    const birthDateBack = user!.birthdate!.split('/');

    const splitName = user.fullName!.split(' ');
    const firstName = splitName?.splice(0, 1).join('');
    const lastName = splitName?.join(' ');

    const now = new Date();

    if (user?.sectors?.length && user?.id) {
      const userRegistered = await makeRemoteGetAllUsers().getAll({
        filter: { cpf: user?.cpf },
      });

      try {
        user.sectors.forEach((sector, index) => {
          if (
            userRegistered.results.length &&
            !userRegistered.results[0].organizers.find(
              item => item.orgUnit.id === sector.id,
            )
          ) {
            makeReduxCreateOrganizer().create({
              user: user?.id,
              org: selectUser?.orgId || 0,
              orgUnit: sector.id,
            });
          }
        });
      } catch (e) {
        console.error(e);
      }
    } else {
      const dataToSend: ReduxCreateOrganizer.Params = {
        // login: user.fullName!.split(' ').join(''),
        user: isFound ? user.id : undefined,
        org: org.org,
        orgUnit: user?.sectors?.[0]?.id || org.orgUnit,
        profile: isFound
          ? undefined
          : {
              firstName,
              lastName,
              email: user.email!,
              phone: user.phone!,
              phone2: user.phone2,
              phone3: user.phone3,
              avatar: user.avatar,
              motherName: user.motherName?.length ? user.motherName : undefined,
              gender: user.gender!,
              birthdate: `${birthDateBack[2]}-${birthDateBack[1]}-${birthDateBack[0]}`,
            },
        documents: isFound
          ? undefined
          : [
              {
                documentId: Number(user.documentId),
                value: user.documents?.[0]?.value ?? String(user.cpf),
                expires: new Date(
                  now.getFullYear() + 10,
                  now.getMonth(),
                  now.getDate(),
                ),
                issued: now,
                issuer: `${firstName} ${lastName}`,
              },
            ],
        // eslint-disable-next-line no-nested-ternary
        address: isFound
          ? undefined
          : user.address
          ? {
              zipcode: user.address!.zipcode!,
              uf: user.address!.uf!,
              city: user.address!.city!,
              country: 'BRA',
              neighborhood: user.address!.neighborhood!,
              street: user.address!.street!,
              number: user.address!.number!,
              complement: user.address!.complement!,
            }
          : undefined,
      };

      try {
        const validate = schema.parse(dataToSend);
        console.log('validate: ', validate);
        makeReduxCreateOrganizer().create(dataToSend);
      } catch (e) {
        console.error(e);
      }
    }
  };

  function next(data: iRegisterManager) {
    setUser({ ...user, ...data });
    handleBar(1);
  }

  function back(data: iRegisterManager) {
    setUser({ ...user, ...data });
    handleBar(-1);
  }

  return (
    <Container>
      <Header>
        {selectUser.role === 'ADM' ? (
          <StepMagic current={crement}>
            <Step title={translator('Dados Pessoais')} />
            <Step title={translator('Contatos')} />
            <Step title={translator('Endereço')} />
            <Step title={translator('Setores')} />
          </StepMagic>
        ) : (
          <StepMagic current={crement}>
            <Step title={translator('Dados Pessoais')} />
            <Step title={translator('Contatos')} />
            <Step title={translator('Endereço')} />
          </StepMagic>
        )}
      </Header>

      <Body>
        {crement === 1 && (
          <PersonalData
            state={user}
            next={next}
            isFound={isFound}
            setIsFound={setIsFound}
            roleToRegister="ORG"
          />
        )}
        {crement === 2 && (
          <Contacts state={user} next={next} back={back} isFound={isFound} />
        )}
        {crement === 3 && (
          <Address state={user} next={next} back={back} isFound={isFound} />
        )}
        {crement === 4 && <Sectors state={user} next={next} back={back} />}
        {loading && <V4hSpin fullScreen />}
      </Body>
    </Container>
  );
};

export default RegisterManager;
