import React from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';
import { useLocation } from 'react-router';
import { schemaPatientData } from '~/validation/validators/document/CreateClinicalDocValidator';
import { Input, Select } from '../UI';
import { translator } from '../i18n';
import { Navigator } from '../medicalCertificate/Navigator';
import { iCreateMedicalCertificate } from '../medicalCertificate/interface';
import { iListConsultant } from '../simplePrescription/interface';
import { Container, Form, Grid } from './styles';

interface iStateParams {
  appointmentId: string;
  professionalId: number;
  consultantId: number;
}

export interface ownProps {
  next: (data: iCreateMedicalCertificate) => void;
  back: (data: iCreateMedicalCertificate) => void;
  state?: iCreateMedicalCertificate;
  consultant: iListConsultant[];
}

const PatientData: React.FC<ownProps> = ({ next, back, state, consultant }) => {
  const stateParams = useLocation<iStateParams>().state;

  const {
    handleSubmit,
    register,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    resolver: zodResolver(schemaPatientData),
    defaultValues: {
      ...state,
      situation:
        state?.situation !== undefined ? state?.situation : 'REGISTERED',
      patient: {
        fullname: state?.patient?.fullname ?? '',
        id: state?.patient?.id ?? -1,
        gender: state?.patient?.gender !== '' ? state?.patient?.gender : 'MALE',
        age: state?.patient?.age ?? -1,
      },
    },
  });

  const onBack = () => back({ ...state });

  const onSubmit = handleSubmit(data => {
    if (data.birthDate) {
      const age = calculateAge(data.birthDate);
      next({ ...data, patient: { ...data.patient, age: Number(age) } });
    } else {
      next({ ...data });
    }
  });

  function calculateAge(birthdate: string): number | null {
    const today = new Date();
    const birthdateParts = birthdate.split('/');

    // Ensure the birthdate is in the correct format
    if (birthdateParts.length !== 3) {
      return null;
    }

    // Extract day, month, and year from the birthdate
    const birthDay = parseInt(birthdateParts[0], 10);
    const birthMonth = parseInt(birthdateParts[1], 10);
    const birthYear = parseInt(birthdateParts[2], 10);

    // Calculate the age
    let age = today.getFullYear() - birthYear;

    // Check if the birthday hasn't occurred yet this year
    const todayMonth = today.getMonth() + 1;
    const todayDay = today.getDate();

    if (
      todayMonth < birthMonth ||
      (todayMonth === birthMonth && todayDay < birthDay)
    ) {
      age -= 1;
    }

    return age;
  }

  const onChangeDate = (value: string) => {
    const date = value.trim();

    setValue('birthDate', date);
  };

  return (
    <Container>
      <Form onSubmit={onSubmit}>
        <Grid>
          <Select
            id="select_professional"
            name="situation"
            label="Situação cadastral"
            required
            defaultValue={state?.situation}
            error={Boolean(errors.situation)}
            register={() => register('situation')}
            message={
              errors.situation?.message
                ? translator(errors.situation?.message)
                : ''
            }
            onChange={e => {
              setValue(
                'situation',
                e.target.value as 'REGISTERED' | 'NOT-REGISTERED',
              );

              if (e.target.value === 'REGISTERED') {
                setValue('patient.id', e.target.value);
              } else {
                setValue('patient.id', undefined);
              }
            }}
          >
            <option value="REGISTERED">Paciente cadastrado</option>
            <option value="NOT-REGISTERED">Paciente não cadastrado</option>
          </Select>
          {watch('situation') === 'NOT-REGISTERED' ? (
            <Input
              id="input_instituition"
              label="Nome do paciente"
              name="patient.fullname"
              defaultValue={watch('patient.fullname')}
              register={() => register('patient.fullname')}
              width="558px"
              error={Boolean(errors?.patient?.fullname)}
              message={
                errors?.patient?.fullname?.message
                  ? translator(errors?.patient?.fullname?.message)
                  : ''
              }
              autoFocus
              onChange={e => {
                setValue('patient.fullname', e.target.value);
              }}
            />
          ) : (
            <Select
              key={stateParams.consultantId}
              name="patient.id"
              id="select_patient"
              label={`${translator('Paciente')}*`}
              onChange={e => {
                setValue('patient.id', Number(e.target.value));
              }}
              defaultValue={watch('patient.id')}
              register={() => register('patient.id')}
              width="558px"
              error={Boolean(errors?.patient?.id)}
              message={
                errors?.patient?.id?.message
                  ? translator(errors?.patient?.id?.message)
                  : ''
              }
              disabled={
                stateParams &&
                stateParams?.consultantId !== undefined &&
                stateParams?.consultantId !== -1
              }
            >
              <option value={-1}>{translator('Selecione um paciente')}</option>
              {consultant.map(consult => (
                <option key={consult.id} value={consult.id}>
                  {`${consult.firstName} ${consult.lastName}`}
                </option>
              ))}
            </Select>
          )}
          {/* Trocar input de date */}
          {watch('situation') === 'NOT-REGISTERED' && (
            <div>
              <ReactInputMask
                mask="99/99/9999"
                name="birthDate"
                className="birthdate"
                defaultValue={state?.birthDate}
                ref={() => register('birthDate')}
                onChange={e => {
                  onChangeDate(e.target.value.trim());
                }}
              >
                <Input
                  gridArea="birthDate"
                  id="registerBirthDate"
                  placeholder={translator('DD/MM/AAAA')}
                  label={translator('Data de Nascimento')}
                  error={Boolean(errors.birthDate)}
                  message={
                    errors?.birthDate?.message
                      ? translator(errors?.birthDate?.message)
                      : ''
                  }
                  required
                />
              </ReactInputMask>
            </div>
          )}
          <Select
            id="select_professional"
            name="patient.gender"
            register={() => register('patient.gender')}
            label="Sexo"
            required
            defaultValue={state?.patient?.gender}
            width="274px"
            onChange={e => {
              setValue('patient.gender', e.target.value);
            }}
          >
            <option value="MALE">Masculino</option>
            <option value="FEMALE">Feminino</option>
          </Select>
        </Grid>
        <Navigator back={onBack} />
      </Form>
    </Container>
  );
};

export default PatientData;
