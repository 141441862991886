/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/button-has-type */
import React, { useCallback, useEffect, useState } from 'react';
import { useToast, ToastId } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { createIntl, createIntlCache } from 'react-intl';

import { getLocale } from '~/utils/getLocale';
import translations from '~/infra/i18n/locales';
import WaitingRoom from './WaitingRoom';
import { ToastNotify } from '~/presentation/components/UI/toastNotify';
import { iStore } from '~/domain/interfaces/models';
import { MessageOptions } from '~/domain/interfaces/redux/message';

import { IconNurse } from '../../base/icons';

import { BodyToast } from './styles/WaitingRoomPageStyled';

import {
  LeftWaitingRoom,
  JoinWaitingRoom,
} from '~/domain/usecases/waitingRoom/socket';

import { ConnectComponent } from './mapper/MapperWaitingRoomLaoha';
import { makeSocketJoinWaitingRoom } from '~/main/factories/usecases/waitingRoom/JoinWaitingRoomFactory';
import { makeReduxUserQueueWaitingRoom } from '~/main/factories/usecases/waitingRoom/UserQueueWaitingRoomFactory';
import { History } from '~/main/routes';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import { GetUserDataWaitingRoom } from '~/domain/usecases/waitingRoom/remote';
import NewWaitingRoom from './NewWaitingRoom';
import { translator } from '~/presentation/components/i18n';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

export interface externalProps {
  medias: GetUserDataWaitingRoom.Model['medias'];
}

export interface ownProps {
  ActionRoom: LeftWaitingRoom;
  notification: {
    status: string;
    userId: number;
    appointmentId?: number;
  };
  waitingRoom?: {
    room: {
      firstName: string;
      lastName: string;
      userId: number;
    }[];
  };
}

interface iStateParams {
  appointmentId: string;
  consultantId: number;
  professionalId: number;
}

const WaitingRoomLaoha: React.FC<ownProps & externalProps> = ({
  ActionRoom,
  notification,
  waitingRoom,
  medias,
}) => {
  const [isStart, setIsStart] = useState(false);
  const modalName = MessageOptions.viewMediaModal;
  const { active } = useSelector((store: iStore) => store.message);
  const toastIdRef = React.useRef<ToastId>();
  const { appointmentId, consultantId, professionalId } =
    useLocation<iStateParams>().state;
  const userLogged = useSelector((store: iStore) => store.auth.info.user);
  const [waitingRoomData, setWaitingRoomData] =
    useState<JoinWaitingRoom.Model>();

  useEffect(() => {
    const room = makeSocketJoinWaitingRoom();

    room.join(
      {
        appointmentId: Number(appointmentId),
        data: {
          role: userLogged!.mainRole!,
          user: userLogged!.id,
          queue: 1,
        },
      },
      (data: JoinWaitingRoom.Model) => {
        console.log('...join waiting room', data);
        data.waitingRoom.users.forEach(el => {
          if (el.id !== userLogged?.id) {
            makeReduxUserQueueWaitingRoom().add({
              data: {
                appointment: parseInt(appointmentId, 10),
                status: 'JOINED',
              },
              from: {
                userId: el.id,
                firstName: el.name,
                lastName: '',
              },
            });
          }
        });
        setWaitingRoomData(data);
      },
    );
  }, []);

  useEffect(() => {
    window.onpopstate = () => {
      leaveWaitingRoom();
      window.onpopstate = null;
    };
  }, [waitingRoomData]);

  useEffect(() => {
    switch (notification?.status) {
      case 'JOINED':
      case 'LEFT':
        if (
          notification.userId !== userLogged?.id &&
          notification.appointmentId === Number(appointmentId)
        ) {
          axios
            .get(
              `${window.config.connection.backendUrl}/api/appointments/${appointmentId}/waitingRooms`,
            )
            .then(res => {
              if (res.data.length) {
                const hasProfessional = Boolean(
                  res.data.find((item: any) => item.role === 'PRO'),
                );

                if (hasProfessional) setIsStart(true);
                else {
                  setIsStart(false);
                  closeAllToasts();
                }
              }
            })
            .catch(err => {
              AlertMessage({
                message: intl.formatMessage({
                  id: 'Não foi possível obter informações da sala de espera, tente novamente',
                }),
                type: 'danger',
              });
            });
        }
        break;
      default:
        break;
    }
  }, [notification]);

  const left = (): void => {
    window.onpopstate = null;

    ActionRoom.left({
      appointmentId: Number(appointmentId),
      waitingRoomUserId: Number(waitingRoomData?.waitingRoomUserId),
    });
    History.push('/appointment');
  };

  const leaveWaitingRoom = useCallback(() => {
    if (waitingRoomData !== undefined) {
      ActionRoom.left({
        appointmentId: Number(appointmentId),
        waitingRoomUserId: Number(waitingRoomData?.waitingRoomUserId),
      });
    }
  }, [waitingRoomData]);

  // Toast

  const toast = useToast({
    containerStyle: {
      width: '758px',
      maxWidth: '100%',
      height: '68px',
    },
    position: 'top',
  });

  const startNotification = useCallback(() => {
    const id = 'start-toast';
    if (toast.isActive('info-toast')) toast.close('info-toast');

    if (!toast.isActive(id) && active === modalName) {
      toastIdRef.current = toast({
        id,
        position: 'top',
        duration: 10000,
        render: () => (
          <ToastNotify icon={IconNurse}>
            <BodyToast>
              <p>
                <b>{translator('O profissional está pronto')}! </b>
                {translator(
                  'Você será redirecionado para a tela pré-consulta.',
                )}
              </p>
            </BodyToast>
          </ToastNotify>
        ),
      });
    }
  }, [active, modalName, toast]);

  const infoNotification = () => {
    if (!toast.isActive('info-toast'))
      toastIdRef.current = toast({
        id: 'info-toast',
        position: 'top',
        duration: 999999,
        render: () => (
          // eslint-disable-next-line react/jsx-no-bind
          <ToastNotify close={closeAllToasts} icon={IconNurse} spin={false}>
            <BodyToast>
              <p>
                Selecionamos estas mídias para você interagir enquanto aguarda
                sua consulta.
              </p>
            </BodyToast>
          </ToastNotify>
        ),
      });
  };

  const startConference = () => {
    History.getHistory().push('/conf', {
      appointmentId: Number(appointmentId),
      waitingRoomUserId: waitingRoomData?.waitingRoomUserId,
    });
  };

  const closeAllToasts = () => {
    toast.closeAll();
  };

  return (
    <NewWaitingRoom
      left={left}
      isStart={isStart}
      startNotification={startNotification}
      infoNotification={infoNotification}
      startConference={startConference}
      closeAllToasts={closeAllToasts}
      medias={medias}
      appointmentId={Number(appointmentId)}
      waitingRoomUserId={waitingRoomData?.waitingRoomUserId}
    />
  );
};

export default ConnectComponent(WaitingRoomLaoha);
