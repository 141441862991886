import React, { useState, useEffect } from 'react';

import { useForm, useFormContext } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useSelector } from 'react-redux';

import { schemaGeneralData } from '~/validation/validators/appointment/UpdateAppointmentValidator';

import { Container, ContainerMain } from './styles/StyledGeneralData';
import { Input, Select } from '../UI';
import { translator } from '../i18n';
import {
  iListSpeciality,
  iListConsultant,
  iListProfessional,
  iUpdateAppointment,
} from './interface';

import { makeReduxSetupProfession } from '~/main/factories/usecases/profession';

import { ConnectComponent } from './mapper/MapperGeneralData';

import { ButtonSave } from './ButtonSave';

import { iStore } from '~/domain/interfaces/models';
import { makeReduxSetShowModal } from '~/main/factories/usecases/showModal/Set';
import {
  makeReduxGetAllAppointmentType,
  makeRemoteGetAppointmentType,
} from '~/main/factories/usecases/appointmentType/GetAppointmentType';
import { GetAppointmentType } from '~/domain/usecases/appointmentType/remote';

export interface externalProps {
  handleChangeEditConsult: Function;
  setValue: Function;
  register: Function;
  watch: Function;
  getValues: Function;
  errors: any;
  state?: iUpdateAppointment;
}

export interface ownProps {
  specialty: iListSpeciality[];
  consultant: iListConsultant[];
  filterProfessional: (id: number | undefined) => iListProfessional[];
}

const GeneralData: React.FC<ownProps & externalProps> = ({
  watch,
  state,
  getValues,
  handleChangeEditConsult,
  specialty,
  consultant,
  filterProfessional,
}): JSX.Element => {
  const [professionId, setProfessionId] = useState(state?.serviceArea);
  const { info } = useSelector((store: iStore) => store.auth);
  const { users } = useSelector((store: iStore) => store.users);
  const { results } = useSelector((store: iStore) => store.professions);

  const { role } = useSelector((states: iStore) => states.auth.selectUser);
  const { appointment } = useSelector((store: iStore) => store.showModal);
  const profession = useSelector(
    (states: iStore) => states.professions.filter?.id,
  );

  const { data: appointmentTypes } = useSelector(
    (store: iStore) => store.appointmentType,
  );

  useEffect(() => {
    setProfessionId(Number(state?.serviceArea));
  }, [state]);

  useEffect(() => {
    makeReduxSetupProfession().setup({
      professionId: Number(state?.serviceArea),
    });
    if (state?.serviceArea) {
      handleChangeEditConsult({
        key: 'serviceArea',
        value: state.serviceArea,
      });
    }
  }, [state?.serviceArea]);

  useEffect(() => {
    makeReduxGetAllAppointmentType().list({});
  }, []);

  const watchSpecialty = watch('specialty');

  // const onSubmit = handleSubmit(data => {
  //   // save(data);
  // });

  const handleChange = (e: any, isNumber = false) => {
    const { name, value } = e.target;
    const valueWithCast = isNumber ? Number(value) : value;

    if (!appointment) makeReduxSetShowModal().set({ appointment: true });

    handleChangeEditConsult({
      key: name,
      value: valueWithCast,
    });
  };

  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <ContainerMain>
      <Container>
        <Select
          key={state?.consultant}
          id="select_appointmentPatient"
          name="consultant"
          defaultValue={state?.consultant}
          label={`${translator('Paciente')}`}
          onChange={e => handleChange(e, true)}
          register={() => register('consultant')}
          error={Boolean(errors.consultant)}
          message={
            errors?.consultant?.message
              ? translator(errors?.consultant?.message)
              : ''
          }
          autoFocus
          disabled={role === 'CON'}
          required
        >
          <option id="option_patient" value={-1}>
            {translator('Selecione um paciente')}
          </option>
          {consultant.map(item => (
            <option id={`${item.id}`} value={Number(item.id)}>
              {`${item.firstName} ${item.lastName}`}
            </option>
          ))}
        </Select>
        <Select
          name="serviceArea"
          id="attendanceArea"
          label={translator('Área do atendimento')}
          placeholder={translator('Selecione a área de atendimento')}
          // defaultValue={professionId}
          register={() => register('serviceArea')}
          value={professionId}
          disabled={role === 'PRO' || role === 'CON'}
          onChange={e => {
            makeReduxSetupProfession().setup({
              professionId: Number(e.target.value),
            });
            handleChange(e, true);
            setProfessionId(Number(e.target.value));
            // setValue('specialty', -1);
          }}
          required
          error={Boolean(errors.serviceArea)}
          message={
            errors?.serviceArea?.message
              ? translator(errors?.serviceArea?.message)
              : ''
          }
        >
          <option value={-1}>
            {translator('Selecione a área de atendimento')}
          </option>
          {results.map(item => (
            <option value={Number(item.profession.id)}>
              {item.profession.name}
            </option>
          ))}
        </Select>
        <Select
          id="select_appointmentSpeciality"
          name="specialty"
          value={state?.specialty}
          onChange={e => handleChange(e, true)}
          register={() => register('specialty')}
          label={`${translator('Especialidade')}`}
          error={Boolean(errors.specialty)}
          message={
            errors?.specialty?.message
              ? translator(errors?.specialty?.message)
              : ''
          }
          required
          disabled={role === 'CON' || role === 'PRO'}
        >
          <option id="option_speciality" value={-1}>
            {translator('Selecione uma especialidade')}
          </option>
          {specialty.map((item, index) => (
            <option id={`option_${index}`} value={Number(item.specialty?.id)}>
              {item.specialty?.name}
            </option>
          ))}
        </Select>
        <Select
          id="select_appointmentProfessional"
          name="professional"
          defaultValue={state?.professional}
          value={state?.professional}
          label={`${translator('Profissional')}*`}
          onChange={e => handleChange(e, true)}
          register={() => register('professional')}
          error={Boolean(errors.professional)}
          message={
            errors?.professional?.message
              ? translator(errors?.professional?.message)
              : ''
          }
          disabled={role === 'CON' || role === 'PRO'}
        >
          <option id="option_professional" value={-1}>
            {translator('Selecione um profissional')}
          </option>
          {filterProfessional(watchSpecialty).map((pro, index) => (
            <option id={`option_${index}`} value={Number(pro.professional.id)}>
              {`${pro.user.firstName} ${pro.user.lastName}`}
            </option>
          ))}
        </Select>
        <Select
          id="select_appointmentType"
          name="type"
          defaultValue={state?.type}
          value={state?.type}
          label={`${translator('Tipo de Atendimento')}*`}
          onChange={e => handleChange(e, true)}
          register={() => register('type')}
          error={Boolean(errors.type)}
          message={errors.type?.message && 'Selecione um tipo de atendimento'}
          disabled={role === 'CON'}
        >
          <option id="option_type" value={-1}>
            {translator('Selecione um tipo')}
          </option>

          {appointmentTypes?.records?.map((item, index) => (
            <option id={`option_${index}`} value={item.id}>
              {translator(item.msgKey)}
            </option>
          ))}
        </Select>

        <Input
          id="input_appointmentTitle"
          name="title"
          defaultValue={state?.title}
          label={`${translator('Título do atendimento')}`}
          onChange={e => handleChange(e, false)}
          register={() => register('title')}
          error={Boolean(errors.title)}
          message={errors.title?.message && 'Digite um título'}
          disabled={role === 'CON'}
        />
      </Container>
      {/* {role === 'CON' ? (
        <ButtonSave action={onSubmit} disable />
      ) : (
        <ButtonSave action={onSubmit} />
      )} */}
    </ContainerMain>
  );
};

export default ConnectComponent(GeneralData);
