/* eslint-disable react/react-in-jsx-scope */
import React, { createRef, useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { iDutyRequesters } from '~/domain/interfaces/models/Duty';
import Switch from '~/presentation/components/UI/Switch';
import { AvatarImage } from '~/presentation/components/avatar-roles';
import { ContainerImage } from '~/presentation/components/avatar-roles/styles/styled';
import { columns } from './columns';
import {
  Container,
  ListHeader,
  ListHeaderTitle,
  Priority,
  ProfessionalBadge,
  ProfessionalBadgeInfo,
  ProfessionalBadgeName,
  ProfessionalBadgeRole,
  ProfessionalBadgeStatus,
  SwitchButtonLabel,
  Table,
  TableContainer,
  Td,
  Th,
  Tr,
} from './styles';
import { makeRemoteChangeProfessionalStatusOnCall } from '~/main/factories/usecases/onCall/ChangeProfessionalStatusOnCallFactory';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import { closeModal } from '~/utils/closeModal';
import { makeReduxSetSpecialistStatusOnCall } from '~/main/factories/usecases/onCall/SetSpecialistStatusOnCallFactory';

type Row = iDutyRequesters['dutyRequesters']['records'][0];

type SelectedRequester = Row['order'] | null;

type PatientDutyListProps = {
  selectedRequester: SelectedRequester;
  setSelectedRequester: (requester: SelectedRequester) => void;
};

const PatientDutyList: React.FC<PatientDutyListProps> = ({
  selectedRequester,
  setSelectedRequester,
}) => {
  const switchRef = createRef<HTMLInputElement>();
  // const { records } = useSelector((store: iStore) => store.duty);
  const auth = useSelector((store: iStore) => store.auth.info);
  const { role, orgId, orgUnitId } = useSelector(
    (store: iStore) => store.auth.selectUser,
  );
  const professional = auth.professionals?.find(
    item => item.orgUnit.id === orgUnitId,
  );

  const { available } = useSelector((store: iStore) => store.onCall);
  const { dutyRequesters } = useSelector((store: iStore) => store.duty);
  const { state } =
    useLocation<{ specialty: string; id: string; leaveConf: boolean }>();

  useEffect(() => {
    makeReduxActiveMessage().active({
      active: 'isProfessionalAvailable',
      actionOk: () => {
        handleStatus(false);
        closeModal();
      },
      data: { leaveConf: !!state?.leaveConf, specialty: state?.specialty },
      actionCancel: () => {
        closeModal();
      },
    });
  }, [state]);

  const handleStatus = (status: boolean) => {
    if (professional)
      makeRemoteChangeProfessionalStatusOnCall()
        .changeProfessionalStatus({
          onCallId: Number(state?.id),
          specialistId: professional?.id,
          body: { status: !status ? 'AVAILABLE' : 'PAUSED' },
        })
        .then(() =>
          makeReduxSetSpecialistStatusOnCall().setSpecialistStatus({
            available: !status,
          }),
        );
  };

  return (
    <Container>
      <ListHeader>
        <ListHeaderTitle>Solicitações de interconsulta</ListHeaderTitle>
        <SwitchButtonLabel>
          {available ? 'Estou disponível' : 'Estou indisponível'}
          <Switch
            id="switch__available"
            ref={switchRef}
            checked={available}
            onChange={() => {
              handleStatus(available);
            }}
          />
        </SwitchButtonLabel>
      </ListHeader>
      <TableContainer>
        <Table>
          <thead>
            <tr>
              {columns.map(column => (
                <Th key={column.id}>{column.label}</Th>
              ))}
            </tr>
          </thead>
          <tbody>
            {dutyRequesters?.records?.map(row => (
              <TableRowItem
                key={row.order}
                row={row}
                active={row.order === selectedRequester}
                onClick={() => setSelectedRequester(row.order)}
              />
            ))}
          </tbody>
        </Table>
      </TableContainer>
    </Container>
  );
};

type TableRowItemProps = {
  row: Row & { active?: boolean };
  active?: boolean;
  onClick: () => void;
};

const TableRowItem: React.FC<TableRowItemProps> = ({ row, ...rest }) => {
  const { requester } = row;
  const hasLocal = requester.org.city || requester.org.province;
  const locality = hasLocal
    ? `${requester.org.city}-${requester.org.province}`
    : 'Não informado';

  const created = Intl.DateTimeFormat('pt-BR', {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  })
    .format(new Date(requester.created))
    .replace(', ', ', às ');

  const priorityMap = (priority: string) => {
    switch (priority) {
      case 'EMERGENCY':
        return 'Alta';

      case 'NORMAL':
        return 'Média';

      case 'LOW':
        return 'Baixa';

      default:
        return 'Baixa';
    }
  };

  return (
    <Tr {...rest}>
      <Td>
        <ProfessionalBadge>
          <ContainerImage id="profile">
            <AvatarImage
              size="root"
              name={requester.name}
              src={requester.avatar}
            />
          </ContainerImage>
          <ProfessionalBadgeInfo>
            <ProfessionalBadgeName>{requester.name}</ProfessionalBadgeName>
            <ProfessionalBadgeRole>
              {requester.specialty.name}
            </ProfessionalBadgeRole>
          </ProfessionalBadgeInfo>
        </ProfessionalBadge>
      </Td>
      <Td>{requester.org.descr}</Td>
      <Td>
        <Priority priority={requester.priority}>
          <div className="status" />
          {priorityMap(requester.priority)}
        </Priority>
      </Td>
      <Td>{created}</Td>
      <Td>
        <ProfessionalBadgeStatus status={requester.status}>
          {requester.status === 'WAITING' ? 'Aguardando' : 'Em atendimento'}
        </ProfessionalBadgeStatus>
      </Td>
    </Tr>
  );
};

export default PatientDutyList;
