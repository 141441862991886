import React from 'react';

import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { Navigator } from '../register/Navigator';

import { Container, Form, Text, Period } from './styles/StyledCertificate';
import { Input } from '~/presentation/components/UI';
import { translator } from '~/presentation/components/i18n';
import { schema } from '~/validation/validators/document/MedicalCertificatePrescriptionContentValidator';

import { iCreateMedicalCertificate } from './interface';

export interface ownProps {
  next: (data: iCreateMedicalCertificate) => any;
  back: (data: iCreateMedicalCertificate) => any;
  state?: iCreateMedicalCertificate;
}

const Certificate: React.FC<ownProps> = ({ next, state, back }) => {
  console.log('## step 3: ', state);
  const { errors, handleSubmit, register, setValue, getValues } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    resolver: zodResolver(schema),
    defaultValues: {
      ...state,
    },
  });

  const onSubmit = handleSubmit(data => {
    next({ ...data });
  });

  const onBack = () => back({ ...(getValues() as iCreateMedicalCertificate) });

  return (
    <Container>
      <Form onSubmit={onSubmit}>
        <Period>
          <Input
            id="input_periodo"
            name="periodo"
            defaultValue={state?.periodo}
            label={`${translator('Tempo de afastamento')}`}
            onChange={e => {
              setValue('periodo', e.target.value);
            }}
            register={() => register('periodo')}
            error={Boolean(errors.periodo)}
            message={
              errors?.periodo?.message
                ? translator(errors?.periodo?.message)
                : ''
            }
            autoFocus
          />
        </Period>

        <Text>
          <p>{`${translator('Observações')}`}</p>
          <textarea
            id="text_area_observacoes"
            name="observacoes"
            ref={register}
            placeholder={`${translator('Motivo do afastamento')}`}
            defaultValue={state?.observacoes}
            onChange={e => {
              setValue('observacoes', e.target.value);
            }}
          />
        </Text>

        <Navigator back={onBack} />
      </Form>
    </Container>
  );
};

export default Certificate;
