/* eslint-disable react/jsx-one-expression-per-line */
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { iStore } from '~/domain/interfaces/models';
import { CreateClinicalDocs as ReduxCreateClinicalDocs } from '~/domain/usecases/clinicalDocs/redux/CreateClinicalDocs';
import { CreateClinicalDocs as RemoteCreateClinicalDocs } from '~/domain/usecases/clinicalDocs/remote/CreateClinicalDocs';
import { makeReduxCreateClinicalDocs } from '~/main/factories/usecases/clinicalDocs/CreateClinicalDocsFactory';
import { translator } from '~/presentation/components/i18n';
import { Signature } from '~/presentation/components/signature';
import { ContentSignature } from '~/presentation/components/signature/styles/StyledSignature';
import { Button } from '~/presentation/components/UI';
import { History } from '~/routes';
import { schema } from '~/validation/validators/document/CreateClinicalDocValidator';
import Request  from './phases/Request';
import PacientData from './phases/PacientData';
import GeneralData, { ownProps as GeneralDataProps } from './phases/GeneralData';
import { iCreateExamRequest } from './interface';
import { ConnectComponent } from './mapper/MapperExamRequestSideBar';
import {
  Container,
  ContainerButton,
  Content,
  Header,
  Main,
  Phases,
} from './styles/StyledExamRequestSideBar';
import { makeRemoteGetClinicalDocs } from '~/main/factories/usecases/clinicalDocs/GetClinicalDocsFactory';
import { makeRemoteCreateForeignClinicalDocs } from '~/main/factories/usecases/clinicalDocs/CreateForeignClinicalDocsFactory';
import { CreateForeignClinicalDocs } from '~/domain/usecases/clinicalDocs/remote';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import storeDev from '~/data/store';
import { MessageOptions } from '~/domain/interfaces/redux/message';

export interface externalProps {
  examRequest?: ReduxCreateClinicalDocs | RemoteCreateClinicalDocs;
  goBackToListDocument: () => void;
}

interface iStateParams {
  appointmentId: string;
  professionalId: number;
  consultantId: number;
}

export type ownProps = GeneralDataProps;

const ExamRequestSideBar: React.FC<ownProps & externalProps> = props => {
  const [phases, setPhases] = useState<number>(1);
  const [disableButton, setDisableButton] = useState<boolean>(false);

  const stateParams = useLocation<iStateParams>().state;
  const { auth } = useSelector((store: iStore) => store);
  const { loading } = useSelector((store: iStore) => store.clinicalDocs);
  const { role, orgUnitId, orgId } = useSelector(
    (store: iStore) => store.auth.selectUser,
  );

  const orgUnitName = orgUnitId
    ? auth.info.professionals?.find(item => item.orgUnit.id === orgUnitId)
        ?.orgUnit.name
    : '';

  const specialty =
    role === 'PRO'
      ? auth.info.professionals?.find(item => item.orgUnit.id === orgUnitId)
          ?.professions.specialties[0].id
      : -1;

  const [exam, setExam] = useState<iCreateExamRequest>({
    professional:
      stateParams && stateParams.professionalId
        ? Number(stateParams.professionalId)
        : -1,
    patient: {
      id: stateParams && stateParams?.consultantId
        ? Number(stateParams?.consultantId)
        : undefined,
        fullname: props.state?.patient?.fullname ?? '',
        gender: props.state?.patient?.gender ?? '',
        age: props.state?.patient?.age ?? -1,
    },
    orgUnitName,
    org: orgId,
    situation: props.state?.situation ?? 'REGISTERED',
  });

  const { goBackToListDocument } = props;

  let dataToSend: RemoteCreateClinicalDocs.Params = {};

  const CreateExamRequest = () => {
    if (stateParams && stateParams.appointmentId !== undefined) {
      dataToSend = {
        appointment:
          stateParams && stateParams.appointmentId
            ? Number(stateParams.appointmentId)
            : undefined,

        type: 3,
        content: {
          solicitacao: [
            {
              exam: exam.exam,
            },
          ],
          indicacaoClinica: exam.indicacaoClinica,
        },
        signatureSource: exam?.signatureSource ?? undefined,
        certificate:
          exam?.signatureSource === 'VIDAAS' ? undefined : exam.certificate,
        certificateId:
          exam?.signatureSource === 'VIDAAS' ? undefined : exam.certificateId,
        sidebar: true,
      };
    } else {
      dataToSend = {
        type: 3,
        content: {
          solicitacao: [
            {
              exam: exam.exam,
            },
          ],
          indicacaoClinica: exam.indicacaoClinica,
        },
        org: orgId,
        orgUnit: orgUnitId,
        patient:
          stateParams && stateParams.consultantId
            ? Number(stateParams.consultantId)
            : Number(exam.patient),
        professional:
          stateParams && stateParams.professionalId
            ? Number(stateParams.professionalId)
            : exam.professional,
        certificate:
          exam?.signatureSource === 'VIDAAS' ? undefined : exam.certificate,
        certificateId:
          exam?.signatureSource === 'VIDAAS' ? undefined : exam.certificateId,
        specialty,
        signatureSource: exam?.signatureSource ?? undefined,
        sidebar: true,
      };
    }
    try {
      if (exam?.signatureSource === 'VIDAAS') {
        if (exam.situation === 'NOT-REGISTERED') {
          makeRemoteCreateForeignClinicalDocs()
            .createForeign({
              body: {
                type: 3,
                certificate:
                  exam?.signatureSource === 'VIDAAS'
                    ? undefined
                    : exam.signatureSource,
                content: {
                  indicacaoClinica: exam.indicacaoClinica,
                  solicitacao: [
                    {
                      exam: exam.exam ?? '',
                    },
                  ],
                },
                patient: {
                  age: Number(exam?.patient?.age),
                  fullname: exam?.patient?.fullname ?? '',
                  gender: exam?.patient?.gender as CreateForeignClinicalDocs.Params['body']['patient']['gender']
                },
                signatureSource: exam.signatureSource ?? undefined,
                org: orgId,
                orgUnit: orgUnitId ?? undefined,
                professional: exam.professional,
                specialty,
              },
            })
            .catch(e => {
              console.log('##error: ', e);
            })
            .then(res => {
              storeDev.dispatch({
                type: MessageOptions.clinicalDocsCreateSuccess,
              });
              makeRemoteGetClinicalDocs(
                ).get({ docId: Number(res?.id) }).then(response => {
                  const url = response?.dataToSign;
                  localStorage.setItem('clinicalDocId', String(response?.id));
                  window.open(url, '_blank');
                  // window.location.href = url;
                }
              ).catch(e => {
                console.log('##error: ', e);
              });
              console.log('##res: ', res);
            });
          return;
        }

        makeReduxCreateClinicalDocs().create({
          ...dataToSend,
          signDocWithVidaas: (id: number) => {
            makeRemoteGetClinicalDocs()
              .get({ docId: id })
              .then(res => {
                const url = res?.dataToSign;
                localStorage.setItem('clinicalDocId', String(id));
                window.open(url, '_blank');
                // History.goBack();
              })

              .catch(e => console.log(e));
          },
        });
      } else {
        if (exam.situation === 'NOT-REGISTERED') {
          makeRemoteCreateForeignClinicalDocs()
            .createForeign({
              body: {
                type: 3,
                certificate:
                  exam?.signatureSource === 'VIDAAS'
                    ? undefined
                    : exam.signatureSource,
                content: {
                  indicacaoClinica: exam.indicacaoClinica,
                  solicitacao: [
                    {
                      exam: exam.exam ?? '',
                    },
                  ],
                },
                patient: {
                  age: Number(exam?.patient?.age),
                  fullname: exam?.patient?.fullname ?? '',
                  gender: 'MALE',
                },
                signatureSource: undefined,
                org: orgId,
                orgUnit: orgUnitId ?? undefined,
                professional: exam.professional,
                specialty,
              },
            })
            .then(res => {
              console.log('##res: ', res);
              AlertMessage({
                message: 'Documento criado com sucesso',
                type: 'success',
              });
              console.log('##res: ', res);
            })
            .catch(err => {
              console.error(err);
            });

          return;
        }

        makeReduxCreateClinicalDocs().create({
          ...dataToSend,
          goBackToListDocument,
        });
      }
    } catch (e) {
      console.error('erro ao enviar para o back', e);
    }
  };

  const data = {
    isSigned: 'Assinado',
    name: 'Danilo',
  };

  function next(dados: iCreateExamRequest) {
    setExam({ ...exam, ...dados });
    handleBar(1);
  }

  function back(dados: iCreateExamRequest) {
    setExam({ ...exam, ...dados });
    handleBar(-1);
  }

  function handleBar(number: number) {
    if (phases + number > 5 || phases + number < 1) {
      setPhases(phases);
    } else {
      setPhases(phases + number);
    }
  }

  const phaseStatus = useMemo(() => {
    const phaseMap: { [key: number]: string } = {
      1: `${translator("Informações gerais")}`,
      2: `${translator("Dados do Paciente")}`,
      3: `${translator("Requisição")}`,
      4: `${translator("Assinatura do documento")}`,
    };
    return `${translator("Etapa")} ${phases} ${translator("de")} ${Object.entries(phaseMap).length}: ${phaseMap[Number(phases)]}`;
  } , [phases]);

  return (
    <Container>
      <Header>Requisição de exames</Header>
      <Content>
        <Main>
          <Phases>{phaseStatus}</Phases>
          {phases === 1 && (
            <GeneralData
              next={next}
              state={exam}
              consultant={props.consultant}
              professional={props.professional}
            />
          )}
          
          {phases === 2 && (
          <PacientData
            next={next}
            back={back}
            state={exam}
            consultant={props.consultant}
            professional={props.professional}
          />
       )}
          {phases === 3 && <Request state={exam} next={next} back={back} />}
          {phases === 4 && (
            <ContentSignature>
              <Signature
                changeSelected={(e: {
                  signatureSource: string | undefined;
                  certificateId: string;
                  certificate: string;
                }) =>
                  setExam({
                    ...exam,
                    certificate: e.certificate,
                    certificateId: e.certificateId,
                    signatureSource: e.signatureSource,
                  })
                }
                changeDisabled={(stat: boolean) => setDisableButton(stat)}
                onSignSubmit={CreateExamRequest}
              />
            </ContentSignature>
          )}
        </Main>
        {phases === 4 ? (
          <ContainerButton>
            <Button
              id="btn_prev_step"
              rounded
              variant="secundary"
              onClick={() => handleBar(-1)}
              size="small"
            >
              {`${translator('Voltar')}`}
            </Button>
            <Button
              id="btn_next_step"
              rounded
              size="small"
              onClick={CreateExamRequest}
              disabled={loading || disableButton}
            >
              {`${translator('Concluir')}`}
            </Button>
          </ContainerButton>
        ) : null}
      </Content>
    </Container>
  );
};

export default ConnectComponent(ExamRequestSideBar);
