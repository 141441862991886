/* eslint-disable react/jsx-curly-brace-presence */
import React, { useMemo, useState } from 'react';
import { iMessage } from '~/domain/interfaces/models/Message';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import { IsProfessionalAvailableAsset } from '~/presentation/base/icons';
import { Container, ContainerModal } from './styles/styles';
import ModalInterconsult from '../modalInterconsult';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import { makeReduxSetSpecialistStatusOnCall } from '~/main/factories/usecases/onCall/SetSpecialistStatusOnCallFactory';

interface iStateParams {
  leaveConf: boolean;
}

interface propsModalIsProfessionalAvailable {
  message: iMessage;
}

const ModalIsProfessionalAvailable: React.FC<propsModalIsProfessionalAvailable> =
  ({ message }) => {
    const [professionalSpecialty, setProfessionalSpecialty] =
      useState('Cardiologia');

    const { active, actionOk, actionCancel, data } = message;

    const handleCancel = () => {
      actionCancel?.();
    };

    const onSubmit = () => {
      actionOk?.();
    };

    const renderSubtitle = useMemo(() => {
      return data?.leaveConf
        ? `Sua última consulta foi concluída e você está retornando ao plantão de ${data?.specialty}.
     Quer ficar disponível para um próximo atendimento?`
        : `Bem-vindo ao plantão de ${data?.specialty}. Quer ficar disponível para um próximo atendimento?`;
    }, [data]);

    const msgName = MessageOptions.isProfessionalAvailable;
    return (
      <>
        {msgName === active && (
          <ContainerModal>
            <Container>
              <ModalInterconsult
                icon={<IsProfessionalAvailableAsset />}
                title="Verifique sua disponibilidade"
                description={renderSubtitle}
                titleOk="Sim, já estou disponível"
                onOk={() => onSubmit()}
                titleCancel="Não, informarei manualmente"
                onCancel={() => handleCancel()}
              />
            </Container>
          </ContainerModal>
        )}
      </>
    );
  };

export default ModalIsProfessionalAvailable;
