import styled, { css } from 'styled-components';

interface ContainerProps {
  width: string;
}

interface InputContainerProps {
  height: string;
  focus: boolean;
  disabled: boolean | undefined;
  error: boolean;
}

interface MessageProps {
  error: boolean;
}

export const Container = styled.div<ContainerProps>`
  width: ${props => props.width};
  display: flex;
  align-items: center;
  margin-top: 4px;
  gap: 5px;
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;

  border-radius: 4px;
  font-weight: lighter;
  padding-left: 16px;
  border: 1px solid #bfc4ca;
  background: #ffffff;
`;

export const Label = styled.span`
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #444a51;
`;

export const InputContainer = styled.span<InputContainerProps>`
  display: flex;
  align-items: center;
  height: ${props => props.height};
  border: 1px solid;
  border-radius: 4px;
  border-color: ${props => (props.focus ? '#106bef' : '#bfc4ca')};

  input {
    font-family: inherit;
    width: 18px;
    height: 18px;
    border: none;
    background-color: transparent;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #444a51;
    padding-left: 16px;

    ::placeholder {
      color: #bfc4ca;
    }

    :focus {
      #spanfocus {
        border-color: #106bef;
      }
    }
  }

  .icon {
    margin-right: 13px;
  }

  :hover {
    border-color: #106bef;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: #fafafb;

      :hover {
        border-color: #bfc4ca;
      }
    `}

  ${({ error }) =>
    error &&
    css`
      border-color: #ea3e4f;

      :hover {
        border-color: #ea3e4f;
      }
    `}
`;

export const Message = styled.span<MessageProps>`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #7b8591;

  ${({ error }) =>
    error &&
    css`
      color: #ea3e4f;
    `}
`;

export const LinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const HighlightLink = styled.div`
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;

  cursor: pointer;
  color: #005bc8;
`;
