import React, { useMemo, useState } from 'react';
import { MessageOptions } from '~/domain/interfaces/redux/message';

import {
  Container,
  ContainerModal,
} from './styles/StyledModalLeaveInterconsult';
import ModalInterconsult from '../modalInterconsult';
import { IconLeaveInterconsult } from '~/presentation/base/icons';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import { iMessage } from '~/domain/interfaces/models';

interface ownProps {
  message: iMessage;
}

const ModalLeaveInterconsult: React.FC<ownProps> = ({ message }) => {
  const [professionalSpecialty, setProfessionalSpecialty] =
    useState('Cardiologia');

  const { active, actionOk, actionCancel, data } = message;

  const handleCancel = () => {
    actionCancel?.();
  };

  const onSubmit = () => {
    actionOk?.();
  };

  const renderSubtitle = useMemo(() => {
    return `${
      data?.type === 'specialist'
        ? 'Se sair agora, não será mais possível atender às solicitações de outros profissionais.'
        : 'Se sair agora, os profissionais não poderão atender à sua solicitação.'
    } Tem certeza de que quer deixar o plantão de ${data?.specialty}?`;
  }, [data]);

  const msgName = MessageOptions.leaveInterconsult;
  return (
    <>
      {msgName === active && (
        <ContainerModal>
          <Container>
            <ModalInterconsult
              icon={<IconLeaveInterconsult />}
              title="Confirme se quer sair do plantão"
              description={renderSubtitle}
              titleOk="Sim, quero sair"
              onOk={() => onSubmit()}
              titleCancel="Não, quero permanecer aqui"
              onCancel={() => handleCancel()}
            />
          </Container>
        </ContainerModal>
      )}
    </>
  );
};

export default ModalLeaveInterconsult;
