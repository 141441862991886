import React, { useEffect, useRef, useState } from 'react';

import {
  Container,
  IconBox,
  Box,
  Title,
  SubTitle,
  Divider,
  Label,
  Required,
  Content,
  CopyLink,
} from './styles';
import { History } from '~/main/routes';
import {
  IconCloseGreen,
  IconCopyInterconsulta,
} from '~/presentation/base/icons';
import Input from '../UI/input';
import { Button } from '../UI';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import { iMessage } from '~/domain/interfaces/models';
import { ModalContainer } from '../instantAppointmentModal/styles';
import { closeModal } from '~/utils/closeModal';

type Props = {
  message: iMessage;
};

const InstantAppointmentSuccessModal: React.FC<Props> = ({ message }) => {
  const [copy, setCopy] = useState(false);
  const { active, actionOk, actionCancel, url, title, mimeType, link, data } =
    message;

  const modalName = MessageOptions.instantConsultationSuccess;
  const isOpen = active === modalName;

  const inputRef = useRef(null);

  const handleCopyLink = () => {
    if (link) {
      navigator.clipboard.writeText(link);
      setCopy(true);
    }
  };

  const redirectToConference = () => {
    History.getHistory().push(`/conf`, {
      appointmentId: data.appointmentId,
      professionalId: data.professional,
    });
    closeModal();
  };

  useEffect(() => {
    setTimeout(() => {
      setCopy(false);
    }, 3000);
  }, [copy]);

  return isOpen ? (
    <ModalContainer>
      <Container>
        <Box>
          <IconBox>
            <IconCloseGreen />
          </IconBox>
          <Title>Consulta instantânea criada com sucesso.</Title>
          <SubTitle>
            Envie o link abaixo para o profissional que estará com você no
            atendimento.
          </SubTitle>
        </Box>
        <Divider />
        <Content>
          <div>
            <div style={{ display: 'flex', marginBottom: 10 }}>
              <Label>Link para o profissional</Label>
              <Required>*</Required>
            </div>
            <Input height="40px" width="327px" value={link} disabled />
          </div>
          <CopyLink onClick={handleCopyLink} disabled={copy}>
            {copy ? (
              <IconCloseGreen height={17} width={17} />
            ) : (
              <IconCopyInterconsulta />
            )}
            {copy ? 'Copiado!' : 'Copiar link'}
          </CopyLink>
        </Content>
        <Divider />
        <Box
          style={{
            backgroundColor: '#F8F8F8',
            borderBottomLeftRadius: 16,
            borderBottomRightRadius: 16,
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              // justifyContent: 'space-between',
              gap: 16,
            }}
          >
            <Button
              variant="secundary"
              type="button"
              style={{ width: '233px' }}
              onClick={closeModal}
            >
              Fechar
            </Button>
            <Button
              type="button"
              style={{ width: '233px' }}
              onClick={redirectToConference}
            >
              Entrar na interconsulta
            </Button>
          </div>
        </Box>
      </Container>
    </ModalContainer>
  ) : null;
};

export default InstantAppointmentSuccessModal;
