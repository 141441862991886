import { array, object, string, number, enum as a, literal } from 'zod';

export const schemaContent = object({
  incrementalId: number().optional(),
  professionals: array(
    object({
      id: number().optional(),
      cardId: number().optional(),
      name: string().nonempty({ message: 'Digite o nome do profissional.' }),
      specialty: string().nonempty({ message: 'Digite a sua especialidade.' }),
      specialtyId: number().optional(),
      email: string().email().optional().or(literal('')),
      phone: string()
        .max(20)
        .min(8, { message: 'Digite o contato do WhatsApp.' })
        .optional()
        .or(literal('')),
      type: a(['INTERNAL', 'EXTERNAL']),
    }),
  )
    .min(1, {
      message: 'É preciso adicionar pelo menos um profissional interno.',
    })
    .refine(
      item => {
        const found = item.find(participant => participant.type === 'INTERNAL');

        return Boolean(found);
      },
      { message: 'É preciso adicionar pelo menos um profissional interno.' },
    ),
  orgUnitId: number().optional(),
});

export const schemaProfessionalExternal = object({
  name: string().nonempty({ message: 'Digite o nome do profissional.' }),
  specialty: string().nonempty({ message: 'Digite a especialidade.' }),
  email: string()
    .email({ message: 'Digite o e-mail do profissional.' })
    .nonempty(),
  phone: string()
    .max(20)
    .min(8, { message: 'Digite o contato do WhatsApp.' })
    .optional()
    .or(literal('')),
  type: string().optional(),
});
