export const columns = [
  {
    id: 'professional',
    label: 'Profissional solicitante',
  },
  {
    id: 'org',
    label: 'Unidade de Saúde',
  },
  {
    id: 'priority',
    label: 'Prioridade',
  },
  {
    id: 'date',
    label: 'Solicitado em',
  },
  {
    id: 'status',
    label: 'Status',
  },
];
