/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import {
  CardInfoContainerScroll,
  Container,
  DataContainer,
} from './styles/StyledConsultationData';
import ConsultDetailFragment from './ConsultDetailFragment';
import ConsultDetailCard from './ConsultDetailCard';
import { AppointmentDetail } from '../../roles/professional/pages/appointment/interface';

interface ownProps {
  infos: AppointmentDetail;
}

const ConsultationData: React.FC<ownProps> = ({ infos }) => {
  const assembleRegValue = (regValue: string, regType: string) => {
    if (regValue && regType) {
      return `${regValue} - ${regType}`;
    }
    return '';
  };

  return (
    <Container>
      <DataContainer>
        <ConsultDetailCard title="Profissional">
          <ConsultDetailFragment
            label="Nome"
            content={infos.participants.professional.name}
          />
          <ConsultDetailFragment
            label="Registro no conselho"
            content={infos.participants.professional.recordAdvice}
          />
          <ConsultDetailFragment
            label="Especialidade"
            content={infos.participants.professional.specialty}
          />
          <ConsultDetailFragment
            label="Prioridade"
            content={infos.priority?.descr}
          />
        </ConsultDetailCard>

        <ConsultDetailCard title="Profissional convidado">
          {infos?.participants?.otherProfessionals &&
            infos?.participants?.otherProfessionals?.map(professionals => {
              return (
                <>
                  <ConsultDetailFragment
                    label="Nome"
                    content={professionals.name}
                  />
                  <ConsultDetailFragment
                    label="Registro no conselho"
                    content={assembleRegValue(
                      professionals.regValue || '',
                      professionals?.regType || '',
                    )}
                  />
                  <ConsultDetailFragment
                    label="Unidade de Saúde"
                    content={professionals.unit}
                  />
                </>
              );
            })}
        </ConsultDetailCard>

        <ConsultDetailCard title="Quadro Clinico">
          <CardInfoContainerScroll>
            {infos.session.clinicalCondition ?? ''}
          </CardInfoContainerScroll>
        </ConsultDetailCard>

        <ConsultDetailCard title="Encaminhamento do atendimento">
          <CardInfoContainerScroll>
            {infos.session?.forwarding ?? ''}
          </CardInfoContainerScroll>
        </ConsultDetailCard>
      </DataContainer>
    </Container>
  );
};

export default ConsultationData;
